import React, { useEffect, useState } from 'react';
import { MainNavbar, SubHeading, SubFooter, FormInput } from '../../components';
import { images, data } from '../../constants';
import { motion, useScroll, useSpring } from 'framer-motion';
import { useNavigate, useLocation } from 'react-router-dom';
import { Form, Drawer, Button, message } from 'antd';

const formlabel = '#a0a0a0';
const inputlabel = '#257a80';

const OpenCouponScreen = ({
  onPostPartnerReg,
  onPostPartnerCoupon,
  sendApp,
  infoDisplay,
  logPage,
}) => {
  // State for storing partner input data
  const [partnerData, setPartnerData] = useState({
    companyId: '',
    couponCode: '',
    customMsg: '',
  });

  // State for displaying the preview
  const [open, setOpen] = useState(false);
	const [email, setEmail] = useState('');
	const [companyName, setCompanyName] = useState('');
  const [houseNo, setHouseNo] = useState('');
  const [locationAddress, setLocationAddress] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
	const [zip, setZip] = useState('');
  const location = useLocation();

  useEffect(() => {
    document.body.style.overflow = 'auto';
  }, []);

  useEffect(() => {
    const ref = document.referrer;
    const loc = location.pathname;
    logPage(ref, loc);
  }, []);

  // Function to handle form submission
  const registerPartner = () => {
		if (!email) {
      message.error('Email is required');
      return false;
    }
    if (!companyName) {
      message.error('Business name is required');
      return false;
    }
    if (!locationAddress) {
      message.error('Street name is required');
      return false;
    }
    if (!city) {
      message.error('City is required');
      return false;
    }
    if (!state) {
      message.error('State is required');
      return false;
    }
    if (!zip) {
      message.error('State is required');
      return false;
    }

    let output = {
      rtype: 'regpartner',
			email: email,
			companyName: companyName,
      address: houseNo + ' ' + locationAddress,
      city: city,
      state: state,
			zip: zip
    };
    onPostPartnerReg(output);
  };

  // Function to handle form submission
  const handleSubmit = (e) => {
    let state = document.getElementById('status');
    state.style.display = 'none';
    state.className = '';
    state.innerHTML = '';

    if (!partnerData.companyId) {
      state.style.display = 'block';
      state.className = 'cus_error center show';
      state.innerHTML = '<b>Partner Id is Required</b>';
      return;
    } else if (!partnerData.couponCode) {
      state.style.display = 'block';
      state.className = 'cus_error center show';
      state.innerHTML = '<b>Coupon code is Required</b>';
      return;
    } else if (partnerData.customMsg && partnerData.customMsg.length > 100) {
      state.style.display = 'block';
      state.className = 'cus_error center show';
      state.innerHTML = '<b>Message length must no be greater than 100</b>';
      return;
    }

    let output = { ...partnerData, rtype: 'partnercoupon' };
    onPostPartnerCoupon(output);
  };

  const navigate = useNavigate();
  const { scrollYProgress } = useScroll();
  const scaleX = useSpring(scrollYProgress, {
    stiffness: 100,
    damping: 30,
    restDelta: 0.001,
  });

  const handleLocationAddress = async (value) => {
    setLocationAddress(value);
  };

  const getHeader = () => {
    return 'Business Information';
  };

  const getButton = () => {
    return [
      <Button className="c__button" onClick={() => registerPartner()}>
        Submit
      </Button>,
    ];
  };

  return (
    <>
      <MainNavbar sendApp={sendApp} infoDisplay={infoDisplay} />
      <motion.div className="progress-bar" style={{ scaleX }} />
      <div className="app__header app__wrapper section__padding notfound__bg">
        <motion.div
          initial={{ opacity: 0, x: -200 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: -200 }}
          className="app__wrapper_info"
        >
          <SubHeading title="Free Coupon for your Customers" />
          <h1 className="app__header-h1">Partner with Us</h1>
          <p
            className="p__mont"
            style={{ margin: '2rem 0' }}
          >{`Collaborate with Us to Deliver Tempting Coupons to Your Valued Customers. ErrandLocal Offers Complimentary Errand Services for Your Customer Base.`}</p>

          <p
            className="p__mont"
            style={{ margin: '0 0 2rem' }}
          >{`Do you own a restaurant?`}</p>
          <span
            onClick={() => window.open('https://get.errandlocal.com', '_self')}
          >
            <button type="button" className="custom__button">
              Become a merchant
            </button>
          </span>
        </motion.div>
        <div className="app__wrapper_img" id="apply">
          <motion.div
            initial={{ opacity: 0, x: 200 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: 200 }}
            className="form"
            autoComplete="off"
            style={{ maxWidth: 450 }}
          >
            <header>
              <h1 className="form-header">Create Coupon</h1>
            </header>

            <div style={{ width: '100%' }}>
              <span>Sample Coupon</span>
              <img
                src={images.couponsample}
                alt="couponsample"
                style={{ width: '100%' }}
              />
            </div>

            <div style={{ width: '100%', marginTop: 20 }}>
              <span>
                No Partner Id?{' '}
                <span onClick={()=> setOpen(true)} style={{ color: inputlabel, cursor: 'pointer' }}>
                  Get a partner Id
                </span>
              </span>
            </div>

            <FormInput
              label="Partner Id *"
              placeholder=""
              inputType="input"
              inputId="partnerId"
              onChange={(e) =>
                setPartnerData({
                  ...partnerData,
                  companyId: e.target.value,
                })
              }
              value={partnerData.companyId}
							maxLength={5}
              containerStyle={{
                marginTop: 15,
                width: '100%',
              }}
              inputContainerStyle={{
                backgroundColor: '#ffffff',
                width: '100%',
              }}
              inputLabelStyle={{
                color: formlabel,
              }}
              inputClass={'cus_input'}
              inputStyle={{ color: inputlabel }}
            />

            <FormInput
              label="Coupon Code * [Preferred code between 5-10 in length]"
              placeholder=""
              inputType="input"
              inputId="couponCode"							
              onChange={(e) =>
                setPartnerData({
                  ...partnerData,
                  couponCode: e.target.value,
                })
              }
              value={partnerData.couponCode}
              maxLength={10}
              containerStyle={{
                marginTop: 15,
                width: '100%',
              }}
              inputContainerStyle={{
                backgroundColor: '#ffffff',
                width: '100%',
              }}
              inputLabelStyle={{
                color: formlabel,
              }}
              inputClass={'cus_input'}
              inputStyle={{ color: inputlabel }}
            />
            <FormInput
              label={`Custom Message [Rem: ${100 - partnerData.customMsg.length}][optional]`}
              placeholder=""
              inputType="textarea"
              inputId="customMsg"
              onChange={(e) =>
                setPartnerData({
                  ...partnerData,
                  customMsg: e.target.value,
                })
              }
              value={partnerData.customMsg}
              maxLength={100}
              containerStyle={{
                marginTop: 15,
                width: '100%',								
              }}
              inputContainerStyle={{
                backgroundColor: '#ffffff',
                width: '100%',	
								height: ''
              }}
              inputLabelStyle={{
                color: formlabel,								
              }}
              inputClass={'cus_textarea'}
              inputStyle={{ color: inputlabel }}
            />
            <fieldset className="card-number">
              <div
                style={{
                  fontSize: 13,
                  color: 'grey',
                  paddingLeft: 10,
                  paddingRight: 10,
                }}
              >{`Coupon generated is valid for 90 days and can be downloaded when you SUBMIT.`}</div>
            </fieldset>
            <fieldset className="card-number">
              <div id="status" style={{ display: 'none' }}></div>
            </fieldset>
            <FormInput
              label="SUBMIT"
              secureTextEntry="text"
              inputType="button"
              inputId="button1"
              onClick={handleSubmit}
              containerStyle={{
                marginTop: 5,
                width: '100%',
              }}
              inputClass={'cus_button'}
              inputStyle={{}}
            />
          </motion.div>
        </div>
      </div>
      <SubFooter sendApp={sendApp} infoDisplay={infoDisplay} />
      <Drawer
        title={getHeader()}
        open={open}
        closable={false}
        maskClosable={true}
        footer={getButton()}
        placement="right"
        onClose={() => setOpen(false)}
        size={`large`}
      >
        {/*Change Address Display*/}
        <>
          <Form
            style={{ backgroundColor: 'rgba(204,220,205,0.2)', padding: 20 }}
            initialValues={{ locationAddress: houseNo + ' ' + locationAddress }}
          >
						<div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginTop: -20,
                marginBottom: 20,
              }}
            >
              <FormInput
                label={`Email *`}
                placeholder="Email"
                secureTextEntry="text"
                inputType="input"
                inputId="email"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                containerStyle={{
                  marginTop: 15,
                  width: '49%',
                  textAlign: 'left',
                }}
                inputContainerStyle={{
                  backgroundColor: '#ffffff',
                  width: '100%',
                }}
                inputLabelStyle={{
                  fontSize: 12,
                }}
                inputClass={'cus_input _1lSOQ6q2xcOkaxVT1smcn7'}
              />

              <FormInput
                label={`Business Name *`}
                placeholder="Business Name"
                secureTextEntry="text"
                inputType="input"
                inputId="companyName"
                onChange={(e) => setCompanyName(e.target.value)}
                value={companyName}
                containerStyle={{
                  marginTop: 15,
                  width: '49%',
                  textAlign: 'left',
                }}
                inputContainerStyle={{
                  backgroundColor: '#ffffff',
                  width: '100%',
                }}
                inputLabelStyle={{
                  fontSize: 12,
                }}
                inputClass={'cus_input _1lSOQ6q2xcOkaxVT1smcn7'}
              />
            </div>
							
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginTop: -20,
              }}
            >
              <FormInput
                label={`House No`}
                placeholder="No"
                secureTextEntry="text"
                inputType="input"
                inputId="houseNo"
                onChange={(e) => setHouseNo(e.target.value)}
                value={houseNo}
                containerStyle={{
                  marginTop: 15,
                  width: '28%',
                  textAlign: 'left',
                }}
                inputContainerStyle={{
                  backgroundColor: '#ffffff',
                  width: '100%',
                }}
                inputLabelStyle={{
                  fontSize: 12,
                }}
                inputClass={'cus_input _1lSOQ6q2xcOkaxVT1smcn7'}
              />

              <FormInput
                label={`Street Name *`}
                placeholder="Street Name"
                secureTextEntry="text"
                inputType="input"
                inputId="locationAddress"
                onChange={(e) => handleLocationAddress(e.target.value)}
                value={locationAddress}
                containerStyle={{
                  marginTop: 15,
                  width: '70%',
                  textAlign: 'left',
                }}
                inputContainerStyle={{
                  backgroundColor: '#ffffff',
                  width: '100%',
                }}
                inputLabelStyle={{
                  fontSize: 12,
                }}
                inputClass={'cus_input _1lSOQ6q2xcOkaxVT1smcn7'}
              />
            </div>

            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginTop: 0,
                marginBottom: 20,
              }}
            >
              <FormInput
                label={`City *`}
                placeholder="City"
                secureTextEntry="text"
                inputType="input"
                inputId="city"
                onChange={(e) => setCity(e.target.value)}
                value={city}
                containerStyle={{
                  marginTop: 15,
                  width: '32%',
                  textAlign: 'left',
                }}
                inputContainerStyle={{
                  backgroundColor: '#ffffff',
                  width: '100%',
                }}
                inputLabelStyle={{
                  fontSize: 12,
                }}
                inputClass={'cus_input _1lSOQ6q2xcOkaxVT1smcn7'}
              />

              <FormInput
                label={`State/Province *`}
                placeholder="State/Province"
                secureTextEntry="text"
                inputType="input"
                inputId="state"
                onChange={(e) => setState(e.target.value)}
                value={state}
                containerStyle={{
                  marginTop: 15,
                  width: '32%',
                  textAlign: 'left',
                }}
                inputContainerStyle={{
                  backgroundColor: '#ffffff',
                  width: '100%',
                }}
                inputLabelStyle={{
                  fontSize: 12,
                }}
                inputClass={'cus_input _1lSOQ6q2xcOkaxVT1smcn7'}
              />
														
              <FormInput
                label={`Zip/Postal Code *`}
                placeholder="Zip/Postal code"
                secureTextEntry="text"
                inputType="input"
                inputId="zip"
                onChange={(e) => setZip(e.target.value)}
                value={zip}
                containerStyle={{
                  marginTop: 15,
                  width: '32%',
                  textAlign: 'left',
                }}
                inputContainerStyle={{
                  backgroundColor: '#ffffff',
                  width: '100%',
                }}
                inputLabelStyle={{
                  fontSize: 12,
                }}
                inputClass={'cus_input _1lSOQ6q2xcOkaxVT1smcn7'}
              />
            </div>
          </Form>
        </>
      </Drawer>
    </>
  );
};

export default OpenCouponScreen;
