import React, { useEffect, useState } from "react";
import { utils } from "../../utils";
import { Drawer, Form, Input, Button, Switch, message } from "antd";
import { useDispatch } from "react-redux";
import { setSavedCards, setPaymentCardData } from "../../includes/navSlice";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { Space, Divider } from "antd";
import jwt_decode from "jwt-decode";
import { useGoogleLogin } from "@react-oauth/google";

const DataForm = ({
  loading,
  isSignup,
  codeEntry,
  handleSwitch,
  handleSignup,
  handleLogin,
  email,
  pass,
  phone,
}) => {
  return (
    <>
      <div style={{ marginBottom: 20 }}>
        <span style={{ fontSize: 16 }}>Email Address *</span>
        <input
          className="cus_input"
          key={uuidv4()}
          style={{
            width: "100%",
          }}
          defaultValue={email ? email : ""}
          id="modalemail"
          disabled={isSignup && codeEntry ? true : false}
        />
      </div>
      {((isSignup && !codeEntry) || !isSignup) && (
        <div style={{ marginBottom: 20 }}>
          <span style={{ fontSize: 16 }}>Password *</span>
          <Input.Password
            className="cus_input modal-text-input"
            key={uuidv4()}
            style={{
              width: "100%",
            }}
            defaultValue={pass ? pass : ""}
            id="modalpass"
          />
        </div>
      )}
      {isSignup && !codeEntry && (
        <div style={{ marginBottom: 20 }}>
          <span style={{ fontSize: 16 }}>Mobile Phone *</span>
          <input
            className="cus_input"
            key={uuidv4()}
            style={{
              width: "100%",
            }}
            defaultValue={phone ? phone : ""}
            id="modalphone"
          />
        </div>
      )}

      {codeEntry && (
        <div style={{ marginBottom: 20 }}>
          <span style={{ fontSize: 16 }}>Email Code *</span>
          <input
            className="cus_input"
            key={uuidv4()}
            style={{
              width: "100%",
            }}
            defaultValue=""
            id="modalcode"
          />
        </div>
      )}

      <Divider style={{ marginTop: -8 }} />
      <div style={{ marginTop: -8, textAlign: "left" }}>
        <Switch checked={isSignup} onChange={handleSwitch} />
        <span style={{ marginLeft: 8 }}>
          {isSignup
            ? `Already have an account? Login`
            : `Don't have an account? Sign up`}
        </span>
      </div>
      <Divider style={{ marginTop: -8 }} />

      <Button
        key="submit"
        className="c__button"
        loading={loading}
        onClick={isSignup ? handleSignup : handleLogin}
      >
        {isSignup ? `${codeEntry ? "Submit" : "Get Code"}` : "Login"}
      </Button>
    </>
  );
};

const LoginSignupModal = ({
  myClass,
  openSignIn,
  setOpenSignIn,
  loginStyle,
}) => {
  const [modalemail, setModalEmail] = useState("");
  const [modalpass, setModalPass] = useState("");
  const [modalphone, setModalPhone] = useState("");

  const [open, setOpen] = useState(false);
  const [isSignup, setIsSignup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [codeEntry, setCodeEntry] = useState(false);
  const [os, setOs] = useState("");
  const [model, setModel] = useState("");
  //const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authToken = "";

  useEffect(() => {
    window.document.body.style.overflow = open ? "hidden" : "";
    setIsSignup(false);
  }, [open]);

  useEffect(() => {
    setOs(utils.getOs); // added parentheses to call function
    setModel(utils.getBrowser); // added parentheses to call function
    initEL();
  }, []);

  useEffect(() => {
    if (openSignIn) {
      setOpen(true);
    }
  }, [openSignIn]);

  const responseGoogle = useGoogleLogin({
    onSuccess: async (response) => {
      try {
        const posturl = "https://www.googleapis.com/oauth2/v3/userinfo";
        const header = { Authorization: `Bearer ${response.access_token}` };
        const dataToPost = "";
        const res = await utils.fetchURL(posturl, "GET", dataToPost, header);
        let data = await res.json();
        data = {
          ...data,
          accessToken: response.access_token,
          lastName: data.family_name,
          firstName: data.given_name,
        };
        onFastLogin(data.email, "google", data);
        //console.log(data);
      } catch (err) {
        console.log(err);
      }
    },
  });

  const onFastLogin = async (email, platform = "", data = {}) => {
    try {
      if (!platform) {
        platform = "google";
      }
      if (platform === undefined) {
        platform = "google";
      }
      if (utils.isValidEmail(email)) {
        const localtime = await utils.getTime();
        const body = {
          rtype: "googlecontinue",
          email: email,
          os: os,
          model: model,
          platform: platform,
          localtime: localtime,
          ...data,
        };
        await doLogin(body, email);
      }
    } catch (e) {
      message.error(e.message);
    }
  };

  const doLogin = async (obj, email) => {
    try {
      if (utils.isValidEmail(email)) {
        setLoading(true);
        const target = utils.USER_URL;
        const header = new Headers({
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + authToken,
        });
        const posturl = utils.BASE_URL + utils.USER_URL; //utils.POST_URL;
        const obj2 = JSON.stringify({
          ...obj,
          target: target,
          token: authToken,
        });
        const dataToPost = await utils.cipherEncode(obj2);
        const res = await utils.fetchURL(posturl, "POST", dataToPost, header);
        const data = await res.json();

        if (data?.msg === "Success") {
          if (data?.screen === "HomeScreen") {
            let thisauth = jwt_decode(data.token);
            await utils.setStorage("__el_data_login_token", data?.token);
            thisauth = await utils.base64Encode(JSON.stringify(thisauth));
            //let decryptedStr = await utils.base64Decode(thisauth);
            await getAuth(thisauth);
            window.location.reload(true);
          } else if (data?.screen === "MyAccountEditScreen") {
            let thisauth = jwt_decode(data.token);
            await utils.setStorage("__el_data_login_token", data?.token);
            thisauth = await utils.base64Encode(JSON.stringify(thisauth));
            //let decryptedStr = await utils.base64Decode(thisauth);
            await getAuth(thisauth);
            window.location.reload(true);
            //await navigate('/edit-address/delivery/'+data?.editid, {user: email});
          }
        } else {
          if (data?.screen === "SignUpScreen") {
            message.error(data?.msg);
            await navigate("/signup", { message: data?.msg, replace: false });
            return;
          }
          message.error(data?.msg);
          await navigate("/signin", { replace: false });
        }
      } else {
        await navigate("/signin", { replace: false });
      }
    } catch (e) {
      message.error(e.message);
    }
  };

  const getAuth = async (thisauth) => {
    try {
      let localEL = "";
      let local_token = "";

      const ed = await utils.getStorage("__el_data_access_token");
      const lt = await utils.getStorage("__el_data_local_token");
      localEL = ed ? ed : "";
      if (lt) {
        local_token = utils.getStorage("__el_data_local_token");
        utils.setStorage("__el_data_local_token", local_token);
      } else {
        local_token = uuidv4();
        utils.setStorage("__el_data_local_token", local_token);
      }

      let headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        "x-local-token": local_token,
        "x-auth-token": localEL,
      };

      if (thisauth) {
        headers["x-auth-data"] = thisauth;
      }

      const target = utils.AUTH_URL;
      const header = new Headers(headers);
      const posturl = utils.BASE_URL + target;
      const dataToPost = JSON.stringify({ rtype: "auth" });
      const res = await utils.fetchURL(posturl, "POST", dataToPost, header);
      const data = await res.json();
      let obj = JSON.parse(JSON.stringify(data));
      let objData = obj?.data;

      if (objData) {
        await utils.setStorage(
          "__el_data_access_token",
          objData.auth.access_token
        );
        await utils.setStorage(
          "__el_data_login_status",
          objData.credentials.el_login
        );
        await utils.setStorage("__el_data_email", objData.credentials.email);
        await utils.setStorage(
          "__el_data_first_name",
          objData.credentials.firstname
        );
        await utils.setStorage(
          "__el_data_last_name",
          objData.credentials.lastname
        );
        await loadEmailData(
          objData.credentials.email,
          objData.auth.access_token
        );
        setOpen(false);
      } else {
        message.error(data?.msg);
      }
    } catch (e) {
      message.error(e.message);
    }
  };

  const loadEmailData = async (email, auth) => {
    try {
      const target = utils.USER_CALL + "?email=" + email.toLowerCase();
      const header = new Headers({
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + auth,
      });
      const posturl = utils.BASE_URL + target; //utils.GET_URL;
      const dataToPost = "";
      const response = await utils.fetchURL(posturl, "GET", dataToPost, header);
      const statusCode = await response.status;

      if (statusCode === 200) {
        const data = await response.json();
        await dispatch(setPaymentCardData(data.user.paymentCards));
        await dispatch(setSavedCards(JSON.stringify(data.user.paymentCards)));
      } else {
      }
    } catch (e) {}
  };

  const initEL = async () => {
    try {
      const ellogin = await utils.getStorage("__el_data_el_login");
      const email = await utils.getStorage("__el_data_email");
      if (utils.isValidEmail(email) && ellogin === "1") {
        setIsLoggedIn(true);
      } else if (utils.isValidEmail(email)) {
        setIsLoggedIn(true);
      } else if (ellogin === "1") {
        setIsLoggedIn(true);
      }
    } catch (e) {
      message.error(e.message);
    }
  };

  const handleCancel = async () => {
    if (openSignIn) {
      await setOpenSignIn(false);
      window.document.body.style.overflow = "";
    }
    setOpen(false);
  };

  const handleSwitch = () => {
    const email = document.getElementById("modalemail").value;
    const pass = document.getElementById("modalpass").value;
    if (document.getElementById("modalphone")) {
      const phone = document.getElementById("modalphone").value;
      setModalPhone(phone);
    }
    setModalEmail(email);
    setModalPass(pass);
    setIsSignup(!isSignup);
  };

  const handleLogin = async () => {
    try {
      const email = document.getElementById("modalemail").value;
      const pass = document.getElementById("modalpass").value;

      if (!email) {
        message.error("Email is required!");
        return false;
      }
      if (!pass) {
        message.error("Password is required!");
        return false;
      }
      const localtime = await utils.getTime();
      const body = {
        rtype: "login",
        email: email,
        pass: pass,
        os: os,
        model: model,
        localtime: localtime,
      };
      await doLogin(body, email);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      message.error("Error logging in:", error.message);
      // Handle error
    }
  };

  const doSignUp = async (obj, email) => {
    try {
      if (utils.isValidEmail(email)) {
        setLoading(true);
        const target = utils.USER_URL;
        const header = new Headers({
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + authToken,
        });
        const posturl = utils.BASE_URL + utils.USER_URL; //utils.POST_URL;
        let dataToPost = JSON.stringify({
          ...obj,
          target: target,
          token: authToken,
        });
        dataToPost = await utils.cipherEncode(dataToPost);
        const res = await utils.fetchURL(posturl, "POST", dataToPost, header);
        const data = await res.json();

        if (data?.msg === "Success") {
          if (data?.screen === "SignInScreen") {
            let thisauth = jwt_decode(data.token);
            await utils.setStorage("__el_data_login_token", data?.token);
            thisauth = await utils.base64Encode(JSON.stringify(thisauth));
            await getAuth(thisauth);
            setCodeEntry(false);
            window.location.reload(true);
          } else if (data?.screen === "OtpScreen") {
            setModalEmail(email);
            setCodeEntry(true);
            setIsSignup(true);
          } else {
            message.error(data?.msg);
          }
        } else {
          message.error(data?.msg);
        }
      } else {
        await navigate("/signin", { replace: false });
      }
    } catch (e) {
      message.error(e.message);
    }
  };

  const handleSignup = async () => {
    try {
      if (!codeEntry) {
        const email = document.getElementById("modalemail").value;
        const pass = document.getElementById("modalpass").value;
        const phone = document.getElementById("modalphone").value;

        // Call signup API here
        if (!email) {
          message.error("Email is required!");
          return false;
        }
        if (!pass) {
          message.error("Password is required!");
          return false;
        }
        if (!phone) {
          message.error("Phone is required!");
          return false;
        }
        const localtime = await utils.getTime();
        const body = {
          rtype: "sendcode",
          email: email,
          pass: pass,
          phone: phone,
          os: os,
          model: model,
          localtime: localtime,
        };

        await doSignUp(body, email);
        setLoading(false);
      } else {
        const email = document.getElementById("modalemail").value;
        const code = document.getElementById("modalcode").value;

        if (!email) {
          message.error("Email is required!");
          return false;
        }
        if (!code) {
          message.error("Code is required!");
          return false;
        }
        const localtime = await utils.getTime();
        const body = {
          rtype: "checkcode",
          email: email,
          otp: code,
          os: os,
          model: model,
          localtime: localtime,
        };
        await doSignUp(body, email);

        setLoading(false);
        setOpen(false);
      }
    } catch (error) {
      setLoading(false);
      message.error("Error signing up:", error);
      // Handle error
    }
  };

  const openDashBoard = async () => {
    await navigate("/dashboard", { replace: false });
  };

  return (
    <>
      {!isLoggedIn && (
        <a
          href="#"
          className={myClass}
          style={loginStyle}
          onClick={() => {
            return false;
          }}
          onMouseDown={() => setOpen(true)}
        >
          Login
        </a>
      )}
      {isLoggedIn && (
        <a
          href="#"
          className={myClass}
          onClick={() => {
            return false;
          }}
          onMouseDown={() => openDashBoard()}
        >
          Dashboard
        </a>
      )}
      
			<Drawer 
				title={isSignup ? "Sign Up" : "Login"}
				open={open} 
				footer={null}				
				closable={false}
				maskClosable={true}						
				placement="right"        
				onClose={() => handleCancel()}
				size={`large`}
				extra={
					<Button
						key="back"
						className="c__button"
						onClick={() => handleCancel()}
					>
						Close
					</Button>
        }
			>
        <div
          key={uuidv4()}
          style={{ backgroundColor: "rgba(204,220,205,0.2)", padding: 20 }}
        >
          <DataForm
            loading={loading}
            isSignup={isSignup}
            codeEntry={codeEntry}
            handleSwitch={handleSwitch}
            handleSignup={handleSignup}
            handleLogin={handleLogin}
            email={modalemail}
            pass={modalpass}
            phone={modalphone}
          />

          <Divider style={{ marginTop: -8 }} />
          <div style={{ width: "100%", textAlign: "center", marginTop: -8 }}>
            OR
          </div>
          <Divider style={{ marginTop: 8 }} />
          <div
            className="continue-wrap"
            style={{
              left: "50%",
              marginLeft: "-130px",
              width: "fit-content",
              position: "relative",
            }}
          >
            <Space direction="vertical">
              <button
                onClick={responseGoogle}
                className="continue continue-google"
              >
                Continue with google
              </button>
            </Space>
            <Divider />
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default LoginSignupModal;
