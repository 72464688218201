import React, { useEffect, useState } from 'react';
import { Us, Gb, Ca, Ng } from 'react-flags-select';
import { utils } from "../../utils";

const countries = utils.supportedCountries;

const CountrySelectorHead = () => {
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [showOptions, setShowOptions] = useState(false);

  const customStyles = {
    marginTop: '0.05rem',
    marginBottom: '0.05rem',
    paddingRight: '1rem',
    paddingLeft: '0.75rem',
    paddingTop: '0.35rem',
    backgroundColor: '#cdd1d7',
    borderRadius: '1rem',
    display: 'inline-block',
    width: 'auto',
    cursor: 'pointer',
		zIndex: 999
  };
	
  const fontSize = {
    fontSize: 20,
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  };

  const handleCountryClick = () => {
    // Toggle the visibility of country options on click
    setShowOptions(!showOptions);
  };

  const handleCountryChange = async(countryCode) => {
    // Handle the change of selected country
    await utils.setLocalCookie('__user_region', countryCode);
    setSelectedCountry(countryCode);
    setShowOptions(false);
		
		if(selectedCountry !== countryCode){
			const uri = window.location.pathname.replace('/', '');
			let currentDomain = window.location.hostname;
			const redirectingFromUrl = document.referrer;
			currentDomain = currentDomain.includes('localhost') ? `localhost:3000` : currentDomain;
			
			let region = countryCode;
			let redirectUrl = '', finalUrl = '', domain = '';
			
			const subdomain = ['ca', 'ng', 'gb'];
			const lowerRegion = region.toLowerCase();
			
			if (!currentDomain.includes('localhost')) {
			
				const parts = currentDomain.split('.');    
				
				// Check if the current domain has more than two parts
				if (parts.length === 3 && lowerRegion !== parts[0] && !subdomain.includes(lowerRegion) && !subdomain.includes(parts[0])) {
					redirectUrl = `https://${currentDomain}`;
				} else if (parts.length === 3 && lowerRegion !== parts[0] && !subdomain.includes(lowerRegion) && subdomain.includes(parts[0])) {
					currentDomain = parts.slice(-2).join('.');
					redirectUrl = `https://${currentDomain}`;
				} else if (parts.length === 2 && subdomain.includes(lowerRegion)) {
					redirectUrl = `https://${lowerRegion}.${currentDomain}`;
				} else if (parts.length > 2) {
					currentDomain = parts.slice(-2).join('.');
				}
				domain = `${currentDomain}`;
				} else {
				domain = currentDomain;
			}
			
			switch (region && !redirectUrl) {
				case 'CA':
				redirectUrl =  currentDomain.includes('localhost') ? `${domain}` : `https://ca.${domain}`;
				break;
				case 'NG':
				redirectUrl =  currentDomain.includes('localhost') ? `${domain}` : `https://ng.${domain}`;
				break;
				case 'GB':
				redirectUrl =  currentDomain.includes('localhost') ? `${domain}` : `https://gb.${domain}`;
				break;
				case 'US':
				redirectUrl = currentDomain.includes('localhost') ? `${domain}` : `https://${domain}`;
				break;
				default:
				// Handle other cases if needed
				break;
			}
			
			finalUrl = uri ? `${redirectUrl}/${uri}` : redirectUrl;
			if(!finalUrl.includes('localhost') || currentDomain !== redirectUrl){
				window.location.href = finalUrl;
			}else{
				window.location.reload();
			}
		}
  };
	
	useEffect(() => {
		const getHis = async() => 
		{		
			const region = await utils.getLocalCookie('__user_region');
			const isPresent = await utils.supportedCountries.some(country => country.code === region);
			
			if(region && isPresent){
				setSelectedCountry(region);
			}
		}
		getHis();
  }, []);

  return (
    <>
      <div style={customStyles} onClick={handleCountryClick}>
        <span style={fontSize}>
          {countries.find(country => country.code === selectedCountry)?.code === 'US' && <Us style={{ borderRadius: '100%' }} />}
          {countries.find(country => country.code === selectedCountry)?.code === 'CA' && <Ca style={{ borderRadius: '100%' }} />}
          {countries.find(country => country.code === selectedCountry)?.code === 'GB' && <Gb style={{ borderRadius: '100%' }} />}
          {countries.find(country => country.code === selectedCountry)?.code === 'NG' && <Ng style={{ borderRadius: '100%' }} />}
          <span style={{ marginLeft: 5, fontSize: 12 }}>{countries.find(country => country.code === selectedCountry)?.code}</span>
        </span>
      </div><br/>

      {showOptions && (
        <ul style={{...customStyles, position: 'absolute'}} className="countryList">
          {countries.map(country => (
					<li key={country.code} onClick={() => handleCountryChange(country.code)}>
              <span style={fontSize}>
                {country.code === 'US' && <Us style={{ borderRadius: '100%' }} />}
                {country.code === 'CA' && <Ca style={{ borderRadius: '100%' }} />}
                {country.code === 'GB' && <Gb style={{ borderRadius: '100%' }} />}
                {country.code === 'NG' && <Ng style={{ borderRadius: '100%' }} />}
                <span style={{ marginLeft: 5, fontSize: 12 }}>{country.shrt}</span>
              </span>
            </li>
          ))}
        </ul>
      )}
    </>
  );
};

export default CountrySelectorHead;
