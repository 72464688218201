import React from 'react';
import { v4 as uuidv4 } from "uuid";
import { Input} from "antd";
import InputMask from 'react-input-mask';

const FormInput = ({
	containerStyle,
	inputContainerStyle,
	inputLabelStyle,
	inputId,
	inputType,
	label,
	placeholder,
	inputStyle,
	inputClass = "",
	value = "",
	prependComponent,
	appendComponent,
	onChange,
	secureTextEntry,
	errorMsg = "",
	maxLength,
	onClick,
	mask,
	options = [],
}) => {
	if(inputType === 'input'){
		return (
			<div style={{ ...containerStyle }}>
			<div style={{ flexDirection: 'row', justifyContent: 'space-between', marginBottom: 5 }}>
			<span style={{ fontSize: 14, ...inputLabelStyle }}>{label}</span>
			<span style={{ fontSize: 14 }}>{errorMsg}</span>
			</div>
			
			<div
			style={{
				flexDirection: 'row',
				fontSize: 18,
				height: 'auto',
				paddingHorizontal: 5,
				marginTop: 0,
				marginBottom: 5,
				borderBottomWidth: 0,
				...inputContainerStyle					
			}}
			>
			{
				prependComponent
			}
			{secureTextEntry !== 'password' && (<input
				id={inputId}
				style={{ flex: 1, ...inputStyle }}
				className={inputClass}					
				placeholder={placeholder}
				type={secureTextEntry}
				maxLength={maxLength}
				value={value}
				onChange={onChange}
			/>)}
			{secureTextEntry === 'password' && (<Input.Password
				autoComplete="off"
				className={inputClass}
				placeholder={placeholder}
				style={{ flex: 1, ...inputStyle }}
				id={inputId}
				maxLength={maxLength}
				type={secureTextEntry}
				value={value}
				onChange={onChange}
			/>)}
			{
				appendComponent
			}
			</div>
			</div>
		)
	}
	else if(inputType === 'inputmask'){
		return (
			<div style={{ ...containerStyle }}>
			<div style={{ flexDirection: 'row', justifyContent: 'space-between', marginBottom: 5 }}>
			<span style={{ fontSize: 14, ...inputLabelStyle }}>{label}</span>
			<span style={{ fontSize: 14 }}>{errorMsg}</span>
			</div>
			
			<div
			style={{
				flexDirection: 'row',
				fontSize: 18,
				height: 'auto',
				paddingHorizontal: 5,
				marginTop: 0,
				marginBottom: 5,
				borderBottomWidth: 0,
				...inputContainerStyle					
			}}
			>
			{
				prependComponent
			}
			<InputMask
			mask={mask}
			id={inputId}
			autoComplete="off"
			style={{ flex: 1, ...inputStyle }}
			className={inputClass}					
			placeholder={placeholder}
			type={secureTextEntry}
			value={value}
			onChange={onChange}
			/>
			{
				appendComponent
			}
			</div>
			</div>
		)
	}	
	else if(inputType === 'select')
	{
		return (
			<div style={{ ...containerStyle }}>
			<div style={{ flexDirection: 'row', justifyContent: 'space-between', marginBottom: 5 }}>
			<span style={{ fontSize: 14, ...inputLabelStyle }}>{label}</span>
			<span style={{ fontSize: 14 }}>{errorMsg}</span>
			</div>
			
			<div
			style={{
				flexDirection: 'row',
				fontSize: 18,
				height: 45,
				paddingHorizontal: 5,
				marginTop: 0,
				marginBottom: 5,
				borderBottomWidth: 0,
				...inputContainerStyle					
			}}
			>
			{
				prependComponent
			}
			<select
			id={inputId}
			style={{ flex: 1, ...inputStyle }}
			className={inputClass}
			type={secureTextEntry}
			value={value}
			onChange={onChange}
			>
			{options?.map(({label, value, disabled}) => (
				<option value={value} disabled={disabled} key={uuidv4()}>{label}</option>
			))}
			</select>
			{
				appendComponent
			}
			</div>
			</div>
		)
	}
	else if(inputType === 'textarea')
	{
		return (
			<div style={{ ...containerStyle }}>
			<div style={{ flexDirection: 'row', justifyContent: 'space-between', marginBottom: 5 }}>
			<span style={{ fontSize: 14, ...inputLabelStyle }}>{label}</span>
			<span style={{ fontSize: 14 }}>{errorMsg}</span>
			</div>
			
			<div
			style={{
				flexDirection: 'row',
				fontSize: 18,
				height: 45,
				paddingHorizontal: 5,
				marginTop: 0,
				marginBottom: 5,
				borderBottomWidth: 1,
				...inputContainerStyle					
			}}
			>
			{
				prependComponent
			}
			<textarea
			placeholder={placeholder}
			id={inputId}
			style={{ flex: 1, ...inputStyle }}
			className={inputClass}
			type={secureTextEntry}
			value={value}
			onChange={onChange}
			maxLength={maxLength}
			/>
			{
				appendComponent
			}
			</div>
			</div>
		)
	}
	else if(inputType === 'button')
	{
		return (
			<div style={{ ...containerStyle }}>
			
			<div
			style={{
				flexDirection: 'row',
				fontSize: 18,
				height: 45,
				paddingHorizontal: 5,
				marginTop: 0,
				marginBottom: 5,
				borderBottomWidth: 1,
				...inputContainerStyle					
			}}
			>
			{
				prependComponent
			}
			<button 
			className={inputClass}
			onClick={onClick}
			>
			{label}
			</button>	
			{
				appendComponent
			}
			</div>
			</div>
		)
	}else{
		return null;
	}
}

export default FormInput