import React, {useEffect, useState} from 'react';
import { motion, useScroll, useSpring  } from "framer-motion";
import { SubFooter, MainNavbar } from '../../components';
import { v4 as uuidv4 } from "uuid";
import { utils } from "../../utils";
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { setAddressData, setErrandAddressData, setPaymentCardData, selectAddressData, selectErrandAddressData, selectPaymentCardData } from "../../includes/navSlice";
import Cookies from "js-cookie";

const ErrandAddressList = ({execAddress}) => 
{
	const navigate = useNavigate();
	const { scrollYProgress } = useScroll();
	const scaleX = useSpring(scrollYProgress, {
		stiffness: 100,
		damping: 30,
		restDelta: 0.001
	});
	
	const dispatch = useDispatch();
	let errandlist = useSelector(selectErrandAddressData);
	errandlist = errandlist ?? [];
	const sessionemail = utils.getStorage('__el_data_email');
	const authToken = utils.getStorage('__el_data_login_token');
	const ellogin = utils.getStorage('__el_data_login_status');
	const [email, setEmail] = useState(sessionemail);
	const [params, setParams] = useState(errandlist);
	const [options, setOptions] = useState([]);		
	
	useEffect(() => 
	{
		setEmail(email);
	}, [email]);
	
	useEffect(() => 
	{
		const getParams = async () => {
			try{
			
				var paramsFromServer;
				if(params.length === 0){
					paramsFromServer = await fetchParams();
					setParams(paramsFromServer?.user?.errandaddresses ?? []);
					dispatch(setAddressData(paramsFromServer?.user?.addresses ?? []));
					dispatch(setPaymentCardData(paramsFromServer?.user?.paymentCards ?? []));
					dispatch(setErrandAddressData(paramsFromServer?.user?.errandaddresses ?? []));
					
				}
			}catch(e){}	
		}	
		getParams();
	}, []);
	
	useEffect(() => {
		let naddress, narr = [];
		
		for(let i=0; i<params.length; i++){
			const desc = params[i]?.address+', '+params[i]?.city+', '+params[i]?.state+'|'+utils.convertToUSPhoneFormat(params[i]?.phone);		
			if(params[i]?.addressMain)
			{
				naddress = {'id': params[i]?.id.toLowerCase(), 'href': {id: params[i]?.id.toLowerCase()}, 'title': params[i]?.addressLabel, 'description': desc, 'address': params[i]?.address, 'city': params[i]?.city, 'state': params[i]?.state, 'phone': params[i]?.phone, 'fill': '#0ECB81', 'label': 'm23.46,32.27s-5.2,-2.97 -5.2,-7.8a5.2,5.29 0 0 1 5.2,-5.42a5.2,5.29 0 0 1 5.2,5.42c0,4.82 -5.2,7.8 -5.2,7.8z'};
			}else{
				naddress = {'id': params[i]?.id.toLowerCase(), 'href': {id: params[i]?.id.toLowerCase(), switchable: true}, 'title': params[i]?.addressLabel, 'description': desc, 'address': params[i]?.address, 'city': params[i]?.city, 'state': params[i]?.state, 'phone': params[i]?.phone, 'fill': utils.getRandomColor(), 'label': 'm26.07,24.81a2.5,2.5 0 1 1 -5,0a2.5,2.5 0 0 1 5,0z'};
			}
			narr.push(naddress);
			if(i === params.length - 1){			
				setOptions(narr);
			}
		}	
	}, [params]);	
	
	const fetchParams = async () => {
		try{
			const target = utils.USER_CALL+'?email='+email;
			const header = new Headers({
				'Content-Type': 'application/json',
				'Accept': 'application/json',
				'Authorization': 'Bearer '+authToken,
			});
			const posturl = utils.BASE_URL+target; //utils.GET_URL;
			const dataToPost = '';			
			const res = await utils.fetchURL(posturl, 'GET', dataToPost, header);
			const response = await res?.json();
			if(response?.response && response?.status && response?.status === 'Success'){
				const d = await utils.cipherDecode(response.response);
				const mainresponse = typeof d === 'object' ? d : JSON.parse(d);
				return mainresponse;
			}else if(response?.error && response?.error.toLowerCase() === 'invalid user'){
				localStorage.clear();
				const cookies = Object.keys(Cookies.get());
				cookies.forEach(cookie => {
					if(cookie !== 'cookieconsent_status'){
					Cookies.remove(cookie);
					}
				});
				navigate('/');
			}
			return response;
		}catch(e){}
		
	};
	
	const SwitchRef = (ref) => {
		navigate(ref);
	}
	
	const OpenRef = (id, switchable, title, address, description) => {
		
		if(switchable && switchable !== 'undefined'){
			execAddress('address', title, description, id);
		}else{
			execAddress('', title, description, id);
		}		
	}
	
	const ListOptions = ( listdata, index ) => {		
		
		return (		
			<div className="sc-iBYQkv gwipAY" onClick={()=> OpenRef(`${listdata.listdata?.href.id}`, `${listdata.listdata?.href?.switchable}`, `${listdata.listdata?.title}`, `${listdata.listdata?.address}`, `${listdata.listdata?.description}`)}>
				<div className=" flex justify-between list-card ">
					<div className="flex justify-between align-center list-card-content">
						<div className="list-icon ">
							<svg width="46" height="49" viewBox="0 0 46 49" fill="none" xmlns="http://www.w3.org/2000/svg">
								<g filter="url(#filter0_d_37786_12291)">
									<rect x="10" y="10" width="26" height="29" rx="4" fill="white"></rect>
									<path d={`${listdata.listdata?.label}`} fill={`${listdata.listdata?.fill}`}></path>
								</g>
								<defs>
									<filter id="filter0_d_37786_12291" x="0" y="0" width="46" height="49" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
										<feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
										<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"></feColorMatrix>
										<feOffset></feOffset>
										<feGaussianBlur stdDeviation="5"></feGaussianBlur>
										<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0"></feColorMatrix>
										<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_37786_12291"></feBlend>
										<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_37786_12291" result="shape"></feBlend>
									</filter>
								</defs>
							</svg>
						</div>
						<div className="flex-column list-card-texts">
							<h4 className="color-primary list-card-heading text-left margin-top-0 margin-bottom-0 btn-danger">{`${listdata.listdata?.title}`}</h4>
							<div className="color-black text-xs margin-top-1">
								<div className="text-left">{`${listdata.listdata?.address}`}</div>
							</div>
						</div>
					</div>
					<div className="flex align-center arrow-wrap">
						<svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M0 12.5825L1.51406 14L9 7V7V7L1.51406 0L0 1.4175L5.96719 7L0 12.5825Z" fill="#CACACA"></path>
						</svg>
					</div>
				</div>
			</div>
		)
	}
	
	return (
		<>
		<motion.div className="pprogress-bar" style={{ scaleX }} />			
		<div className="el-global--wrapper">
			<div className="el-entry el-page--transition">
				<MainNavbar />
				<div className="el-preview container" id="el-dashboard-container">
					<div className="sc-lhlUkk kRzDDN">
						<div className="animated margin-bottom-4 flex justify-between mx-auto align-center c-box-width">
							<div className="cus_title_wrap">
								<h2>Errand Addresses</h2>						
							</div>
							<div className="cus_add_button_wrap">
								<button type="solid" className="cus_add_button" onClick={()=> SwitchRef('/add-new/errand')}>Add New</button>
							</div>
						</div>
						<div className="sc-cabOPr kUdOjq login-card--wrap auth-wrap elAnimated elFadeInUp text-center el-overflow--hidden slide-in-bottom c-box-width c-box">
							<div className="cus_back_button_wrap">
								<button type="solid" style={{display: "flow-root", marginBottom: 30}} className="cus_back_button" onClick={()=> SwitchRef('/dashboard')}>Back</button>
							</div>
							{ellogin === '1' && options.map((listdata, index) => (
								<ListOptions listdata={listdata} index={index} key={uuidv4()} /> 
							))}
							{ellogin === '1' && options.length === 0 && (
								<div>{`You don't have saved list`}</div>	
							)}
						</div>
					</div>
					
				</div>
			</div>
		</div>
		<SubFooter />
		</>
	)
}

export default ErrandAddressList;
