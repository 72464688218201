import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { MapUpdate } from "../../components";
import { Drawer, Button, message } from "antd";
import { utils } from "../../utils";


const ErrandFormModal = ({
	moreInfo,
  setMoreInfo,
	errandInfo,
	updateErrandData,
	errandOrderPlaced,
	updateErrandCartData
}) => {

  const [open, setOpen] = useState(false);  
  const [errandAddress, setErrandAddress] = useState("");
  const [name, setName] = useState("");
  const [qty, setQty] = useState("");
  const [desc, setDesc] = useState("");
  const [length, setLength] = useState("");
  const [width, setWidth] = useState("");
  const [height, setHeight] = useState("");
  
  
  useEffect(() => {
    window.document.body.style.overflow = open ? "hidden" : "";	
  }, [open]);
  


  const handleContinue = async() => {
	  		
		try{
			
			let weightDoc = document.getElementById('weight');
			
			if(!name){
				message.error('Item name is required'); 
				return
			}
			
			if(!qty){
				message.error('Item quantity is required'); 
				return
			}
			
			if(qty < 1){
				message.error('Enter a valid quantity'); 
				return
			}
			
			if(!weightDoc.checked){
				message.error('Confirm that the item is less than 25 lbs'); 
				return
			}
			
						
			let selectProduct = {};			
			selectProduct.product = {};
			let newcart = false;
			const sname = errandInfo?.storeName;
			const szip = errandInfo?.storeZip;			
			const scity = errandInfo?.storeCity;			
			const sstate = errandInfo?.storeState;			
			const sinstruction = errandInfo?.storeInstruction;
			
			selectProduct.product.id = uuidv4();
			selectProduct.product.storeId = uuidv4();
			selectProduct.product.cartId = uuidv4();
			selectProduct.product.storeName = sname;
			selectProduct.product.storeZip = szip;
			selectProduct.product.storeCity = scity;
			selectProduct.product.storeState = sstate;
			selectProduct.product.name = name;						
			selectProduct.product.quantity = qty;
			selectProduct.product.description = desc;
			selectProduct.product.width = width;
			selectProduct.product.length = length;
			selectProduct.product.height = height;
			selectProduct.productNote = sinstruction;
			
			//console.log(selectProduct);
			
			const orderPlaced = [
				...errandOrderPlaced, 
				selectProduct
			];		
			
			const orderToCart = {
				'fulfillmentType': "Errand",
				'orderPlaced': orderPlaced,
			};
			
			await updateErrandCartData(orderToCart);
			setOpen(false);
			
		}
		catch(err){message.error(err.message)}		
	  
  };

  const getHeader = () => {
      return "Package Information";
  };

  const getButton = () => {
      return [
        <Button key="back" className="c__button" onClick={() => handleContinue()}>
          Add to list
        </Button>,
      ];
  };

  return (
    <>
      
			<div style={{marginTop: 20, cursor: 'pointer', fontFamily: 'Montserrat'}} onClick={() => setOpen(true)}>
				<span style={{padding: '8px 18px',borderRadius:3, border: '1px solid #7f86ac',color: '#1f2960'}}>ADD ITEM</span>
			</div>	  
      
			<Drawer
				title={getHeader()}
				open={open}
				closable={false}
				maskClosable={true}
				footer={getButton()}							
				placement="right"        
				onClose={() => setOpen(false)}
				size={`large`}
			>
			
		  <div style={{ backgroundColor: "rgba(204,220,205,0.2)", padding: 20 }}>
          <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: -20, paddingTop: 20, paddingBottom: 20}}>
						<label htmlFor="weight" style={{color: 'rgb(105, 114, 128)', fontFamily: 'Montserrat', cursor: 'pointer'}}>{`Package is less than 25 lbs`}</label>
						<input type="checkbox" id="weight"/>
					</div>
								
					<div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: -20}}>
						<input 
							type="text" 
							placeholder="Item to be picked"
							className="_1lSOQ6q2xcOkaxVT1smcn7 _3gouMfJy504Yjz5rHjoHpu" 
							style={{
								width: '65%', 
								height: '100%',
								padding: 12,
								fontSize: 15,								
							}}
							value={name}
							onChange={(e) => setName(e.target.value)}
						/>
						<input 
							type="text" 
							placeholder="Item Qty"
							className="_1lSOQ6q2xcOkaxVT1smcn7 _3gouMfJy504Yjz5rHjoHpu" 
							style={{
								width: '30%', 
								height: '100%',
								padding: 12,
								fontSize: 15,								
							}}
							value={qty}
							onChange={(e) => setQty(e.target.value)}
						/>
					</div>
					<div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: -20}}>						
						<textarea 
							placeholder="Message or description [Optional]" 
							style={{
								width: '100%', 
								height: '100%',
								padding: 12,
								fontSize: 15,
							}} 
							className="_1lSOQ6q2xcOkaxVT1smcn7 _3gouMfJy504Yjz5rHjoHpu"
							value={desc}
							onChange={(e) => setDesc(e.target.value)}
						>
						</textarea>
					</div>							
					
					<span onClick={()=> ''} onMouseDown={()=> setMoreInfo(!moreInfo)} style={{color: '#1677ff', fontFamily: 'Montserrat', cursor: 'pointer', marginTop: -10}}>{`${moreInfo ? 'Show Less' : 'Show More'}`}</span>
								
					{moreInfo && (<div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: -10}}>
						<input 
							type="text" 
							placeholder="Length in cm"
							className="_1lSOQ6q2xcOkaxVT1smcn7 _3gouMfJy504Yjz5rHjoHpu" 
							style={{
								width: '32%', 
								height: '100%',
								padding: 12,
								fontSize: 15,								
							}}
							value={length}
							onChange={(e) => setLength(e.target.value)}
						/>
						<input 
							type="text" 
							placeholder="Width in cm"
							className="_1lSOQ6q2xcOkaxVT1smcn7 _3gouMfJy504Yjz5rHjoHpu" 
							style={{
								width: '32%', 
								height: '100%',
								padding: 12,
								fontSize: 15,								
							}}
							value={width}
							onChange={(e) => setWidth(e.target.value)}
						/>
						<input 
							type="text" 
							placeholder="Height in cm"
							className="_1lSOQ6q2xcOkaxVT1smcn7 _3gouMfJy504Yjz5rHjoHpu" 
							style={{
								width: '32%', 
								height: '100%',
								padding: 12,
								fontSize: 15,								
							}}
							value={height}
							onChange={(e) => setHeight(e.target.value)}
						/>
					</div>)}
					
		  </div>	

	  </Drawer>
    </>
  );
};

export default ErrandFormModal;
