import React, { useEffect, useState } from "react";
import { utils } from "../../utils";
import { useParams } from "react-router-dom";
import {
  Button,
} from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { LoginSignupModal } from '../../components';

const ErrandPaymentSummarySection = ({
  subTotal,
	currency,
	taxrate,
	servicerate,
	latitude,
	longitude,
	storeLat,
	storeLng,
	tip,
	tipPercentage,
	handleTipPercentage,
	handleCustomTip,
	handleCoupon,
	applyCoupon,
	strikeFee,
	coupon,
	couponError,
	strikeValue,
	couponAmount,
	shopStoreName,
	cartStoreName,
	card,
	defaultCard,
	onFinish,
	errandInfo,
	updateErrandData,
	tipError,
	setPaymentInfoData,
	displayFeeInfo,
}) => {

  const navigate = useNavigate();
  const [totalNow, setTotalNow] = useState("");
  const [deliveryTip, setDeliveryTip] = useState("");
  const [minimumTip, setMinimumTip] = useState(0);
  const [deliveryFee, setDeliveryFee] = useState("");
  const [serviceCharge, setServiceCharge] = useState("");
  const [salesTax, setSalesTax] = useState("");
  const { stateId, cityId, zipId, shopName, shopId } = useParams();
  const ellogin = utils.getStorage('__el_data_login_status');
  
  useEffect(() => {
		const initial = async() => 
		{
			let deliveryFee, deliveryTip, serviceCharge, salesTax, sumTotal, sumTotal2, mins, miles, totalNow, totalNow2;
			if (parseFloat(latitude) !== 0 && storeLat) 
			{		
				if(parseFloat(subTotal) > 0)
				{				
					const mph = await utils.getDriveData('mph');
					let perHrEarnings = await utils.getDriveData('perHrEarnings');
					let xtra = await utils.getDriveData('errandExtra');
					perHrEarnings = parseFloat(perHrEarnings) + parseFloat(xtra);
					const meterPerMile = await utils.getDriveData('meterPerMile');
					const minTip = await utils.getDriveData('minTip');
					setMinimumTip(minTip);
					
					const start = [storeLat, storeLng];
					const end = [latitude, longitude];
					const distanceInMeters = utils.gis.calculateDistance(start, end);
					miles = distanceInMeters / meterPerMile;
					deliveryFee = utils.errandDeliveryFee(miles, mph, perHrEarnings);
					const hours = miles / mph;
					mins = hours * 60; // in minutes	
					
					serviceCharge = utils.serviceCharge(
						deliveryFee,
						parseFloat(servicerate) * 100
					);
					
					if (tipPercentage) {
						deliveryTip = utils.errandDeliveryTip(deliveryFee, tipPercentage, minTip);
					} else {
						deliveryTip = tip
						? utils.errandDeliveryDefaultTip(deliveryFee, minTip, tip)
						: utils.errandDeliveryDefaultTip(deliveryFee, minTip);
					}
					
					sumTotal = (parseFloat(subTotal) +
						parseFloat(serviceCharge) +
						parseFloat(deliveryFee));
						
					sumTotal2 = strikeFee ? 
						(parseFloat(subTotal) +
						parseFloat(serviceCharge))
						: (parseFloat(subTotal) +
						parseFloat(serviceCharge) +
						parseFloat(deliveryFee));
						
					salesTax = parseFloat(sumTotal * taxrate).toFixed(2);
					totalNow = parseFloat(
						parseFloat(sumTotal) + parseFloat(salesTax) + parseFloat(deliveryTip)
					).toFixed(2);
					
					totalNow2 = strikeFee ? parseFloat(
						parseFloat(sumTotal2) + parseFloat(salesTax) + parseFloat(deliveryTip)
					).toFixed(2) : parseFloat(
						parseFloat(sumTotal) + parseFloat(salesTax) + parseFloat(deliveryTip)
					).toFixed(2);
				}
				else
				{
					serviceCharge = '0.00';
					deliveryTip = '0.00';
					sumTotal = '0.00';
					sumTotal2 = '0.00';
					salesTax = '0.00';
					totalNow = '0.00';
					totalNow2 = '0.00';
					deliveryFee = '0.00';
					mins = 0;
					miles = 0;
				}
				
				if(strikeFee){
					setTotalNow(totalNow2);
				}else{
					setTotalNow(totalNow);
				}
				setDeliveryTip(deliveryTip);
				setDeliveryFee(deliveryFee);
				setServiceCharge(serviceCharge);
				setSalesTax(salesTax);
				setPaymentInfoData({
					tip: deliveryTip,
					tipPercentage: tipPercentage,
					tax: salesTax,
					serviceCharge: serviceCharge,
					subTotal: subTotal,
					total: totalNow,
					deliveryFee: deliveryFee,
					minutes: mins,
					miles: miles,
					coupon: coupon,
					strikeFee: strikeFee,
					strikeValue: strikeValue,
					couponAmount: couponAmount,
				});
				
				const newErrandData = {
					...errandInfo,
					tip: deliveryTip,
					tipPercentage: tipPercentage,
					tax: salesTax,
					serviceCharge: serviceCharge,
					subTotal: subTotal,
					total: totalNow,
					deliveryFee: deliveryFee,
					minutes: mins,
					miles: miles,
					coupon: coupon,
					strikeFee: strikeFee,
					strikeValue: strikeValue,
					couponAmount: couponAmount,
				};		
				
				updateErrandData(newErrandData);
				
			}else if(parseFloat(latitude) === 0){
				
				const getAddr = await utils.getLocalCookie("elAddr");
				if(getAddr?.address){
					
					const newErrandData = {
						...errandInfo,
						address: getAddr?.address,
						latitude: getAddr?.latitude,
						longitude: getAddr?.longitude,
					};		
					
					updateErrandData(newErrandData);
				}
				
			}		
		}
		initial();
  }, [tip, tipPercentage, subTotal, latitude, strikeFee, strikeValue]);
  
  useEffect(() => {
	if (errandInfo && parseFloat(deliveryFee) > 0) 
	{	
		setDeliveryFee(errandInfo?.deliveryFee);
		const newErrandData = {
		  ...errandInfo,
		  deliveryFee: errandInfo?.deliveryFee,
		};
		updateErrandData(newErrandData);
		
	}
  }, [errandInfo?.deliveryFee]);

  return (
	<>	
    <div
      data-name="review_order_section"
      className="_3qmRovatNE8F-poVd71z1x"
      style={{ position: "sticky", top: "0" }}
    >
      {shopStoreName === cartStoreName && (
        <div className="_2H1xmkEO8YyPfcJK2JBPRf">
		  <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: 25}}>
			<Button type="text" className="backShop" style={{ display: 'flex', alignItems: 'center', minWidth: 0, fontWeight: 600}} icon={<ArrowLeftOutlined />} size={`large`} onClick={()=> navigate(`/search/errands`, { replace: false })}>GO BACK</Button>
			
			
			{ellogin !== '1' && (              
				<Button type="primary" style={{minWidth: 0, backgroundColor: '#201e1c', fontWeight: 700, textTransform: 'uppercase'}} size={`large`}><LoginSignupModal/></Button>
			)}
		  </div>		  
		  
          <div className="_3d6nC894F8WmQuCr07KBmV">			
            <button
              className="_1CwjZK_2Wbc4SetuxezVf9 _2s2DfQ5MrOu2S-UPSvVNvV kWIBU2YWlCYMwPcuZ1LGo"
              tabIndex="0"
              type="button"
              id="card-button"
              onClick={onFinish}
            >
              <span className="pM13OFAYII2NPEKQyOhw0">Pay Now</span>
              <span className="_2j28j12Hog5LpqnjU_RN_6">
                {`${currency}${totalNow}`}
              </span>
            </button>
          </div>
        </div>
      )}

      <div className="_3cy9d0UulZVYEbBYfzpqOI">
        <div className="Ic_d9HedAzQOW2XGCAfTt">
          <div className="PEPfcZBRW0x-qBmIZymWP">
            <div className="_3MgjRlaF4xz3pfsIgczfD2" data-active="false">
						
						
							<div
                className="_2f3eYR_d4YoVfew7WnuhRoo"
                data-active={`${tipPercentage === 0 ? "true" : "false"}`}
								style={{
									left: 0,  
									overflow: 'hidden',
									position: 'relative',
									width: '100%',
									opacity: 1,
								}}
              >
                <div className="yNIm2lvE_j8Du8jBoXORx">
                  <label
                    htmlFor="customTipValue"
                    className="_2LHIV8HfhKnOukKsXBjlZC"
                  >
                    <input
                      unit={currency}
                      type="text"
                      maxLength={10}
                      placeholder="COUPON CODE"
                      aria-label="Coupon"
                      id="couponValue"
                      className="_1FZmgafDZ0ZT6CJwZ1-Hbj pD2uv1-3ZsHbVFTwgeXuH"
                      value={coupon}
                      onChange={(e) => handleCoupon(e.target.value)}
											style={{fontSize: 16}}
                    />
										<span  
											style={{
												right: 20, 
												top: 15, 
												fontSize: 16, 
												color: '#536a3d',
												position: 'absolute',
												cursor: 'pointer',
											}}
											onClick={()=> applyCoupon(coupon)}
										>APPLY COUPON</span>
										{couponError && (
											<span style={{ color: "#cc0023", fontSize: 13 }}>
												{`${couponError}.`}
											</span>
										)}
                  </label>
				  
                </div>
             </div>
              
              <div className="_355vsd42sm3bOJ-pF_EohX">
                <div className="DbddfTcdzWAtgD3V_wtui">
                  <span className="_39F2vqpsBE7Hjqa_LlkXfW">Delivery Fee</span>
                  <span className="kdBYgFY2CJ3leNObcIYh9">
                    <button
                      tabIndex="0"
                      className="_3iQhJxftrPXNqjrlvxWD49 _3wA0xmLI_Y5mwTRS066tsu"
                      aria-disabled="false"
                      aria-label="More information"
											onClick={()=> displayFeeInfo("Delivery Fee", "The delivery fee for each shop differs depending on various factors, including your location.")}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 13 14"
                        aria-hidden="true"
                        className="_3x3rsGu1COgquP1_9hW0HR"
                      >
                        <title>Info</title>
                        <path
                          fill="currentColor"
                          d="M5.88 3.88h1.25v1.25H5.88V3.88Zm0 2.5h1.25v3.75H5.88V6.37ZM6.5.75a6.25 6.25 0 1 0 0 12.5 6.25 6.25 0 0 0 0-12.5ZM6.5 12a5 5 0 1 1 .01-10.01A5 5 0 0 1 6.5 12Z"
                        ></path>
                      </svg>
                    </button>
                  </span>
                </div>
                <span 
									className="_2zFESOr6H1nuRM7FMEh4f3"
									style={strikeFee ? {textDecoration: 'line-through', color: '#858c98'} : {textDecoration: 'none'}}
								>
                  {`${currency}${deliveryFee}`}
                </span>
              </div>

              <div className="_355vsd42sm3bOJ-pF_EohX">
                <div className="DbddfTcdzWAtgD3V_wtui">
                  <span className="_39F2vqpsBE7Hjqa_LlkXfW">
                    Service Fee
                  </span>
                  <span className="kdBYgFY2CJ3leNObcIYh9">
                    <button
                      tabIndex="0"
                      className="_3iQhJxftrPXNqjrlvxWD49 _3wA0xmLI_Y5mwTRS066tsu"
                      aria-disabled="false"
                      aria-label="More information"
											onClick={()=> displayFeeInfo("Service Fee", `Our services rely on a fee that enables us to sustain them and innovate with new technology. The fee's upper limit is ${currency}10.`)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 13 14"
                        aria-hidden="true"
                        className="_3x3rsGu1COgquP1_9hW0HR"
                      >
                        <title>Info</title>
                        <path
                          fill="currentColor"
                          d="M5.88 3.88h1.25v1.25H5.88V3.88Zm0 2.5h1.25v3.75H5.88V6.37ZM6.5.75a6.25 6.25 0 1 0 0 12.5 6.25 6.25 0 0 0 0-12.5ZM6.5 12a5 5 0 1 1 .01-10.01A5 5 0 0 1 6.5 12Z"
                        ></path>
                      </svg>
                    </button>
                  </span>
                </div>
                <span className="_2zFESOr6H1nuRM7FMEh4f3">
                  {`${currency}${serviceCharge}`}
                </span>
              </div>							
							
              <div className="_355vsd42sm3bOJ-pF_EohX">
                <div className="DbddfTcdzWAtgD3V_wtui">
                  <span className="_39F2vqpsBE7Hjqa_LlkXfW">Sales Tax</span>
                  <span className="kdBYgFY2CJ3leNObcIYh9"></span>
                </div>
                <span className="_2zFESOr6H1nuRM7FMEh4f3">
                  {`${currency}${salesTax}`}
                </span>
              </div>

              <div className="_355vsd42sm3bOJ-pF_EohX">
                <div className="DbddfTcdzWAtgD3V_wtui">
                  <span className="_39F2vqpsBE7Hjqa_LlkXfW">
                    <b>Driver Tip</b>
                  </span>
                  <span className="kdBYgFY2CJ3leNObcIYh9">
                    100% goes to your driver
                  </span>
                </div>
                <span className="_2zFESOr6H1nuRM7FMEh4f3">
                  {`${currency}${deliveryTip}`}
                </span>
              </div>

              <div className="QHHd4zMwtY4PbS57ENGbH">
                <div
                  className="_1j90V-dQzY-_uE7jVkJKOq"
                  data-active={`${tipPercentage === 10 ? "true" : "false"}`}
                  role="button"
                  tabIndex={0}
                  onClick={() => handleTipPercentage(10)}
                >
                  10%
                </div>
                <div
                  className="_1j90V-dQzY-_uE7jVkJKOq"
                  data-active={`${tipPercentage === 15 ? "true" : "false"}`}
                  role="button"
                  tabIndex={0}
                  onClick={() => handleTipPercentage(15)}
                >
                  15%
                </div>
                <div
                  className="_1j90V-dQzY-_uE7jVkJKOq"
                  data-active={`${tipPercentage === 20 ? "true" : "false"}`}
                  role="button"
                  tabIndex={0}
                  onClick={() => handleTipPercentage(20)}
                >
                  20%
                </div>
                <div
                  className="_1j90V-dQzY-_uE7jVkJKOq"
                  data-active={`${tipPercentage === 25 ? "true" : "false"}`}
                  role="button"
                  tabIndex={0}
                  onClick={() => handleTipPercentage(25)}
                >
                  25%
                </div>
                <div
                  className="_1j90V-dQzY-_uE7jVkJKOq"
                  data-active={`${tipPercentage === 0 ? "true" : "false"}`}
                  role="button"
                  tabIndex={0}
									style={{marginRight: 0}}
                  onClick={() => handleTipPercentage(0)}
                >
                  Custom
                </div>
              </div>
              <div
                className="_2f3eYR_d4YoVfew7WnuhRo"
                data-active={`${tipPercentage === 0 ? "true" : "false"}`}
								style={{marginTop: 50}}
              >
                <div className="yNIm2lvE_j8Du8jBoXORx">
                  <label
                    htmlFor="customTipValue"
                    className="_2LHIV8HfhKnOukKsXBjlZC"
                  >
                    <input
                      unit={currency}
                      type="text"
                      maxLength={10}
                      placeholder="Enter custom tip amount"
                      aria-label="Enter custom tip amount"
                      id="customTipValue"
                      className={`_1FZmgafDZ0ZT6CJwZ1-Hbj pD2uv1-3ZsHbVFTwgeXuH ${currency === 'CAD$' ? '_m55' : '_m25'}`}
                      value={tip}											
                      onChange={(e) => handleCustomTip(e.target.value)}
                    />
                    <span className="_7tUKIqpIbqdY6qltkAg4E" style={{fontSize: 14}}>{`${currency}`}</span>
										{tipError && (
											<span style={{ color: "#cc0023", fontSize: 13 }}>
												{`Minimum tip is ${currency}${minimumTip}.`}
											</span>
										)}
                  </label>				  
                </div>
              </div>
            </div>
          </div>

          <div className="a6kXrE6Oy-4wrSbVyCQRr">
            <b>Total</b>
            <span 
							className="sFOqemvNTee_5M0DSKEOV"
							style={strikeValue ? {textDecoration: 'line-through', color: '#858c98'} : {textDecoration: 'none'}}
						>
              <b>{`${currency}${totalNow}`}</b>
            </span>
          </div>
					
					{strikeValue && (<><div className="a6kXrE6Oy-4wrSbVyCQRr" style={{marginTop: -2, color: '#536a3d'}}>
            <b>Coupon Amount</b>
            <span className="sFOqemvNTee_5M0DSKEOV" style={{color: '#536a3d'}} >
              <b>{`- ${currency}${couponAmount}`}</b>
            </span>
          </div><div className="a6kXrE6Oy-4wrSbVyCQRr" style={{marginTop: -2}}>
            <b>Adjusted Total</b>
            <span className="sFOqemvNTee_5M0DSKEOV">
              <b>{`${currency}${(totalNow - couponAmount) < 0 ? '0.00' : (totalNow - couponAmount).toFixed(2)}`}</b>
            </span>
          </div></>)}
					
					
        </div>
        <section
          aria-label="Checkout errors"
          className="FSF87LbkKfS8chI2-NKWJ"
        ></section>
      </div>
    </div>
	</>
  );

};

export default ErrandPaymentSummarySection;
