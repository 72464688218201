import React from 'react';
import { motion, useScroll, useSpring  } from "framer-motion";
//import './SubPrivacyPolicy.css';

const SubPrivacyPolicy = () => {

	const { scrollYProgress } = useScroll();
	const scaleX = useSpring(scrollYProgress, {
		stiffness: 100,
		damping: 30,
		restDelta: 0.001
	});
	
//Last Updated, September 2, 2022

return (
  <>
  <div className="" id="home">
	<div className="typology-fake-bg">
	<div className="typology-section">
	<div className="section-content section-content-a">
	<div className="typology-posts">
	<motion.div className="pprogress-bar" style={{ scaleX }} />
	<article className="typology-post typology-layout-a  post-8485 post type-post status-publish format-standard has-post-thumbnail hentry category-news category-tips tag-188 tag-186 tag-185 tag-entrepreneur tag-fail tag-idea tag-non-tech tag-startup tag-why">
		<header className="entry-header">
			<h2 className="entry-title h1"><a href="https://errandlocal.com">PRIVACY POLICY</a></h2> 
			<div className="entry-meta" style={{marginLeft: 15}}>
				<div className="meta-item meta-author">By <span className="vcard author"><span className="fn">ErrandLocal</span></span>
				</div>
				<div className="meta-item meta-category"><a href="https://errandlocal.com/" rel="category tag">Last Updated, April 4, 2023</a>
				</div>
			</div>
			<div className="post-letter">P</div>
		</header>
		<div className="entry-content">
			<p>ERRANDLOCAL LLC. (also referred to as “ErrandLocal,” “we,” “us,” or “our”) takes steps to protect your privacy.  In this Privacy Notice (“Notice”), we describe the types of personal information we may collect from you in connection with your use of our websites including, but not limited to, <a href="https://errandlocal.com/" target="_blank">{`https://errandlocal.com`}</a>, the ErrandLocal channels, our online ordering platform, mobile application, social media pages, or other online properties (collectively, the “Website”), or when you use any of the products, services, content, features, technologies, or functions we offer (collectively, the “Services”).</p><br/>
            <p>This Notice is designed to help you obtain information about our privacy practices and to help you understand your privacy choices when you use our Website and Services.  Please note that our Service offerings may vary by region.</p><br/>
            <p>For all purposes, the English language version of this privacy notice shall be the original, governing instrument. In the event of any conflict between the English language version of this privacy notice and any subsequent translation into any other language, the English language version shall govern and control.</p><br/>

            <h2 className="project-h2">Personal Information We Collect</h2>
            <p className="mb-0">We collect information that relates to you (“Personal Data”) in connection with your use of the Website, our Services. or otherwise in the context of our relationship with you.  The types of Personal Data that we may obtain from you may include:</p>

            <div className="row">
                <div className="col-md-6 col-12 mb-5 mb-md-0">
                    <h3 className="landing__section-list-header" style={{fontWeight: 600}}>
                        Biographical Data provided directly by you:
                    </h3>
					<div>
                    <ul className="mb-0 list-bullets-styled">
                        <li>Contact and profile information</li>
                        <li>Payment information</li>
                        <li>Transaction information</li>
                        <li>Communications, Participation and other content</li>
                        <li>Other information you provide</li>
                    </ul>
					</div>
                </div>

                <div className="col-md-6 col-12">
                    <h3 className="landing__section-list-header" style={{fontWeight: 600}}>
                        Personal Information Generated and/or Automatically Collected during your use of the Platform and/or Services:
                    </h3>
					<div>
                    <ul className="mb-0 list-bullets-styled">
                        <li>Information about your device and its software</li>
                        <li>Information about the way you access and use our Services</li>
                        <li>Analytics information</li>
                        <li>Location Information</li>
                    </ul>
					</div>
                </div>
            </div>

            <div className="row">
                <div className="col-md-6 col-12 mb-5 mb-md-0">
                    <h3 className="landing__section-list-header" style={{fontWeight: 600}}>
                        Data from other sources:
                    </h3>
					<div>
                    <ul className="mb-0 list-bullets-styled">
                        <li>Information from other Account holders</li>
                        <li>Information we receive from third-party sites you connect to our Services</li>
                        <li>Information we receive from affiliates or third parties</li>
                    </ul>
					</div>
                </div>

                <div className="col-md-6 col-12">
                    <h3 className="landing__section-list-header" style={{fontWeight: 600}}>
                        Device and Website Usage Data, including:
                    </h3>
					<div>
                    <ul className="mb-0 list-bullets-styled">
                        <li>IP Addresses</li>
                        <li>Cookie ID and/or other device identifiers</li>
                        <li>Information relating to your access to the Website, such as device characteristics, date & time</li>
                        <li>Language preferences</li>
                        <li>Information on actions taken while using the Website</li>
                    </ul>
					</div>
                </div>
            </div>

            <div className="row">
                <div className="col-md-6 col-12 mb-5 mb-md-0">
                    <h3 className="landing__section-list-header" style={{fontWeight: 600}}>
                        Data Collected in connection with “Know Your Customer” (KYC) Compliance, including:
                    </h3>
					<div>
                    <ul className="mb-0 list-bullets-styled">
                        <li>Government-issued ID</li>
                        <li>Proof of Address</li>
                        <li>Photographs, if you elect to provide them to us</li>
                        <li>Video, if you elect to provide them to us</li>
                    </ul>
					</div>
                </div>

                <div className="col-md-6 col-12">
                    <h3 className="landing__section-list-header" style={{fontWeight: 600}}>
                        Mobile application usage data, including:
                    </h3>
					<div>
                    <ul className="mb-0 list-bullets-styled">
                        <li>Session data: IP address, version of the operating system, brand and model of the device, unique identifiers of the device, browser used, information about the time the Application was accessed, name and parameters of the network connection.</li>
                        <li>Information about applications installed on the User’s device (metadata from applications): application name, application identifier and version, device identifier and checksum. Detecting malicious apps and protecting users from fraud are the main reasons for collecting information about installed apps.</li>
                        <li>Information on actions taken while using the mobile application</li>
                        <li>Crash and application errors diagnostics data</li>
                    </ul>
					</div>
                </div>
            </div>


            <h2 className="project-h2">How We Use Your Personal Information</h2>
            <p >The business purposes for which we collect, use, retain, and share your Personal Data may include:</p><br/>
			<div>
            <ul className="list-bullets-styled">
                <li>To provide Services through operating the Website, including to:
                    <ul>
                        <li>Register, create, and maintain your account;</li>
                        <li>Authenticate your identity and/or your access to an account, or us verify your identity;</li>
                        <li>Initiate, facilitate, process, and/or execute transactions;</li>
                        <li>Communicate with you regarding your account or any Services you use;</li>
                        <li>Perform creditworthiness, KYC, or other similar reviews;</li>
                        <li>Evaluate applications; or</li>
                        <li>Compare information for accuracy and verification purposes.</li>
                    </ul>
                </li>
                <li>To manage risk and protect you, other persons, and the Website and Services.</li>
                <li>To provide a personalized experience and implement your preferences.</li>
                <li>To better understand customers and how they use and interact with the Website and Services.</li>
                <li>To market to you.</li>
                <li>To provide personalized Services, offers, and promotions on our Website and third-party websites.</li>
                <li>To provide you with location-specific options, functionalities, and offers.</li>
                <li>To comply with our policies and obligations, including, but not limited to, disclosures and responses in response to any requests from law enforcement authorities and/or regulators in accordance with any applicable law, rule, regulation, judicial or governmental order, regulatory authority of competent jurisdiction, discovery request or similar legal process.</li>
                <li>To resolve disputes, collect fees, or troubleshoot problems.</li>
                <li>To provide customer service to you or otherwise communicate with you.</li>
                <li>To manage our business.</li>
            </ul>
			</div>
			
			
            <p>
                We may also process Personal Data for other purposes based upon your consent when required by applicable law.
            </p>

            <h2 className="project-h2">Sources From Which We Collect Personal Information</h2>
			
            <div>We collect Personal Data from a number of sources, including
            <ul className="list-bullets-styled">
                <li>
                    <b>Directly from you: </b>
                    We collect Personal Data directly from you when you use our Website or Services, communicate with us, or interact directly with us.
                </li>
                <li>
                    <b>From service providers and/or data processors who assist us in providing the Website or the Services: </b>
                    We may engage service providers to assist us in facilitating the Website or the Services to you, at our direction and on our behalf.  These service providers may collect information about you and provide it to us.
                </li>
                <li>
                    <b>From other users on the ErrandLocal Website or from affiliates integrated with the ErrandLocal Website or Services: </b>
                    Other users may provide us with information about you in connection with transactions or chats.  Affiliates may also provide information to us about you related to your interactions or transactions with such affiliates.
                </li>
                <li>
                    From third-parties who may help us verify identity, prevent fraud, and protect the security of transactions.
                </li>
                <li>
                    From third-parties who may help us evaluate your creditworthiness or financial standing.
                </li>
                <li>
                    From third-parties who may help us analyze Personal Data, improve the Website or the Services or your experience on it, market products or services, or provide promotions and offers to you.
                </li>
                <li>
                    From social media platforms, if you interact with us through social media.
                </li>
            </ul>
			</div>

            <h2 className="project-h2">Our Sharing and Disclosure of Personal Information</h2>
            <div>Under certain circumstances, we may disclose certain Personal Data with other persons. The categories of persons with whom we may share Personal Data include:
            <ul className="list-bullets-styled">
                <li>
                    <b>Service providers and/or data processors: </b>
                    We may share Personal Data with third-party service providers that perform services and functions at our direction and on our behalf. These third-party service providers may, for example, provide you with Services, verify your identity, assist in processing transactions, send you advertisements for our products and Services, or provide customer support.
                </li>
                <li>
                    <b>Other parties to transactions, such as merchants: </b>
                    We may share information with the other participants to your transactions, including other users from whom you are requesting services from.
                </li>
                <li>
                    Financial institutions and other companies involved in helping you make payments in connection with transactions 
                </li>
                <li>
                    Affiliates that receive referrals from our Website
                </li>
                <li>
                    Advertising, Marketing, and Promotions providers.
                </li>
                <li>Public: 
                    <ul>
                        <li>Ratings and Reviews;</li>
                        <li>Photo Uploads;</li>
                        <li>Chat Functionality;</li>
                    </ul>
                </li>
                <li>Other third-parties for our business purposes or as permitted or required by law, including: 
                    <ul>
                        <li>To comply with any legal, regulatory or contractual obligation, or with any legal or regulatory process (such as a valid court order or subpoena);</li>
                        <li>To establish, exercise, or defend legal claims;</li>
                        <li>In response to a request by a government agency, such as law enforcement authorities or a judicial order;</li>
                        <li>To enforce our Website Terms of Service or our internal policies;</li>
                        <li>To prevent physical harm or financial loss, in connection with an investigation of suspected or actual illegal activity, or to otherwise protect our or others’ rights, property, or safety;</li>
                        <li>To facilitate a purchase or sale of all or part of ErrandLocal’s business.  For example, by sharing data with a company we plan to merge with or be acquired by; or</li>
                        <li>To support our audit, compliance, and corporate governance functions.</li>
                    </ul>
                </li>
            </ul>
			</div>
			
            <h2 className="project-h2">Third-Party Websites</h2>
			
            <p>Our websites and online Services may include links to third-party websites, plug-ins and applications. Except where we post, link to, or expressly adopt or refer to this Privacy Policy, this Privacy Policy does not apply to, and we are not responsible for, any personal information practices of third-party websites and online services or the practices of other third parties. To learn about the personal information practices of third parties, please visit their respective privacy notices.</p>

            <h2 className="project-h2">Cookies and Online Advertising</h2>			
			<div>
            <ul className="list-bullets-styled">
                <li>A cookie is a small text file that a website saves on your computer or mobile device when you visit the website.</li>
                <li>Our Website uses cookies and tracking technologies to operate, and to target advertising that may be of interest to you.  For further information, please refer to our Cookie Policy.</li>
                <li>ErrandLocal may partner with third-party ad networks to either display advertising on the ErrandLocal Website or on third-party websites.  These websites and third-party ad networks are not controlled by ErrandLocal.  Ad network partners use data technologies to collect information about your online activities to provide you targeted advertising based upon your interests. If you wish not to have this information used for the purpose of serving you targeted ads, you may be able to opt-out by visiting:
                    <ul>
                        <li>
							<a target="_blank" href="http://optout.aboutads.info/" rel="noreferrer">{`http://optout.aboutads.info/`}</a>
                        </li>
                        <li>
                            <a target="_blank" href="http://optout.networkadvertising.org/" rel="noreferrer">{`http://optout.networkadvertising.org/`}</a>
                        </li>
                    </ul>
                </li>
            </ul>
			</div>

            <p>Please note this does not opt you out from being served advertising; you will continue to receive generic ads that are not based on your specific interests.
			You can control the use of cookies at the individual browser level. If you reject cookies, you may still use our Website, but your ability to use some features or areas of our Website may be limited.</p>

            <h2 className="project-h2">Data Retention</h2>
            <p>We retain Personal Data for the period necessary for the purposes for which it was collected, or for such periods as required by applicable law.  This may involve retaining Personal Data for periods following a transaction.  We make efforts to delete your Personal Data once it is no longer required for any of the business purposes described above.</p>

            <h2 className="project-h2">Data Security</h2>
            <p>ErrandLocal has implemented safeguards designed to protect your Personal Data, including measures designed to prevent Personal Data against loss, misuse, and unauthorized access and disclosure.  Still, ErrandLocal cannot ensure or warrant the security or confidentiality of information you transmit to us or receive from us by Internet or wireless connection.  Transferring data through the Internet always carries some risk, even if ErrandLocal makes efforts to protect data once it has received it.</p>

            <h2 className="project-h2">Children under 18 years of age</h2>
            <p>ErrandLocal’s Website is not intended for children under the age of 18. We do not knowingly collect data from children under the age of 18 without verified parental consent. If we learn that we have collected information, including Personal Data, from an individual under 18 years of age without parental consent, we will delete that information immediately.</p>

            <h2 className="project-h2">Modifications to the Privacy Notice</h2>
            <p>ErrandLocal reserves the right to change this Notice from time to time. We will notify you of modifications to this Notice by posting a revised version of this Notice here, by email, or by means of a prominent notice on the ErrandLocal Website home page. We recommend that you periodically check the Website for any changes.</p>

            <h2 className="project-h2">Contact us</h2>
            <p>If you have any questions about this Notice, or wish to make an inquiry with us regarding Personal Data or privacy, please contact us at: <a href="mailto:privacy@errandlocal.com">privacy@errandlocal.com</a></p>

		</div>
		<div className="entry-footer">
			<a href="/tos" className="typology-button">Terms of use</a> 
		</div>
	</article>
	</div>
	</div>
	</div>
	</div>
  </div>
  </>
);

}

export default SubPrivacyPolicy;
