import React,{ useEffect, useState } from "react";
import { EditAddress } from '../../components';
import { utils } from '../../utils';
import { v4 as uuidv4 } from "uuid";
import { useParams, useNavigate, useLocation } from 'react-router-dom';

const EditAddressScreen = ({logPage}) => 
{	
	const navigate = useNavigate();
	const location = useLocation();
	
	const { addressId, typeId } = useParams();
	const [refId, setRefId] = useState('');	
	
	useEffect(() => {
		if(!typeId)
		{
			navigate("/notfound");
			return false;
		}
		if(typeId === 'delivery' || typeId === 'errand'){
			document.title = `Edit ${typeId.charAt(0).toUpperCase() + typeId.slice(1)} Address | Order Online | ErrandLocal`;			
		}else{
			navigate("/notfound");
			return false;
		}
		
		// Fetch data using the addressId
		//const address = "7830 N Central Expy Dallas";
		//const newAddress = convertAddress(address);
		//setRefId(typeId+' '+addressId+' '+newAddress);
	}, [addressId, typeId]);
	
	useEffect(() => {
		const ref = document.referrer;
    const loc = location.pathname;
		logPage(ref, loc);
	}, []);
	
	useEffect(() => {
		document.body.style.overflow = "auto";
	}, []);
	
	useEffect(() => {
		const checkLogin = async() => {
			const sessionemail = await utils.getStorage('__el_data_email');
			const redirect = await utils.checkLogin(sessionemail);
			if((redirect && sessionemail) || redirect){
				navigate(redirect);
			}
		}
		checkLogin();		
	}, []);
	
	
	function convertAddress(address){
		return address.toLowerCase().replace(/[^0-9a-zA-Z -]/g, '').replace(/ /g, '-').replace(/--/g, '-');
	}
	
	const SwitchRef = (ref) => {
		navigate(ref);
	}
	
	const onDelete = (ref) => {
		setRefId(ref);
	}
	
	return (
		<>		
			<EditAddress addressId={addressId} typeId={typeId} handleDelete={refId} key={uuidv4()} />
		</>			
	)
};

export default EditAddressScreen;
