import React,{ useEffect, useState } from "react";
import { useLocation, Link } from 'react-router-dom';
import { MainNavbar, SubFooter } from '../../components';
import { motion, useScroll, useSpring  } from "framer-motion";
import { Spin } from "antd";

const FaqGroupScreen = ({sendApp, infoDisplay, logPage}) => 
{
	const [hoveredIndex, setHoveredIndex] = useState(null);
	const [loading, setLoading] = useState(true);
	const location = useLocation();
	
	useEffect(() => {
		document.title = `Help - FAQ | Order Online | ErrandLocal`;
	}, []);
	
	useEffect(() => {
		window.document.body.style.overflow = "auto";
	}, []);	
	
	useEffect(() => {
		const ref = document.referrer;
    const loc = location.pathname;
		logPage(ref, loc);
	}, [location.pathname]);
	
	useEffect(() => {
    const handleLoad = () => {
      // The entire page has finished loading			
      setLoading(false);
    };

		handleLoad();
		/*
    // Attach the handleLoad function to the window.onload event
		// window.onload = handleLoad;

    // Cleanup: Remove the event listener when the component unmounts
    return () => {
     // window.onload = null;
    };
		*/
  }, []);
	
	const { scrollYProgress } = useScroll();
	const scaleX = useSpring(scrollYProgress, {
		stiffness: 100,
		damping: 30,
		restDelta: 0.001
	});
	
	const handleMouseOver = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseOut = () => {
    setHoveredIndex(null);
  };
		
	const groupData = [
		{
			name: 'General',
			description: 'General information about ErrandLocal services.',
			url: './faqs/general',
		},
		{
			name: 'Shipping',
			description: 'Details about shipping and delivery services.',
			url: './faqs/shipping',
		},
		{
			name: 'Returns',
			description: 'Information about the returns and exchange policy.',
			url: './faqs/returns',
		},
		{
			name: 'Account',
			description: 'Questions about your account.',
			url: './faqs/account',
		},
		{
			name: 'Payments',
			description: 'Payment methods and billing-related FAQs.',
			url: './faqs/payments',
		},
	];

	
	return (
    <div>
      {loading ? (
				<div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#ffffff'}}><Spin size={`large`} style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '25%'}}/></div>
      ) : (
        <>
					<MainNavbar sendApp={sendApp} infoDisplay={infoDisplay}/>
					<motion.div className="progress-bar" style={{ scaleX }} />
						<div className="app__header app__wrapper section__padding notfound__bg">
							<div style={styles.container}>
								<h1 style={styles.title}>How can we help you today?</h1>
								<div style={styles.socialMediaContainer}>
									{groupData.map((group, index) => (
										<Link 
											to={`.${group.url}`}
											key={index}
											style={{
												...styles.socialMediaCard,
												backgroundColor: index === hoveredIndex ? '#e0e0e0' : '#ececec',
												color: index === hoveredIndex ? '#3d64a6' : '#444444',
											}}
											onMouseOver={() => handleMouseOver(index)}
											onMouseOut={handleMouseOut}
										>		
											<h2 style={styles.title}>{group.name}</h2>
											<p style={{padding: 10}}>{group.description}</p>
										</Link>
									))}
								</div>
							</div>
						</div>
					<SubFooter sendApp={sendApp} infoDisplay={infoDisplay}/>
				</>
      )}
    </div>
  );
  
};


const styles = {
  container: {
    maxWidth: '800px',
    margin: '0px auto',
    padding: 0,
    textAlign: 'center',
  },
  title: {
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: '20px',
  },
  socialMediaContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    flexWrap: 'wrap',	
  },
  socialMediaCard: {
    margin: '10px',
		display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
		borderRadius: 5,
		borderWidths: 1,
		borderColor: '#000',
		padding: 15,
		width: 300,
		minHeight: 200,
		maxHeight: 250,
		cursor: 'pointer',
  },
  socialMediaIcon: {
    width: '50px',
    height: '50px',
    marginBottom: '10px',
  },
  socialMediaHandle: {
    fontSize: '16px',
    fontWeight: 'bold',
  },
};

export default FaqGroupScreen;

