import React,{useEffect, useState} from "react";
import { Dashboard, AlertModal } from '../../components';
import { utils } from "../../utils";
import Cookies from "js-cookie";
import { message } from "antd";
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { selectOrderData, setOrderData, selectErrandOrderData, setErrandOrderData, setAddressData, setErrandAddressData, setPaymentCardData, selectAddressData, selectErrandAddressData, selectPaymentCardData, nErrandData, nDeliveryData } from "../../includes/navSlice";



const DashboardScreen = ({logPage}) => 
{	
	const navigate = useNavigate();
	const location = useLocation();
	
	const dispatch = useDispatch();
	const preOrderData = useSelector(selectOrderData);
	const preErrandOrderData = useSelector(selectErrandOrderData);
	const orderData = typeof preOrderData === "object" ? preOrderData : JSON.parse(preOrderData);
	const errandOrderData = typeof preErrandOrderData === "object" ? preErrandOrderData : JSON.parse(preErrandOrderData);
	const [alertModalTitle, setAlertModalTitle] = useState("");	
	const [alertModalBody, setAlertModalBody] = useState("");	
	const [alertModalOpen, setAlertModalOpen] = useState(false);	
	const [alertModalButton, setAlertModalButton] = useState("");
	const [alertModalButtonFunc, setAlertModalButtonFunc] = useState();
	const [alertModalButtonFunc2, setAlertModalButtonFunc2] = useState();
	
	useEffect(() => {		
		window.document.body.style.overflow = alertModalOpen ? "hidden" : "auto";
	}, [alertModalOpen]);
	
	useEffect(() => {
		const ref = document.referrer;
    const loc = location.pathname;
		logPage(ref, loc);
	}, []);
	
	useEffect(() => {
		const checkLogin = async() => {
			const sessionemail = await utils.getStorage('__el_data_email');
			const redirect = await utils.checkLogin(sessionemail);
			if((redirect && sessionemail) || redirect){
				navigate(redirect);
			}
		}
		checkLogin();
	}, []);
	
	useEffect(() => {
		document.title = `Dashboard | Order Online | ErrandLocal`;
		const checkLogin = async() => {
			const getAddr = await utils.getLocalCookie('elAddr');
			const sessionemail = await utils.getStorage('__el_data_email');
			
			const redirect = await utils.checkLogin(sessionemail);
			
			if((redirect && sessionemail) || redirect){
				message.error('Please login to continue');
				navigate(redirect);	
				return
			}else if (!getAddr) {
				const authToken = await utils.getStorage('__el_data_login_token');
				const target = await utils.USER_CALL;
				const res = await fetch(target+'?email='+sessionemail, {
					method: 'GET',
					headers: {
						'Content-Type': 'application/json',
						'Accept': 'application/json',
						'Authorization': 'Bearer '+authToken,
					},
					timeout: 5000,
					rejectUnauthorized: false,
				});
				let data = await res.json();
				if(data?.user?.address && data?.user?.gps?.latitude)
				{
					
					let cookieData = {
						address: data?.user?.address,
						city: data?.user?.city,
						state: data?.user?.state,
						zip: data?.user?.zip,
						country: data?.user?.country,
						phone: data?.user?.phone,
						apt: data?.user?.aptsuite,
						access: data?.user?.accesscode,
						instruction: data?.user?.instruction,
						latitude: data?.user?.gps?.latitude,
						longitude: data?.user?.gps?.longitude,
					}
					await utils.setLocalCookie("elAddr", cookieData);
										
					let newDeliveryData = {
						...orderData,
						accessCode: data?.user?.accesscode,
						apartment: data?.user?.aptsuite,
						city: data?.user?.city,
						state: data?.user?.state,
						zip: data?.user?.zip,
						country: data?.user?.country,
						phone: data?.user?.phone,
						address: data?.user?.address,
						firstName: data?.user?.firstName,
						lastName: data?.user?.lastName,
					};
					
					let newErrandData = {
						...errandOrderData,
						accessCode: data?.user?.accesscode,
						apartment: data?.user?.aptsuite,
						city: data?.user?.city,
						state: data?.user?.state,
						zip: data?.user?.zip,
						country: data?.user?.country,
						phone: data?.user?.phone,
						address: data?.user?.address,
						firstName: data?.user?.firstName,
						lastName: data?.user?.lastName,
					};
					
					dispatch(setOrderData(JSON.stringify(newDeliveryData)));
					dispatch(setErrandOrderData(JSON.stringify(newErrandData)));
					dispatch(setAddressData(data?.user?.addresses));
					dispatch(setPaymentCardData(data?.user?.paymentCards));
					dispatch(setErrandAddressData(data?.user?.errandaddresses));
				}
			}
			
			const firstname = await utils.getStorage('__el_data_first_name');
			if(firstname === 'null' || firstname === null || !firstname){
				message.info('Update your delivery address');
				navigate('/delivery-addresses');
				return;
			}
		}
		checkLogin();
	}, []);
	
	const signOut = (title, description) => {
		setAlertModalTitle(title ? title : 'Sign Out');
		const b = description.split('|');
		let mainbody = '';
		for(let i=0; i<b.length; i++){
			mainbody += "<p style='font-size: 18px'>"+b[i]+"</p>";
		}
		setAlertModalBody(mainbody+"<br/><br/><div class='card-number' style='outline: none' key='d1'><div id='modal-status' style='display: none' key='d2'></div></div>");
		setAlertModalButton("2");
		setAlertModalButtonFunc('no');
		setAlertModalButtonFunc2('yes');
		setAlertModalOpen(!alertModalOpen);	
	}
	
	
	const ExecSignOut = (type) => 
	{
		setAlertModalOpen(!alertModalOpen);
		if(type === 'yes')
		{
			localStorage.clear();
			const cookies = Object.keys(Cookies.get());
			cookies.forEach(cookie => {
			  if(cookie !== 'cookieconsent_status'){
				Cookies.remove(cookie);
			  }
			});
			dispatch(setOrderData(JSON.stringify(nDeliveryData)));
			dispatch(setErrandOrderData(JSON.stringify(nErrandData)));			
			navigate('/');
			window.location.reload();
		}
	}
	
		
	return (
	<>
	<Dashboard signOut={signOut}/>
	{alertModalOpen && (<AlertModal setAlertOpenModal={setAlertModalOpen} alertModalTitle={alertModalTitle} alertModalBody={alertModalBody} alertModalButton={alertModalButton} setAlertModalButtonFunc={()=> ExecSignOut(alertModalButtonFunc)} setAlertModalButtonFunc2={()=> ExecSignOut(alertModalButtonFunc2)} alertType="signout"/>)}
	</>)
};

export default DashboardScreen;

