import React, {useEffect} from "react";
import { SubPrivacyPolicy, SubFooter, SubNavbar } from '../../components';
import { useLocation } from 'react-router-dom';

const PrivacyScreen = ({sendApp, infoDisplay, logPage}) => {
	
	const location = useLocation();
	
	useEffect(() => {
		window.document.body.style.overflow = "auto";
	}, []);
	
	useEffect(() => {
		const ref = document.referrer;
    const loc = location.pathname;
		logPage(ref, loc);
	}, []);	
	
	return (
	<>
		<SubNavbar infoDisplay={infoDisplay} />
		<SubPrivacyPolicy infoDisplay={infoDisplay} />
		<SubFooter sendApp={sendApp} infoDisplay={infoDisplay}/>
	</>
	)
};

export default PrivacyScreen;