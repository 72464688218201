import React, { useState, useEffect } from 'react';
import { utils } from "../../utils";
import { Modal, Button, message } from "antd";

const NotAllowedScreen = ({userCountry = false, setUserCountry}) => {
  const [showForm, setShowForm] = useState(false);	
	const [selectedCountry, setSelectedCountry] = useState(''); 
	
	useEffect(() => {
    // Function to get user's country using geolocation API
    const getForm = async () => {
      try {
				const region = await utils.getStorage('__user_region');
				if(!region){
					setShowForm(true);
				}				
      } catch (error) {}
    };
    // Call the function to get user's country on component mount
    getForm();
  }, []);

  useEffect(() => {
    // Function to get user's country using geolocation API
    const getUserCountry = async () => {
      try {
				const region = await utils.getStorage('__user_region');
				if(region){
					setUserCountry(region);
					utils.setStorage('__user_region', region);
				}				
      } catch (error) {
        // Set a default country or handle the error accordingly
        setUserCountry('Unknown');
      }
    };
    // Call the function to get user's country on component mount
    getUserCountry();
  }, []);	
	
  useEffect(() => {
    // List of supported countries
		const isPresent = utils.supportedCountries.some(country => country.code === userCountry);

    // Check if the user's country is in the supported list
    if (isPresent && userCountry) {	
      utils.setStorage('__user_region', userCountry);
			window.location.reload();
    }
  }, [userCountry]);
	
	const handleCountryChange = (event) => {
    setSelectedCountry(event.target.value);
  };

  const updateLastVisit = () => {
    setShowForm(false);
  };

  const handleSubmit = async () => {
		if(!selectedCountry){
			updateLastVisit();
			return;
		}
		await setUserCountry(selectedCountry);
    await utils.setStorage('__user_region', selectedCountry);
		updateLastVisit();
  };	

  if (showForm) {
    return (
      <Modal
        visible={showForm}
        title={'OUT OF SERVICE COUNTRY'}
        onCancel={() => updateLastVisit()}
        footer={[
          <Button
            key="submit"
            className="c__button"
            onClick={() => handleSubmit()}
          >
            Continue
          </Button>,
          <Button
            key="cancel"
            className="c__button"
            onClick={() => updateLastVisit()}
          >
            Cancel
          </Button>
        ]}
        style={{
          maxHeight: '80vh',
          overflow: 'auto',
        }}
      >
        <div style={{ backgroundColor: "rgba(204,220,205,0.2)", padding: 20 }}>
					<span>{`Select a country to continue.`}</span>
          <div style={{ marginTop: -5 }}>
						<select
							id="countrySelect" 
							onChange={handleCountryChange} 
							value={selectedCountry}
							className="_1lSOQ6q2xcOkaxVT1smcn7 _3gouMfJy504Yjz5rHjoHpu"
              style={{
                width: '100%',
                height: '100%',
                padding: 12,
                fontSize: 15,
              }}
						>
							<option value="">Select a country</option>
							{utils.supportedCountries.map(country => (
								<option key={country.code} value={country.code}>
									{country.lng}
								</option>
							))}
						</select>
          </div>
        </div>
      </Modal>
    );
  } else if (userCountry === false) {
		return null;
	} else if (userCountry === null) {
    return (
			<div className="restricted-page-container">
				<div className="restricted-message">
					<h1>{`Sorry, this page is not available in your country.`}</h1>
					<p>{`Contact support for more information.`}</p>
				</div>
			</div>
		);
  }
};

export default NotAllowedScreen;