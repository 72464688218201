import React, {useEffect, useState} from "react";
import { SubFooter, HomeNavbar, EmailSubscriptionForm } from '../../components';
import { Input, Button, message } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import { utils } from "../../utils";
import { useSelector, useDispatch } from "react-redux";
import {
  selectOrderData,
  selectErrandOrderData,
  setSavedAddresses,
  setOrderData,
  setErrandOrderData,
} from "../../includes/navSlice";
import { useNavigate, useLocation } from 'react-router-dom';

const HomeScreenCA = ({sendApp, infoDisplay, logPage}) => {
	
	const navigate = useNavigate();	
	const location = useLocation();	
	const dispatch = useDispatch();
	const preOrderData = useSelector(selectOrderData);
	const preErrandOrderData = useSelector(selectErrandOrderData);
	const orderData =
	typeof preOrderData === "object" ? preOrderData : JSON.parse(preOrderData);
	const errandOrderData =
	typeof preErrandOrderData === "object" ? preErrandOrderData : JSON.parse(preErrandOrderData);
	const [click, setClick] = useState(false);
	const [address, setAddress] = useState('');
	const [subscription, setSubscription] = useState(false);
	const [errorDisplay, setErrorDisplay] = useState('');
	const [deliveryInfo, setDeliveryInfo] = useState(orderData);
	const [errandInfo, setErrandInfo] = useState(errandOrderData);
	const ellogin = utils.getStorage('__el_data_login_status');	
	
	useEffect(() => {
		window.document.body.style.overflow = "auto";
	}, []);
	
	
	useEffect(() => {
		document.title = `Order Online | ErrandLocal`;
		const checkAddress = async() => {
			try{
				const getAddr = await utils.getLocalCookie('elAddr');
				if(getAddr?.address){
					navigate('/search', { replace: true });
					}else if(ellogin === '1'){
					navigate('/search', { replace: true });
				}
			}catch(e){}
		}
		checkAddress();
	}, []);
	
	useEffect(() => {
		const ref = document.referrer;
    const loc = location.pathname;
		logPage(ref, loc);
		setTimeout(()=>{
			setSubscription(true);
		}, 30000);
	}, []);
	
	const updateDeliveryData = async (data) => 
	{
		try {
		  setDeliveryInfo(data);
		  dispatch(setOrderData(JSON.stringify(data)));
		} catch (e) {}
	};
	
	const updateErrandData = async (data) => 
	{
		try {
		  setErrandInfo(data);
		  dispatch(setErrandOrderData(JSON.stringify(data)));
		} catch (e) {}
	};
	
	const onAddressSubmit = async () => 
	{
		try{
			setClick(true);
			setErrorDisplay('');
			if(address){}else{
				setClick(false);
				setErrorDisplay('Valid delivery address is required [Example: 1 Main St, Vancouver, BC]');
				message.error('Please enter delivery address');				
				return;
			}
			
			const target = utils.USER_CALL;
			const header = new Headers({
				'Content-Type': 'application/json',
				'Accept': 'application/json',
				'Authorization': 'Bearer ',
			});
			const region = await utils.getLocalCookie('__user_region');
			let posturl = '';
			
			if(region){
				posturl = utils.BASE_URL+target+'?request=address&address='+address+'&homepage=true&country='+region;
				}else{
				window.location.reload();
				return;
			}
			
			const dataToPost = "";
			const res = await utils.fetchURL(posturl, 'GET', dataToPost, header);
			const fetchParams = await res.json();
			setClick(false);
			if(fetchParams?.data?.zip){
				const getAddr = await utils.getLocalCookie("elAddr");
				
				let cookieData = {
					...getAddr,
					address: fetchParams?.data?.address,
					city: fetchParams?.data?.city,
					state: fetchParams?.data?.state,
					zip: fetchParams?.data?.zip,
					country: fetchParams?.data?.country,
					latitude: fetchParams?.data?.loc?.latitude,
					longitude: fetchParams?.data?.loc?.longitude,
				}
				await utils.setLocalCookie("elAddr", cookieData);
				
				const newDeliveryData = {
				  ...deliveryInfo,	  
				  type: 'Delivery',
				  deliveryFee: 0,
				  address: fetchParams?.data?.address,
				  apartment: '',
				  latitude: fetchParams?.data?.loc?.latitude,
				  longitude: fetchParams?.data?.loc?.longitude,
				  city: fetchParams?.data?.city,
				  state: fetchParams?.data?.state,
				  zip: fetchParams?.data?.zip,
				  country: fetchParams?.data?.country,
				  accessCode: '',
				};
				
				const newErrandData = {
				  ...errandInfo,	  
				  type: 'Errand',
				  deliveryFee: 0,
				  address: fetchParams?.data?.address,
				  apartment: '',
				  latitude: fetchParams?.data?.loc?.latitude,
				  longitude: fetchParams?.data?.loc?.longitude,
				  city: fetchParams?.data?.city,
				  state: fetchParams?.data?.state,
				  zip: fetchParams?.data?.zip,
				  country: fetchParams?.data?.country,
				  accessCode: '',
				};
				
				updateDeliveryData(newDeliveryData);
				updateErrandData(newErrandData);
				
				let newSavedData = {
					id: 'default',
					addressLabel: '',
					address: fetchParams?.data?.address,
					addressMain: true,				
					apartment: '',
					latitude: fetchParams?.data?.loc?.latitude,
					longitude: fetchParams?.data?.loc?.longitude,
					city: fetchParams?.data?.city,
					state: fetchParams?.data?.state,
					zip: fetchParams?.data?.zip,
					country: fetchParams?.data?.country,
					accessCode: '',
					instruction: '',
				};			
				dispatch(setSavedAddresses(JSON.stringify([newSavedData])));
				navigate('/search', { replace: false });
				
				}else if(fetchParams?.error){
				if(fetchParams?.msg.toLowerCase() === 'invalid address'){
					setErrorDisplay('Valid delivery address is required [Example: 1 Main St, Vancouver, BC]');
					message.error('Please enter a valid delivery address');
					setTimeout(()=>{
						navigate('/search', { replace: false });
					}, 5000);	
					}else{
					setErrorDisplay(fetchParams?.msg);
					message.error(fetchParams?.msg);
				}
				}else{
				setErrorDisplay('Valid delivery address is required [Example: 1 Main St, Vancouver, BC]');
				message.error('Please enter a valid delivery address');
			}
			}catch(e){
			setClick(false);
			message.error(e.message);
		}		
	}	
	
	const handleKeyDown = (event) => {
		if (event.key === "Enter") {
		  if(!click){
				setClick(true);
				onAddressSubmit();
			}
		}
	};
	
	return (
		<>
		<HomeNavbar sendApp={sendApp} infoDisplay={infoDisplay}/>
		
		<div id="content" className="project-content">
		<main className="main">					
		<section className="pt-lg-5 mt-lg-5">				  
		<section
		id="projects-list"
		data-aos="fade-up"
		className="aos-init aos-animate"					
		>
		<>
		<h2 className="project-h2" style={{display: 'flex', justifyContent: 'center', padding: '0 20px', marginTop: -50}}>{`Enter your address 📍`}</h2>
		<div className="search-input-container">
		<Input placeholder="Enter delivery address" className="search-input" value={address} onChange={(e) => setAddress(e.target.value)} onKeyDown={handleKeyDown}/>
		{click && (
		<Button type="text" shape="circle" icon={<ArrowRightOutlined />} className="submit-button" loading />)}
		{!click && (
		<Button type="text" shape="circle" icon={<ArrowRightOutlined />} className="submit-button" onClick={()=> onAddressSubmit()} />)}
		</div>
		<span style={{color: 'red', display: 'flex', justifyContent: 'center', padding: '0 20px'}}>{errorDisplay}</span>
		</>
		<div className="project-container">
		<div className="project-section-title">
		<p className="project-h6 subtitle">WITH ERRANDLOCAL</p>
		<h2 className="project-h2">Experience fast errand services in Canada!</h2>
		<ul className="service">
		<li>Mail and Package Pickup.</li>
		<li>Grocery Shopping.</li>
		<li>Prescription Medication Pickup.</li>
		<li>Pet Supplies.</li>
		<li>Dry Cleaning Pickup.</li>
		<li>Takeout or Food Delivery.</li>
		<li>Package Drop-off.</li>
		<li>Office Supplies.</li>
		<li>Fresh Flower Pickup.</li>
		<li>Bakery Pickup.</li>
		<li>Laundry Pickup/Drop-off.</li>
		<li>Household Essentials.</li>
		<li>Newspaper or Magazine Delivery.</li>    
		<li>& Many More 🚚.</li>    
		</ul>	
		</div>						
		{/*<div className="project-section-title">
			<p className="project-h6 subtitle">COUNTIES SERVED</p>
			<h2 className="project-h2">Dallas, Collin, Denton, Rockwall, Kaufman, Tarrant, Ellis, Fannin, Grayson, Hunt, Johnson, Parker.</h2>
		</div>*/}
		<div className="project-row" style={{marginTop: -150}}>
		<div
		data-aos="fade-up"
		className="col-lg-6 aos-init"
		>
		<div className="image">
		<a href="https://get.errandlocal.com/">
		<img
		src="https://errandlocal.com/images/restaurant.jpg"
		height="755px"
		className="lazyload featured img-fluid"
		width="640px"
		alt=""
		/>
		</a>
		</div>
		</div>
		<div
		data-aos="fade-up"
		className="col-lg-6 aos-init"
		>
		<div className="text-info">
		<span
		className="categories "
		>
		<span className="tag">MORE CUSTOMERS</span>
		</span>
		<span
		className="categories ml-1"
		>
		<span className="tag">INCREASED SALES</span>
		</span>
		<h2 className="title project-h2">OWN A RESTAURANT?</h2>
		<p className="project-p">
		Join the ErrandLocal family and reach new customers! List your restaurant with us and let us help you deliver delicious meals straight to their doorstep.
		</p>
		<a
		href="https://get.errandlocal.com/"
		className="btn btn-outline-secondary mt-lg-2"
		>
		GET STARTED
		</a>
		</div>
		</div>
		</div>
		
		<div className="project-row flex-row-reverse">
		<div
		data-aos="fade-up"
		className="col-lg-6 aos-init"
		>
		<div className="image">
		<span>
		<img
		src="https://errandlocal.com/images/available.png"
		height="755px"
		className="lazyload featured img-fluid"
		width="640px"
		alt=""
		/>
		</span>
		</div>
		</div>
		<div
		data-aos="fade-up"
		className="col-lg-6 aos-init"
		>
		<div className="text-info">
		<h2 className="title project-h2">MOBILE APP</h2>
		<p className="project-p">
		Get errands done in a flash with ErrandLocal App - your one-stop-shop for quick and reliable food delivery and task completion. Download now and experience convenience like never before!
		</p>
		<p className="project-p">
		Available on IOS App Store & Google Play.
		</p>
		<span style={{cursor: 'pointer'}} onClick={()=> sendApp()} className="btn btn-outline-secondary mt-lg-2">
		DOWNLOAD APP
		</span>
		</div>				  
		</div>
		</div>
		
		<div className="project-row">
		<div
		data-aos="fade-up"
		className="col-lg-6 aos-init"
		>
		<div className="image">
		<a href="https://get.errandlocal.com/">
		<img
		src="https://errandlocal.com/images/driver.png"
		height="755px"
		className="lazyload featured img-fluid"
		width="640px"
		alt=""
		/>
		</a>
		</div>
		</div>
		<div
		data-aos="fade-up"
		className="col-lg-6 aos-init"
		>
		<div className="text-info">
		<span
		className="categories "
		>
		<span className="tag">EARN CAD$30/HR</span>
		</span>
		<span
		className="categories ml-1"
		>
		<span className="tag">100% TIPS</span>
		</span>
		<h2 className="title project-h2">DRIVE WITH US</h2>
		<p className="project-p">
		Looking for a flexible and rewarding way to earn extra income? Join the ErrandLocal team today and become a delivery driver! Sign up now to start enjoying the freedom and benefits of working on your own schedule.
		</p>
		<a
		href="https://driver.errandlocal.com/"
		className="btn btn-outline-secondary mt-lg-2"
		>
		SIGN UP NOW
		</a>
		</div>
		</div>
		</div>		
		
		</div>
		</section>
		</section>
		</main>
		</div>
		{subscription && <EmailSubscriptionForm />}
		<SubFooter sendApp={sendApp} infoDisplay={infoDisplay}/>
		</>
	)
};

export default HomeScreenCA;
