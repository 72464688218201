import React, {useState, useEffect} from 'react';
import { GiHamburgerMenu } from 'react-icons/gi';
import { v4 as uuidv4 } from "uuid";
import { useNavigate } from 'react-router-dom';
import { MdOutlineRestaurantMenu } from 'react-icons/md';
import images from '../../constants/images';
import { Modal, AlertModal, CountrySelectorHead } from '../../components';
import { utils } from '../../utils';
import { message } from "antd";
import Cookies from "js-cookie";

const HomeNavbar = () => {
	
  const navigate = useNavigate();
	
	const [region, setRegion] = useState('');
  const [click, setClick] = useState(false);
  const [toggleMenu, setToggleMenu] = useState(false);
  const [sitePhone, setSitePhone] = useState('');
  const [modalBody, setModalBody] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const [modalPage, setModalPage] = useState('');
  const [modalButton, setModalButton] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalButtonFunc, setModalButtonFunc] = useState(null);
  
  const [alertModalTitle, setAlertModalTitle] = useState("");	
  const [alertModalBody, setAlertModalBody] = useState("");	
  const [alertModalOpen, setAlertModalOpen] = useState(false);	
  const [alertModalButton, setAlertModalButton] = useState("");
  const [alertModalButtonFunc, setAlertModalButtonFunc] = useState();
  const [alertModalButtonFunc2, setAlertModalButtonFunc2] = useState();
  const sessionemail = utils.getStorage('__el_data_email');
  const ellogin = utils.getStorage('__el_data_login_status');
  const eltoken = utils.getStorage('__el_data_login_token');
	
  useEffect(() => {		
		window.document.body.style.overflow = alertModalOpen ? "hidden" : "auto";
	}, [alertModalOpen]);
  
  useEffect(() => {		
		window.document.body.style.overflow = modalOpen ? "hidden" : "auto";
	}, [modalOpen]);
  
  useEffect(() => {		
		setClick(click);
	}, [click]);	
	
  useEffect(() => {		
		const getHis = async() => 
		{		
			const region = await utils.getLocalCookie('__user_region');
			const isPresent = await utils.supportedCountries.find(country => country.code === region);
			
			if(region){
				setRegion(region);
				setSitePhone(isPresent?.phone);
			}
		}
		getHis();
	}, []);
	  
  
  const sendLink = async (obj) => {
		try{
			let url, state = document.getElementById('modal-status');
			setClick(true);		
			const authToken = eltoken ? eltoken : "";
			if(modalPage === 'merchant'){
				url = utils.MERCHANT_URL;
				}else if(modalPage === 'driver'){
				url = utils.DRIVER_URL;
				}else if(modalPage === 'customer'){
				url = utils.USER_URL;
			}
			const header = {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+authToken
			};
			const dataToPost = await utils.cipherEncode(JSON.stringify(obj));
			const res = await utils.fetchURL(url, 'POST', dataToPost, header);
			const data = await res.json();
			setClick(false);
			
			if(data.msg === 'Success'){
				await setModalOpen(!modalOpen);
				message.success('Download link was sent to your phone');				
				}else{				
				state.style.display = 'block';
				state.className = 'cus_error center show';		
				state.innerHTML = '<b>'+data.msg+'</b>';
			}
			}catch(e){
			setClick(false);
			message.error(e.message);
		}
		
	}
	
  const ExecOperation = (type) => {
		
		let linkphonetype;				
		let state = document.getElementById('modal-status');
		let linkphone = document.getElementById('linkphone').value;		
		
		if(type === 'notspecific'){
			if(!document.getElementById('android').checked && !document.getElementById('ios').checked){
				state.style.display = 'block';
				state.className = 'cus_error center show';		
				state.innerHTML = '<b>Choose phone operating system</b>';
				return;
			}
			if(document.getElementById('android').checked){
				linkphonetype = 'android';
				}else if(document.getElementById('ios').checked){
				linkphonetype = 'ios';
				}else {
				return;
			}
			}else{
			if(type){
				linkphonetype = type;
				}else {
				return;
			}
		}
		
		if(!linkphone){
			state.style.display = 'block';
			state.className = 'cus_error center show';		
			state.innerHTML = '<b>Phone number is required</b>';
			return;
		}
		
		var obj = {
			'data_type': 'linkrequest',
			'data_os': linkphonetype,			
			'data_phone': linkphone,
		}
		
		sendLink(obj);	
	}
  
  const sendAppCustomer = (app) => {
		setModalPage('customer');
		if(app){	
			setModalTitle('ErrandLocal App - '+app);
			setModalBody("<label key='l1'>Phone number *</label><br/><input maxlength=10 type='phone' id='linkphone' placeholder='' class='modal-text-input' name='linkphone'/><br/><br/><div class='card-number' style='outline: none' key='d1'><div id='modal-status' style='display: none' key='d2'></div></div>");
			setModalButton(true);
			setModalButtonFunc(app);
			setModalOpen(!modalOpen);
			window.document.body.style.overflow = 'hidden';
			}else{
			setModalTitle('ErrandLocal App');
			setModalBody("<label key='l1'>Choose operating system *</label><br/><br/>Android <input type='radio' id='android' class='modal-radio' name='os'/> | iOS <input type='radio' id='ios' class='modal-radio' name='os'/> <br/><br/><label key='l2'>Phone number *</label><br/><input maxlength=10 type='phone' id='linkphone' class='modal-text-input' name='linkphone'/><br/><br/><div class='card-number' style='outline: none' key='d1'><div id='modal-status' style='display: none' key='d2'></div></div>");
			setModalButton(true);
			setModalButtonFunc('notspecific');
			setModalOpen(!modalOpen);
			window.document.body.style.overflow = 'hidden';
		}
	}
  
  
  const signOut = (title, description) => {
		setAlertModalTitle(title ? title : 'Sign Out');
		const b = description.split('|');
		let mainbody = '';
		for(let i=0; i<b.length; i++){
			mainbody += "<p style='font-size: 18px'>"+b[i]+"</p>";
		}
		setAlertModalBody(mainbody+"<br/><br/><div class='card-number' style='outline: none' key='d1'><div id='modal-status' style='display: none' key='d2'></div></div>");
		setAlertModalButton("2");
		setAlertModalButtonFunc('no');
		setAlertModalButtonFunc2('yes');
		setAlertModalOpen(!alertModalOpen);	
	}
	
  const ExecSignOut = async(type) => {
		setAlertModalOpen(!alertModalOpen);
		if(type === 'yes')
		{			
			const cookies = Object.keys(Cookies.get());
			cookies.forEach(cookie => {
				if (!utils.cookieExceptions.includes(cookie)) {
					Cookies.remove(cookie);
				}
			});

			const storage = Object.keys(localStorage);
			storage.forEach(store => {
				if (!utils.storeExceptions.includes(store)) {
					localStorage.removeItem(store);
				}
			});
			navigate('/');
			window.location.reload();
		}
	}
  
  
  return (
		<>
    {ellogin === '1' && (<nav className="app__navbar">
      <div className="app__navbar-logo">
			<a href="https://errandlocal.com" className=""><img src={images.fullerrandlocal} alt={`ERRANDLOCAL ${region}`} /></a>
      </div>
      <ul className="app__navbar-links">
			<li><a className="p__opensans" onClick={()=> navigate('/')}>Order Food</a></li>
			<li><a className="p__opensans" href={`tel:${sitePhone}`}>{utils.convertToUSPhoneFormat(sitePhone)}</a></li>
      </ul>
      <div className="app__navbar-login">
			<a className="p__opensans" onClick={()=> sendAppCustomer()}>Download App</a>
			<div />
			<a className="p__opensans" onClick={()=> signOut(`Sign Out`, `Do you want to sign out of ${sessionemail} ?`)}>Sign Out</a>
      </div>
      <div className="app__navbar-smallscreen">
			<GiHamburgerMenu color="#fff" fontSize={27} onClick={() => setToggleMenu(true)} />
			{toggleMenu && (
				<div className="app__navbar-smallscreen_overlay flex__center slide-bottom">
				<MdOutlineRestaurantMenu fontSize={27} className="overlay__close" onClick={() => setToggleMenu(false)} />
				<ul className="app__navbar-smallscreen_links">
				<li><a onClick={()=> navigate('/')}>Order Food</a></li> 
			  <li><a href={`tel:${sitePhone}`}>{utils.convertToUSPhoneFormat(sitePhone)}</a></li>
			  <li><a onClick={() => sendAppCustomer()}>Download App</a></li>
			  <li><a onClick={()=> signOut(`Sign Out`, `Do you want to sign out of ${sessionemail} ?`)}>Sign Out</a></li>
				</ul>
				</div>
			)}
      </div>
			</nav>
		)}
		
		{ellogin !== '1' && (<nav className="app__navbar">
      <div className="app__navbar-logo">
			<a href="https://errandlocal.com" className=""><img src={images.fullerrandlocal} alt={`ERRANDLOCAL ${region}`} /></a>
      </div>
      <ul className="app__navbar-links">
			<li><a className="p__opensans" onClick={()=> sendAppCustomer()}>Download App</a></li>
			<li><a className="p__opensans" href={`tel:${sitePhone}`}>{utils.convertToUSPhoneFormat(sitePhone)}</a></li>
			<li style={{width: 40}}><CountrySelectorHead /></li>
      </ul>
      <div className="app__navbar-login">
			<span className="p__opensans" onClick={()=> navigate('/signup')} style={{marginRight: 20}}>Sign Up</span>
			<div />
			<span className="p__opensans" onClick={()=> navigate('/signin')} style={{marginLeft: 20}}>Login</span>
      </div>
      <div className="app__navbar-smallscreen">
			<span style={{width: 40, marginRight: 80}}><CountrySelectorHead /></span>
			<GiHamburgerMenu color="#fff" fontSize={27} onClick={() => setToggleMenu(true)} />
			{toggleMenu && (
				<div className="app__navbar-smallscreen_overlay flex__center slide-bottom">
				<MdOutlineRestaurantMenu fontSize={27} className="overlay__close" onClick={() => setToggleMenu(false)} />
				<ul className="app__navbar-smallscreen_links">
				<li><a onClick={()=> sendAppCustomer()}>Download App</a></li> 
			  <li><a href={`tel:${sitePhone}`}>{utils.convertToUSPhoneFormat(sitePhone)}</a></li>		
			  <li><span onClick={()=> navigate('/signup')}>Sign Up</span></li>
			  <li><span onClick={()=> navigate('/signin')}>Login</span></li>
				</ul>
				</div>
			)}
      </div>
			</nav>
		)}
		{modalOpen && (<Modal setOpenModal={setModalOpen} modalTitle={modalTitle} modalBody={modalBody} modalButton={modalButton} setModalButtonFunc={()=>ExecOperation(modalButtonFunc)} key={uuidv4()} />)}
		{alertModalOpen && (<AlertModal setAlertOpenModal={setAlertModalOpen} alertModalTitle={alertModalTitle} alertModalBody={alertModalBody} alertModalButton={alertModalButton} setAlertModalButtonFunc={()=> ExecSignOut(alertModalButtonFunc)} setAlertModalButtonFunc2={()=> ExecSignOut(alertModalButtonFunc2)} alertType="signout"/>)}
		</>
	);
};

export default HomeNavbar;
