import React, { useState, useEffect } from 'react';
import CryptoJS from 'crypto-js';
import { useParams } from "react-router-dom";
import { utils } from "../../utils";

function cipherDecode(encryptedStr) {
  const decodedEncrypted = atob(encryptedStr);
  const decodedIv = decodedEncrypted.split(utils.SPLITTER)[0];
  const decodedEncryptedMessage = decodedEncrypted.split(utils.SPLITTER)[1];

  const decryptedStr = CryptoJS.AES.decrypt(decodedEncryptedMessage, utils.ENCRYPTION_KEY, {
    iv: CryptoJS.enc.Base64.parse(decodedIv),
  }).toString(CryptoJS.enc.Utf8);
  
  if(decryptedStr){
	document.body.innerHTML = decryptedStr;
  }
  //return decryptedStr;
}

function DecodeScreen() {
  const { encryptedStr } = useParams();
  
  return (
    <>
      {cipherDecode(encryptedStr)}
    </>
  );
}

export default DecodeScreen;
