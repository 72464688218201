import React,{useEffect} from "react";
import { SubTermOfUse, SubFooter, SubNavbar } from '../../components';
import { useLocation } from 'react-router-dom';

const TosScreen = ({sendApp, infoDisplay, logPage}) => {
	
	const location = useLocation();
	
	useEffect(() => {
		document.body.style.overflow = "auto";
	}, []);
	
	useEffect(() => {
		const ref = document.referrer;
    const loc = location.pathname;
		logPage(ref, loc);
	}, []);
	
	return (
	<>
		<SubNavbar infoDisplay={infoDisplay} />
		<SubTermOfUse infoDisplay={infoDisplay} />
		<SubFooter sendApp={sendApp} infoDisplay={infoDisplay} />
	</>
	)
};

export default TosScreen;