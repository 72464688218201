import React, {useEffect} from "react";
import { useLocation } from 'react-router-dom';
import { Driver, SubFooter, DriverNavbar } from '../../components';

const DriverScreen = ({onPostDriver, mainstartpoint, mainstage, sendApp, infoDisplay, logPage}) => {
	
	const location = useLocation();
	
	useEffect(() => {
		window.document.body.style.overflow = "auto";
		document.title = "Drive with Errandlocal | ErrandLocal";
	}, []);	
	
	useEffect(() => {
		const ref = document.referrer;
    const loc = location.pathname;
		logPage(ref, loc);
	}, [location.pathname]);
	
	return (
		<>
			<DriverNavbar sendApp={sendApp} infoDisplay={infoDisplay}/>
			<Driver onPostDriver={onPostDriver} mainstartpoint={mainstartpoint} mainstage={mainstage} infoDisplay={infoDisplay} />
			<SubFooter sendApp={sendApp} infoDisplay={infoDisplay}/>
		</>
	)
};

export default DriverScreen;
