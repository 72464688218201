import React, {useState} from 'react';
import { GiHamburgerMenu } from 'react-icons/gi';
import { MdOutlineRestaurantMenu } from 'react-icons/md';
import images from '../../constants/images';
import './SubNavbar.css';

const SubNavbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);	
	
  return (
    <nav className="app__navbar">
      <div className="app__navbar-logo">
        <a href="https://errandlocal.com"><img src={images?.fullerrandlocal} alt="ERRANDLOCAL" /></a>
	  </div>
	  <div className="app__navbar-login">
        <a href="https://get.errandlocal.com/" className="p__opensans">For Restaurants</a>
        <div />
        <a href="https://driver.errandlocal.com/" className="p__opensans">For Drivers</a>
      </div>
      <div className="app__navbar-smallscreen">
        <GiHamburgerMenu color="#fff" fontSize={27} onClick={() => setToggleMenu(true)} />
        {toggleMenu && (
          <div className="app__navbar-smallscreen_overlay flex__center slide-bottom">
            <MdOutlineRestaurantMenu fontSize={27} className="overlay__close" onClick={() => setToggleMenu(false)} />
            <ul className="app__navbar-smallscreen_links">
              <li><a href="https://get.errandlocal.com/" className="p__opensans">For Restaurants</a></li>
              <li><a href="https://driver.errandlocal.com/" className="p__opensans">For Restaurants</a></li>
            </ul>
          </div>
        )}
      </div>
    </nav>
  );
};

export default SubNavbar;
