import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { FormInput } from "../../components";
import { utils } from "../../utils";
import { Link } from 'react-router-dom';

const depn = {
	'zip': 10,
	'phone': 10,
	'emailcode': 4,
	'password': 8,
};

const formlabel = "#a0a0a0";
const inputlabel = "#257a80";

const DriverForm = ({onPostDriver, mainstartpoint, mainstage}) => 
{	
	const [email, setEmail] = useState('');
	const [phone, setPhone] = useState('');	
	const [zip, setZip] = useState('');
	const [emailcode, setEmailCode] = useState('');
	const [address, setAddress] = useState('');
	const [fullname, setFullName] = useState('');
	const [city, setCity] = useState('');
	const [password, setPassword] = useState('');
	const [stage, setStage] = useState('');	
	const [startpoint, setStartPoint] = useState('');
	const [state, setState] = useState('');
	const [aptsuite, setAptSuite] = useState('');
	const [dob, setDob] = useState('');
	const [stateOptions, setStateOptions] = useState([]);
	
	useEffect(() => 
	{
		if(localStorage.getItem('driverReg'))
		{	
			let nstage = localStorage.getItem('driverReg');
			let nstart = localStorage.getItem('driverStart');
			let ncountry = localStorage.getItem('driverCountry');
			setStage(nstage);
			setStartPoint(nstart);
			const options = utils.getStateOptions(ncountry);
			setStateOptions(options);
		}
		
	},[stage, startpoint, stateOptions]);
	
	useEffect(() => 
	{
		if(mainstage !== undefined){
			setStage(mainstage);
			setStartPoint(mainstartpoint);	
		}
	},[mainstartpoint, mainstage]);
	
	const processQuery = (e) => 
	{		
		var state = document.getElementById('status');	
		state.style.display = 'none';
		state.className = '';		
		state.innerHTML = '';
		var email = document.getElementById('email').value;
		var phone = document.getElementById('phone').value;
		var zip = document.getElementById('zip').value;
		localStorage.setItem('driverSession', email);
		
		if(!email){
			state.style.display = 'block';
			state.className = 'cus_error center show';		
			state.innerHTML = '<b>Email Address is Required</b>';
			return;
		}else if(!phone){
			state.style.display = 'block';
			state.className = 'cus_error center show';		
			state.innerHTML = '<b>Phone Number is Required</b>';
			return;
		}else if(phone.length !== depn.phone){
			state.style.display = 'block';
			state.className = 'cus_error center show';		
			state.innerHTML = '<b>Enter a valid phone number</b>';
			return;
		}else if(!zip){
			state.style.display = 'block';
			state.className = 'cus_error center show';		
			state.innerHTML = '<b>Zip/Postal Code is Required</b>';
			return;
		}
		
		// Display confirmation prompt
    const confirmEmail = window.confirm(`Is your email address ${email} correct?`);
    if (!confirmEmail) {
        return; // If user cancels, stop further processing
    }
		
		var output = {
			'data_type': 'signupDriver',
			'data_email': email,
			'data_phone': phone,
			'data_zipcode': zip			
		}
		onPostDriver(output);
	}
	
	const processQuery2 = (e) => 
	{
	
		var state = document.getElementById('status2');	
		state.style.display = 'none';
		state.className = '';		
		state.innerHTML = '';
		var email = localStorage.getItem('driverSession');
		var emailcode = document.getElementById('emailcode').value;
		var address = document.getElementById('address').value;
		var fullname = document.getElementById('fullname').value;
		var city = document.getElementById('city').value;
		var dob = document.getElementById('dob').value;
		var aptsuite = document.getElementById('aptsuite').value;
		var password = document.getElementById('password').value;
		var sel = document.getElementById("state");
		var countstate = sel[sel.selectedIndex].innerHTML;
		
		
		if(!fullname){
			state.style.display = 'block';
			state.className = 'cus_error center show';		
			state.innerHTML = '<b>Full Name is Required</b>';
			return;
		}else if(!address){
			state.style.display = 'block';
			state.className = 'cus_error center show';		
			state.innerHTML = '<b>Address is Required</b>';
			return;
		}else if(!city){
			state.style.display = 'block';
			state.className = 'cus_error center show';		
			state.innerHTML = '<b>City is Required</b>';
			return;
		}
		/*else if(!emailcode){
			state.style.display = 'block';
			state.className = 'cus_error center show';		
			state.innerHTML = '<b>Email Code is Required</b>';
			return;
		}else if(emailcode.length !== depn.emailcode){
			state.style.display = 'block';
			state.className = 'cus_error center show';		
			state.innerHTML = '<b>Enter a valid email code</b>';
			return;
		}
		*/else if(!countstate || countstate === 'Select State'){
			state.style.display = 'block';
			state.className = 'cus_error center show';		
			state.innerHTML = '<b>Please select state</b>';
			return;
		}else if(!dob){
			state.style.display = 'block';
			state.className = 'cus_error center show';		
			state.innerHTML = '<b>Date of Birth is Required</b>';
			return;
		}else if(dob.length !== 10){
			state.style.display = 'block';
			state.className = 'cus_error center show';		
			state.innerHTML = '<b>Enter a valid date of birth</b>';
			return;
		}else if(!password){
			state.style.display = 'block';
			state.className = 'cus_error center show';		
			state.innerHTML = '<b>Password is Required</b>';
			return;
		}else if(password.length < depn.password){
			state.style.display = 'block';
			state.className = 'cus_error center show';		
			state.innerHTML = '<b>Password must be at least '+depn.password+' in length</b>';
			return;
		}
		
		var output = {
			'data_type': 'signupDriver2',
			'data_email': email,			
			'data_fullname': fullname,
			'data_address': address,			
			'data_city': city,	
			'data_emailcode': emailcode,
			'data_password': password,
			'data_state': countstate,
			'data_dob': dob,
			'data_aptsuite': aptsuite,
		}
		onPostDriver(output);
	}
	
	const onBack = (e) =>
	{
		setStage('');
		setStartPoint('');
		localStorage.setItem('driverReg', '');
		localStorage.setItem('driverStart', '');
		localStorage.setItem('driverCountry', '');
	}
	
	const onRestart = (e) =>
	{
		setStage('');
		setStartPoint('');
		localStorage.setItem('driverReg', '');
		localStorage.setItem('driverStart', '');
		localStorage.setItem('driverCountry', '');
		localStorage.removeItem('driverReg');
		localStorage.removeItem('driverStart');	
		localStorage.removeItem('driverCountry');
	}
	return (
	<>
		{stage === '' && (<motion.div 
			initial={{ opacity: 0, x: 200 }}
			animate={{ opacity: 1, x: 0 }}
			exit={{ opacity: 0, x: 200 }}		
			className="form" 
			autoComplete="off"
			style={{maxWidth: 450}}
		>
			<header>
				<h1 className="form-header">Start Here</h1>
			</header>
			
			{/* Email */}
			<FormInput
				label="What's your email address? *"
				placeholder="e.g johndoe@errandlocal.com"
				secureTextEntry="email"
				inputType="input"
				inputId="email"
				onChange={(e) => setEmail(e.target.value)}
				value={email}
				containerStyle={{
					marginTop: 15,
					width: '100%'
				}}
				inputContainerStyle={{
					backgroundColor: '#ffffff',
					width: '100%'
				}}
				inputLabelStyle={{
					color: formlabel,
				}}
				inputClass={'cus_input'}
				inputStyle={{color: inputlabel}}
			/>
			
			{/* Phone */}
			<FormInput
				label="What's your phone number? *"
				placeholder="e.g 2141234567"
				secureTextEntry="phone"
				inputType="input"
				inputId="phone"
				onChange={(e) => setPhone(e.target.value)}
				value={phone}
				maxLength={`${depn.phone}`}
				containerStyle={{
					marginTop: 15,
					width: '100%'
				}}
				inputContainerStyle={{
					backgroundColor: '#ffffff',
					width: '100%'
				}}
				inputLabelStyle={{
					color: formlabel,
				}}
				inputClass={'cus_input'}
				inputStyle={{color: inputlabel}}
			/>
			
			{/* Zip */}
			<FormInput
				label="What's your zip/postal code? *"
				placeholder="e.g 75243, E1A 7H6"
				secureTextEntry="text"
				inputType="input"
				inputId="zip"
				onChange={(e) => setZip(e.target.value)}
				value={zip}
				maxLength={`${depn.zip}`}
				containerStyle={{
					marginTop: 15,
					width: '100%'
				}}
				inputContainerStyle={{
					backgroundColor: '#ffffff',
					width: '100%'
				}}
				inputLabelStyle={{
					color: formlabel,
				}}
				inputClass={'cus_input'}
				inputStyle={{color: inputlabel}}
			/>
			
			
			<fieldset className="card-number">
				<div style={{fontSize: 13, color: 'grey', paddingLeft: 10, paddingRight: 10, }}>By clicking "GET STARTED" you agree to ErrandLocal's Terms of Service and Privacy Policy</div>
			</fieldset>
			<fieldset className="card-number">
				<div id="status" style={{display: 'none'}}></div>
			</fieldset>
			
			{/* Button */}
			<FormInput
				label="Get Started"
				secureTextEntry="text"
				inputType="button"
				inputId="button1"
				onClick={processQuery}
				containerStyle={{
					marginTop: 5,
					width: '100%'
				}}
				inputClass={'cus_button'}
				inputStyle={{}}
			/>	
		</motion.div>)}
				
		{stage === 'OtpScreen' && (<motion.div 
			initial={{ opacity: 0, x: 200 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: 200 }}		
			className="form" 
			autoComplete="off"
			style={{maxWidth: 450}}
		>
			<header>
				<h1 style={{fontSize: 24, color: '#0ECB81'}}>{'Let`s do it - Complete the form below'}</h1>
			</header>	
			<header>
				<h1 style={{fontSize: 16, color: '#000000'}}>Starting Point - {startpoint}</h1>
			</header>
			{/* Name */}
			<FormInput
				label="Full Name *"
				placeholder="e.g John Doe"
				secureTextEntry="text"
				inputType="input"
				inputId="fullname"
				onChange={(e) => setFullName(e.target.value)}
				value={fullname}
				containerStyle={{
					marginTop: 15,
					width: '100%'
				}}
				inputContainerStyle={{
					backgroundColor: '#ffffff',
					width: '100%'
				}}
				inputLabelStyle={{
					color: formlabel,
				}}
				inputClass={'cus_input'}
				inputStyle={{color: inputlabel}}
			/>
			{/* Address */}
			<FormInput
				label="Address *"
				placeholder="e.g 1 Main Street"
				secureTextEntry="text"
				inputType="input"
				inputId="address"
				onChange={(e) => setAddress(e.target.value)}
				value={address}
				containerStyle={{
					marginTop: 15,
					width: '100%'
				}}
				inputContainerStyle={{
					backgroundColor: '#ffffff',
					width: '100%'
				}}
				inputLabelStyle={{
					color: formlabel,
				}}
				inputClass={'cus_input'}
				inputStyle={{color: inputlabel}}
			/>
			
			<fieldset className="row-flex">
				{/* AptSuite */}
				<FormInput
					label="Apt/Suite"
					placeholder="e.g 111"
					secureTextEntry="text"
					inputType="input"
					inputId="aptsuite"
					onChange={(e) => setAptSuite(e.target.value)}
					value={aptsuite}
					containerStyle={{
						marginTop: 15,
						width: '100%'
					}}
					inputContainerStyle={{
						backgroundColor: '#ffffff',
						width: '100%'
					}}
					inputLabelStyle={{
						color: formlabel,
					}}
					inputClass={'cus_input cus_input_half'}
					inputStyle={{color: inputlabel}}
				/>
				
				{/* City */}
				<FormInput
					label="City *"
					placeholder="e.g Dallas"
					secureTextEntry="text"
					inputType="input"
					inputId="city"
					onChange={(e) => setCity(e.target.value)}
					value={city}
					containerStyle={{
						marginTop: 15,
						width: '100%'
					}}
					inputContainerStyle={{
						backgroundColor: '#ffffff',
						width: '100%'
					}}
					inputLabelStyle={{
						color: formlabel,
					}}
					inputClass={'cus_input cus_input_half'}
					inputStyle={{color: inputlabel}}
				/>
			</fieldset>
			<fieldset className="row-flex">					
				
				{/* State */}
				<FormInput
					label="State/Province *"
					placeholder="e.g Dallas"
					secureTextEntry="text"
					inputType="select"
					inputId="state"
					onChange={(e) => setState(e.target.value)}
					value={state}
					containerStyle={{
						marginTop: 15,
						width: '100%'
					}}
					inputContainerStyle={{
						backgroundColor: '#ffffff',
						width: '98%'
					}}
					inputLabelStyle={{
						color: formlabel,
					}}
					inputClass={'cus_select'}
					inputStyle={{color: inputlabel, backgroundColor: '#ffffff'}}
					options={stateOptions}
				/>
				
				
				{/* Date of Birth */}
				<FormInput
					label="Date of Birth [mm/dd/yyyy]*"
					placeholder="e.g 12/31/2000"
					secureTextEntry="text"
					inputType="input"
					inputId="dob"
					onChange={(e) => setDob(e.target.value)}
					value={dob}
					containerStyle={{
						marginTop: 15,
						width: '100%'
					}}
					inputContainerStyle={{
						backgroundColor: '#ffffff',
						width: '100%'
					}}
					inputLabelStyle={{
						color: formlabel,
					}}
					inputClass={'cus_input cus_input_half'}
					inputStyle={{color: inputlabel}}
				/>
				
				
			</fieldset>
			<fieldset className="row-flex">			
				
				{/* Password */}
				<FormInput
					label="Set Password *"
					placeholder="********"
					secureTextEntry="password"
					inputType="input"
					inputId="password"
					onChange={(e) => setPassword(e.target.value)}
					value={password}
					containerStyle={{
						marginTop: 15,
						width: '100%'
					}}
					inputContainerStyle={{
						backgroundColor: '#ffffff',
						width: '100%'
					}}
					inputLabelStyle={{
						color: formlabel,
					}}
					inputClass={'cus_input cus_input_half'}
					inputStyle={{color: inputlabel}}
				/>
			
				
				{/* Email Code */}
				<FormInput
					label="Email Code [optional]"
					placeholder="e.g 1234"
					secureTextEntry="text"
					inputType="input"
					inputId="emailcode"
					onChange={(e) => setEmailCode(e.target.value)}
					value={emailcode}
					containerStyle={{
						marginTop: 15,
						width: '100%'
					}}
					inputContainerStyle={{
						backgroundColor: '#ffffff',
						width: '100%'
					}}
					inputLabelStyle={{
						color: formlabel,
					}}
					inputClass={'cus_input cus_input_half'}
					inputStyle={{color: inputlabel}}
					maxLength={`${depn.emailcode}`}
				/>
				
			</fieldset>
			
			<fieldset className="card-number">
				<div style={{fontSize: 13, color: 'grey', paddingLeft: 10, paddingRight: 10, }}>{`By clicking "SUBMIT" you agree to ErrandLocal's Terms of Service and Privacy Policy`}</div>
			</fieldset>
			<fieldset className="card-number">
				<div id="status2" style={{display: 'none'}}></div>
			</fieldset>	
			{/* Button */}
			<FormInput
				label="Submit"
				secureTextEntry="text"
				inputType="button"
				inputId="button2"
				onClick={processQuery2}
				containerStyle={{
					marginTop: 5,
					width: '100%'
				}}
				inputClass={'cus_button'}
				inputStyle={{}}
			/>	
			<fieldset className="card-number">
				<div><Link to="#" onClick={onBack} className="flex font-bold items-center gap-2" style={{color: '#d13529', fontWeight: 'bold'}}> {`<<`} Go Back </Link></div>
			</fieldset>
		</motion.div>)}
		
		{stage === 'HomeScreen' && (<motion.div 
			initial={{ opacity: 0, x: 200 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: 200 }}		
			className="form" 
			autoComplete="off"
		>
			<header>
				<h1 style={{fontSize: 24, color: '#0ECB81'}}>Driver Registration was Successful</h1>
			</header>
			
			<header>
				<h3 style={{fontSize: 18, color: '#000000', fontWeight: 'bold', marginTop: 10}}>A link to download the ErrandLocal Driver App have been sent to your phone</h3>
			</header>
			
			<fieldset className="card-number">				
				<div>- Download the app.</div>
			</fieldset>
			<fieldset className="card-number">
				<div>- Login with your email & password.</div>
			</fieldset>
			<fieldset className="card-number">
				<div>- Submit required documents for verification.</div>
			</fieldset>
			<fieldset className="card-number">
				<div>- Verification usually within 48 working hours.</div>
			</fieldset>
			<fieldset className="card-number">
				<div>- Start to deliver with ErrandLocal when approved.</div>
			</fieldset>
			<fieldset className="card-number">
				<div><Link to="#" onClick={onRestart} className="flex font-bold items-center gap-2" style={{color: '#d13529', fontWeight: 'bold'}}> {`<<`} Clear Process </Link></div>
			</fieldset>
			
		</motion.div>)}
		
	</>
	);
};

export default DriverForm;

