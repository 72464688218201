import React from 'react';
import { SubHeading, DriverForm } from '../../components';
import { images, data } from '../../constants';
import { motion, useScroll, useSpring  } from "framer-motion";

const Driver = ({onPostDriver, mainstartpoint, mainstage}) => {
	const { scrollYProgress } = useScroll();
	const scaleX = useSpring(scrollYProgress, {
		stiffness: 100,
		damping: 30,
		restDelta: 0.001
	});
	
	
	const ReqCard = ({ award: { imgUrl, title, subtitle } }) => (
	  <div className="app__laurels_awards-card">
		<img src={imgUrl} alt="awards" />
		<div className="app__laurels_awards-card_content">
		<p className="p__cormorant">{title}</p>
		<p className="p__mont">{subtitle}</p>
		</div>
	  </div>
	);
	
	const Requirements = () => (
	  <div className="app__bg app__wrapper section__padding" id="requirement">
		<div className="app__wrapper_info">
		<SubHeading title="Requirements" />
		<h1 className="headtext__cormorant">What you need to start</h1>
		
		<div className="app__laurels_awards">
		{data.requirements.map((award) => <ReqCard award={award} key={award.title} />)}
		</div>
		</div>
		
		<div className="app__wrapper_img">
		<img src={images.dl} alt="dl_img" />
		</div>
	  </div>
	);
	
	const MenuItem = ({ title, price, tags }) => (
	  <div className="app__menuitem">
		<div className="app__menuitem-head">
		<div className="app__menuitem-name">
		<p className="p__cormorant">{title}</p>
		</div>
		<div className="app__menuitem-dash" />
		<div className="app__menuitem-price">
		<p className="p__cormorant">{price}</p>
		</div>
		</div>
		
		<div className="app__menuitem-sub">
		<p className="p__mont">{tags}</p>
		</div>
	  </div>
	);
	
	const SpecialMenu = () => (
	  <div className="app__specialMenu flex__center section__padding" id="schedule">
		<div className="app__specialMenu-title">
		<SubHeading title="Work Hours That Fits your Schedule" />
		<h1 className="headtext__cormorant">Work Hours</h1>
		</div>
		
		<div className="app__specialMenu-menu">
		<motion.div 
		initial={{ opacity: 0, scale: 0.6 }}
		animate={{ opacity: 1, scale: 1 }}
		exit={{ opacity: 0, scale: 0.6 }} 
		className="app__specialMenu-menu_cocktails  flex__center">
		<div className="app__specialMenu_menu_items">
		{data.right.map((r, index) => (
			<MenuItem key={r.title + index} title={r.title} price={r.price} tags={r.tags} />
		))}
		</div>
		</motion.div>
		
		<div className="app__specialMenu-menu_img">
		<img src={images.driver} alt="driver__img" />
		</div>
		
		<motion.div 
		initial={{ opacity: 0, scale: 0.6 }}
		animate={{ opacity: 1, scale: 1 }}
		exit={{ opacity: 0, scale: 0.6 }} 
		className="app__specialMenu-menu_cocktails  flex__center">
		<div className="app__specialMenu_menu_items">
		{data.left.map((l, index) => (
			<MenuItem key={l.title + index} title={l.title} price={l.price} tags={l.tags} />
		))}
		</div>
		</motion.div>
		</div>
		
		<div style={{ marginTop: 15 }}>
		<a href="#apply"><button type="button" className="custom__button">Apply Now</button></a>
		</div>
	  </div>
	);
	
	
	return (
		<>
	  <motion.div className="progress-bar" style={{ scaleX }} />
	  <div className="app__header app__wrapper section__padding">
		<motion.div
		initial={{ opacity: 0, x: -200 }}
		animate={{ opacity: 1, x: 0 }}
		exit={{ opacity: 0, x: -200 }}
		className="app__wrapper_info"
		>
		<SubHeading title="Get more in pay" />
		<h1 className="app__header-h1">Make the move</h1>
		<p className="p__mont" style={{ margin: '2rem 0' }}>{`Earn up to $25/hr when you deliver with ErrandLocal. Signup and start earning on your terms.`}</p>
		<a href="#apply"><button type="button" className="custom__button">Apply Now</button></a>
		</motion.div>
		
		<div className="app__wrapper_img" id="apply">
		<DriverForm onPostDriver={onPostDriver} mainstartpoint={mainstartpoint} mainstage={mainstage}/>
		</div>
	  </div>
	  <Requirements />
	  <SpecialMenu />	  
		</>
	)
}

export default Driver;
