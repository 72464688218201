import React, { useEffect, useState  } from "react";
import "./ScrollHighlight.css";

const getTextWidth = (text) => {
	const span = document.createElement('span');
	span.style.visibility = 'hidden';
	span.style.whiteSpace = 'nowrap';
	document.body.appendChild(span);
	span.textContent = text;
	const width = span.offsetWidth;
	document.body.removeChild(span);
	return width;
};

const nearestIndexMain = ( sectionPositionArray ) => {	
	let sectIdArr = [];
	let sectCount = sectionPositionArray.length;
	let marginLeft = 0;
	let widthArr = [];
	
	for(let i=0; i<sectCount; i++)
	{
		let sectId = sectionPositionArray[i].headerID;
		let sectElement = document.getElementById(sectId);
		if(sectElement === null){
			window.location.reload();
			return;
		}
		let sectOffset = sectElement.getBoundingClientRect().top;
		if(sectOffset <= 0)
		{
			if(!sectIdArr.includes(sectId)){
				sectIdArr.push(sectId);				
				let sectEl = document.getElementById(i+sectId);
				let txt = sectEl.innerHTML;
				let txtwidth = getTextWidth(txt);
				widthArr.push(txtwidth);
				
				marginLeft += txtwidth + 10;
			}
		}
	}
	const lastIndex = sectIdArr.length > 0 ? sectIdArr.length - 1 : 0;
	let sectId2 = sectionPositionArray[lastIndex].headerID;
	let sectElement2 = document.getElementById(lastIndex+sectId2);
	let max = Math.max(...widthArr);
	let min = Math.min(...widthArr);
	let avg = (max + min) / 2;
	sectElement2.parentNode.scrollTo((marginLeft - avg), 0);
	return lastIndex;
};
 
const ScrollHighlight = ({ navHeader }) => {	
	const [activeIndex, setActiveIndex] = useState(0);
	const [activeIndex2, setActiveIndex2] = useState(0);
	
	useEffect(() => {
		const handleScroll = (e) => {
			var index = nearestIndexMain(navHeader);			
			if(activeIndex2 - 1 === activeIndex){
				setActiveIndex(activeIndex2);
				setActiveIndex2(activeIndex2);
			}else{
				setActiveIndex(index);
				setActiveIndex2(index);
			}
		};

		document.addEventListener("scroll", handleScroll);
		//console.log('A');
		return () => {
			//console.log('B');
			document.removeEventListener("scroll", handleScroll);			
		};
	}, []);
			
	const onClickAbout = (id, index, navHeader) => {
		//console.log('C');
		const elementToView = document.getElementById(id);
		window.scrollTo(0, elementToView.offsetTop);		
		setActiveIndex(index);
		setActiveIndex2(index);
		nearestIndexMain(navHeader);		
		return;
	}

	return (
		<>
			{navHeader.map((header, index) => (
				<span
					key={index + header.headerID}
					id={index + header.headerID}
					className={activeIndex === index ? `_obgv77 _oj9oqm` : `_obgv77`}
					href={`#${header.headerID}`}
					onMouseDown={()=> onClickAbout(`${header.headerID}`, index, navHeader)}
				>
					{header.headerTitle}
				</span>
			))}
		</>
	);
}

export default ScrollHighlight; 