import React, { useRef, useEffect, useState, useCallback } from "react";
import { utils } from "../../utils";

const StoreListMap = ({ markers, height, width, searchCoordinate, title }) => {
  const mapRef = useRef(null);
  const [map, setMap] = useState(null);
  const marker1 = utils?.MAP_MARKER_1;
  const marker2 = utils?.MAP_MARKER_2;
  
  const loadMap = useCallback(async() => {
	  try{
		  
		  if(!mapRef.current){
			  console.log('map current error');			  
			  return false;
		  }
		  const bounds = new window.google.maps.LatLngBounds();
		  const newMap = new window.google.maps.Map(mapRef.current, {
			zoom: 17,
			gestureHandling: 'greedy',
			disableDefaultUI: true, 
			zoomControl: true, 
			zoomControlOptions: {position: window.google.maps.ControlPosition.LEFT_TOP,},
			styles: [
				{
				  featureType: "poi",
				  elementType: "labels",
				  stylers: [{ visibility: "off" }],
				},
				{
				  featureType: "transit",
				  elementType: "labels",
				  stylers: [{ visibility: "off" }],
				},
			],
		  });
		  
		  markers.forEach((marker) => {
			const position = { lat: parseFloat(marker?.lat), lng: parseFloat(marker?.lng) };
			const newMarker = new window.google.maps.Marker({
			  position,
			  map: newMap,
			  icon: marker1,
			});

			bounds.extend(position);

			const infowindow = new window.google.maps.InfoWindow({
			  content: marker?.info,
			});

			newMarker.addListener("mouseover", () => {
			  newMarker.setIcon(marker2);
			  infowindow.open(map, newMarker);          
			});

			newMarker.addListener("mouseout", () => {
			  newMarker.setIcon(marker1);
			  infowindow.close();
			});
		  });
		  //console.log("step 4...");
		  const adjustButton = document.createElement("div");
		  adjustButton.classList.add("search-area-button");
		  adjustButton.textContent = title;
		  newMap.controls[window.google.maps.ControlPosition.TOP_RIGHT].push(
			adjustButton
		  );
		  adjustButton.addEventListener("click", () => {
			// Code to adjust the marker to the address
			//console.log("Adjusting marker to address...");
		  }); 
		  
		  newMap.fitBounds(bounds);
		  
		  newMap.addListener("click", (event) => {
			const newLat = event.latLng.lat();
			const newLng = event.latLng.lng();
			//console.log("New coordinates:", newLat, newLng);
			searchCoordinate(newLat, newLng);
		  });
		  
		  setMap(newMap);
	  }catch(e){console.log('map error =>', e.message)}
	  
  }, [markers, map, searchCoordinate, marker1, marker2, mapRef.current]); 
    
  const loadAPI = useCallback(async() => {
	  try{
		  const mapKey = await utils.GOOGLE_API_KEY;
		  const script = document.createElement("script");
		  //&callback=initMap
		  script.src = `https://maps.googleapis.com/maps/api/js?key=${mapKey}`;
		  script.defer = true;
		  script.onload = () => {			
				setTimeout(() => {
					loadMap();
				}, 1000);
		  };
		  document.body.appendChild(script);
	  }catch(e){
		  console.log('map error2 =>', e.message);
	  }
  }, []);	
  
  const Init = useCallback(() => {
	  if (markers?.length && markers[0]?.lat) 
	  {
			if(window?.google?.maps){				
				loadMap();
			}else{				
				loadAPI();
			}		
	  }
  }, [markers]);
  
  useEffect(() => {	
		window.initMap = Init();
		return () => {
			delete window?.initMap;
		};	
  }, [Init, markers]); 

  return (<div ref={mapRef} style={{ height, width, outline: 'none' }} />);
};

export default StoreListMap;