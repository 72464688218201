import React, { useState, useEffect } from 'react';
import { motion, useScroll, useSpring } from "framer-motion";
import { utils } from "../../utils";
import { FormInput, MainNavbar, SubFooter } from "../../components";
import { useSelector } from "react-redux";
import { selectSavedCards } from "../../includes/navSlice";
import { useNavigate, useParams } from 'react-router-dom';
import { Space, message, Alert} from "antd";

const formlabel = "#a0a0a0";
const inputlabel = "#257a80";

const Tip = () => 
{
	const { scrollYProgress } = useScroll();
	const scaleX = useSpring(scrollYProgress, {
		stiffness: 100,
		damping: 30,
		restDelta: 0.001
	});	

	let savecards = useSelector(selectSavedCards);
	savecards =
    typeof savecards === "object" ? savecards : JSON.parse(savecards);
		
	const sessionemail = utils.getStorage('__el_data_email');
	const authToken = utils.getStorage('__el_data_login_token');
	const navigate = useNavigate();
	
	const [params, setParams] = useState([]);
	const [tip, setTip] = useState('');
	const [card, setCard] = useState('');
	const [email, setEmail] = useState(sessionemail);
	const [click, setClick] = useState(false);
	const [visible, setVisible] = useState(false);	
	const [msgType, setMsgType] = useState('');	
	const [msgBody, setMsgBody] = useState('');	
	const [msgHead, setMsgHead] = useState('');	
	
	const [os, setOs] = useState('');
	const [model, setModel] = useState('');
	
	const { orderId } = useParams();
	
	useEffect(() => 
	{
		setOs('');
		setModel('');
	},[]);
	
	useEffect(() => 
	{
		setEmail(email);
	},[email]);
	
	useEffect(() => 
	{
		if(savecards?.length > 0){
			setParams(savecards);
		}
	},[savecards]);
	
	
	useEffect(() => {
		for(var i=0; i<params.length; i++){			
			if(params[i]?.cardMain && params[i]?.cardEnding !== undefined)
			{
				setCard(params[i]?.cardType+' Ending in '+params[i]?.cardEnding);
			}
		}	
	}, [params]);
	
	
	const SwitchRef = (ref) => {
		navigate(ref);
	}
	
	const onTip = async () => 
	{
		try{
			if(tip){}else{
				setVisible(true);
				setMsgHead('');
				setMsgBody('Tip field is required');
				setMsgType('error');
				window.scrollTo({top: 0, behavior: 'smooth'});
				return;
			}
			setClick(true);
			const target = utils.USER_URL;
			const header = new Headers({
				'Content-Type': 'application/json',
				'Accept': 'application/json',
				'Authorization': 'Bearer '+authToken,
			});
			const posturl = utils.BASE_URL+target; //utils.POST_URL;
			const localtime = await utils.getTime();
			const obj = JSON.stringify({'rtype': 'tippay', 'email': email, 'order': orderId, 'amount': tip, 'os': os, 'model': model, 'localtime': localtime, 'target': target, 'token': authToken});
			const dataToPost = await utils.cipherEncode(obj);			
			const res = await utils.fetchURL(posturl, 'POST', dataToPost, header);
			const data = await res.json();
			setClick(false);
			if(data.msg === 'Success'){
				setVisible(true);
				setMsgHead('');
				setMsgBody('Thank you for appreciating your driver');
				setMsgType('success');
				window.scrollTo({top: 0, behavior: 'smooth'});				
			}else{
				setVisible(true);
				setMsgHead('');
				setMsgBody(data.msg);
				setMsgType('error');
				window.scrollTo({top: 0, behavior: 'smooth'});
			}
		}catch(e){
			setClick(false);
			message.error(e.message);			
		}		
	}
	
	function handleClose(){
		setVisible(false);
	}

    function renderForm() {
        return (
		<>
			<motion.div className="pprogress-bar" style={{ scaleX }} />			
			<div className="el-global--wrapper">
				<div className="el-entry el-page--transition">
					<MainNavbar />
					<div className="el-preview container slide-in-bottom" id="el-dashboard-container">
						<div className="sc-lhlUkk kRzDDN">
							<div className="animated margin-bottom-4 flex justify-between mx-auto align-center c-box-width">
								<div className="cus_title_wrap">
									<h2>{`Extra Tip`}</h2>
								</div>
								<div className="">
									
								</div>
							</div>
							<Space className="alertbutton" direction="vertical">
								{visible && (<Alert				
									message={msgHead}
									description={msgBody}
									type={msgType}
									closable 
									afterClose={handleClose}				
								/>)}
							</Space>
							<div className="sc-cabOPr kUdOjq login-card--wrap auth-wrap elAnimated elFadeInUp text-center el-overflow--hidden slide-in-bottom c-box-width c-box">
								<div className="cus_back_button_wrap" style={{margin: "0px 0px"}}>
									<button type="solid" style={{display: "flow-root", marginBottom: 30}} className="cus_back_button" onClick={()=> SwitchRef('/past-orders')}>Back</button>
								</div>
		
            <div                
				style={{
                    backgroundColor: '#f7f7f7',
					background: "rgb(255, 255, 255) none repeat scroll 0% 0%",
					boxShadow: "rgba(0, 0, 0, 0.06) 0px 0px 25px",
					borderRadius: 4,
					padding: "32px 23px",
					margin: "32px 0px",
                }}
            >
					
				<div
					style={{
						marginBottom: 5,
					}}
				>
				
				</div>
				
				{/* Phone */}
				<FormInput
					label="Tip Amount *"
					placeholder="Tip"
					secureTextEntry="phone"
					inputType="input"
					inputId="tip"
					onChange={(e) => setTip(e.target.value)}
					value={tip}
					maxLength={`6`}
					containerStyle={{
						marginTop: 15,
						marginBottom: 15,
						width: '100%',
						textAlign: 'left',
					}}
					inputContainerStyle={{
						backgroundColor: '#ffffff',
						width: '100%'
					}}
					inputLabelStyle={{
						color: formlabel						
					}}
					inputClass={'cus_input'}
					inputStyle={{color: inputlabel}}
				/>
				

				<div
					style={{
						marginTop: 5,
						borderRadius: 5,
						paddingHorizontal: 10,			
					}}
				>
					<span
						style={{		
							fontSize: 13,				
						}}
					>
						Minimum tip is $2.
					</span>	
					{card && (<><br/><span
						style={{		
							fontSize: 13,
							color: '#257a80',
						}}
					>
					{card} will be charge.
					</span></>)}				
				</div>
				
				{/* Button */}
				<FormInput
					label="Submit"
					secureTextEntry="text"
					inputType="button"
					inputId="button1"
					onClick={()=> `${!click ? onTip() : ''}`}
					containerStyle={{
						marginTop: 5,
						marginBottom: 25,
						width: '100%'
					}}
					inputClass={'cus_button'}
					inputStyle={{}}
				/>
				
            </div>
									
						</div>
					</div>
					
				</div>
			</div>
		</div>
		<SubFooter />
		</>
        )
    }
	
	if(sessionemail)
	{
		return (
			
			<div 
				style={{}}
			>
				{renderForm()}
			</div>
			
		)
	}
	else
	{
		return null
	}
};


export default Tip;