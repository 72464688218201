import React from 'react';
import { motion, useScroll, useSpring  } from "framer-motion";
import { SubFooter, MainNavbar } from '../../components';
import { v4 as uuidv4 } from "uuid";
import { useNavigate } from 'react-router-dom';
import { utils } from "../../utils";
/* 
const accountArr = [{'id': 'name', 'href': {id: 'name1', switchable: true}, 'title': 'Name', 'description': 'Adekunle Ogundijo', 'fill': utils.getRandomColor(), 'label': 'm26.07,24.81a2.5,2.5 0 1 1 -5,0a2.5,2.5 0 0 1 5,0z'},{'id': 'address', 'href': {id: 'address1', switchable: true}, 'title': 'Address', 'description': '17601 Preston Rd, Dallas, TX', 'fill': utils.getRandomColor(), 'label': 'm26.07,24.81a2.5,2.5 0 1 1 -5,0a2.5,2.5 0 0 1 5,0z'}]; */

const MyAccount = ({signOut}) => 
{
	const navigate = useNavigate();
	const { scrollYProgress } = useScroll();
	const scaleX = useSpring(scrollYProgress, {
		stiffness: 100,
		damping: 30,
		restDelta: 0.001
	});	
	
	const SwitchRef = (ref) => {
		navigate(ref);  
	}
		
	const ListOptions = ( listdata, index ) => {
		const sessionemail = utils.getStorage('__el_data_email');
		
		return (
		
			<div className="sc-iBYQkv gwipAY" onClick={()=> listdata.listdata?.id === 'signout' ? signOut(`Sign Out`, `Do you want to sign out of ${sessionemail} ?`) : SwitchRef(`${listdata.listdata?.href}`)}>
				<div className=" flex justify-between list-card ">
					<div className="flex justify-between align-center list-card-content">
						<div className="list-icon ">
							<svg width="46" height="49" viewBox="0 0 46 49" fill="none" xmlns="http://www.w3.org/2000/svg">
								<g filter="url(#filter0_d_37786_12291)">
									<rect x="10" y="10" width="26" height="29" rx="4" fill="white"></rect>
									<path d={`${listdata.listdata?.label}`} fill={`${listdata.listdata?.fill}`}></path>
								</g>
								<defs>
									<filter id="filter0_d_37786_12291" x="0" y="0" width="46" height="49" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
										<feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
										<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"></feColorMatrix>
										<feOffset></feOffset>
										<feGaussianBlur stdDeviation="5"></feGaussianBlur>
										<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0"></feColorMatrix>
										<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_37786_12291"></feBlend>
										<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_37786_12291" result="shape"></feBlend>
									</filter>
								</defs>
							</svg>
						</div>
						<div className="flex-column list-card-texts">
							<h4 className="color-primary list-card-heading text-left margin-top-0 margin-bottom-0 btn-danger">{`${listdata.listdata?.title ?? ''}`}</h4>
							<div className="color-black text-xs margin-top-1">
								<div className="text-left">{`${listdata.listdata?.description ?? ''}`}</div>
							</div>
						</div>
					</div>
					<div className="flex align-center arrow-wrap">
						<svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M0 12.5825L1.51406 14L9 7V7V7L1.51406 0L0 1.4175L5.96719 7L0 12.5825Z" fill="#CACACA"></path>
						</svg>
					</div>
				</div>
			</div>
		)
	}
	
	return (
		<>
		<motion.div className="pprogress-bar" style={{ scaleX }} />			
		<div className="el-global--wrapper">
			<div className="el-entry el-page--transition">
				<MainNavbar />
				<div className="el-preview container" id="el-dashboard-container">
					<div className="sc-lhlUkk kRzDDN">
						<div className="animated margin-bottom-4 flex justify-between mx-auto align-center c-box-width">
							<div className="cus_title_wrap">
								<h2>Profile</h2>						
							</div>
							<div className="cus_add_button_wrap">
								<button type="solid" className="cus_add_button" onClick={()=> SwitchRef('/dashboard')}>Orders</button>
							</div>
						</div>
						<div className="sc-cabOPr kUdOjq login-card--wrap auth-wrap elAnimated elFadeInUp text-center el-overflow--hidden slide-in-bottom c-box-width c-box">
							<div
								style={{
									marginBottom: 15,
								}}
							>
							
							</div>
							{utils.profileOptions.map((listdata, index) => (
								<ListOptions listdata={listdata} index={index} key={uuidv4()} /> 
							))}
						</div>
					</div>
					
				</div>
			</div>
		</div>
		<SubFooter />
		</>
	)

}

export default MyAccount;

