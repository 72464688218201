import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { MapUpdate, FormInput } from "../../components";
import { utils } from "../../utils";
import {
  Drawer,
  Button,
  Input,
  Form,
  Select,
  Divider,
  message,
  Space,
  Radio,
	Alert
} from "antd";
const { Option } = Select;
window['visible'] = false;

const ErrandStoreAddressModal = ({
	errandInfo,
  updateErrandData,
	address,
	errandAddressDataLog,
	updateDeliveryData,
	deliveryInfo,
}) => {
	
	
  const [open, setOpen] = useState(false);  
  const [edit, setEdit] = useState(true);  
  const [loading, setLoading] = useState(false);  
	const [houseNo, setHouseNo] = useState("");
  const [errandAddress, setErrandAddress] = useState("");
  const [errandAddressName, setErrandAddressName] = useState("");
  const [businessInstruction, setBusinessInstruction] = useState("");
  const [phone, setPhone] = useState("");
  const [apt, setApt] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
	const [lat, setLat] = useState(0);
  const [lng, setLng] = useState(0);
	const [msgType, setMsgType] = useState('');
	const [msgBody, setMsgBody] = useState('');
	const [msgHead, setMsgHead] = useState('');
	
  const [marker, setMarker] = useState(null);
  const [params, setParams] = useState({});
	
	const [addresses, setAddresses] = useState([]);
	

  const [defaultAddress, setDefaultAddress] = useState(
    addresses?.find((address) => address?.addressMain)?.id || ""
  );
	
	const authToken = utils.getStorage("__el_data_login_token");
  const ellogin = utils.getStorage("__el_data_login_status");  
  
  useEffect(() => {
		if(edit){
			//setErrandAddress(errandInfo?.storeAddress ?? "");
			parseAddress(errandInfo?.storeAddress, 'houseNo');
			parseAddress(errandInfo?.storeAddress, 'streetName');
			setErrandAddressName(errandInfo?.storeName ?? "");
			setBusinessInstruction(errandInfo?.storeInstruction ?? "");
			setLat(errandInfo?.storeLat ?? 0);
			setLng(errandInfo?.storeLng ?? 0);
			setPhone(errandInfo?.storePhone ?? "");
			setApt(errandInfo?.storeSuite ?? "");
			setCity(errandInfo?.storeCity ?? "");		
			setState(errandInfo?.storeState ?? "");				
		}
  }, [errandInfo, edit]);
	
  useEffect(() => {
		//console.log(errandAddressDataLog);
		setAddresses(errandAddressDataLog ?? []);
		//setErrandAddress(errandInfo?.storeAddress ?? "");
		parseAddress(errandInfo?.storeAddress, 'houseNo');
		parseAddress(errandInfo?.storeAddress, 'streetName');
		setErrandAddressName(errandInfo?.storeName ?? "");
		setBusinessInstruction(errandInfo?.storeInstruction ?? "");
		setLat(errandInfo?.storeLat ?? 0);
		setLng(errandInfo?.storeLng ?? 0);
		setPhone(errandInfo?.storePhone ?? "");
		setApt(errandInfo?.storeSuite ?? "");		
		setCity(errandInfo?.storeCity ?? "");		
		setState(errandInfo?.storeState ?? "");		
		setDefaultAddress("");		
  }, [open]);
	
	
  useEffect(() => {
		let selected,
		newEntry = [];		
		for (var i = 0; i < addresses.length; i++) {
			if (
				addresses[i].id === defaultAddress &&
				addresses[i]?.address !== "null" &&
				addresses[i]?.address !== null &&
				addresses[i]?.address !== ""
			) {
				setEdit(false);
				selected = {
					...addresses[i],
					addressMain: true,
					latitude: addresses[i]?.lat,
					longitude: addresses[i]?.lng,
				};
				newEntry.push(selected);
			} else if (
				addresses[i]?.address !== "null" &&
				addresses[i]?.address !== ""
			) {
				newEntry.push({ ...addresses[i], addressMain: false });
			}
			if (i === addresses.length - 1) {				
				setAddresses(newEntry);
				//console.log('newEntry Updated');
			}
		}
	
  }, []);
	
	
  useEffect(() => {
    window.document.body.style.overflow = open ? "hidden" : "";	
  }, [open]);
	
	const parseAddress = async (address, type) => {
		await utils.parseAddress(address, function(err, addressObj){
			if(type === 'houseNo'){
				setHouseNo(addressObj.house_number ?? '');
				return addressObj.house_number ?? '';
			}else	if(type === 'streetName'){				
				setErrandAddress(addressObj.street_name ?? '');
				return addressObj.street_name ?? '';
			}else{
				return '';
			}
		});	
	}
  
  const handleAddressName = (value) => {
		setEdit(true);
    setErrandAddressName(value);		
  };
	
  const handleAddress = (value) => {
		setEdit(true);
    setErrandAddress(value);		
  };
	
  const handleCity = (value) => {
		setEdit(true);
    setCity(value);		
  };
	
  const handleState = (value) => {
		setEdit(true);
    setState(value);		
  };
	
  const handleBusinessInstruction = (value) => {		
    setBusinessInstruction(value);		
  };
	
	const handleOpenLogin = async () => {
    await setOpen(false);
    //openLogin();
  };
	
	const handleDefaultAddress = async(e) => {
		window['visible'] = false;
		setMsgHead('');
		setMsgBody('');
		setMsgType('');
    setDefaultAddress(e.target.value);
		
    let newEntry = [];
    let selected = {};
    for (let i = 0; i < addresses.length; i++) {
      if (
        addresses[i].id === e.target.value &&
        addresses[i]?.address !== "null" &&
        addresses[i]?.address !== ""
      ) {
				setEdit(false);
        selected = { ...addresses[i], addressMain: true };
        newEntry.push(selected);
				
        const naddress = selected?.address ?? "";
        const naddressLabel = selected?.addressLabel ?? "";
        const ninstruction = selected?.instruction ?? "";
        const nlat = selected?.lat ?? 0;
        const nlng = selected?.lng ?? 0;
        const ncity = selected?.city ?? "";
        const nstate = selected?.state ?? "";
        const nzip = selected?.zip ?? "";
        const ncountry = selected?.country ?? "";
        const nphone = selected?.phone ?? "";
        const napt = selected?.aptsuite ?? "";
				
				//setErrandAddress(naddress);
				parseAddress(naddress, 'houseNo');
				parseAddress(naddress, 'streetName');
				setErrandAddressName(naddressLabel);
				setBusinessInstruction(ninstruction);				
				setPhone(nphone);
				setApt(napt);
				setLat(parseFloat(nlat));
				setLng(parseFloat(nlng));		
				setCity(ncity);		
				setState(nstate);		
												
				const getAddr = await utils.getLocalCookie("elAddr");				
				const mph = await utils.getDriveData('mph');
				let perHrEarnings = await utils.getDriveData('perHrEarnings');
				const xtra = await utils.getDriveData('errandExtra');
				perHrEarnings = perHrEarnings + xtra;
				const meterPerMile = await utils.getDriveData('meterPerMile');
				const minTip = await utils.getDriveData('minTip');				
				let mileRadius = await utils.getDriveData('mileRadius');
				mileRadius = parseFloat(mileRadius);		
				
				const start = [
					parseFloat(nlat),
					parseFloat(nlng),
				];
				
				if(parseFloat(errandInfo?.latitude) < 1){
					window.location.reload();
					return;
				}
				
				if(parseFloat(nlat) < 1){
					window.location.reload();
					return;
				}
				
				let end;
				if(errandInfo?.latitude){
					end = [
						parseFloat(errandInfo?.latitude),
						parseFloat(errandInfo?.longitude),
					];
					
				}else if(getAddr?.latitude){
					end = [
						parseFloat(getAddr?.latitude) ?? parseFloat(errandInfo?.latitude),
						parseFloat(getAddr?.longitude) ?? parseFloat(errandInfo?.longitude),
					];
				}else{
					window.location.reload();
					return;
				}		
				
				const distanceInMeters = utils.gis.calculateDistance(start, end);
				const miles = distanceInMeters / meterPerMile;			
				
				if(miles > mileRadius){
					const msg = `Errand address is too far (over ${mileRadius} miles)`;
					message.error(msg); 
					window['visible'] = true;
					setMsgHead('');
					setMsgBody(msg);
					setMsgType('error');
					return
				}	
				
				const deliveryFee = parseFloat(utils.errandDeliveryFee(miles, mph, perHrEarnings));
				
				
				const newErrandData = {
					...errandInfo,
					storeLabel: naddressLabel,
					storeName: naddressLabel,
					storeAddress: naddress,
					storeSuite: napt,
					storePhone: nphone,
					storeInstruction: ninstruction,
					storeId: uuidv4(),
					storeState: nstate,
					storeCity: ncity,
					storeZip: nzip,
					storeLat: parseFloat(nlat) ?? 0,
					storeLng: parseFloat(nlng) ?? 0,
					deliveryFee: deliveryFee,
				};
				await updateErrandData(newErrandData); 
				
      } else if (
        addresses[i]?.address !== "null" &&
        addresses[i]?.address !== null &&
        addresses[i]?.address !== ""
      ) {
        newEntry.push({ ...addresses[i], addressMain: false });
      }
			
      if (i === addresses.length - 1) {
				setAddresses(addresses);
			}
    }
  };

  const radioStyle = {
    display: "flex",
    height: "30px",
    lineHeight: "30px",
    fontFamily: "Montserrat",
    fontWeight: 600,
    color: "#3e5725",
		justifyContent: "start"
  };
	
	const search = async (query) => {
    try {
      if (!query) {
        return;
      }
			
			const getcountry = await utils.getCountryByStateProvince(state);
			let region = await utils.getLocalCookie('__user_region');
			
			if(region && getcountry && getcountry !== region){
				region = getcountry;
				await utils.setLocalCookie('__user_region', getcountry);
			}
			let target = '';			
			if(region){
				target =
        utils.USER_CALL + "?request=address&address=" + query + '&country='+region;
			}else{
				target =
        utils.USER_CALL + "?request=address&address=" + query;
			}
      const header = new Headers({
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + authToken,
      });
      const posturl = utils.BASE_URL + target; //utils.GET_URL;

      const dataPost = "";
      const res = await utils.fetchURL(posturl, "GET", dataPost, header);
      const response = await res.json();
      return response;
    } catch (error) {
      //console.error(error);
    }
  };

  const handleContinue = async() => {	
		let msg = '';
		window['visible'] = false;
		setMsgHead('');
		setMsgBody('');
		setMsgType('');
		
		if(errandAddress && !errandAddressName){
			msg = `Business/Contact Name is Required`;
			message.error(msg); 
			window['visible'] = true;
			setMsgHead('');
			setMsgBody(msg);
			setMsgType('error');
			return
		}
		
		if(!errandAddress){
			msg = `Street Name is Required`;
			message.error(msg); 
			window['visible'] = true;
			setMsgHead('');
			setMsgBody(msg);
			setMsgType('error');
			return
		}
		
		if(!city){
			msg = `City is Required`;
			message.error(msg); 
			window['visible'] = true;
			setMsgHead('');
			setMsgBody(msg);
			setMsgType('error');
			return
		}
		
		if(!state){
			msg = `State is Required`;
			message.error(msg); 
			window['visible'] = true;
			setMsgHead('');
			setMsgBody(msg);
			setMsgType('error');
			return
		}
		
		const searchAddress = `${houseNo} ${errandAddress}, ${city}, ${state}`;
		
		if(errandAddress && errandAddressName)
		{
			if(edit)
			{
				setLoading(true);
				let fetchParams = await search(searchAddress);			
				setParams(fetchParams);
				setLat(fetchParams?.data?.loc?.latitude);
				setLng(fetchParams?.data?.loc?.longitude);
				
				const getAddr = await utils.getLocalCookie("elAddr");				
				const mph = await utils.getDriveData('mph');
				let perHrEarnings = await utils.getDriveData('perHrEarnings');
				const xtra = await utils.getDriveData('errandExtra');
				perHrEarnings = perHrEarnings + xtra;
				const meterPerMile = await utils.getDriveData('meterPerMile');
				const minTip = await utils.getDriveData('minTip');
				let mileRadius = await utils.getDriveData('mileRadius');
				mileRadius = parseFloat(mileRadius);	
				
				const start = [
					fetchParams?.data?.loc?.latitude ?? 0,
					fetchParams?.data?.loc?.longitude ?? 0,
				];
				//console.log('A', start);
				
				let end;
				if(getAddr?.latitude){
					end = [
						parseFloat(getAddr?.latitude) ?? parseFloat(errandInfo?.latitude),
						parseFloat(getAddr?.longitude) ?? parseFloat(errandInfo?.longitude),
					];
					//console.log('B', end);
				}else{
					end = [
						parseFloat(errandInfo?.latitude),
						parseFloat(errandInfo?.longitude),
					];
					//console.log('C', end);
				}
				
				const distanceInMeters = utils.gis.calculateDistance(start, end);
				const miles = distanceInMeters / meterPerMile;
				
				
				if(miles > mileRadius){
					msg = `Pickup address is too far (over ${mileRadius} miles)`;
					message.error(msg); 
					window['visible'] = true;
					setMsgHead('');
					setMsgBody(msg);
					setMsgType('error');
					setLoading(false);
					return
				}	
				
				const deliveryFee = parseFloat(utils.errandDeliveryFee(miles, mph, perHrEarnings));				
				
				const newErrandData = {
					...errandInfo,
					storeLabel: errandAddressName,
					storeName: errandAddressName,
					storeAddress: houseNo+' '+errandAddress,
					storeSuite: apt,
					storePhone: phone,
					storeInstruction: businessInstruction,
					storeId: uuidv4(),
					storeState: fetchParams?.data?.state ?? state,
					storeCity: fetchParams?.data?.city ?? city,
					storeZip: fetchParams?.data?.zip ?? "",
					storeLat: fetchParams?.data?.loc?.latitude ?? 0,
					storeLng: fetchParams?.data?.loc?.longitude ?? 0,
					deliveryFee: deliveryFee,
				};
				
				//console.log(newErrandData);				
				updateErrandData(newErrandData); 
				setLoading(false);
			}
			
		}
		
	  setOpen(false);
  };

  const getHeader = () => {
      return "Pickup Address";
  };

  const getButton = () => {
      return [
        <Button 
					key="back" 
					className="c__button"
					loading={loading}
					onClick={() => handleContinue()}
				>
          Continue
        </Button>,
      ];
  };

  return (
    <>
      
			<div className="_bucj1r" data-name="cart.errandInfo.address.pickup">
          <svg
            className="_17lw9oo"
            aria-label="Delivery icon"
            width="20"
            height="25"
            viewBox="0 0 17 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Delivery icon</title>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M8.5 0C4.0879 0 0.5 3.57642 0.5 7.99164C0.5 9.84522 1.14432 11.6412 2.32265 13.072L7.72807 19.6357C7.91803 19.8664 8.20119 20 8.5 20C8.79881 20 9.08197 19.8664 9.27193 19.6357L14.6773 13.072C15.8557 11.6412 16.5 9.84522 16.5 7.99164C16.5 3.57642 12.9121 0 8.5 0ZM6.5 8C6.5 6.89543 7.39543 6 8.5 6C9.60457 6 10.5 6.89543 10.5 8C10.5 9.10457 9.60457 10 8.5 10C7.39543 10 6.5 9.10457 6.5 8Z"
              fill="#4C4C4C"
            ></path>
          </svg>
          <div className="_1ucqjmf">
            <div>
              <div data-name="delivery-name" className="_15mkp10 _1upj7ie">
                <p data-name="delivery-date-name" className="Bv001L9NJtjGjDwPCRoIt">
									{`Pickup From`}									
                </p>
								<p data-name="delivery-date-time">
                  {`${errandAddressName ? errandAddressName : ""}`}        
                </p>
              </div>
              <div className="_1r1eulg">
                <p data-name="errand-address" className="_1IWUl16W8WLdjHSoXcSyru _17y0oyu">
                  {!errandAddress ? "+ Add address" : houseNo+' '+errandAddress+" "}
									{apt && apt.substring(0, 1) !== "#" ? "#" + apt : apt}
                </p>
              </div>
							<div className="_1r1eulg">
                <p data-name="delivery-address" className="_17y0oyu">      
                  {phone ? "Phone #: "+phone : ""}
                </p>
              </div>
            </div>
            <button
              aria-disabled="false"
              className="_1JdD97nX-WGcPASVXFJXKw _1SEARqWERjoS6g0rgsTmMe _1p5njgr"
              tabIndex="0"
              type="button"
              id="errandAddress"
              onClick={() => setOpen(true)}
            >
              Edit
            </button>
          </div>
        </div>	
      
	  
			<Drawer
				title={getHeader()}
				open={open}
				closable={false}
				maskClosable={true}
				footer={getButton()}							
				placement="right"        
				onClose={() => setOpen(false)}
				size={`large`}
			>
						
		  <div style={{ backgroundColor: "rgba(204,220,205,0.2)", padding: 20 }}>
				<div>
				
				<div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: -20}}>
				<FormInput
				label={`Business/Contact Name *`}
				placeholder="Contact Name"
				secureTextEntry="text"
				inputType="input"
				inputId="houseNo"
				onChange={(e) => handleAddressName(e.target.value)}
				value={errandAddressName}
				containerStyle={{
					marginTop: 15,
					width: '49%',
					textAlign: 'left',
				}}
				inputContainerStyle={{
					backgroundColor: '#ffffff',
					width: '100%'
				}}
				inputLabelStyle={{
					fontSize: 13
				}}
				inputClass={'cus_input _1lSOQ6q2xcOkaxVT1smcn7'}
				/>
				
				<FormInput
				label={`Phone`}
				placeholder="(999) 999-9999"
				secureTextEntry="text"
				inputType="inputmask"
				inputId="phone"
				mask="(999) 999-9999"
				onChange={(e) => setPhone(e.target.value)}
				value={phone}
				containerStyle={{
					marginTop: 15,
					width: '49%',
					textAlign: 'left',
				}}
				inputContainerStyle={{
					backgroundColor: '#ffffff',
					width: '100%'
				}}
				inputLabelStyle={{
					fontSize: 13
				}}
				inputClass={'cus_input _1lSOQ6q2xcOkaxVT1smcn7'}	
				/>		
				</div>
				
				<div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 0}}>
				<FormInput
				label={`House No #`}
				placeholder="No"
				secureTextEntry="text"
				inputType="input"
				inputId="houseNo"
				onChange={(e) => setHouseNo(e.target.value)}
				value={houseNo}
				containerStyle={{
					marginTop: 15,
					width: '28%',
					textAlign: 'left',
				}}
				inputContainerStyle={{
					backgroundColor: '#ffffff',
					width: '100%'
				}}
				inputLabelStyle={{
					fontSize: 13
				}}
				inputClass={'cus_input _1lSOQ6q2xcOkaxVT1smcn7'}
				/>
				
				<FormInput
				label={`Street Name *`}
				placeholder="Street Name"
				secureTextEntry="text"
				inputType="input"
				inputId="errandAddress"
				onChange={(e) => handleAddress(e.target.value)}
				value={errandAddress}
				containerStyle={{
					marginTop: 15,
					width: '70%',
					textAlign: 'left',
				}}
				inputContainerStyle={{
					backgroundColor: '#ffffff',
					width: '100%'
				}}
				inputLabelStyle={{
					fontSize: 13
				}}
				inputClass={'cus_input _1lSOQ6q2xcOkaxVT1smcn7'}	
				/>		
				</div>
				
				<div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 0}}>
				
				<FormInput
				label={`Suite #`}
				placeholder="Suite #"
				secureTextEntry="text"
				inputType="input"
				inputId="apt"
				onChange={(e) => setApt(e.target.value)}
				value={apt}
				containerStyle={{
					marginTop: 15,
					width: '24%',
					textAlign: 'left',
				}}
				inputContainerStyle={{
					backgroundColor: '#ffffff',
					width: '100%'
				}}
				inputLabelStyle={{
					fontSize: 13
				}}
				inputClass={'cus_input _1lSOQ6q2xcOkaxVT1smcn7'}	
				/>
				
				<FormInput
				label={`City *`}
				placeholder="City"
				secureTextEntry="text"
				inputType="input"
				inputId="city"
				onChange={(e) => handleCity(e.target.value)}
				value={city}
				containerStyle={{
					marginTop: 15,
					width: '36%',
					textAlign: 'left',
				}}
				inputContainerStyle={{
					backgroundColor: '#ffffff',
					width: '100%'
				}}
				inputLabelStyle={{
					fontSize: 13
				}}
				inputClass={'cus_input _1lSOQ6q2xcOkaxVT1smcn7'}	
				/>
				
				<FormInput
				label={`State/Province *`}
				placeholder="State/Province"
				secureTextEntry="text"
				inputType="input"
				inputId="state"
				onChange={(e) => handleState(e.target.value)}
				value={state}
				containerStyle={{
					marginTop: 15,
					width: '37%',
					textAlign: 'left',
				}}
				inputContainerStyle={{
					backgroundColor: '#ffffff',
					width: '100%'
				}}
				inputLabelStyle={{
					fontSize: 13
				}}
				inputClass={'cus_input _1lSOQ6q2xcOkaxVT1smcn7'}	
				/>
				</div>
				
				<div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 0}}>
					<textarea
						className="_1lSOQ6q2xcOkaxVT1smcn7 _3gouMfJy504Yjz5rHjoHpu"
						placeholder="Instruction to Business [Optional]"
						title="Instruction to Business [Optional]"
						style={{
							width: '100%', 
							height: '100%',
							padding: 12,
							fontSize: 15,
						}} 
						value={businessInstruction}
						onChange={(e) => handleBusinessInstruction(e.target.value)}
					/>
				</div>
				</div>
				
				<Space className="alertbutton" direction="vertical">
				{window['visible'] && (
					<Alert message={msgHead} description={msgBody} type={msgType} />
				)}
				</Space>
		
				{ellogin === "1" && addresses.length > 0 && (
					<>
						<br />
						<div style={{ ...radioStyle, textDecoration: "underline" }}>
							SAVED ERRAND ADDRESSES
						</div>
						<Radio.Group
							onChange={handleDefaultAddress}
							value={defaultAddress}
						>
							{addresses.map((address) => (
								<Radio
									style={radioStyle}
									key={address.id}
									value={address.id}
								>
									{`${address?.addressLabel ? address?.addressLabel : ""}${
										address?.addressLabel ? ":" : ""
									} ${address?.address}`}
								</Radio>
							))}
						</Radio.Group>
					</>
				)}

				{ellogin === "1" && addresses.length === 0 && (
					<div style={radioStyle}>No Saved Errand Address</div>
				)}

				{ellogin === "0" && (
					<button
						type="button"
						onClick={() => handleOpenLogin()}
						style={{
							border: "none",
							paddingLeft: 0,
							marginLeft: 0,
							minWidth: 0,
							...radioStyle,
						}}
					>
						<span style={{ color: "#000", textDecoration: "underline" }}>
							Log in for saved address
						</span>
					</button>
				)}
		  </div>		

	  </Drawer>
    </>
  );
};

export default ErrandStoreAddressModal;
