import React,{ useEffect } from "react";
import { DeliveryCode } from '../../components';
import { v4 as uuidv4 } from "uuid";
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { utils } from "../../utils";

const DeliveryCodeScreen = ({logPage}) => 
{	
	const navigate = useNavigate();
	const location = useLocation();
	
	useEffect(() => {
		document.title = `Delivery Code | Order Online | ErrandLocal`;	
	}, []);
	
	useEffect(() => {
		document.body.style.overflow = "auto";
	}, []);
	
	useEffect(() => {
		const ref = document.referrer;
    const loc = location.pathname;
		logPage(ref, loc);
	}, []);
	
	useEffect(() => {
		const checkLogin = async() => {
			const sessionemail = await utils.getStorage('__el_data_email');
			const redirect = await utils.checkLogin(sessionemail);
			if((redirect && sessionemail) || redirect){
				navigate(redirect);
			}
		}
		checkLogin();
	}, []);
	
	
  
	function convertAddress(address){
		return address.toLowerCase().replace(/[^0-9a-zA-Z -]/g, '').replace(/ /g, '-').replace(/--/g, '-');
	}
	
	const SwitchRef = (ref) => {
		navigate(ref);
	}
	
	return (
		<>		
			<DeliveryCode key={uuidv4()} />
		</>			
	)
};

export default DeliveryCodeScreen;
