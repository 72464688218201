import React, { useEffect, useState } from "react";
import { utils } from "../../utils";
import { MapUpdate, FormInput } from '../../components';
import { v4 as uuidv4 } from "uuid";
import { useSelector, useDispatch } from "react-redux";
import { Modal, Button, Form, message, Drawer, Radio } from "antd";
import {
  selectSavedAddresses,
  setSavedAddresses,
} from "../../includes/navSlice";

const AddressNavbarModal = ({
  deliveryInfo,
  errandInfo,
  fulfillmentType,
  setFulfillmentType,
  address,
  setAddress,
  updateDeliveryData,
  updateErrandData,
  triggerModal,
  setTriggerModal,
}) => {
	
const dispatch = useDispatch();
let savedAddresses = useSelector(selectSavedAddresses);
savedAddresses =
typeof savedAddresses === "object"
? savedAddresses
: JSON.parse(savedAddresses);

const [open, setOpen] = useState(false);
const [adjust, setAdjust] = useState(false);
const [next, setNext] = useState(false);
const [loading, setLoading] = useState(false);
const [newAddress, setNewAddress] = useState('');
const [houseNo, setHouseNo] = useState('');
const [locationAddress, setLocationAddress] = useState('');
const [city, setCity] = useState('');
const [state, setState] = useState('');
const [lat, setLat] = useState(0);
const [lng, setLng] = useState(0);  
const [marker, setMarker] = useState(null);
const [params, setParams] = useState({});
const [addresses, setAddresses] = useState(savedAddresses || []);
//console.log('85', savedAddresses)
const [defaultAddress, setDefaultAddress] = useState(
	addresses?.find((address) => address?.addressMain)?.id || ""
);

const authToken = utils.getStorage('__el_data_login_token'); 
const ellogin = utils.getStorage("__el_data_login_status");

useEffect(() => {
	setAdjust(adjust);    
}, [adjust]);

useEffect(() => {
	setNewAddress(address);    
	//console.log('newAddress', newAddress);    
}, [address]);

useEffect(() => {	
	const initial = async() => {
		const getAddr = await utils.getLocalCookie('elAddr');
		
		if(errandInfo?.address && errandInfo?.latitude){
			const {address, latitude, longitude, city, state} = errandInfo;
			//setLocationAddress(address);
			await parseAddress(address, 'houseNo');
			await parseAddress(address, 'streetName');
			setCity(city);
			setState(state);
			setLat(parseFloat(latitude));
			setLng(parseFloat(longitude));
			}else if(errandInfo?.address && getAddr?.latitude){
			const {address, city, state} = errandInfo;
			//setLocationAddress(address);
			await parseAddress(address, 'houseNo');
			await parseAddress(address, 'streetName');
			setCity(city);
			setState(state);
			setLat(parseFloat(getAddr?.latitude));
			setLng(parseFloat(getAddr?.longitude));
			}else if(deliveryInfo?.address && deliveryInfo?.latitude){
			const {address, latitude, longitude, city, state} = deliveryInfo;
			//setLocationAddress(address);
			await parseAddress(address, 'houseNo');
			await parseAddress(address, 'streetName');
			setCity(city);
			setState(state);
			setLat(parseFloat(latitude));
			setLng(parseFloat(longitude));
			}else if(deliveryInfo?.address && getAddr?.latitude){
			const {address, city, state} = deliveryInfo;
			//setLocationAddress(address);
			await parseAddress(address, 'houseNo');
			await parseAddress(address, 'streetName');
			setCity(city);
			setState(state);
			setLat(parseFloat(getAddr?.latitude));
			setLng(parseFloat(getAddr?.longitude));
			}else{
			//console.log('deliveryInfo Issue');
		}
		
	}
	initial();
}, [deliveryInfo, open, errandInfo]);

useEffect(() => {
	window.document.body.style.overflow = open ? "hidden" : "";	
}, [open]);

useEffect(() => {	
	/*
	if(lat !== 0){
		setAdjust(true);
		const newDeliveryData = {
			...deliveryInfo,
			latitude: marker?.lat,
			longitude: marker?.lng,
		};
		
		const newErrandData = {
			...errandInfo,
			latitude: marker?.lat,
			longitude: marker?.lng,
		};
		
		updateDeliveryData(newDeliveryData);
		updateErrandData(newErrandData);
	}*/
}, []);

useEffect(() => {
	const initial = async() => {
		if (lat !== 0 && marker?.lat) {
			setAdjust(true);			
			const newErrandData = {
				...errandInfo,        
				latitude: marker?.lat,
				longitude: marker?.lng,
			};    
			
			const newDeliveryData = {
				...deliveryInfo,        
				latitude: marker?.lat,
				longitude: marker?.lng,
			};
			await updateErrandData(newErrandData);
			await updateDeliveryData(newDeliveryData);
			
			let selected,
			newEntry = [];
			for (let i = 0; i < addresses.length; i++) {
				if (
					addresses[i].id === defaultAddress &&
					addresses[i]?.address !== "null" &&
					addresses[i]?.address !== null &&
					addresses[i]?.address !== ""
					) {
					selected = {
						...addresses[i],
						addressMain: true,
						latitude: marker?.lat,
						longitude: marker?.lng,
					};
					const getAddr = await utils.getLocalCookie("elAddr");
					
					let cookieData = {
						...getAddr,
						latitude: marker?.lat,
						longitude: marker?.lng,
					}
					await utils.setLocalCookie("elAddr", cookieData);
					newEntry.push(selected);
					} else if (
					addresses[i]?.address !== "null" &&
					addresses[i]?.address !== ""
					) {
					newEntry.push({ ...addresses[i], addressMain: false });
				}
				if (i === addresses.length - 1) {
					dispatch(setSavedAddresses(JSON.stringify(newEntry)));
					//console.log('301', newEntry);
					setAddresses(newEntry);
				}
			}
		}
		
	}
	initial();
}, [marker]);

useEffect(() => {	
	if(triggerModal){
		openAddressModal();
		}else{
		closeAddressModal();
	}
}, [triggerModal]);

const handleLocationAddress = async(value) => {	
	setLocationAddress(value);
	setLat(0);
	setLng(0);
};

const parseAddress = async (address, type) => {
	await utils.parseAddress(address, function(err, addressObj){
		if(type === 'houseNo'){
			setHouseNo(addressObj.house_number ?? '');
			return addressObj.house_number ?? '';
		}else	if(type === 'streetName'){				
			setLocationAddress(addressObj.street_name ?? '');
			return addressObj.street_name ?? '';
		}else{
			return '';
		}
	});	
}

const handleChangeUpdate = async() => {	
	//
	if(!locationAddress){message.error('Street name is required'); return false;}	
	if(!city){message.error('City is required'); return false;}	
	if(!state){message.error('State is required'); return false;}	
	setLoading(true);
	const searchAddress = `${houseNo} ${locationAddress}, ${city}, ${state}`;
	//console.log(searchAddress);
	let fetchParams = await search(searchAddress);	
	setParams(fetchParams);
	//console.log('F', fetchParams);
	setLat(fetchParams?.data?.loc?.latitude);
	setLng(fetchParams?.data?.loc?.longitude);
	setLoading(false);
	return;
};


const handleNextButton = async() => {
	setLat(0);
	setLng(0);
	setAdjust(false);
	setNext(true);
	return;
};

const handleSaveButton = async() => {	
	const nlat = marker?.lat ? marker?.lat : params?.data?.loc?.latitude;
	const nlng = marker?.lng ? marker?.lng : params?.data?.loc?.longitude;
	const ncity = params?.data?.city;
	const nstate = params?.data?.state;
	const nzip = params?.data?.zip;
	const ncountry = params?.data?.country;
	
	const getAddr = await utils.getLocalCookie("elAddr");
	
	let cookieData = {
		...getAddr,
		address: houseNo+' '+locationAddress,
		city: ncity,
		state: nstate,
		zip: nzip,
		country: ncountry,
		latitude: nlat,
		longitude: nlng,
	}
	//console.log(cookieData);
	await utils.setLocalCookie("elAddr", cookieData);
	
	setLat(nlat);
	setLng(nlng);
	setAddress(houseNo+' '+locationAddress, nlat, nlng);
	setFulfillmentType('Delivery');	
	setNext(false);
	//console.log(locationAddress, nlat, nlng);
	
	const newDeliveryData = {
	  ...deliveryInfo,	  
	  type: 'Delivery',
	  address: houseNo+' '+locationAddress,
	  latitude: ''+nlat,
	  longitude: ''+nlng,
	  city: ncity,
	  state: nstate,
	  zip: nzip,
	  country: ncountry,
	};
	
	const newErrandData = {
	  ...errandInfo,	  
	  type: 'Errand',
	  address: houseNo+' '+locationAddress,
	  latitude: ''+nlat,
	  longitude: ''+nlng,
	  city: ncity,
	  state: nstate,
	  zip: nzip,
	  country: ncountry,
	};
	//console.log(newErrandData);
	
	await updateDeliveryData(newDeliveryData);
	await updateErrandData(newErrandData);
	closeAddressModal();
	return;
};

const handleDefaultAddress = async(e) => {
	setDefaultAddress(e.target.value);
	
	let newEntry = [];
	let selected = {};
	for (let i = 0; i < addresses.length; i++) {
		if (
			addresses[i].id === e.target.value &&
			addresses[i]?.address !== "null" &&
			addresses[i]?.address !== null &&
			addresses[i]?.address !== ""
      ) {
			selected = { ...addresses[i], addressMain: true };
			newEntry.push(selected);
      } else if (
			addresses[i]?.address !== "null" &&
			addresses[i]?.address !== null &&
			addresses[i]?.address !== ""
      ) {
			newEntry.push({ ...addresses[i], addressMain: false });
		}
		
		if (i === addresses.length - 1) {			
			const nlat = selected?.lat ?? selected?.latitude;
			const nlng = selected?.lng ?? selected?.longitude;
			const ncity = selected?.city;
			const nstate = selected?.state;
			const nzip = selected?.zip;
			const ncountry = selected?.country;
			const naddress = selected?.address;
			const napt = selected?.apartment || "";
			const naccess = selected?.accessCode || "";
			setLat(parseFloat(nlat));
			setLng(parseFloat(nlng));
			setAddress(naddress);
			//setAptNo(napt);
			//setAccessCode(naccess);
			
			const getAddr = await utils.getLocalCookie("elAddr");
			
			let cookieData = {
				...getAddr,
				address: naddress,
				city: ncity,
				state: nstate,
				zip: nzip,
				country: ncountry,
				apt: napt,
				access: naccess,
				latitude: nlat,
				longitude: nlng,
			}
			await utils.setLocalCookie("elAddr", cookieData);					
			
			setFulfillmentType("Delivery");
			//setSchedule(false);
			//setChange(false);
			setMarker(null);
			/* 
			const mph = await utils.getDriveData('mph');
			const perHrEarnings = await utils.getDriveData('perHrEarnings');		
			const meterPerMile = await utils.getDriveData('meterPerMile');
			
			const start = [gps?.latitude, gps?.longitude];
			const end = [nlat, nlng];
			const distanceInMeters = utils.gis.calculateDistance(start, end);
			const miles = distanceInMeters / meterPerMile;
			const deliveryFee = await utils.deliveryFee(miles, mph, perHrEarnings);
			//console.log('OK', start, end, naddress);
			setFee(`${deliveryInfo?.currency}${deliveryFee}`);	 */		
			
			const newErrandData = {
				...errandInfo,
				type: "Errand",
				address: naddress,
				apartment: napt,
				latitude: parseFloat(nlat),
				longitude: parseFloat(nlng),
				city: ncity,
				state: nstate,
				zip: nzip,
				country: ncountry,
				accessCode: naccess,
			};
			
			const newDeliveryData = {
				...deliveryInfo,
				type: "Delivery",
				address: naddress,
				apartment: napt,
				latitude: parseFloat(nlat),
				longitude: parseFloat(nlng),
				city: ncity,
				state: nstate,
				zip: nzip,
				country: ncountry,
				accessCode: naccess,
			};
			
			dispatch(setSavedAddresses(JSON.stringify(newEntry)));
			//console.log('759', newEntry);
			setAddresses(newEntry);
			await updateErrandData(newErrandData);
			await updateDeliveryData(newDeliveryData);
			let mileRadius = await utils.getDriveData('mileRadius');
			mileRadius = parseFloat(mileRadius);
				
		}
	}
};


const openAddressModal = async () => {
	await setOpen(true);
};

const closeAddressModal = async () => {    
	await setTriggerModal(false);
	await setOpen(false);
};

const radioStyle = {
	display: "flex",
	height: "30px",
	lineHeight: "30px",
	fontFamily: "Montserrat",
	fontWeight: 600,
	color: "#3e5725",
	justifyContent: 'start'
};

const getHeader = () => {
	return "Delivery Address";
};

const getButton = () => {
	
	if(lat !== 0 && !next && params?.data?.loc?.latitude){
		return [
			<Button
			key={uuidv4()}
			className="c__button"
			onClick={() => closeAddressModal()}
			>
			Close
			</Button>,
			<Button
			key={uuidv4()}
			className="c__button"
			onClick={() => handleNextButton()}
			>
			Next
			</Button>
		];
		}else if(next && params?.data?.loc?.latitude){
		return [
			<Button
			key={uuidv4()}
			className="c__button"
			onClick={() => handleSaveButton()}
			>
			Save
			</Button>
		];
		}else{
		return [
			<Button
			key={uuidv4()}
			className="c__button"
			onClick={() => closeAddressModal()}
			>
			Close
			</Button>,
			<Button
			key={uuidv4()}
			className="c__button"
			loading={loading}
			onClick={() => handleChangeUpdate()}
			>
			Use
			</Button>,
		];
	}
};

const search = async (query) => {
	try {
		if(!query){return}
		const getcountry = await utils.getCountryByStateProvince(state);
		let region = await utils.getLocalCookie('__user_region');
		
		if(region && getcountry && getcountry !== region){
			region = getcountry;
			await utils.setLocalCookie('__user_region', getcountry);
		}
		
		let target = '';		
		if(region){
			target =
			utils.USER_CALL + "?request=address&address=" + query + '&country='+region;
			}else{
			target =
			utils.USER_CALL + "?request=address&address=" + query;
		}
		const header = new Headers({
			'Content-Type': 'application/json',
			'Accept': 'application/json',
			'Authorization': 'Bearer '+authToken,
		});
		const posturl = utils.BASE_URL+target; //utils.GET_URL;
		const dataToPost = '';			
		const res = await utils.fetchURL(posturl, 'GET', dataToPost, header);
		const response = await res.json();
		return response;
    } catch (error) {
		//console.error(error);
	}
};

return (
	<>
	<div className="deliveryAddressInputSuggest" id="deliveryAddressInputSuggest" onClick={()=> openAddressModal()}>{newAddress}</div>
	
	<Drawer
	title={getHeader()}
	open={open}
	//onCancel={() => closeAddressModal()}
	closable={false}
	maskClosable={false}
	footer={getButton()}							
	placement="right"        
	onClose={() => closeAddressModal()}
	size={`large`}
	>
	
	<>
	<Form
	style={{ backgroundColor: "rgba(204,220,205,0.2)", padding: 20 }}
	initialValues={{ locationAddress: houseNo+' '+locationAddress }}
	>						
	<div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: -20}}>
	<FormInput
	label={`House No`}
	placeholder="No"
	secureTextEntry="text"
	inputType="input"
	inputId="houseNo"
	onChange={(e) => setHouseNo(e.target.value)}
	value={houseNo}
	containerStyle={{
		marginTop: 15,
		width: '28%',
		textAlign: 'left',
	}}
	inputContainerStyle={{
		backgroundColor: '#ffffff',
		width: '100%'
	}}
	inputLabelStyle={{
		fontSize: 13
	}}
	inputClass={'cus_input _1lSOQ6q2xcOkaxVT1smcn7'}
	/>
	{/*
	<input 
	type="text"
	placeholder="Delivery Address"
	className="_1lSOQ6q2xcOkaxVT1smcn7 _3gouMfJy504Yjz5rHjoHpu"
	style={{
		width: '70%', 
		height: '100%',
		padding: 12,
		fontSize: 15,								
	}}
	value={locationAddress}
	onChange={(e) => handleLocationAddress(e.target.value)}
	/>
*/}
	
	<FormInput
	label={`Street Name *`}
	placeholder="Street Name"
	secureTextEntry="text"
	inputType="input"
	inputId="locationAddress"
	onChange={(e) => handleLocationAddress(e.target.value)}
	value={locationAddress}
	containerStyle={{
		marginTop: 15,
		width: '70%',
		textAlign: 'left',
	}}
	inputContainerStyle={{
		backgroundColor: '#ffffff',
		width: '100%'
	}}
	inputLabelStyle={{
		fontSize: 13
	}}
	inputClass={'cus_input _1lSOQ6q2xcOkaxVT1smcn7'}
	/>	
	</div>
	
	<div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 0, marginBottom: 10}}>
		
	<FormInput
	label={`City *`}
	placeholder="City"
	secureTextEntry="text"
	inputType="input"
	inputId="city"
	onChange={(e) => setCity(e.target.value)}
	value={city}
	containerStyle={{
		marginTop: 15,
		width: '49%',
		textAlign: 'left',
	}}
	inputContainerStyle={{
		backgroundColor: '#ffffff',
		width: '100%'
	}}
	inputLabelStyle={{
		fontSize: 13
	}}
	inputClass={'cus_input _1lSOQ6q2xcOkaxVT1smcn7'}
	/>
	
	<FormInput
	label={`State/Province *`}
	placeholder="State/Province"
	secureTextEntry="text"
	inputType="input"
	inputId="state"
	onChange={(e) => setState(e.target.value)}
	value={state}
	containerStyle={{
		marginTop: 15,
		width: '49%',
		textAlign: 'left',
	}}
	inputContainerStyle={{
		backgroundColor: '#ffffff',
		width: '100%'
	}}
	inputLabelStyle={{
		fontSize: 13
	}}
	inputClass={'cus_input _1lSOQ6q2xcOkaxVT1smcn7'}
	/>
	</div>
	
	
	{lat !== 0 && (<div className="mapupdate"><MapUpdate lat={lat} lng={lng} height={300} width={'100%'} setMarker={setMarker} /></div>)}
	</Form>
	
	
		{/*ellogin === "1" && addresses?.length > 0 && (
			<>
			<br />
			<div style={{ ...radioStyle, textDecoration: "underline" }}>
			SAVED DELIVERY ADDRESSES
			</div>
			<Radio.Group
			onChange={handleDefaultAddress}
			value={defaultAddress}
			>
			{addresses.map((address) => (
				<Radio
				style={radioStyle}
				key={address.id}
				value={address.id}
				>
				{`${address.addressLabel ? address.addressLabel : ""}${
					address.addressLabel ? ":" : ""
				} ${address.address}`}
				</Radio>
			))}
			</Radio.Group>
			</>
		)}
		
		{ellogin === "1" && addresses.length === 0 && (
			<div style={radioStyle}>No Saved Address</div>
		)*/}
	
	</>
	</Drawer>
	</>
);
};

export default AddressNavbarModal;
