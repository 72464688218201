import React,{ useEffect } from "react";
import { AddAddress } from '../../components';
import { v4 as uuidv4 } from "uuid";
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { utils } from "../../utils";

const AddAddressScreen = ({logPage}) => 
{	
	const navigate = useNavigate();
	const location = useLocation();
	const { typeId } = useParams();
	
	useEffect(() => {
		if(!typeId)
		{
			navigate("/notfound");
			return false;
		}
		if(typeId === 'delivery' || typeId === 'errand'){
			document.title = `New ${typeId.charAt(0).toUpperCase() + typeId.slice(1)} Address | Order Online | ErrandLocal`;			
		}else{
			navigate("/notfound");
			return false;
		}
		
	}, [typeId]);
	
	useEffect(() => {
		window.document.body.style.overflow = "auto";
	}, []);
	
	useEffect(() => {
		const checkLogin = async() => {
			const sessionemail = await utils.getStorage('__el_data_email');
			const redirect = await utils.checkLogin(sessionemail);
			if((redirect && sessionemail) || redirect){
				navigate(redirect);
			}
		}
		checkLogin();
	}, []);
	
	useEffect(() => {
		const ref = document.referrer;
    const loc = location.pathname;
		logPage(ref, loc);
	}, []);
	  
	function convertAddress(address){
		return address.toLowerCase().replace(/[^0-9a-zA-Z -]/g, '').replace(/ /g, '-').replace(/--/g, '-');
	}
	
	const SwitchRef = (ref) => {
		navigate(ref);
	}
	
	return (
		<>		
			<AddAddress typeId={typeId} key={uuidv4()} />
		</>			
	)
};

export default AddAddressScreen;
