import React, {useEffect, useState} from "react";
import { PaymentCardList, AlertModal } from '../../components';
import { utils } from "../../utils";
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from "react-redux";
import {
  setPaymentCardData,
  setSavedCards,
} from "../../includes/navSlice";
import { message } from "antd";

const PaymentCardListScreen = ({logPage}) => 
{
	const navigate = useNavigate();
	const location = useLocation();
	const dispatch = useDispatch();
	const [os, setOs] = useState('');
	const [model, setModel] = useState('');
	const [alertModalTitle, setAlertModalTitle] = useState("");	
	const [alertModalBody, setAlertModalBody] = useState("");	
	const [alertModalOpen, setAlertModalOpen] = useState(false);	
	const [alertModalButton, setAlertModalButton] = useState("");
	const [alertModalButtonFunc, setAlertModalButtonFunc] = useState();
	const [alertModalButtonFunc2, setAlertModalButtonFunc2] = useState();
	const email = utils.getStorage('__el_data_email');
	const authToken = utils.getStorage('__el_data_login_token');
	
	useEffect(() => {		
		window.document.body.style.overflow = alertModalOpen ? "hidden" : "auto";
	}, [alertModalOpen]);
	
	useEffect(() => {
		const ref = document.referrer;
    const loc = location.pathname;
		logPage(ref, loc);
	}, []);
	
	useEffect(() => {
		setOs('');
		setModel('');
		document.title = `Payment Cards | Order Online | ErrandLocal`;
		const checkLogin = async() => {
			const sessionemail = await utils.getStorage('__el_data_email');
			const redirect = await utils.checkLogin(sessionemail);
			if((redirect && sessionemail) || redirect){
				navigate(redirect);
			}
		}
		checkLogin();
	}, []);
	
	const execCard = (app, title, description, cardId) => {
		if(app){
			setAlertModalTitle(title ? title : 'Address');
			const b = description.split('|');
			let mainbody = '';
			for(let i=0; i<b.length; i++){
				mainbody += "<p style='font-size: 18px'>"+b[i]+"</p>";
			}
			setAlertModalBody(mainbody+"<br/><br/><div class='card-number' style='outline: none' key='d1'><div id='modal-status' style='display: none' key='d2'></div></div>");
			setAlertModalButton("2");
			setAlertModalButtonFunc('usePaymentCard|'+cardId);
			setAlertModalButtonFunc2('deletePaymentCard|'+cardId);
			setAlertModalOpen(!alertModalOpen);		
		}else{
			setAlertModalTitle(title ? title : 'Address');
			const b = description.split('|');
			let mainbody = '';
			for(let i=0; i<b.length; i++){
				mainbody += "<p style='font-size: 18px'>"+b[i]+"</p>";
			}
			setAlertModalBody(mainbody+"<br/><br/><div class='card-number' style='outline: none' key='d1'><div id='modal-status' style='display: none' key='d2'></div></div>");
			setAlertModalButton("1");
			setAlertModalButtonFunc('');
			setAlertModalButtonFunc2('deletePaymentCard|'+cardId);
			setAlertModalOpen(!alertModalOpen);
		}
	}
	
	
	const useCard = async(action, thiscard) => 
	{
		try
		{	
			if(action === 'deletePaymentCard'){
				if(window.confirm('Are you sure you want to delete?'))
				{
					handleDelete(thiscard);
				}
				return false;
			};
			const header = new Headers({
				'Content-Type': 'application/json',
				'Accept': 'application/json',
				'Authorization': 'Bearer '+authToken,
			});
			const posturl = utils.BASE_URL+utils.USER_URL; //utils.POST_URL;
			const target = utils.USER_URL;
			const localtime = await utils.getTime();
			const obj = JSON.stringify({'rtype': 'cardswitch', 'email': email, 'cardId': thiscard, 'os': os, 'model': model, 'localtime': localtime, 'target': target, 'token': authToken});
			const dataToPost = await utils.cipherEncode(obj);			
			const res = await utils.fetchURL(posturl, 'POST', dataToPost, header);
			const data = await res.json();
			
			if(data.msg === 'Success'){
				await dispatch(setPaymentCardData(utils.cipherDecode(data.paymentcard)));
				await dispatch(setSavedCards(JSON.stringify(utils.cipherDecode(data.paymentcard))));
				message.success('Card has been set to default');
				window.scrollTo({top: 0, behavior: 'smooth'});				
				setTimeout(function(){				
					window.location.reload(true);
					//navigate('/payments', {replace: true});
				}, 5000);
			}else{
				message.error(data.msg);
				window.scrollTo({top: 0, behavior: 'smooth'});
			}

		}
		catch(e)
		{
			message.error(e.message);			
		}	
	}
	
	const handleDelete = async (aid) => 
	{
		try{
			const header = new Headers({
				'Content-Type': 'application/json',
				'Accept': 'application/json',
				'Authorization': 'Bearer '+authToken,
			});
			const posturl = utils.BASE_URL+utils.USER_URL; //utils.POST_URL;
			const target = utils.USER_URL;
			const localtime = await utils.getTime();
			const obj = JSON.stringify({'rtype': 'deletecard', 'email': email, 'cardId': aid, 'os': os, 'model': model, 'localtime': localtime, 'target': target, 'token': authToken});
			const dataToPost = await utils.cipherEncode(obj);			
			const res = await utils.fetchURL(posturl, 'POST', dataToPost, header);
			const data = await res.json();
			
			if(data.msg === 'Success'){
				await dispatch(setPaymentCardData(utils.cipherDecode(data.paymentcard)));
				await dispatch(setSavedCards(JSON.stringify(utils.cipherDecode(data.paymentcard))));
				message.success('Card has been successfully deleted');
				window.scrollTo({top: 0, behavior: 'smooth'});
				setTimeout(function(){
					window.location.reload(true);
				}, 5000);
			}else{
				message.error(data.msg);
				window.scrollTo({top: 0, behavior: 'smooth'});
			}
						
		}catch(e){
			message.error(e.message);
		}		
	}	
	
	const ExecCardOperation = (type) => 
	{
		setAlertModalOpen(!alertModalOpen);
		const b = type.split('|');
		let action, target;		
		action = b[0];
		target = b[1];
		useCard(action, target);
	}
	
	return (
		<>
			<PaymentCardList execCard={execCard}/>
			{alertModalOpen && (<AlertModal setAlertOpenModal={setAlertModalOpen} alertModalTitle={alertModalTitle} alertModalBody={alertModalBody} alertModalButton={alertModalButton} setAlertModalButtonFunc={()=> ExecCardOperation(alertModalButtonFunc)} setAlertModalButtonFunc2={()=> ExecCardOperation(alertModalButtonFunc2)} alertType="card"/>)}

		</>
	)
};

export default PaymentCardListScreen;
