import React, { useState, useEffect } from 'react';
import { utils } from "../../utils";
import { Modal, Button, message } from "antd";

const EmailSubscriptionForm = () => {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [showForm, setShowForm] = useState(false);
	const authToken = utils.getStorage('__el_data_login_token');

  useEffect(() => {
    const lastVisit = localStorage.getItem('lastVisit');
    let lastEntry = localStorage.getItem('userDetails');
    if (lastVisit) {
      const sevenDaysAgo = new Date();
      sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
			const lastVisitDate = new Date(lastVisit);
			lastEntry = JSON.parse(lastEntry);
      if (lastVisitDate < sevenDaysAgo && !lastEntry.email) {
        setShowForm(true);				
      } else {				
        setShowForm(false);
      }
    } else {
      setShowForm(true);
    }
  }, []);

  const updateLastVisit = () => {
    localStorage.setItem('lastVisit', new Date().toISOString());
    localStorage.setItem('userDetails', JSON.stringify({ email, name }));
    setShowForm(false);
  };

  const handleSubmit = async () => {
    await postRequest({ email, name });
		updateLastVisit();
  };
	
	const postRequest = async(obj) => 
	{
		try{
			if(utils.isValidEmail(email))
			{				
				const target = utils.USER_URL;
				const header = new Headers({
					'Content-Type': 'application/json',
					'Accept': 'application/json',
					'Authorization': 'Bearer '+authToken,
				});
				const posturl = utils.BASE_URL+target; //utils.POST_URL;
				let dataToPost = JSON.stringify({...obj, 'target': target, 'token': authToken, 'rtype': 'emailsubscribe'});
				
				dataToPost = await utils.cipherEncode(dataToPost);			
				const res = await utils.fetchURL(posturl, 'POST', dataToPost, header);
				const data = await res.json();
				
				if(data?.msg === 'Success')
				{					
					message.success('Email was succesfully enrolled.');
				}else{
					message.error('Unable to save email');
					return;
				}				
			}
			else
			{
				message.error('Email is not valid');
				return;
			}
		}catch(e){
			message.error(e.message);
		}
	}
	


  if (showForm) {
    return (
      <Modal
        visible={showForm}
        title={'SUBSCRIBE FOR UPDATES'}
        onCancel={() => updateLastVisit()}
        footer={[
          <Button
            key="submit"
            className="c__button"
            onClick={() => handleSubmit()}
          >
            Subscribe
          </Button>,
          <Button
            key="cancel"
            className="c__button"
            onClick={() => updateLastVisit()}
          >
            Cancel
          </Button>
        ]}
        style={{
          maxHeight: '80vh',
          overflow: 'auto',
        }}
      >
        <div style={{ backgroundColor: "rgba(204,220,205,0.2)", padding: 20 }}>
					<span>{`Let us give you updates on what is happening in our community.`}</span>
          <div style={{ marginTop: -5 }}>
            <input
              type="text"
              placeholder="Name"
              className="_1lSOQ6q2xcOkaxVT1smcn7 _3gouMfJy504Yjz5rHjoHpu"
              style={{
                width: '100%',
                height: '100%',
                padding: 12,
                fontSize: 15,
              }}
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <input
              type="email"
              title="Email"
              placeholder="Email"
              className="_1lSOQ6q2xcOkaxVT1smcn7 _3gouMfJy504Yjz5rHjoHpu"
              style={{
                width: '100%',
                height: '100%',
                padding: 12,
                fontSize: 15,
              }}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
        </div>
      </Modal>
    );
  } else {
    return null;
  }
};

export default EmailSubscriptionForm;
