import React, { useState, useEffect } from "react";
import moment from "moment-timezone";
import { utils } from "../../utils";
import { images } from "../../constants";
import { v4 as uuidv4 } from "uuid";
import { Divider, Layout, message, Skeleton, Button } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import {
  ScrollHighlight,
  BusinessHoursModal,
  AddressModal,
  LoginSignupModal,
  OrderModal,
  AlertModal,
  StoreMenuData,
} from "../../components";
import { useSelector, useDispatch } from "react-redux";
import {
  selectSavedAddresses,
  selectSavedStores,
  selectOrderCart,
  selectErrandOrderData,
  selectOrderData,
  setSavedStores,
  setSavedAddresses,
  setSavedCards,
  setPaymentCardData,
  setAddressData,
  setErrandAddressData,
  setOrderCart,
  setOrderData,
  setErrandOrderData,
} from "../../includes/navSlice";
import { useParams, useNavigate, useLocation } from "react-router-dom";


let newmenuEntry = [];

const StoreScreen = ({logPage}) => {
  let savedAddresses = useSelector(selectSavedAddresses);
  savedAddresses =
	typeof savedAddresses === "object"
	? savedAddresses
	: JSON.parse(savedAddresses);
  const savedStores = useSelector(selectSavedStores);
  const preOrderCart = useSelector(selectOrderCart);
  const preOrderData = useSelector(selectOrderData);
  const preErrandOrderData = useSelector(selectErrandOrderData);
  const cartOrder =
	typeof preOrderCart === "object" ? preOrderCart : JSON.parse(preOrderCart);
  const orderData =
	typeof preOrderData === "object" ? preOrderData : JSON.parse(preOrderData);
	const errandOrderData =
	typeof preErrandOrderData === "object" ? preErrandOrderData : JSON.parse(preErrandOrderData);
	
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
	
  const [orderPlaced, setOrderPlaced] = useState([]);
  const [orderCart, setCartOrder] = useState([]);
  const [cartLength, setCartLength] = useState(0);
  const [amountTotal, setAmountTotal] = useState(0);
  const [qtyTotal, setQtyTotal] = useState(0);
  const [fulfillmentType, setFulfillmentType] = useState("");
  const [checkOutError, setCheckOutError] = useState("");
  const [storeId, setStoreId] = useState("");
  const [gridData, setGridData] = useState([]);
  const [entryData, setEntryData] = useState(newmenuEntry);
  
  const [navHeader, setNavHeader] = useState([]);
  const [featured, setFeatured] = useState("");
  const [shopThumbnail, setShopThumbnail] = useState("");
  const [shopBackgroundImage, setShopBackgroundImage] = useState("");
  const [shopStoreName, setShopStoreName] = useState("");
  const [shopAddress, setShopAddress] = useState("");
  const [shopCity, setShopCity] = useState("");
  const [shopState, setShopState] = useState("");
  const [shopZip, setShopZip] = useState("");
  const [shopPhone, setShopPhone] = useState("");
  const [shopMember, setShopMember] = useState("");
  const [shopOperationTime, setShopOperationTime] = useState("");
  const [shopOperationColor, setShopOperationColor] = useState("");
  const [shopTimeZone, setShopTimeZone] = useState("");
  const [shopHours, setShopHours] = useState({});
  const [deliveryInfo, setDeliveryInfo] = useState(orderData);
  const [errandInfo, setErrandInfo] = useState(errandOrderData);
  const [closeTime, setCloseTime] = useState(0);
  const [storeOpen, setStoreOpen] = useState(false);
  
  const [address, setAddress] = useState("");
  const [openSignIn, setOpenSignIn] = useState(false);
  const [openOrderModal, setOpenOrderModal] = useState(false);
  const [addressModalOpen, setAddressModalOpen] = useState(false);
  const [cartSubTotal, setCartSubTotal] = useState(0);
  const [cartType, setCartType] = useState("");
  const [cartData, setCartData] = useState("");
  const [width, setWidth] = useState(0);
  const [gps, setGps] = useState({});
  const [hqId, setHqId] = useState("");
  const [addresses, setAddresses] = useState(savedAddresses);
	//console.log(addresses)
	
  const [alertModalTitle, setAlertModalTitle] = useState("");
  const [alertModalBody, setAlertModalBody] = useState("");
  const [alertModalOpen, setAlertModalOpen] = useState(false);
  const [alertModalButton, setAlertModalButton] = useState("");
  const [alertModalButtonFunc, setAlertModalButtonFunc] = useState();
  const [alertModalButtonFunc2, setAlertModalButtonFunc2] = useState();
  const [randomColor, setRandomColor] = useState('#000000');
  const [bgColor, setBgColor] = useState('#000000');
	
	const [isLoading, setIsLoading] = useState(false);
	const [loading, setLoading] = useState(false);
	
	
  const sessionemail = utils.getStorage("__el_data_email");
  const authToken = utils.getStorage("__el_data_login_token");
  const ellogin = utils.getStorage("__el_data_login_status");
	
  const { stateId, cityId, zipId, shopName, shopId } = useParams();
  const { Content, Footer } = Layout;
  const localcart = localStorage.getItem("__cart");
	
  const updateTotal = async () => {
    let newOrder = 0;
    let newqtyOrder = 0;
    for (let i = 0; i < orderPlaced.length; i++) {
      newOrder = newOrder + orderPlaced[i].product.price;
      newqtyOrder = newqtyOrder + orderPlaced[i].product.quantity;
		}
    setAmountTotal(newOrder);
    setQtyTotal(newqtyOrder);
		
    setAddress(deliveryInfo?.address);
    const deliveryType = deliveryInfo?.type ? deliveryInfo?.type : "Delivery";
    setFulfillmentType(deliveryType);
	};
	
  useEffect(() => {
    initEL();
		setRandomColor(utils.getRandomColor());
		setBgColor(utils.getLightRandomColor());
	}, []);
	
	
	useEffect(() => {
		const ref = document.referrer;
    const loc = location.pathname;
		logPage(ref, loc);
	}, []);
	
	/*
		useEffect(() => 
		{
    const handleScroll = () => {
		const isScrolledToBottom =
		window.innerHeight + document.documentElement.scrollTop ===
		document.documentElement.offsetHeight;
		
		if (isScrolledToBottom && !isLoading && gridData?.menu?.categoryIds?.length > 0) {
		const categoryMenuLen = gridData?.menu?.categoryIds?.length;
		const fromCategoryLen = gridData?.menu?.fromCategory;
		const allCategoryLen = gridData?.menu?.allCategory;
		const allLen = fromCategoryLen + categoryMenuLen;
		if(allLen < allCategoryLen - 1)
		{
		if (stateId && cityId && zipId && shopName && shopId) {
		if (deliveryInfo?.latitude > 0) {
		if (shopId && shopId.length < 11 && shopId.length > 4) {			
		const { latitude, longitude } = deliveryInfo;
		//loadData2(shopId.toUpperCase(), latitude, longitude, allLen, 15);								
		}
		}
		}
		}
		}
    };
		
    window.addEventListener('scroll', handleScroll);
		
    return () => {
		window.removeEventListener('scroll', handleScroll);
    };
		}, [isLoading, gridData]); // Add/remove scroll event listener when isLoading changes
	*/
	
  useEffect(() => {
    if (stateId && cityId && zipId && shopName && shopId) {
			const fromCategoryLen = gridData?.menu?.fromCategory;
			if(!fromCategoryLen)
			{				
				document.title = `${shopName} | Order Online | ErrandLocal`;
				
				setStoreId(shopId.toUpperCase());
				if (deliveryInfo?.latitude > 0) {
					if (shopId && shopId.length < 11 && shopId.length > 4 && entryData.length === 0) {
						const { latitude, longitude } = deliveryInfo;
						loadData(shopId.toUpperCase(), latitude, longitude);
					}
					// eslint-disable-next-line
					} else {
					loadData(shopId.toUpperCase());
					// eslint-disable-next-line
				}
			}
			} else {
			
      navigate("/404");
      // eslint-disable-next-line
		}
	}, [stateId, cityId, zipId, shopName, shopId]);
	
  useEffect(() => {
    document.body.style.overflow = "auto";
	}, []);
	
  useEffect(() => {
		if(cartOrder?.orderPlaced && cartOrder?.orderPlaced.length > 0){
			if(cartOrder?.orderPlaced[0]?.product?.storeId !== deliveryInfo?.storeId){
				const sId = cartOrder?.orderPlaced[0]?.product?.storeId;
				const sName = cartOrder?.orderPlaced[0]?.product?.storeName;
				const sCity = cartOrder?.orderPlaced[0]?.product?.storeCity;
				const sState = cartOrder?.orderPlaced[0]?.product?.storeState;
				const sZip = cartOrder?.orderPlaced[0]?.product?.storeZip;				
				
				const navStore = utils.convertAddress(sName);
				const navCity = utils.convertAddress(sCity);
				const navId = utils.convertAddress(sId);
				const navZip = utils.convertAddress(sZip);
				const navState = utils.convertAddress(sState);
				const navLink = `/${navState}/${navCity}/${navZip}/${navStore}/${navId}`;
				
				
				updateDeliveryData({...deliveryInfo, checkOutLink: navLink, storeId: sId, storeName: sName, storeCity: sCity, storeState: sState, storeZip: sZip});				
			}
		}
    
	}, [cartOrder, deliveryInfo]);
	
  useEffect(() => {
    const checkLogin = async () => {
      const redirect = await utils.checkLogin(sessionemail);
			const getAddr = await utils.getLocalCookie("elAddr");
			
      if ((redirect && sessionemail) || redirect) 
			{
				
			}
			else if (!getAddr?.address && sessionemail && ellogin === "1")
			{
        const header = {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + authToken,
				};
        const target = await utils.USER_CALL;
        const posturl = target + "?email=" + sessionemail;
        const dataToPost = "";
        const res = await utils.fetchURL(posturl, "GET", dataToPost, header);
        let data = await res.json();
        if (data?.response && data?.status && data?.status === "Success") {
          const d = await utils.cipherDecode(data.response);
          data = typeof d === "object" ? d : JSON.parse(d);
				}
				
        if (data?.user?.address && data?.user?.gps?.latitude) {
					
					let cookieData = {
						address: data?.user?.address,
						city: data?.user?.city,
						state: data?.user?.state,
						zip: data?.user?.zip,
						country: data?.user?.country,
						phone: data?.user?.phone,
						apt: data?.user?.aptsuite,
						access: data?.user?.accesscode,
						instruction: data?.user?.instruction,
						latitude: data?.user?.gps?.latitude,
						longitude: data?.user?.gps?.longitude,
					}
					await utils.setLocalCookie("elAddr", cookieData);
					
					let newDeliveryData = {
						...deliveryInfo,
						accessCode: data?.user?.accesscode,
						apartment: data?.user?.aptsuite,
						city: data?.user?.city,
						state: data?.user?.state,
						zip: data?.user?.zip,
						country: data?.user?.country,
						phone: data?.user?.phone,
						address: data?.user?.address,
						firstName: data?.user?.firstName,
						lastName: data?.user?.lastName,
					};
					
					let newErrandData = {
						...errandInfo,
						accessCode: data?.user?.accesscode,
						apartment: data?.user?.aptsuite,
						city: data?.user?.city,
						state: data?.user?.state,
						zip: data?.user?.zip,
						country: data?.user?.country,
						phone: data?.user?.phone,
						address: data?.user?.address,
						firstName: data?.user?.firstName,
						lastName: data?.user?.lastName,
					};
					
					
          if (
            data?.user?.addresses[0]?.address !== "null" &&
						data?.user?.addresses[0]?.address !== null &&
            data?.user?.addresses[0]?.address !== ""
						) {
						//console.log('312', data?.user?.addresses)
            await dispatch(
              setSavedAddresses(JSON.stringify(data?.user?.addresses))
						);
            await dispatch(setAddressData(data?.user?.addresses));
					}
					
					await updateDeliveryData(newDeliveryData);
					await updateErrandData(newErrandData);
					
          await dispatch(
            setSavedCards(JSON.stringify(data?.user?.paymentCards))
					);
          await dispatch(setPaymentCardData(data?.user?.paymentCards));
          await dispatch(setErrandAddressData(data?.user?.errandaddresses));
				}
			}
			else if (getAddr?.address && sessionemail && ellogin === "1") 
			{
				
        const cookieadd = getAddr?.address;
        const cookielat = getAddr?.latitude;
        const cookielng = getAddr?.longitude;
        const cookiecity = getAddr?.city;
        const cookiestate = getAddr?.state;
        const cookiezip = getAddr?.zip;
        const cookiecountry = getAddr?.country;
        const cookiephone = getAddr?.phone;
        const cookieapt = getAddr?.apt;
        const cookieaccess = getAddr?.access;
        const cookieinstruction = getAddr?.instruction;
				
        let newSavedData = {
          id: "default",
          addressLabel: "",
          address: cookieadd,
          addressMain: true,
          latitude: cookielat,
          longitude: cookielng,
          city: cookiecity,
          state: cookiestate,
          zip: cookiezip,
          country: cookiecountry,
          phone: cookiephone,
          accessCode: cookieaccess,
          apartment: cookieapt,
          instruction: cookieinstruction,
				};
				
        let newDeliveryData = {
          ...deliveryInfo,
          accessCode: cookieaccess,
          apartment: cookieapt,
          city: cookiecity,
          state: cookiestate,
          zip: cookiezip,
          country: cookiecountry,
          phone: cookiephone,
          address: cookieadd,
				};
				
        let newErrandData = {
          ...errandInfo,
          accessCode: cookieaccess,
          apartment: cookieapt,
          city: cookiecity,
          state: cookiestate,
          zip: cookiezip,
          country: cookiecountry,
          phone: cookiephone,
          address: cookieadd,
				};				
				
        await updateDeliveryData(newDeliveryData);
        await updateErrandData(newErrandData);
				
        if (addresses.length === 1) {
          const header = {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + authToken,
					};
          const target = await utils.USER_CALL;
          const posturl = target + "?email=" + sessionemail;
          const dataToPost = "";
          const res = await utils.fetchURL(posturl, "GET", dataToPost, header);
					
          let newEntry = [];
          let dbl = false;
          let data = await res.json();
          if (data?.response && data?.status && data?.status === "Success") {
            data = await JSON.parse(utils.cipherDecode(data.response));
					}
          let naddresses = data?.user?.addresses;
					
          for (let i = 0; i < naddresses.length; i++) {
            if (
              naddresses[i].id !== null &&
              naddresses[i].id !== "default" &&
              naddresses[i].address !== cookieadd &&
              naddresses[i].latitude !== cookielat &&
              cookielat &&
              naddresses[i]?.address !== "null" &&
              naddresses[i]?.address !== null &&
              naddresses[i]?.address !== ""
							) {
              newEntry.push({ ...naddresses[i], addressMain: false });
							} else if (
              naddresses[i].address === cookieadd &&
              naddresses[i]?.address !== "null" &&
              naddresses[i]?.address !== null &&
              naddresses[i]?.address !== ""
							) {
              newEntry.push({ ...naddresses[i], addressMain: true });
              dbl = true;
						}
            if (i === naddresses.length - 1) {
              if (dbl) {
								//console.log('430', [...newEntry])
                dispatch(setSavedAddresses(JSON.stringify([...newEntry])));
                dispatch(
                  setSavedCards(JSON.stringify(data?.user?.paymentCards))
								);
                dispatch(setAddressData(data?.user?.addresses));
                dispatch(setPaymentCardData(data?.user?.paymentCards));
                dispatch(setErrandAddressData(data?.user?.errandaddresses));
								} else {
								//console.log('439', [newSavedData, ...newEntry])
                dispatch(
                  setSavedAddresses(JSON.stringify([newSavedData, ...newEntry]))
								);
                dispatch(
                  setSavedCards(JSON.stringify(data?.user?.paymentCards))
								);
                dispatch(setAddressData(data?.user?.addresses));
                dispatch(setPaymentCardData(data?.user?.paymentCards));
                dispatch(setErrandAddressData(data?.user?.errandaddresses));
							}
						}
					}
					} else {
					//console.log('453', [newSavedData])         
					//dispatch(setSavedAddresses(JSON.stringify([newSavedData])));
				}
			}
		};
    checkLogin();
	}, []);
	
  useEffect(() => {
    Object.entries(orderCart).forEach((entry) => {
      const [key, val] = entry;
      if (key === "orderPlaced") {
        if (val.length > 0) {
          setCartLength(val.length);
          setOrderPlaced(val);
					//console.log(val);
				}
			}
      if (key === "fulfillmentType") {
        setFulfillmentType(val);
			}
		});
	}, [orderCart]);
	
  useEffect(() => {
    updateTotal();
	}, [orderCart, orderPlaced]);
	
  useEffect(() => {
    setCartOrder(cartOrder);
	}, []);
	
  useEffect(() => {
    if (!localStorage.getItem("__cart") && orderPlaced.length > 0) {
      window.location.reload(true);
      return;
		}
	}, [localcart, orderPlaced]);
	
  useEffect(() => {
    if (document.getElementById("_viewOrderBtn")) {
      const mobileViewBtn = document.getElementById("_viewOrderBtn");
      const shoppingCart = document.getElementById("shoppingCart");
      if (width <= 1100) {
        mobileViewBtn.style.display = "flex";
				} else {
        mobileViewBtn.style.display = "none";
        shoppingCart.style.display = "flex";
			}
		}
	}, [width]);
	
  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
		};
		
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
		};
	}, []);	
	
	
  useEffect(() => {
    //console.log(errandInfo);
	}, [errandInfo]);	
	
  const loadData = async (storeId, latitude, longitude) => 
	{
		setLoading(true);
		let response;
    try {
      let addressLocation = "";
      if (storeId.length < 11 && storeId.length > 4) {
				} else {
        message.info("Find stores near you");
        setTimeout(() => {
          navigate("/search/restaurants", { replace: false });
				}, 5000);
        return;
			}
      if (latitude) {
        addressLocation = "&latitude=" + latitude + "&longitude=" + longitude;
			}
      const target =
			utils.USER_CALL +
			"?request=shopitems&shop=" +
			storeId.toUpperCase() +
			addressLocation;
      const header = new Headers({
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + authToken,
			});
      const posturl = utils.BASE_URL + target; //utils.GET_URL;
      const dataToPost = "";
			
      response = await utils.fetchURL(posturl, "GET", dataToPost, header);
			
      const statusCode = await response.status;
			
      if (statusCode === 200) {
        let data = await response.json();
        if (data?.response && data?.status && data?.status === "Success") {
          data = await JSON.parse(utils.cipherDecode(data.response));
				}
				
				const categoryMenuLen = data?.menu?.categoryIds?.length;
				const fromCategoryLen = data?.menu?.fromCategory;
				const allCategoryLen = data?.menu?.allCategory;
				let allLen = fromCategoryLen + categoryMenuLen;
				if (allLen < allCategoryLen - 1) 
				{
					loadData2(
						storeId,
						latitude,
						longitude,
						allLen,
						15,
						data
					); 
					await setGridData(data);
					await initParams(data);
					}else{
					await setGridData(data);			
					await initParams(data, 1);
				}				
				setLoading(false);
				} else {
				setLoading(false);				
        message.error(response.error+', Please try again');				
			}
			} catch (e) {
      message.info("Find stores near you");
      setTimeout(() => {
        navigate("/search/restaurants", { replace: false });
			}, 5000);
      setLoading(false);
		}
	};
	
	const loadData2 = async (storeId, latitude, longitude, from, limit, preGrid = []) => {
		let response;
		setIsLoading(true);
    try {
      let addressLocation = "";
      
      if (latitude) {
        addressLocation = "&latitude=" + latitude + "&longitude=" + longitude + "&from=" + from + "&limit=" + limit;
			}
			
      const target =
			utils.USER_CALL +
			"?request=shopitems&shop=" +
			storeId.toUpperCase() +
			addressLocation;
      const header = new Headers({
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + authToken,
			});
      const posturl = utils.BASE_URL + target; //utils.GET_URL;
      const dataToPost = "";
			
      response = await utils.fetchURL(posturl, "GET", dataToPost, header);
			
      const statusCode = await response.status;
			
      if (statusCode === 200) {
        let data = await response.json();
        if (data?.response && data?.status && data?.status === "Success") {
          data = await JSON.parse(utils.cipherDecode(data.response));
				}
				
				const sres1 = await preGrid?.menu?.categoryIds;
				const sres2 = await preGrid?.relationships?.categories;
				const sres3 = await preGrid?.relationships?.products;					
				
				const res1 = await data.menu.categoryIds;
				const res2 = await data.relationships.categories;
				const res3 = await data.relationships.products;
				
				const newCat = [...sres1, ...res1];
				const newResCat = [...sres2, ...res2];
				const newResPro = [...sres3, ...res3];
				
				const nData = {shopId: preGrid.shopId, storeInfo: preGrid.storeInfo, menu: {shopId: preGrid.menu.shopId, fromCategory: preGrid.menu.fromCategory, allCategory: preGrid.menu.allCategory, categoryIds: newCat}, relationships: {categories: newResCat, products: newResPro, productTypes: {}}};
				
				const newLen = await data.menu.categoryIds.length;
				
				const categoryMenuLen = newLen;
				const fromCategoryLen = from;
				const allCategoryLen = data?.menu?.allCategory;
				let allLen = fromCategoryLen + categoryMenuLen;
				
				
				if (allLen < allCategoryLen - 1)
				{
					await loadData2(
						storeId,
						latitude,
						longitude,
						allLen,
						15,
						nData
					);
					await setGridData(nData);
					//await initParams2(nData);
					}else{
					//console.log(nData);
					await setGridData(nData);
					await initParams(nData, 1);
				}
				
				
			}
			} catch (e) {
			
		}
		setIsLoading(false);
	};
	
  const initEL = async () => {
    try {
      const ellogin = await utils.getStorage("__el_data_el_login");
      const email = await utils.getStorage("__el_data_email");
      if (!utils.isValidEmail(email) && ellogin === "0") {
        //await navigate('/login');
				} else if (!utils.isValidEmail(email)) {
        //await navigate('/login');
				} else if (ellogin === "0") {
        //await navigate('/login');
			}
		} catch (e) {}
	};
	
  const initParams = async(altData, last = 0) => {
    if (deliveryInfo?.address === undefined) {
      window.location.reload();
      return;
		}
		
    let initData;
    if (altData) {
      initData = altData;
			} else {
      initData = gridData;
		}
    if (Object.keys(initData).length === 0) {
      return;
		}
		
		const getAddr = await utils.getLocalCookie("elAddr");
		
    const shopData = initData?.storeInfo;
    setAddress(deliveryInfo?.address);
    setFeatured(shopData?.features);		
		
    if (deliveryInfo?.latitude) {
      const { latitude, longitude } = deliveryInfo;
			
			const mph = await utils.getDriveData('mph');
			const perHrEarnings = await utils.getDriveData('perHrEarnings');
			const meterPerMile = await utils.getDriveData('meterPerMile');
			const minTip = await utils.getDriveData('minTip');
			let mileRadius = await utils.getDriveData('mileRadius');
			mileRadius = parseFloat(mileRadius);	
      
      const start = [shopData?.gps?.latitude, shopData?.gps?.longitude];
      const end = [latitude, longitude];
      const distanceInMeters = utils.gis.calculateDistance(start, end);
      const miles = distanceInMeters / meterPerMile;
			
      if (miles > mileRadius) {
        displayInfo(
          "Too far!",
          `The restaurant is located too far away. Can you please locate a nearby restaurant that is no more than ${mileRadius} miles away?`
				);
			}
      const deliveryFee = utils.deliveryFee(miles, mph, perHrEarnings);
      let newDeliveryData = {
        ...deliveryInfo,
        deliveryFee: deliveryFee,
			};
      updateDeliveryData(newDeliveryData);
		}
		
    if (
      getAddr?.address &&
      getAddr?.address !== deliveryInfo?.address
			) {	
			const newDeliveryData = {
				...deliveryInfo,
				address: getAddr?.address,
				latitude: getAddr?.latitude,
				longitude: getAddr?.longitude,
			};		
			const newErrandData = {
				...errandInfo,
				address: getAddr?.address,
				latitude: getAddr?.latitude,
				longitude: getAddr?.longitude,
			};			
			updateErrandData(newErrandData);
			updateDeliveryData(newDeliveryData);
      setAddress(getAddr?.address);
		}
		
    const deliveryType = deliveryInfo?.type ? deliveryInfo?.type : "Delivery";
    setFulfillmentType(deliveryType);
		
    if (utils.convertAddress(shopData?.name) !== shopName) {
      navigate("/404");
      return;
		}
    if (utils.convertAddress(shopData?.state) !== stateId) {
      navigate("/404");
      return;
		}
    if (utils.convertAddress(shopData?.city) !== cityId) {
      navigate("/404");
      return;
		}
    if (utils.convertAddress(shopData?.zip) !== zipId) {
      navigate("/404");
      return;
		}
    //console.log(shopData?.hqId);
		
    let newDeliveryData = {
      ...deliveryInfo,
      deliveryFee: shopData?.charges?.deliveryFee,
      currency: shopData?.charges?.currency,
      hqId: shopData?.hqId,
		};
		
    updateDeliveryData(newDeliveryData);
		
    setHqId(shopData?.hqId);
    setGps(shopData?.gps);
    setShopHours(shopData?.days);
    setShopThumbnail(shopData?.avatar);
    setShopBackgroundImage(shopData?.bgImage);
    setShopStoreName(shopData?.name);
    setShopCity(shopData?.city);
    setShopZip(shopData?.zip);
    setShopState(shopData?.state);
    document.title = `${shopData?.name} | Order Online | ErrandLocal`;
    setShopAddress(
      shopData?.address +
			" " +
			shopData?.city +
			", " +
			shopData?.state +
			" " +
			shopData?.zip
		);
    setShopPhone(shopData?.contactPhone);
    setShopMember("NEW");
		
    let storeOperationMsg, msgColor;
    // Get the open and close times from the JSON data
    const { open, close, next, isClose, isBreak, timeZone } = shopData?.today;
		
    const nextOpen = shopData["days"][next]["open"];
    const now = moment.tz(timeZone);
    // Get the abbreviated day of the week
    //const dayOfWeek = now.format("ddd");
    const tday = now.clone().format("MM/DD/YYYY");
		
    let tOpenTime = open.toString();
    tOpenTime = tOpenTime.slice(0, -2) + ":" + tOpenTime.slice(-2);
    let tCloseTime = close.toString();
    tCloseTime = tCloseTime.slice(0, -2) + ":" + tCloseTime.slice(-2);
		
    let openTime = moment.tz(
      tday + " " + tOpenTime,
      "MM/DD/YYYY hh:mm",
      timeZone
		);
    let closeTime = moment.tz(
      tday + " " + tCloseTime,
      "MM/DD/YYYY hh:mm",
      timeZone
		);
    if (closeTime.clone().isBefore(openTime)) {
      const closeDay = now.clone().add(1, "days").format("MM/DD/YYYY");
      closeTime = moment.tz(
        closeDay + " " + tCloseTime,
        "MM/DD/YYYY hh:mm",
        timeZone
			);
		}
		
    //console.log(now.clone().format('MM/DD/YYYY hh:mm'));
    //console.log(openTime.clone().format('MM/DD/YYYY hh:mm'));
		
    if (isClose) {
      storeOperationMsg = `Opens On ${
        utils.convertToFullDay(next) +
        " " +
        utils.convertTimeTo12HourFormat(nextOpen)
			}`;
      msgColor = "#7f7f7f";
			} else if (isBreak) {
      storeOperationMsg = `Opens On ${
        utils.convertToFullDay(next) +
        " " +
        utils.convertTimeTo12HourFormat(nextOpen)
			}`;
      msgColor = "#7f7f7f";
			} else if (now.isBefore(openTime)) {
      storeOperationMsg = `Opening By ${utils.convertTimeTo12HourFormat(open)}`;
      msgColor = "#7f7f7f";
			} else if (now.isAfter(closeTime)) {
      storeOperationMsg = `CLOSE`;
      msgColor = "#ec4f38";
			} else {
      storeOperationMsg = `OPEN`;
      msgColor = "#2e8540";
      setStoreOpen(true);
		}
		
    let closetime = parseFloat(close);
    setCloseTime(closetime);
    setShopOperationTime(storeOperationMsg);
    setShopOperationColor(msgColor);
    setShopTimeZone(timeZone);
		
    const categoryMenus = initData?.menu.categoryIds;
		//console.log(categoryMenus);
    const categoryRelations = initData?.relationships?.categories;
    const productRelations = initData?.relationships?.products;
    //const productTypeRelations = initData?.relationships?.productTypes;
    let navEntry = [];
    let menuEntry = [];
		
    for (let i = 0; i < categoryMenus.length; i++) {
      const thisId = categoryMenus[i];
      for (let j = 0; j < categoryRelations.length; j++) {
        if (
          thisId === categoryRelations[j].id &&
          !categoryRelations[j]?.hidden
					) {
          const uuid = uuidv4();
          const thisEntry = {
            headerTitle: categoryRelations[j].name,
            headerID: uuid,
					};
					
          let nextEntry = {
            headerTitle: categoryRelations[j].name,
            headerID: uuid,
            productList: [],
					};
          let displayEntry = [];
          const thisProductIds = categoryRelations[j].productIds;
          if (thisProductIds.length > 0) {
            navEntry.push(thisEntry);
					}
          for (let k = 0; k < thisProductIds.length; k++) {
            const thisProduct = thisProductIds[k];
            for (let l = 0; l < productRelations.length; l++) {
              if (thisProduct === productRelations[l].id) {
                const productId = productRelations[l]?.id;
                const productName = productRelations[l]?.name
								? productRelations[l]?.name
								: "";
                const productPrice = productRelations[l]?.price
								? productRelations[l]?.price
								: "";
                const productCurrency = productRelations[l]?.currency
								? productRelations[l]?.currency
								: "";
                const productDescription = productRelations[l]?.description
								? productRelations[l]?.description
								: "";
                const productImage = productRelations[l]?.image
								? productRelations[l]?.image
								: "";
                const productStatus = productRelations[l]?.itemStatus
								? productRelations[l]?.itemStatus
								: "";
                const productTypeIds = productRelations[l]?.productTypeIds
								? productRelations[l]?.productTypeIds
								: [];
								
                const newEntry = {
                  id: productId,
                  name: productName,
                  price: productPrice,
                  currency: productCurrency,
                  description: productDescription,
                  image: productImage,
                  productTypes: productTypeIds,
                  productStatus: productStatus,
								};
                displayEntry.push(newEntry);
							}
						}
						
            if (thisProductIds.length - 1 === k) {
              nextEntry.productList = displayEntry;
              menuEntry.push({ ...nextEntry });
						}
					}
				}
			}
		}	
    
		if(last === 1){
			setNavHeader(navEntry);
		}
		setEntryData(menuEntry);
	};
	
	/*
		const initParams2 = (initData) => {
		
		const categoryMenus = initData?.menu.categoryIds;
    const categoryRelations = initData?.relationships?.categories;
    const productRelations = initData?.relationships?.products;
    //const productTypeRelations = initData?.relationships?.productTypes;
    let navEntry = [];
    let menuEntry = [];		
		
    for (let i = 0; i < categoryMenus.length; i++) {
		const thisId = categoryMenus[i];
		for (let j = 0; j < categoryRelations.length; j++) {
		if (
		thisId === categoryRelations[j].id &&
		!categoryRelations[j]?.hidden
		) {
		const uuid = uuidv4();
		const thisEntry = {
		headerTitle: categoryRelations[j].name,
		headerID: uuid,
		};
		
		let nextEntry = {
		headerTitle: categoryRelations[j].name,
		headerID: uuid,
		productList: [],
		};
		let displayEntry = [];
		const thisProductIds = categoryRelations[j].productIds;
		if (thisProductIds.length > 0) {
		navEntry.push(thisEntry);
		}
		for (let k = 0; k < thisProductIds.length; k++) {
		const thisProduct = thisProductIds[k];
		for (let l = 0; l < productRelations.length; l++) {
		if (thisProduct === productRelations[l].id) {
		const productId = productRelations[l]?.id;
		const productName = productRelations[l]?.name
		? productRelations[l]?.name
		: "";
		const productPrice = productRelations[l]?.price
		? productRelations[l]?.price
		: "";
		const productCurrency = productRelations[l]?.currency
		? productRelations[l]?.currency
		: "";
		const productDescription = productRelations[l]?.description
		? productRelations[l]?.description
		: "";
		const productImage = productRelations[l]?.image
		? productRelations[l]?.image
		: "";
		const productStatus = productRelations[l]?.itemStatus
		? productRelations[l]?.itemStatus
		: "";
		const productTypeIds = productRelations[l]?.productTypeIds
		? productRelations[l]?.productTypeIds
		: [];
		
		const newEntry = {
		id: productId,
		name: productName,
		price: productPrice,
		currency: productCurrency,
		description: productDescription,
		image: productImage,
		productTypes: productTypeIds,
		productStatus: productStatus,
		};
		displayEntry.push(newEntry);
		}
		}
		
		if (thisProductIds.length - 1 === k) {
		nextEntry.productList = displayEntry;
		menuEntry.push({ ...nextEntry });
		}
		}
		}
		}
    }
    
		
    setEntryData(menuEntry);
		setNavHeader(navEntry);
		
		}
	*/
	
  const displayInfo = (title, description) => {
    setAlertModalTitle(title ? title : "Title");
    const b = description.split("|");
    let mainbody = "";
    for (let i = 0; i < b.length; i++) {
      mainbody += "<p style='font-size: 18px'>" + b[i] + "</p>";
		}
    setAlertModalBody(
      mainbody +
			"<br/><br/><div class='card-number' style='outline: none' key='d1'><div id='modal-status' style='display: none' key='d2'></div></div>"
		);
    setAlertModalButton("1");
    setAlertModalButtonFunc("");
    setAlertModalButtonFunc2("cancel");
    setAlertModalOpen(!alertModalOpen);
	};
	
  const onCartEdit = async (productId, cartId) => {
    //setLoading(true);
    cartInfoDisplay(productId, cartId, "edit");
    //setLoading(false);
	};
	
  const onCartRemove = async (cartId) => {
    let newOrders = [];
    let prevOrders = orderCart["orderPlaced"];
    for (let i = 0; i < prevOrders.length; i++) {
      if (cartId !== prevOrders[i].product.cartId) {
        newOrders = [...newOrders, prevOrders[i]];
			}
		}
		
    let orderToCart = { ...orderCart, orderPlaced: newOrders };
    updateCartData(orderToCart);
	};
	
  const onCheckOut = async () => {
    setCheckOutError("");
		/*
			console.log(deliveryInfo);
			console.log(orderCart);		
			return;
		*/
		const minorder = gridData?.storeInfo?.minorder || 2000;		
    const priorCart = orderCart["orderPlaced"];
		if (priorCart.length < 1) {
			setCheckOutError(`Your cart is empty`);
      return;
		}
		
    const deliveryDay =
		deliveryInfo?.day === "ASAP"
		? deliveryInfo?.day
		: deliveryInfo?.day.split(",")[1];
		
    const deliveryTime = deliveryInfo?.time;
    const unix = `${deliveryDay} ${deliveryTime}`;
    const unixTime = new Date(unix);
    const unixDeliveryTime = unixTime.getTime();
		
    const date = new Date();
    const daytime = date.getTime();
		
    var price = 0,
		quantity = 0,
		currency = "";
    
		for (let i = 0; i < priorCart.length; i++) {
			currency = priorCart[i].product.currency;
			price = price + priorCart[i].product.price;
			quantity = quantity + priorCart[i].product.quantity;
		}
		//console.log(minorder, price);
		const amountOrderLeft = parseFloat((minorder - price) / 100).toFixed(2);
		
		if (amountOrderLeft > 0) {
			setCheckOutError(
				`Add ${currency}${amountOrderLeft} to meet the minimum order`
			);
			return;
			} else if (!fulfillmentType) {
			openAddressModal();
			message.error(`Select fulfillment method`);
			setCheckOutError(`Select fulfillment method`);
			return;
			} else if (!address) {
			openAddressModal();
			message.error(`Add delivery address`);
			setCheckOutError(`Add delivery address`);
			return;
			} else if (deliveryDay === "ASAP" && !storeOpen) {
			openAddressModal();
			message.error(`Please order ahead`);
			setCheckOutError(`Please order ahead`);
			return;
			} else if (unixDeliveryTime < daytime) {
			openAddressModal();
			message.error(`Please order ahead`);
			setCheckOutError(`Please order ahead`);
			return;
			} else if (deliveryInfo?.latitude) {
			const { latitude, longitude } = deliveryInfo;
			
			const mph = await utils.getDriveData('mph');
			const perHrEarnings = await utils.getDriveData('perHrEarnings');
			const meterPerMile = await utils.getDriveData('meterPerMile');
			const minTip = await utils.getDriveData('minTip');				
			let mileRadius = await utils.getDriveData('mileRadius');
			mileRadius = parseFloat(mileRadius);
			
			const start = [gps?.latitude, gps?.longitude];
			const end = [latitude, longitude];
			const distanceInMeters = utils.gis.calculateDistance(start, end);
			const miles = distanceInMeters / meterPerMile;
			const deliveryFee = utils.deliveryFee(miles, mph, perHrEarnings);
			
			
			
			if (miles > mileRadius) {
				setCheckOutError(`Restaurant is too far!`);
				displayInfo(
					"Too far!",
					`The restaurant is located too far away. Can you please locate a nearby restaurant that is no more than ${mileRadius} miles away?`
				);
				return;
			}
		}		
		
		let orderToCart = { ...orderCart, fulfillmentType: fulfillmentType };
		updateCartData(orderToCart);
		let newDeliveryData = { ...deliveryInfo, type: fulfillmentType };
		updateDeliveryData(newDeliveryData);
		
		const checkOutData = priorCart[0]?.product;
		const navStore = utils.convertAddress(checkOutData?.storeName);
		const navCity = utils.convertAddress(checkOutData?.storeCity);
		const navId = utils.convertAddress(checkOutData?.storeId);
		const navZip = utils.convertAddress(checkOutData?.storeZip);
		const navState = utils.convertAddress(checkOutData?.storeState);
		
		navigate(
			`/checkout/${navState}/${navCity}/${navZip}/${navStore}/${navId}`,
			{ replace: false }
		);
    
	};
	
  const onViewOrder = async () => {
    if (document.getElementById("shoppingCart")) {
      const shoppingCart = document.getElementById("shoppingCart");
      if (width <= 1100) {
        shoppingCart.style.display = "flex";
				} else {
        shoppingCart.style.display = "none";
			}
		}
	};
	
  const closeViewOrder = async () => {
    if (document.getElementById("shoppingCart")) {
      const shoppingCart = document.getElementById("shoppingCart");
      shoppingCart.style.display = "none";
		}
	};
	
  const AddonData = ({ name, index }) => {
    if (index >= 0) {
      return (
        <li
				className="_30zFN38AZ0D4RcGWr47sgk"
				data-name={`${name}`}
				key={uuidv4()}
        >
				<div>{`${name}`}</div>
        </li>
			);
			} else {
      return null;
		}
	};
	
  const SkeletonMenus = () => {
    return (
      <div key={uuidv4()}>
			<div key={uuidv4()}>
			<div key={uuidv4()}>
			<div className="_yq7j8e" key={uuidv4()}>
			<span className="_rnsmcq">{``}</span>
			<div className="_18z5nku" key={uuidv4()}>
			<div
			className="_1yc6rdp"
			key={uuidv4()}
			style={{ backgroundColor: "#ececec" }}
			>
			<Skeleton.Input active />
			<div className="_1gg3ue">
			<Skeleton.Input block active />
			</div>
			</div>
			
			<div className="_1743i26" role="region">
			<div className="_19hi95f" key={uuidv4()}>
			<Skeleton.Button
			style={{
				height: 160,
				marginHorizontal: "2.5%",
				width: "95%",
			}}
			block
			active
			/>
			</div>
			<div className="_19hi95f" key={uuidv4()}>
			<Skeleton.Button
			style={{
				height: 160,
				marginHorizontal: "2.5%",
				width: "95%",
			}}
			block
			active
			/>
			</div>
			</div>
			</div>
			</div>
			</div>
			</div>
      </div>
		);
	};
	
  const openLogin = async () => {
    try {
      await setOpenSignIn(true);
		} catch (e) {}
	};
	
  const openAddressModal = async () => {
    try {
      await setAddressModalOpen(true);
		} catch (e) {}
	};
	
	const updateErrandData = async (data) => {
    try {
      setErrandInfo(data);
      dispatch(setErrandOrderData(JSON.stringify(data)));
		} catch (e) {}
	};
	
  const updateDeliveryData = async (data) => {
    try {
      setDeliveryInfo(data);
      dispatch(setOrderData(JSON.stringify(data)));
		} catch (e) {}
	};
	
  const updateCartData = async (data) => {
    try {
      await dispatch(setOrderCart(JSON.stringify(data)));
      await setCartOrder(data);
      await setCartLength(data["orderPlaced"].length);
      await setOrderPlaced(data["orderPlaced"]);
			} catch (e) {
      //console.log(e.message);
		}
	};
	
  const saveStore = async (data) => {
    try {
      dispatch(setSavedStores(data));
		} catch (e) {}
	};
	
  const cartInfoDisplay = (id, cartdata, type) => {
    setCartData(cartdata);
    setCartType(type);
    setOpenOrderModal(true);
	};
	
	const SubMenus = ({ productData, totalCount, index, cartInfoDisplay, storeId }) => {
		let half = totalCount / 2;
		if (index < half) {
			return (
				<>
				<div
				role="button"
				tabIndex="0"
				className={`${
					parseFloat(productData.productStatus) === 1
					? "_194c2bm"
					: "_194c2bm unavailableItem"
				}`}
				onClick={() =>
					`${
						parseFloat(productData.productStatus) === 1
						? cartInfoDisplay(storeId, productData, "custom")
						: ""
					}`
				}
				>
				<div className="_15q0jjh">
				<div className="_qsnllp">
				<p className="_1n57n8z">{`${productData.name}`}</p>
				<p className="_vlgzaz">{`${productData.description}`}</p>
				{productData.price > 0 && (
					<p className="_lu8b5g">
					{`${productData.currency}`}
					{`${(productData.price / 100).toFixed(2)}`}
					</p>
				)}
				{productData.price <= 0 && <p className="_lu8b5g"></p>}
				</div>
				</div>
				{productData.image && (
					<div
					className={`${
						parseFloat(productData.productStatus) === 1
						? "_1pbw5a2"
						: "_1pbw5a2 unavailableItemImg"
					}`}
					style={{
						backgroundImage:
						"url(" +
						productData.image +
						"?fit=crop&amp;w=320&amp;h=320)",
						flex: 1,
						justifyContent: 'center',
						alignItems: 'center',
						alignSelf: 'center',
						maxWidth: 122,
						width: 120,
						height: 120,
						objectFit: 'cover',
						objectPosition: 'center',
					}}
					></div>
				)}
				</div>
				<hr className="_5lfk5c" />
				</>
			);
			} else {
			return null;
		}
	};
	
	const SubMenus2 = ({ productData, totalCount, index, cartInfoDisplay, storeId }) => {
		//console.log(productData);
		let half = totalCount / 2;
		if (index >= half) {
			return (
				<>
				<div
				role="button"
				tabIndex="0"
				className={`${
					parseFloat(productData.productStatus) === 1
					? "_194c2bm"
					: "_194c2bm unavailableItem"
				}`}
				onClick={() =>
					`${
						parseFloat(productData.productStatus) === 1
						? cartInfoDisplay(storeId, productData, "custom")
						: ""
					}`
				}
				>
				<div className="_15q0jjh">
				<div className="_qsnllp">
				<p className="_1n57n8z">{`${productData.name}`}</p>
				<p className="_vlgzaz">{`${productData.description}`}</p>
				{productData.price > 0 && (
					<p className="_lu8b5g">
					{`${productData.currency}`}
					{`${(productData.price / 100).toFixed(2)}`}
					</p>
				)}
				{productData.price <= 0 && <p className="_lu8b5g"></p>}
				</div>
				</div>
				{productData.image && (
					<div
					className={`${
						parseFloat(productData.productStatus) === 1
						? "_1pbw5a2"
						: "_1pbw5a2 unavailableItemImg"
					}`}
					style={{
						backgroundImage:
						"url(" +
						productData.image +
						"?fit=crop&amp;w=320&amp;h=320)",
						flex: 1,
						justifyContent: 'center',
						alignItems: 'center',
						alignSelf: 'center',
						maxWidth: 122,
						width: 120,
						height: 120,
						objectFit: 'cover',
						objectPosition: 'center',
					}}
					></div>
				)}
				</div>
				<hr className="_5lfk5c" />
				</>
			);
			} else {
			return null;
		}
	};
	
	
	
	
  if (!loading && featured === "2") 
	{
    return (
      <>
			<Content>
			<div className="_1wozt5f _14a9t3k">
			<div className="_12atkkn">
			<div
			className="_1axwt32 _1gbkmlt"
			style={{
				backgroundImage: `${
					shopBackgroundImage
					? "url(" + shopBackgroundImage + ")"
					: "none"
				}`,
			}}
			>
			<div className="_bxoxda _11ochor">
			<div className="_zdt81h">
			<div className="_1dogarg">
			<div className="_5if1ut">
			<div className="_dfgcxn">
			<img
			className="_nngzzd"
			src={shopThumbnail}
			alt={shopStoreName}
			/>
			<h1 className="_1qulqfk">{shopStoreName}</h1>
			<address className="_8mmg2s">{shopAddress}</address>
			<div className="_g4kti3">
			<div className="_63g2va" role="button" tabIndex="0">
			{utils.convertToUSPhoneFormat(shopPhone)}
			</div>
			<div className="_xg6t39">{shopMember}</div>
			</div>
			<span
			className="_1si8x2j"
			style={{
				color: shopOperationColor,
				fontWeight: 900,
				marginTop: 10,
			}}
			>
			{shopOperationTime}
			</span>
			<br />
			<br />
			<BusinessHoursModal
			hoursData={gridData?.storeInfo?.days}
			/>
			</div>
			<div className="_ucfoif"></div>
			</div>
			</div>
			</div>
			</div>
			</div>
			</div>
			</div>
			
			<div
			className="site-layout-background"
			style={{ padding: 24, minHeight: 360, backgroundColor: "#ffffff" }}
			>
			<div className="_6wlw6o">
			<main id="mainContent" aria-label="Menu content">
			<div></div>
			<section
			style={{
				position: "sticky",
				top: "0",
				backgroundColor: "#ffffff",
				zIndex: 1,
				padding: 0,
			}}
			>
			<div className="_ruof1q">
			<div className="_86m4">
			<div className="_1jx0i45">
			{navHeader.length > 0 && (
				<ScrollHighlight navHeader={navHeader} />
			)}
			</div>
			</div>
			</div>
			</section>
			<div className="_as2sev">
			<div></div>
			
			
			{entryData.map((eData, index) => (
				<div key={`${eData.headerID}${index}`} data-c={`${eData.headerID}${index}`} r-color={randomColor}>
				<div>
				<div>
				
				<div className="_yq7j8e">
				<a
				className="_rnsmcq"
				id={`${eData.headerID}`}
				href={`#${eData.headerID}`}
				>
				{eData.headerTitle}
				</a>
				<div className="_18z5nku">
				<div
				className="_1yc6rdp"
				style={{
					border: `1px solid ${randomColor}`,
					backgroundColor: bgColor,
				}}
				>
				<svg
				className="_1dcg472"
				xmlns="http://www.w3.org/2000/svg"
				width={15}
				height={15}
				aria-hidden="true"
				viewBox="0, 0, 400, 400"
				>
				<title>List</title>
				<g className="svgg">
				<path
				className="path0"
				d="m2.5,373.97a17.87,21.62 0 0 1 17.87,-21.62l357.35,0a17.87,21.62 0 0 1 0,43.23l-357.35,0a17.87,21.62 0 0 1 -17.87,-21.62zm0,-172.93a17.87,21.62 0 0 1 17.87,-21.62l357.35,0a17.87,21.62 0 0 1 0,43.23l-357.35,0a17.87,21.62 0 0 1 -17.87,-21.62zm0,-172.93a17.87,21.62 0 0 1 17.87,-21.62l357.35,0a17.87,21.62 0 0 1 0,43.23l-357.35,0a17.87,21.62 0 0 1 -17.87,-21.62z"
				stroke="none"
				fill={randomColor}
				fillRule="evenodd"
				></path>
				</g>
				</svg>
				<div
				className="_1gg3ue"
				style={{ color: randomColor }}
				>{`${eData.headerTitle}`}</div>
				</div>
				
				<div className="_1743i26" role="region">				
				<div className="_19hi95f" key={`0ii`}>
				{eData?.productList.filter((item, index) => index < eData?.productList.length / 2).map((productData, index) => (
					
					<div key={`${productData.id}`}>
					<div					
					role="button"
					tabIndex="0"
					className={`${
						parseFloat(productData.productStatus) === 1
						? "_194c2bm"
						: "_194c2bm unavailableItem"
					}`}
					onClick={() =>
						`${
							parseFloat(productData.productStatus) === 1
							? cartInfoDisplay(storeId, productData, "custom")
							: ""
						}`
					}
					>
					<div className="_15q0jjh">
					<div className="_qsnllp">
					<p className="_1n57n8z">{`${productData.name}`}</p>
					<p className="_vlgzaz">{`${productData.description}`}</p>
					{productData.price > 0 && (
						<p className="_lu8b5g">
						{`${productData.currency}`}
						{`${(productData.price / 100).toFixed(2)}`}
						</p>
					)}
					{productData.price <= 0 && <p className="_lu8b5g"></p>}
					</div>
					</div>
					{productData.image && (
						<div
						className={`${
							parseFloat(productData.productStatus) === 1
							? "_1pbw5a2"
							: "_1pbw5a2 unavailableItemImg"
						}`}
						style={{
							backgroundImage:
							"url(" +
							productData.image +
							"?fit=crop&amp;w=320&amp;h=320)",
							flex: 1,
							justifyContent: 'center',
							alignItems: 'center',
							alignSelf: 'center',
							maxWidth: 122,
							width: 120,
							height: 120,
							objectFit: 'cover',
							objectPosition: 'center',
						}}
						></div>
					)}
					</div>
					<hr className="_5lfk5c" />
					</div>				
				))}
				</div>
				
				<div className="_19hi95f" key={`1ii`}>
				{eData?.productList.filter((item, index) => index >= eData?.productList.length / 2).map((productData, index) => (			
					
					<div key={`${productData.id}`}>
					<div
					role="button"
					tabIndex="0"
					className={`${
						parseFloat(productData.productStatus) === 1
						? "_194c2bm"
						: "_194c2bm unavailableItem"
					}`}
					onClick={() =>
						`${
							parseFloat(productData.productStatus) === 1
							? cartInfoDisplay(storeId, productData, "custom")
							: ""
						}`
					}
					>
					<div className="_15q0jjh">
					<div className="_qsnllp">
					<p className="_1n57n8z">{`${productData.name}`}</p>
					<p className="_vlgzaz">{`${productData.description}`}</p>
					{productData.price > 0 && (
						<p className="_lu8b5g">
						{`${productData.currency}`}
						{`${(productData.price / 100).toFixed(2)}`}
						</p>
					)}
					{productData.price <= 0 && <p className="_lu8b5g"></p>}
					</div>
					</div>
					{productData.image && (
						<div
						className={`${
							parseFloat(productData.productStatus) === 1
							? "_1pbw5a2"
							: "_1pbw5a2 unavailableItemImg"
						}`}
						style={{
							backgroundImage:
							"url(" +
							productData.image +
							"?fit=crop&amp;w=320&amp;h=320)",
							flex: 1,
							justifyContent: 'center',
							alignItems: 'center',
							alignSelf: 'center',
							maxWidth: 122,
							width: 120,
							height: 120,
							objectFit: 'cover',
							objectPosition: 'center',
						}}
						></div>
					)}
					</div>
					<hr className="_5lfk5c" />
					</div>			
				)			
				)}
				</div>
				
				</div>
				</div>
				</div>
				
				</div>
				</div>
				
				</div>
			))}
						
			
			{isLoading && (
				<div>
				<div>
				<div>
				<div className="_yq7j8e">
				<a
				className="_rnsmcq"
				id={uuidv4()}
				href={`#${uuidv4()}`}
				>
				{`Loading More`}
				</a>
				<div className="_18z5nku">
				<div
				className="_1yc6rdp"																
				>
				<div
				className="_1gg3ue"
				style={{ color: '#201e1e' }}
				>{`Loading More...`}</div>
				</div>
				</div>
				</div>
				</div>
				</div>
				</div>
			)}
			
			
			<button
			aria-disabled="false"
			className="_viewOrderBtn"
			disabled=""
			tabIndex="0"
			type="button"
			id="_viewOrderBtn"
			onClick={() => onViewOrder()}
			>
			<div className="_viewOrderSpan">
			<span className="_2YWgulDMXDKTMsKU8JdXQs">View Cart</span>
			<span className="_2T1auHz6PdoVoXHFFUbElm">{`$${(
				amountTotal / 100
			).toFixed(2)}`}</span>
			</div>
			<span className="_viewOrderCount">
			<div className="_3CTlUEiPeJKa55n-Ucpoki _2jB9r-9rzlZqqWUH1E8muO">{`${qtyTotal}`}</div>
			</span>
			</button>
			
			{openSignIn && (
				<LoginSignupModal
				openSignIn={openSignIn}
				setOpenSignIn={setOpenSignIn}
				loginStyle={{
					width: 0,
					height: 0,
					left: -1000,
					position: "absolute",
				}}
				/>
			)}
			
			{openOrderModal && (
				<OrderModal
				openOrderModal={openOrderModal}
				setOpenOrderModal={setOpenOrderModal}
				setCartSubTotal={setCartSubTotal}
				cartSubTotal={cartSubTotal}
				savedStores={savedStores}
				saveStore={saveStore}
				cartData={cartData}
				cartType={cartType}
				loading={setLoading}
				refId={storeId}
				headqId={hqId}
				gridData={gridData}
				orderCart={orderCart}
				updateCartData={updateCartData}
				setCheckOutError={setCheckOutError}
				deliveryInfo={deliveryInfo}
				shopHours={shopHours}
				shopStoreName={shopStoreName}
				shopStoreCity={shopCity}
				shopStoreZip={shopZip}
				shopStoreState={shopState}
				updateDeliveryData={updateDeliveryData}
				orderModalStyle={{
					width: 0,
					height: 0,
					left: -1000,
					position: "absolute",
				}}
				/>
			)}
			{/*<Divider orientation="left">Review</Divider>
				<div style={{ paddingLeft: 20, paddingRight: 20 }}>
				<Space style={{ marginBottom: 20 }}>
				<div>Sept 9, 2022 - </div>
				<div>Right on time... Laura</div>
				</Space>
				<br />
				<Rate
				allowHalf
				defaultValue={2.5}
				style={{ marginBottom: 10 }}
				/>
			</div>*/}
			<Divider />
			</div>
			</main>
			
			<section
			aria-label="Shopping cart"
			className="M0cnph9mBkjQ7sKnRJoCn"
			id="shoppingCart"
			>
			<div
			style={{
				display: "flex",
				justifyContent: "space-between",
				marginBottom: 25,
			}}
			>
			<Button
			type="text"
			className="backShop"
			style={{ display: 'flex', alignItems: 'center', minWidth: 0, fontWeight: 600 }}
			icon={<ArrowLeftOutlined />}
			size={`large`}
			onClick={() => navigate("/search/restaurants", { replace: false })}
			>
			FIND STORE
			</Button>
			{width <= 1100 && (
				<Button
				type="text"
				className="closeViewOrder"
				style={{ minWidth: 0, fontWeight: 600 }}
				size={`large`}
				onClick={() => closeViewOrder()}
				danger
				>
				CLOSE
				</Button>
			)}
			</div>
			<div className="c5PRj4_tt33kyMy30ibSp">
			<span style={{ fontSize: 25, fontWeight: "bolder" }}>
			YOUR ORDER
			</span>
			</div>
			<div className="_1lSOQ6q2xcOkaxVT1smcn7 _3gouMfJy504Yjz5rHjoHpu">
			<AddressModal
			fulfillmentType={fulfillmentType}
			setFulfillmentType={setFulfillmentType}
			address={address}
			setAddress={setAddress}
			openLogin={openLogin}
			deliveryInfo={deliveryInfo}
			errandInfo={errandInfo}
			shopHours={shopHours}
			updateDeliveryData={updateDeliveryData}
			updateErrandData={updateErrandData}
			addressModalOpen={addressModalOpen}
			setAddressModalOpen={setAddressModalOpen}
			shopTimeZone={shopTimeZone}
			gps={gps}
			/>
			</div>
			
			{cartLength === 0 && (
				<div
				className="_2mfDffMxberUHEHnzJbsqf"
				style={{ height: "auto" }}
				>
				<img
				src={images.cart}
				title="Your cart is empty"
				alt="Your cart is empty"
				loading="lazy"
				/>
				</div>
			)}
			
			{orderPlaced.filter((orderData, index) => index === 0).map((orderData) => (
				<div key="e8929" className="_vlgzaz">{`${orderData?.product?.storeName}'s Cart`}</div>
			))}
			
			{cartLength > 0 && (
				<div
				data-name="cart.products"
				className="_1pclOaC04dlaY0UHl3zyWt"
				>				
				{orderPlaced.map((orderData, index) => (
					<div
					className="B7a69Q2IoGnNLBxAiDcbR"
					data-name="cart.product"
					style={{ cursor: "pointer" }}
					key={uuidv4()}
					>
					<div className="_25s2omhUhQfcHCtikP0XBk">
					<div
					className="_8Tpso4fqXWiM2ktK2uB2K"
					data-name="cart.product.quantity"
					
					>{`${orderData?.product?.quantity}x`}</div>
					<div
					className="_28IBU17s5t7XL-a2xuGSRc"
					>
					<div
					className="_3KjnRbm5_nWZGCD1uQPzjP"
					>
					<div
					className="h8oaw1War6h7kCEs3RATY"
					onClick={() =>
						onCartEdit(
							orderData?.product?.id,
							orderData?.product?.cartId
						)
					}
					>
					<div
					className="_3KsO2l-LNdW3dDO2uuHK6N"
					data-name="cart.product.name"
					>{`${orderData?.product?.name}`}</div>
					<div
					className="_3T8zb1IA4akzgtCDPmtwOd"
					></div>
					<div
					className="_3eybxaF9nXFCPI55vgY_Gx"
					data-name="cart.product.price"
					>{`${orderData?.product?.displayprice}`}</div>
					</div>
					<div
					className="_3qdtGWGgRqWfao-VslXx0u"
					onClick={() =>
						onCartEdit(
							orderData?.product?.id,
							orderData?.product?.cartId
						)
					}
					>
					<div
					className="_3ylDuZwTTDugvTa4GQcSov"
					>
					<ul
					className="MIwqEkDopWWLPJ0sgK7Sa"
					data-name="product_detail_list"
					>
					{orderData?.productChecked.map(
						({ name, id }, index) => (
							<AddonData
							name={name}
							id={id}
							index={index}
							key={uuidv4()}
							/>
						)
					)}
					{orderData?.productNote && (
						<li
						className="_30zFN38AZ0D4RcGWr47sgk"
						data-name={`product-note`}
						key={uuidv4()}
						>
						<div>{`${orderData?.productNote}`}</div>
						</li>
					)}
					</ul>
					</div>
					</div>
					<div className="_23zWnWb43MZ_hovn0xDJL6">
					<button
					data-name="edit_item"
					className="_2w2jqstPutCrrmGTTRZg5X"
					onClick={() =>
						onCartEdit(
							orderData?.product?.id,
							orderData?.product?.cartId
						)
					}
					>
					Edit
					</button>
					<button
					data-name="delete_item"
					className="_2w2jqstPutCrrmGTTRZg5X"
					onClick={() =>
						onCartRemove(orderData?.product?.cartId)
					}
					>
					Remove
					</button>
					</div>
					</div>
					</div>
					</div>
					</div>
				))}
				</div>
			)}
			
			<div
			className="_2r-vdmLSoI1KSdsmnMT_l9"
			data-name="cart.placeOrder.button"
			>
			<div data-name="cart.placeOrder.button">
			
			{checkOutError && orderPlaced.length > 0 && (
				<div className="_1qovpV8LpPfvmFSsbXj-DR eSlRH0G6u0haaQUiGCluU _2uN2zP5QFqNQqEbJS8CzOu">
				<div className="_38hkkZ9YqW58C_n3Ke-lJV">
				<svg
				width="16"
				height="16"
				viewBox="0 0 16 16"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
				aria-hidden="true"
				aria-label="Information icon"
				>
				<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM9.5 4.5C9.5 5.32843 8.82843 6 8 6C7.17157 6 6.5 5.32843 6.5 4.5C6.5 3.67157 7.17157 3 8 3C8.82843 3 9.5 3.67157 9.5 4.5ZM9 8V13H7V8H9Z"
				fill="#201E1E"
				></path>
				</svg>
				</div>
				<span className="_3VpT59o1JAnGF5Luv-ZduG">{`${checkOutError}`}</span>
				</div>
			)}
			
			{orderPlaced.length > 0 && (
				<button
				aria-disabled="false"
				className="_1JdD97nX-WGcPASVXFJXKw _1CwjZK_2Wbc4SetuxezVf9 _2s2DfQ5MrOu2S-UPSvVNvV _1xPD7V9facbrjoKQLjyWQL"
				disabled=""
				tabIndex="0"
				type="button"
				onClick={() => onCheckOut()}
				>
				<div className="_15EJq4yLpZOO3b1FQ32X32">
				<span className="_2YWgulDMXDKTMsKU8JdXQs">
				Checkout
				</span>
				<span className="_2T1auHz6PdoVoXHFFUbElm">{`$${(
					amountTotal / 100
				).toFixed(2)}`}</span>
				</div>
				<span className="_3sB4RNUJzURH4mii1IcjPI">
				<div className="_3CTlUEiPeJKa55n-Ucpoki _2jB9r-9rzlZqqWUH1E8muO">{`${qtyTotal}`}</div>
				</span>
				</button>
			)}
			
			</div>
			</div>
			
			</section>
			
			</div>
			</div>
			
			<div className="_1wozt5f _14a9t3k">
			<div className="_12atkkn">
			<div
			style={{ display: "grid", padding: "20px 120px", gridGap: 20 }}
			>
			<a
			href="https://errandlocal.com/tos"
			style={{ color: "#f8f8f8" }}
			>
			Terms of use
			</a>
			<a
			href="https://errandlocal.com/privacy"
			style={{ color: "#f8f8f8" }}
			>
			Privacy
			</a>
			<a
			href="https://driver.errandlocal.com/"
			style={{ color: "#f8f8f8" }}
			>
			Become a driver
			</a>
			<a
			href="https://get.errandlocal.com/"
			style={{ color: "#f8f8f8" }}
			>
			List your restaurant
			</a>
			</div>
			</div>
			</div>
			</Content>
			
			{alertModalOpen && (
				<AlertModal
				setAlertOpenModal={setAlertModalOpen}
				alertModalTitle={alertModalTitle}
				alertModalBody={alertModalBody}
				alertModalButton={alertModalButton}
				setAlertModalButtonFunc={() => setAlertModalOpen(false)}
				setAlertModalButtonFunc2={() => setAlertModalOpen(false)}
				alertType="alert"
				/>
			)}
      </>
		);
	} 
	
	else if (!loading) 
	{
    return (
      <>
			<Content>
			<div className="_1wozt5f _14a9t3k">
			<div className="_12atkkn">
			<div className="_1axwt32 _1gbkmlt"></div>
			</div>
			</div>
			
			<div
			className="site-layout-background"
			style={{ padding: 24, minHeight: 360, backgroundColor: "#ffffff" }}
			>
			<div className="_6wlw6o">
			<main id="mainContent" aria-label="Menu content">
			<div
			style={{
				display: "flex",
				justifyContent: "center",
				fontSize: 40,
				fontWeight: 700,
				opacity: 0.2,
				position: "relative",
				height: "30vh",
				margin: "0 auto",
				marginTop: "20vh",
				padding: 20,
			}}
			>
			Restaurant listings is currently not available.
			</div>
			</main>
			<section
			aria-label="Shopping cart"
			className="M0cnph9mBkjQ7sKnRJoCn"
			id="shoppingCart"
			>
			<div
			style={{
				display: "flex",
				justifyContent: "space-between",
				marginBottom: 25,
			}}
			>
			<Button
			type="text"
			className="backShop"
			style={{ display: 'flex', alignItems: 'center', minWidth: 0, fontWeight: 600 }}
			icon={<ArrowLeftOutlined />}
			size={`large`}
			onClick={() => navigate("/search/restaurants", { replace: false })}
			>
			FIND STORE
			</Button>
			{width <= 1100 && (
				<Button
				type="text"
				className="closeViewOrder"
				style={{ minWidth: 0, fontWeight: 600 }}
				size={`large`}
				onClick={() => closeViewOrder()}
				danger											
				>
				CLOSE
				</Button>
			)}
			</div>
			<div className="c5PRj4_tt33kyMy30ibSp">
			<span style={{ fontSize: 25, fontWeight: "bolder" }}>
			YOUR ORDER
			</span>
			</div>
			<div className="_1lSOQ6q2xcOkaxVT1smcn7 _3gouMfJy504Yjz5rHjoHpu">
			<AddressModal
			fulfillmentType={fulfillmentType}
			setFulfillmentType={setFulfillmentType}
			address={address}
			setAddress={setAddress}
			openLogin={openLogin}
			deliveryInfo={deliveryInfo}
			errandInfo={errandInfo}
			shopHours={shopHours}
			updateDeliveryData={updateDeliveryData}
			updateErrandData={updateErrandData}
			addressModalOpen={addressModalOpen}
			setAddressModalOpen={setAddressModalOpen}
			shopTimeZone={shopTimeZone}
			gps={gps}
			/>
			</div>
			
			{cartLength === 0 && (
				<div
				className="_2mfDffMxberUHEHnzJbsqf"
				style={{ height: "auto" }}
				>
				<img
				src={images.cart}
				title="Your cart is empty"
				alt="Your cart is empty"
				loading="lazy"
				/>
				</div>
			)}
			
			{cartLength > 0 && (
				<div
				data-name="cart.products"
				className="_1pclOaC04dlaY0UHl3zyWt"
				>
				{orderPlaced.map((orderData, index) => (
					<div
					className="B7a69Q2IoGnNLBxAiDcbR"
					data-name="cart.product"
					style={{ cursor: "pointer" }}
					key={uuidv4()}
					>
					<div className="_25s2omhUhQfcHCtikP0XBk" key={uuidv4()}>
					<div
					className="_8Tpso4fqXWiM2ktK2uB2K"
					data-name="cart.product.quantity"
					key={uuidv4()}
					>{`${orderData?.product?.quantity}x`}</div>
					<div
					className="_28IBU17s5t7XL-a2xuGSRc"
					key={uuidv4()}
					>
					<div
					className="_3KjnRbm5_nWZGCD1uQPzjP"
					key={uuidv4()}
					>
					<div
					className="h8oaw1War6h7kCEs3RATY"
					key={uuidv4()}
					onClick={() =>
						onCartEdit(
							orderData?.product?.id,
							orderData?.product?.cartId
						)
					}
					>
					<div
					className="_3KsO2l-LNdW3dDO2uuHK6N"
					data-name="cart.product.name"
					key={uuidv4()}
					>{`${orderData?.product?.name}`}</div>
					<div
					className="_3T8zb1IA4akzgtCDPmtwOd"
					key={uuidv4()}
					></div>
					<div
					className="_3eybxaF9nXFCPI55vgY_Gx"
					data-name="cart.product.price"
					key={uuidv4()}
					>{`${orderData?.product?.displayprice}`}</div>
					</div>
					<div
					className="_3qdtGWGgRqWfao-VslXx0u"
					key={uuidv4()}
					onClick={() =>
						onCartEdit(
							orderData?.product?.id,
							orderData?.product?.cartId
						)
					}
					>
					<div
					className="_3ylDuZwTTDugvTa4GQcSov"
					key={uuidv4()}
					>
					<ul
					className="MIwqEkDopWWLPJ0sgK7Sa"
					data-name="product_detail_list"
					key={uuidv4()}
					>
					{orderData?.productChecked.map(
						({ name, id }, index) => (
							<AddonData
							name={name}
							id={id}
							index={index}
							key={uuidv4()}
							/>
						)
					)}
					{orderData?.productNote && (
						<li
						className="_30zFN38AZ0D4RcGWr47sgk"
						data-name={`product-note`}
						key={uuidv4()}
						>
						<div>{`${orderData?.productNote}`}</div>
						</li>
					)}
					</ul>
					</div>
					</div>
					<div className="_23zWnWb43MZ_hovn0xDJL6">
					<button
					data-name="edit_item"
					className="_2w2jqstPutCrrmGTTRZg5X"
					onClick={() =>
						onCartEdit(
							orderData?.product?.id,
							orderData?.product?.cartId
						)
					}
					>
					Edit
					</button>
					<button
					data-name="delete_item"
					className="_2w2jqstPutCrrmGTTRZg5X"
					onClick={() =>
						onCartRemove(orderData?.product?.cartId)
					}
					>
					Remove
					</button>
					</div>
					</div>
					</div>
					</div>
					</div>
				))}
				</div>
			)}
			
			<div
			className="_2r-vdmLSoI1KSdsmnMT_l9"
			data-name="cart.placeOrder.button"
			>
			<div data-name="cart.placeOrder.button">
			{checkOutError && orderPlaced.length > 0 && (
				<div className="_1qovpV8LpPfvmFSsbXj-DR eSlRH0G6u0haaQUiGCluU _2uN2zP5QFqNQqEbJS8CzOu">
				<div className="_38hkkZ9YqW58C_n3Ke-lJV">
				<svg
				width="16"
				height="16"
				viewBox="0 0 16 16"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
				aria-hidden="true"
				aria-label="Information icon"
				>
				<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM9.5 4.5C9.5 5.32843 8.82843 6 8 6C7.17157 6 6.5 5.32843 6.5 4.5C6.5 3.67157 7.17157 3 8 3C8.82843 3 9.5 3.67157 9.5 4.5ZM9 8V13H7V8H9Z"
				fill="#201E1E"
				></path>
				</svg>
				</div>
				<span className="_3VpT59o1JAnGF5Luv-ZduG">{`${checkOutError}`}</span>
				</div>
			)}
			
			{orderPlaced.length > 0 && (
				<button
				aria-disabled="false"
				className="_1JdD97nX-WGcPASVXFJXKw _1CwjZK_2Wbc4SetuxezVf9 _2s2DfQ5MrOu2S-UPSvVNvV _1xPD7V9facbrjoKQLjyWQL"
				disabled=""
				tabIndex="0"
				type="button"
				onClick={() => onCheckOut()}
				>
				<div className="_15EJq4yLpZOO3b1FQ32X32">
				<span className="_2YWgulDMXDKTMsKU8JdXQs">
				Checkout
				</span>
				<span className="_2T1auHz6PdoVoXHFFUbElm">{`$${(
					amountTotal / 100
				).toFixed(2)}`}</span>
				</div>
				<span className="_3sB4RNUJzURH4mii1IcjPI">
				<div className="_3CTlUEiPeJKa55n-Ucpoki _2jB9r-9rzlZqqWUH1E8muO">{`${qtyTotal}`}</div>
				</span>
				</button>
			)}
			</div>
			</div>
			</section>
			</div>
			</div>
			
			<div className="_1wozt5f _14a9t3k">
			<div className="_12atkkn">
			<div
			style={{ display: "grid", padding: "20px 120px", gridGap: 20 }}
			>
			<a
			href="https://errandlocal.com/tos"
			style={{ color: "#f8f8f8" }}
			>
			Terms of use
			</a>
			<a
			href="https://errandlocal.com/privacy"
			style={{ color: "#f8f8f8" }}
			>
			Privacy
			</a>
			<a
			href="https://driver.errandlocal.com/"
			style={{ color: "#f8f8f8" }}
			>
			Become a driver
			</a>
			<a
			href="https://get.errandlocal.com/"
			style={{ color: "#f8f8f8" }}
			>
			List your restaurant
			</a>
			</div>
			</div>
			</div>
			</Content>
			
			{alertModalOpen && (
				<AlertModal
				setAlertOpenModal={setAlertModalOpen}
				alertModalTitle={alertModalTitle}
				alertModalBody={alertModalBody}
				alertModalButton={alertModalButton}
				setAlertModalButtonFunc={() => setAlertModalOpen(false)}
				setAlertModalButtonFunc2={() => setAlertModalOpen(false)}
				alertType="alert"
				/>
			)}
      </>
		);
	}
	else 
	{
    return (
      <>
			<Content>
			<div className="_1wozt5f _14a9t3k">
			<div className="_12atkkn">
			<div
			className="_1axwt32 _1gbkmlt"
			style={{ backgroundImage: "none" }}
			>
			<div className="_bxoxda _11ochor">
			<div className="_zdt81h">
			<div className="_1dogarg">
			<div className="_5if1ut">
			<div className="_dfgcxn">
			<Skeleton.Button
			style={{ minHeight: 30 }}
			block
			active
			/>
			</div>
			<div className="_ucfoif"></div>
			</div>
			</div>
			</div>
			</div>
			</div>
			</div>
			</div>
			
			<div
			className="site-layout-background"
			style={{ padding: 24, minHeight: 360, backgroundColor: "#ffffff" }}
			>
			<div className="_6wlw6o">
			<main id="mainContent" aria-label="Menu content">
			<div></div>
			<div>
			<div className="_ruof1q">
			<div className="_86m4">
			<div className="_1jx0i45">
			<Skeleton.Input active />
			<Skeleton.Input active />
			<Skeleton.Input active />
			</div>
			</div>
			</div>
			</div>
			<div className="_as2sev">
			<div></div>
			<SkeletonMenus />
			<Divider />
			<SkeletonMenus />
			
			<Divider orientation="left"></Divider>
			<div style={{ paddingLeft: 20, paddingRight: 20 }}>
			<Skeleton active />
			</div>
			<Divider />
			</div>
			</main>
			
			<section
			aria-label="Shopping cart"
			className="M0cnph9mBkjQ7sKnRJoCn"
			></section>
			</div>
			</div>
			</Content>
			<Footer
			className="_ruof1q"
			style={{
				position: "relative",
				backgroundColor: "#000",
				marginBottom: 0,
				marginRight: 473,
				paddingLeft: "8.3%",
				paddingRight: "9.86%",
			}}
			>
			<div className="_86m4" style={{ backgroundColor: "#000", margin: 0 }}>
			<div style={{ width: "100%", height: 100, padding: 24 }}></div>
			</div>
			</Footer>
      </>
		);
	}
	
};

export default StoreScreen;
