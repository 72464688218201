import React, {useEffect, useState} from "react";
import { AddressList, AlertModal } from '../../components';
import { utils } from "../../utils";
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from "react-redux";
import {
  setSavedAddresses,
} from "../../includes/navSlice";
import { message } from "antd";

const AddressListScreen = ({logPage}) => 
{	
	const navigate = useNavigate();
	const location = useLocation();
	const dispatch = useDispatch();
	const [os, setOs] = useState('');
	const [model, setModel] = useState('');
	const [alertModalTitle, setAlertModalTitle] = useState("");	
	const [alertModalBody, setAlertModalBody] = useState("");	
	const [alertModalOpen, setAlertModalOpen] = useState(false);	
	const [alertModalButton, setAlertModalButton] = useState("");
	const [alertModalButtonFunc, setAlertModalButtonFunc] = useState();
	const [alertModalButtonFunc2, setAlertModalButtonFunc2] = useState();
	
	useEffect(() => {		
		window.document.body.style.overflow = alertModalOpen ? "hidden" : "auto";
	}, [alertModalOpen]);
	
	useEffect(() => {
		setOs('');
		setModel('');
		document.title = `Delivery Addresses | Order Online | ErrandLocal`;
		const checkLogin = async() => {
			const sessionemail = await utils.getStorage('__el_data_email');
			const redirect = await utils.checkLogin(sessionemail);
			if((redirect && sessionemail) || redirect){
				navigate(redirect);
			}
		}
		checkLogin();
	}, []);
	
	useEffect(() => {
		const ref = document.referrer;
    const loc = location.pathname;
		logPage(ref, loc);
	}, []);
	
	function removeNullValuesFromString(inputString) {
		// Split the string into an array using commas as separators
		const parts = inputString.split(', ');

		// Filter out null values
		const filteredParts = parts.filter(part => part !== 'null');

		// Join the filtered parts back into a string with commas
		const resultString = filteredParts.join(', ');

		return resultString;
	}
	
	const execAddress = (app, title, description, addressId) => {
		
		app = title === 'null' && description === 'null' ? '' : app;
		title = title === 'null' ? 'Address' : title;
		description = description === 'null' ? '' : description;
		
		if(app){
			setAlertModalTitle(title ? title : 'Address');
			const b = description.split('|');
			let mainbody = '';
			for(let i=0; i<b.length; i++){
				mainbody += "<p style='font-size: 18px'>"+removeNullValuesFromString(b[i])+"</p>";
			}
			setAlertModalBody(mainbody+"<br/><br/><div class='card-number' style='outline: none' key='d1'><div id='modal-status' style='display: none' key='d2'></div></div>");
			setAlertModalButton("2");
			setAlertModalButtonFunc('useDeliveryAddress|'+addressId);
			setAlertModalButtonFunc2('editDeliveryAddress|'+addressId);
			setAlertModalOpen(!alertModalOpen);		
		}else{
			setAlertModalTitle(title ? title : 'Address');
			const b = description.split('|');
			let mainbody = '';
			for(let i=0; i<b.length; i++){
				mainbody += "<p style='font-size: 18px'>"+removeNullValuesFromString(b[i])+"</p>";
			}
			setAlertModalBody(mainbody+"<br/><br/><div class='card-number' style='outline: none' key='d1'><div id='modal-status' style='display: none' key='d2'></div></div>");
			setAlertModalButton("1");
			setAlertModalButtonFunc('');
			setAlertModalButtonFunc2('editDeliveryAddress|'+addressId);
			setAlertModalOpen(!alertModalOpen);
		}
	}
	
	const useAddress = async(action, thisaddress) => 
	{
		try
		{
			if(action === 'editDeliveryAddress'){
				navigate("/edit-address/delivery/"+thisaddress);
				return false;
			};
			const email = await utils.getStorage('__el_data_email');
			const authToken = utils.getStorage('__el_data_login_token');
			const target = utils.USER_URL;
			const header = new Headers({
				'Content-Type': 'application/json',
				'Accept': 'application/json',
				'Authorization': 'Bearer '+authToken,
			});
			const posturl = utils.BASE_URL+target; //utils.POST_URL;
			const localtime = await utils.getTime();
			const obj = JSON.stringify({'rtype': 'addressswitch', 'email': email, 'addressId': thisaddress, 'os': os, 'model': model, 'localtime': localtime, 'target': target, 'token': authToken});
			const dataToPost = await utils.cipherEncode(obj);			
			const res = await utils.fetchURL(posturl, 'POST', dataToPost, header);
			const data = await res.json();
			
			if(data.msg === 'Success'){
				await dispatch(setSavedAddresses(utils.cipherDecode(data.addresses)));
				message.success('Address has been set to default');
				window.scrollTo({top: 0, behavior: 'smooth'});
				setTimeout(function(){
					window.location.reload(true);
				}, 5000);
			}else{
				message.error(data.msg);
				window.scrollTo({top: 0, behavior: 'smooth'});
			}			
		}
		catch(e)
		{
			message.error(e.message);
		}	
	}	
	
	const ExecAddressOperation = (type) => 
	{
		setAlertModalOpen(!alertModalOpen);
		const b = type.split('|');
		let action, target;		
		action = b[0];
		target = b[1];
		useAddress(action, target);
	}
	
	return (
		<>
			<AddressList execAddress={execAddress}/>
			{alertModalOpen && (<AlertModal setAlertOpenModal={setAlertModalOpen} alertModalTitle={alertModalTitle} alertModalBody={alertModalBody} alertModalButton={alertModalButton} setAlertModalButtonFunc={()=> ExecAddressOperation(alertModalButtonFunc)} setAlertModalButtonFunc2={()=> ExecAddressOperation(alertModalButtonFunc2)} alertType="address"/>)}			
		</>
	)
};

export default AddressListScreen;
