import React, { useRef, useEffect } from "react";
import { utils } from "../../utils";

const MapUpdate = ({ lat, lng, height, width, setMarker }) => {
  const mapRef = useRef(null);
  
  const loadMap = (lat, lng) => {		
      const mapOptions = {
        center: { lat, lng },
        zoom: 17,
		gestureHandling: 'greedy',
		//scaleControl: false,
		//streetViewControl: false,
		//fullscreenControl: false,
		//mapTypeControl: false,
		disableDefaultUI: true, 
		zoomControl: true, 
		zoomControlOptions: {position: window.google.maps.ControlPosition.LEFT_TOP,},
		styles: [
			{
			  featureType: "poi",
			  elementType: "labels",
			  stylers: [{ visibility: "off" }],
			},
			{
			  featureType: "transit",
			  elementType: "labels",
			  stylers: [{ visibility: "off" }],
			},
		],		
      };
	  
      const map = new window.google.maps.Map(mapRef.current, mapOptions);

      const marker = new window.google.maps.Marker({
        position: { lat, lng },
        map,
		icon: utils.MAP_MARKER_1,
      });

	  /*
      map.addListener("click", (event) => {
        const newLat = event.latLng.lat();
        const newLng = event.latLng.lng();
        //console.log("New coordinates:", newLat, newLng);
		marker.setPosition({ lat: newLat, lng: newLng });
      });
	  */
	  
	  map.addListener("drag", () => {
        const newLat = map.getCenter().lat();
        const newLng = map.getCenter().lng();
		//console.log("New coordinates:", newLat, newLng);
        marker.setPosition({ lat: newLat, lng: newLng });
		setMarker({ lat: newLat, lng: newLng });
      });
	  
	  map.addListener("dragend", () => {
        const newLat = map.getCenter().lat();
        const newLng = map.getCenter().lng();
		//console.log("New coordinates:", newLat, newLng);
        marker.setPosition({ lat: newLat, lng: newLng });
		setMarker({ lat: newLat, lng: newLng });
      });
	  
	  map.addListener("zoom_changed", () => {
		const newLat = map.getCenter().lat();
        const newLng = map.getCenter().lng();
		//console.log("New coordinates:", newLat, newLng);
        marker.setPosition({ lat: newLat, lng: newLng });
		setMarker({ lat: newLat, lng: newLng });
      });
	  
	  const adjustButton = document.createElement("div");
      adjustButton.classList.add("adjust-button");
      adjustButton.textContent = "Adjust pin";
      map.controls[window.google.maps.ControlPosition.TOP_RIGHT].push(
        adjustButton
      );
	
      adjustButton.addEventListener("click", () => {
        // Code to adjust the marker to the address
        //console.log("Adjusting marker to address...");
      });
	  
    };

  const loadAPI = async() => {		
	  const mapKey = await utils.GOOGLE_API_KEY;
	  const script = document.createElement("script");
	  script.src = `https://maps.googleapis.com/maps/api/js?key=${mapKey}`;
	  script.defer = true;
	  script.onload = () => {
		loadMap(lat, lng);
	  };
	  document.body.appendChild(script);
  };
  
  
  useEffect(() => {
	  if (lat && lng) {
			if(window?.google?.maps){
				loadMap(lat, lng);
			}else{
				loadAPI();
			}
	  }
  }, [lat, lng]);

  return <div ref={mapRef} style={{ height, width }} />;
};

export default MapUpdate;
