import React, {useState, useEffect} from 'react';
import { GiHamburgerMenu } from 'react-icons/gi';
import { MdOutlineRestaurantMenu } from 'react-icons/md';
import { motion } from "framer-motion";
import images from '../../constants/images';
import { utils } from '../../utils';
//import './DriverNavbar.css';

const DriverNavbar = ({sendApp}) => {
  const [toggleMenu, setToggleMenu] = useState(false);
	const [sitePhone, setSitePhone] = useState('');	
	
	useEffect(() => {		
		const getHis = async() => 
		{		
			const region = await utils.getLocalCookie('__user_region');
			const isPresent = await utils.supportedCountries.find(country => country.code === region);			
			if(region){
				setSitePhone(isPresent?.phone);
			}
		}
		getHis();
	}, []);
  
  return (
    <nav className="app__navbar">
		<div className="app__navbar-logo">
		<a href="https://driver.errandlocal.com/"><img src={images.drivererrandlocal} alt="ERRANDLOCAL DRIVER" /></a>
		</div>
		<motion.ul className="app__navbar-links">
		<li><a className="p__opensans" href="#apply">Apply</a></li>
		<li><a className="p__opensans" href="#requirement">Requirement</a></li>
		<li><a className="p__opensans" href="https://errandlocal.com/">Order Food</a></li>
		<li><a className="p__opensans" href="https://get.errandlocal.com/">Restaurant Owner?</a></li>
		<li><a className="p__opensans" href={`tel:${sitePhone}`}>{utils.convertToUSPhoneFormat(sitePhone)}</a></li>
		</motion.ul>
		<div className="app__navbar-login">
		<a className="p__opensans" onClick={()=> sendApp()}>Download App</a>
		</div>
		<div className="app__navbar-smallscreen">
		<GiHamburgerMenu color="#ffffff" fontSize={27} onClick={() => setToggleMenu(true)} />
		{toggleMenu && (
			<div className="app__navbar-smallscreen_overlay flex__center slide-bottom">
			<MdOutlineRestaurantMenu fontSize={27} className="overlay__close" onClick={() => setToggleMenu(false)} />
			<motion.ul className="app__navbar-smallscreen_links">
			<li><a href="#apply" onClick={() => setToggleMenu(false)}>Apply</a></li>
			<li><a href="#requirement" onClick={() => setToggleMenu(false)}>Requirement</a></li>
			<li><a href="https://errandlocal.com/" onClick={() => setToggleMenu(false)}>Order Food</a></li>
			<li><a href="https://get.errandlocal.com/" onClick={() => setToggleMenu(false)}>Restaurant Owner?</a></li>
			<li><a onClick={() => setToggleMenu(false)} href={`tel:${sitePhone}`}>{utils.convertToUSPhoneFormat(sitePhone)}</a></li>
			<li><a onClick={()=> sendApp()}>Download App</a></li>
			</motion.ul>
			</div>
		)}
		</div>
    </nav>
	);
};

export default DriverNavbar;
