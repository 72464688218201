import React, { useState, useEffect } from 'react';
import { motion, useScroll, useSpring } from "framer-motion";
import { utils } from "../../utils";
import { FormInput, MainNavbar, SubFooter, MapModal } from "../../components";
import { useNavigate } from 'react-router-dom';
import { Space, message, Alert} from "antd";
import { setAddressData, setErrandAddressData, setPaymentCardData, selectAddressData, selectErrandAddressData, selectPaymentCardData } from "../../includes/navSlice";
import { useSelector, useDispatch } from "react-redux";
import Cookies from "js-cookie";

const depn = {
	'zip': 10,
	'phone': 11,
};

const formlabel = "#a0a0a0";
const inputlabel = "#257a80";

const EditAddress = ({addressId, typeId}) => 
{	
	const { scrollYProgress } = useScroll();
	const scaleX = useSpring(scrollYProgress, {
		stiffness: 100,
		damping: 30,
		restDelta: 0.001
	});
	
	const dispatch = useDispatch();
	let errandlist = useSelector(selectErrandAddressData);
	errandlist = errandlist ?? [];
	const [params, setParams] = useState(errandlist);
	const navigate = useNavigate();
	
	const [aid, setId] = useState(addressId);
	const [instruction, setInstruction] = useState('');
	const [phoneNo, setPhoneNo] = useState('');
	const [email, setEmail] = useState('');
	const [business, setBusiness] = useState('');
	const [houseNo, setHouseNo] = useState('');
	const [addr, setAddr] = useState('');
	const [addrlabel, setAddrLabel] = useState('');
	const [aptsuite, setAptSuite] = useState('');
	const [accesscode, setAccessCode] = useState('');
	const [city, setCity] = useState('');
	const [state, setState] = useState('');
	const [zip, setZip] = useState('');
	const [title, setTitle] = useState('Edit Address');
	const [addressModalOpen, setAddressModalOpen] = useState(false);
	const [gps, setGps] = useState({latitude: 0, longitude: 0});
	const [marker, setMarker] = useState(null);
	const [visibleDelete, setVisibleDelete] = useState(true);
	const [click, setClick] = useState(false);
	const [load, setLoad] = useState(false);
	const [visible, setVisible] = useState(false);	
	const [msgType, setMsgType] = useState('');	
	const [msgBody, setMsgBody] = useState('');	
	const [msgHead, setMsgHead] = useState('');	
	const [authToken, setAuthToken] = useState('');
	const [stateOptions, setStateOptions] = useState([]);
	
	
	const [os, setOs] = useState('');
	const [model, setModel] = useState('');		
	
	useEffect(() => 
		{
			const checkData = async() => {
				if(!typeId){
					navigate('/delivery-addresses');
					}else if(typeId === 'delivery' || typeId === 'errand'){	
					setOs('');
					setModel('');
					const sessionemail = await utils.getStorage('__el_data_email');
					const authTok = await utils.getStorage('__el_data_login_token');
					//const region = await utils.getLocalCookie('__user_region');	
					//const options = await utils.getStateOptions(region);
					setEmail(sessionemail);
					setAuthToken(authTok);
					setStateOptions([]);
					}else{
					navigate('/delivery-addresses');
				}
				if(!addressId){
					navigate('/delivery-addresses');
				}
			}
			checkData();
		},[typeId]);
		
		useEffect(() => 
			{
				setId(aid);
			},[aid]);
			
			useEffect(() => 
				{
					setClick(click);
				},[click]);
				
				useEffect(() => 
					{
						setTitle(title);
					},[title]);
					
					useEffect(() => 
						{		
							const getParams = async () => {
								try{
									setHouseNo('');
									setAddr('');
									setAptSuite('');
									setAccessCode('');
									setAddrLabel('');
									setCity('');
									setState('');
									setZip('');
									if(email && !load)
									{
										var paramsFromServer, paddress, paddresslabel, paptsuite, paccesscode, pcity, pstate, pzip, pinstruction, pphone, pbusiness, pgps, pcountry;
										
										paramsFromServer = await fetchParams();
										
										pphone = paramsFromServer?.phone ?? '';
										paddress = paramsFromServer?.address ?? '';
										paddresslabel = paramsFromServer?.addresslabel ?? '';
										paptsuite = paramsFromServer?.aptsuite ?? '';
										paccesscode = paramsFromServer?.accesscode ?? '';
										pcity = paramsFromServer?.city ?? '';
										pstate = paramsFromServer?.state ?? '';
										pzip = paramsFromServer?.zip ?? '';
										pinstruction = paramsFromServer?.instruction ?? '';
										pgps = paramsFromServer?.gps ?? {latitude: 0, longitude: 0};
										pcountry = paramsFromServer?.country ?? 'US';
										
										if(typeId === 'errand'){
											pbusiness = paramsFromServer?.businessName ?? '';
											setBusiness(pbusiness);
										}
										
										if(paddress === '' && typeId.toUpperCase() === 'DELIVERY'){
											setVisibleDelete(false);
										}
										const options = await utils.getStateOptions(pcountry);
										const aNo = await parseAddress(paddress, 'houseNo');
										const adNo = await parseAddress(paddress, 'streetName');
										let pNo = pphone;
										if (pNo.length > 10) {
											pNo = pNo.slice(-10);
										}
										setHouseNo(aNo);
										setAddr(adNo);
										setStateOptions(options);
										setPhoneNo(pNo);										
										setAddrLabel(paddresslabel);
										setAptSuite(paptsuite);
										setAccessCode(paccesscode);
										setCity(pcity);
										setState(pstate);
										setZip(pzip);
										setGps(pgps);
										setInstruction(pinstruction);
										setTitle('Edit '+paddresslabel);
										setLoad(true);
									}
								}catch(e){console.log(e.message)}	
							}		
							getParams();
						}, [aid, email]);
						
						useEffect(() => {
							window.document.body.style.overflow = addressModalOpen ? "hidden" : "auto";
						}, [addressModalOpen]);
						
						
						useEffect(() => {
							if(marker){
							}
						}, [marker]);
						
						const SwitchRef = (ref) => {
							navigate(ref);
						}
						
						
						const parseAddress = async (address, type) => {
							await utils.parseAddress(address, function(err, addressObj){
								if(type === 'houseNo'){
									setHouseNo(addressObj.house_number ?? '');
									return addressObj.house_number ?? '';
								}else	if(type === 'streetName'){				
									setAddr(addressObj.street_name ?? '');
									return addressObj.street_name ?? '';
								}else{
									return '';
								}
							});	
						}
						
						const fetchParams = async () => {		
							try{
								const target = typeId === 'errand' ? utils.USER_CALL+'?email='+email+'&errandaddressId='+addressId : utils.USER_CALL+'?email='+email+'&addressId='+addressId;
								const header = new Headers({
									'Content-Type': 'application/json',
									'Accept': 'application/json',
									'Authorization': 'Bearer '+authToken,
								});
								const posturl = utils.BASE_URL+target; //utils.GET_URL;
								const res = await utils.fetchURL(posturl, 'GET', '', header);
								const data = await res.json();
								return data;
							}catch(e){}
							
						};
						
						
						const onUpdate = async () => 
						{
							try{
								if(addr && city && state && zip){}else{
									setVisible(true);
									setMsgHead('');
									setMsgBody('Please check all required fields');
									setMsgType('error');
									window.scrollTo({top: 0, behavior: 'smooth'});
									return;
								}
								setClick(true);
								const target = utils.USER_URL;
								const header = new Headers({
									'Content-Type': 'application/json',
									'Accept': 'application/json',
									'Authorization': 'Bearer '+authToken,
								});
								const posturl = utils.BASE_URL+target; //utils.POST_URL;
								const localtime = await utils.getTime();
								const obj = JSON.stringify({'rtype': 'editaddress', 'email': email, 'phone': phoneNo, 'addresslabel': addrlabel, 'address': houseNo+' '+addr, 'aptsuite': aptsuite, 'accesscode': accesscode, 'city': city, 'state': state, 'zip': zip, 'instruction': instruction, 'addressId': aid, 'os': os, 'model': model, 'localtime': localtime, 'target': target, 'token': authToken});
								const dataToPost = await utils.cipherEncode(obj);			
								const res = await utils.fetchURL(posturl, 'POST', dataToPost, header);
								const data = await res.json();
								setClick(false);
								
								if(data.msg === 'Success'){
									setVisible(true);
									setMsgHead('');
									setMsgBody('Address was updated successfully');
									setMsgType('success');
									window.scrollTo({top: 0, behavior: 'smooth'});				
									}else{
									setVisible(true);
									setMsgHead('');
									setMsgBody(data.msg);
									setMsgType('error');
									window.scrollTo({top: 0, behavior: 'smooth'});
								}
								}catch(e){
								message.error(e.message);
								setClick(false);
							}		
						}
						
						const handleDelete = async (addressId) => 
						{
							if(window.confirm('Do you want to delete address?'))
							{
								try{
									//typeId
									setClick(true);
									const target = utils.USER_URL;
									const header = new Headers({
										'Content-Type': 'application/json',
										'Accept': 'application/json',
										'Authorization': 'Bearer '+authToken,
									});
									const posturl = utils.BASE_URL+target; //utils.POST_URL;
									const localtime = await utils.getTime();
									const obj = JSON.stringify({'rtype': 'deleteaddress', 'email': email, 'addressId': addressId, 'os': os, 'model': model, 'localtime': localtime, 'target': target, 'token': authToken});
									const dataToPost = await utils.cipherEncode(obj);			
									const res = await utils.fetchURL(posturl, 'POST', dataToPost, header);
									const data = await res.json();
									setClick(false);	
									if(data.msg === 'Success'){
										setVisible(true);
										setMsgHead('');
										setMsgBody('Address was successfully deleted');
										setMsgType('success');
										window.scrollTo({top: 0, behavior: 'smooth'});
										await getParams2();
										setTimeout(function(){						
											if(typeId === 'delivery'){
												navigate('/delivery-addresses');
												}else if(typeId === 'errand'){
												navigate('/errand-addresses');
											}
										}, 5000);
										}else{
										setVisible(true);
										setMsgHead('');
										setMsgBody(data.msg);
										setMsgType('error');
										window.scrollTo({top: 0, behavior: 'smooth'});
									}
									}catch(e){
									setClick(false);
									message.error(e.message);				
								}		
							}
						}
											
						const getParams2 = async () => {
							try{		
								var paramsFromServer;
								if(params.length === 0){
									paramsFromServer = await fetchParams2();
									setParams(paramsFromServer?.user?.errandaddresses ?? []);
									dispatch(setAddressData(paramsFromServer?.user?.addresses ?? []));
									dispatch(setPaymentCardData(paramsFromServer?.user?.paymentCards ?? []));
									dispatch(setErrandAddressData(paramsFromServer?.user?.errandaddresses ?? []));				
								}
							}catch(e){}	
						}
												
						const fetchParams2 = async () => {
							try{
								const target = utils.USER_CALL+'?email='+email;
								const header = new Headers({
									'Content-Type': 'application/json',
									'Accept': 'application/json',
									'Authorization': 'Bearer '+authToken,
								});
								const posturl = utils.BASE_URL+target; //utils.GET_URL;
								const dataToPost = '';			
								const res = await utils.fetchURL(posturl, 'GET', dataToPost, header);
								const response = await res?.json();
								if(response?.response && response?.status && response?.status === 'Success'){
									const d = await utils.cipherDecode(response.response);
									const mainresponse = typeof d === 'object' ? d : JSON.parse(d);
									return mainresponse;
									}else if(response?.error && response?.error.toLowerCase() === 'invalid user'){
									localStorage.clear();
									const cookies = Object.keys(Cookies.get());
									cookies.forEach(cookie => {
										if(cookie !== 'cookieconsent_status'){
											Cookies.remove(cookie);
										}
									});
									navigate('/');
								}
								return response;
							}catch(e){}
							
						};
						
						const onErrandUpdate = async () => 
						{
							try{
								if(addr && city && state && zip){}else{
									setVisible(true);
									setMsgHead('');
									setMsgBody('Please check all required fields');
									setMsgType('error');
									window.scrollTo({top: 0, behavior: 'smooth'});
									return;
								}
								setClick(true);
								const target = utils.USER_URL;
								const header = new Headers({
									'Content-Type': 'application/json',
									'Accept': 'application/json',
									'Authorization': 'Bearer '+authToken,
								});
								const posturl = utils.BASE_URL+target; //utils.POST_URL;
								const localtime = await utils.getTime();
								const obj = JSON.stringify({'rtype': 'editerrandaddress', 'email': email, 'phone': phoneNo, 'addresslabel': addrlabel, 'address': houseNo+' '+addr, 'aptsuite': aptsuite, 'businessName': business, 'instruction': instruction, 'city': city, 'state': state, 'zip': zip, 'addressId': aid, 'os': os, 'model': model, 'localtime': localtime, 'target': target, 'token': authToken});
								const dataToPost = await utils.cipherEncode(obj);			
								const res = await utils.fetchURL(posturl, 'POST', dataToPost, header);
								const data = await res.json();
								setClick(false);
								
								if(data.msg === 'Success'){
									setVisible(true);
									setMsgHead('');
									setMsgBody('Address was updated successfully');
									setMsgType('success');
									window.scrollTo({top: 0, behavior: 'smooth'});				
									}else{
									setVisible(true);
									setMsgHead('');
									setMsgBody(data.msg);
									setMsgType('error');
									window.scrollTo({top: 0, behavior: 'smooth'});
								}
								}catch(e){
								message.error(e.message);
								setClick(false);
							}		
						}
						
						const handleDeleteErrand = async () => 
						{
							if(window.confirm('Do you want to delete address?'))
							{
								try{				
									setClick(true);
									const target = utils.USER_URL;
									const header = new Headers({
										'Content-Type': 'application/json',
										'Accept': 'application/json',
										'Authorization': 'Bearer '+authToken,
									});
									const posturl = utils.BASE_URL+target; //utils.POST_URL;
									const localtime = await utils.getTime();
									const obj = JSON.stringify({'rtype': 'deleteerrandaddress', 'email': email, 'addressId': aid, 'os': os, 'model': model, 'localtime': localtime, 'target': target, 'token': authToken});
									const dataToPost = await utils.cipherEncode(obj);			
									const res = await utils.fetchURL(posturl, 'POST', dataToPost, header);
									const data = await res.json();
									setClick(false);	
									if(data.msg === 'Success'){
										setVisible(true);
										setMsgHead('');
										setMsgBody('Address was successfully deleted');
										setMsgType('success');
										window.scrollTo({top: 0, behavior: 'smooth'});
										setTimeout(function(){
											if(typeId === 'delivery'){
												navigate('/delivery-addresses');
												}else if(typeId === 'errand'){
												navigate('/errand-addresses');
											}
										}, 5000);
										}else{
										setVisible(true);
										setMsgHead('');
										setMsgBody(data.msg);
										setMsgType('error');
										window.scrollTo({top: 0, behavior: 'smooth'});
									}
									}catch(e){
									setClick(false);
									message.error(e.message);			
								}
							}
						}	
										
						const updateMarker = async () => {
							try{
								if(addressId){}else{
									setVisible(true);
									setMsgHead('');
									setMsgBody('Error occurred, Please try later');
									setMsgType('error');
									window.scrollTo({top: 0, behavior: 'smooth'});
									return;
								}
								setAddressModalOpen(false);
								setClick(true);
								const target = utils.USER_URL;
								const header = new Headers({
									'Content-Type': 'application/json',
									'Accept': 'application/json',
									'Authorization': 'Bearer '+authToken,
								});
								const posturl = utils.BASE_URL+target; //utils.POST_URL;
								const localtime = await utils.getTime();
								const obj = JSON.stringify({'rtype': 'locationupdate', 'email': email, 'addressId': addressId, 'latitude': marker?.lat ? marker?.lat : gps?.latitude, 'longitude': marker?.lng ? marker?.lng : gps?.longitude, 'type': `${typeId === 'errand' ? 'errand' : 'address'}`, 'os': os, 'model': model, 'localtime': localtime, 'target': target, 'token': authToken});
								const dataToPost = await utils.cipherEncode(obj);	
								//console.log(utils.cipherDecode(dataToPost));
								const res = await utils.fetchURL(posturl, 'POST', dataToPost, header);
								const data = await res.json();
								setClick(false);
								
								if(data.msg === 'Success'){
									setVisible(true);
									setMsgHead('');
									setMsgBody('Address location was updated successfully');
									setMsgType('success');
									window.scrollTo({top: 0, behavior: 'smooth'});				
									}else{
									setVisible(true);
									setMsgHead('');
									setMsgBody(data.msg);
									setMsgType('error');
									window.scrollTo({top: 0, behavior: 'smooth'});
								}
								}catch(e){
								message.error(e.message);
								setClick(false);
							}
							
						};
						
						
						function handleClose(){
							setVisible(false);
						}
						
						
						function renderForm() {
							return (
								<>
								<motion.div className="pprogress-bar" style={{ scaleX }} />			
								<div className="el-global--wrapper">
								<div className="el-entry el-page--transition">
								<MainNavbar />
								<div className="el-preview container" id="el-dashboard-container">
								<div className="sc-lhlUkk kRzDDN">
								<div className="animated margin-bottom-4 flex justify-between mx-auto align-center c-box-width">
								<div className="cus_title_wrap">
								<h2>{`Edit  ${typeId.charAt(0).toUpperCase() + typeId.slice(1)} Address`}</h2>						
								</div>
								<div className="cus_add_button_wrap">
								{visibleDelete && (<button type="solid" className="cus_add_button" onClick={()=>`${typeId === 'errand' ? handleDeleteErrand(addressId) : handleDelete(addressId)}`}>Delete</button>)}
								</div>
								</div>
								<Space className="alertbutton" direction="vertical">
								{visible && (<Alert				
									message={msgHead}
									description={msgBody}
									type={msgType}
									closable 
									afterClose={handleClose}				
								/>)}
								</Space>
								<div className="sc-cabOPr kUdOjq login-card--wrap auth-wrap elAnimated elFadeInUp text-center el-overflow--hidden slide-in-bottom c-box-width c-box">
								<div className="cus_back_button_wrap" style={{margin: "0px 0px"}}>
								<button type="solid" style={{display: "flow-root", marginBottom: 30}} className="cus_back_button" onClick={()=> SwitchRef(`${typeId === 'errand' ? '/errand-addresses' : '/delivery-addresses'}`)}>Back</button>
								</div>
								
								<div                
								style={{
									backgroundColor: '#f7f7f7',
									background: "rgb(255, 255, 255) none repeat scroll 0% 0%",
									boxShadow: "rgba(0, 0, 0, 0.06) 0px 0px 25px",
									borderRadius: 4,
									padding: "32px 23px",
									margin: "32px 0px",
								}}
								>
								
								{gps?.latitude > 0 && (<div
									style={{
										marginBottom: 30,
										cursor: 'pointer'
									}}
									onClick={() => setAddressModalOpen(true)}
									>
									<span style={{color: '#0ecb81', fontSize: 18}}>Adjust address location on the map</span>
								</div>)}
								
								{/* Phone */}
								<FormInput
								label="Phone Number *"
								placeholder="Phone Number"
								secureTextEntry="phone"
								inputType="input"
								inputId="phoneno"
								onChange={(e) => setPhoneNo(e.target.value)}
								value={phoneNo}
								maxLength={`${depn.phone}`}
								containerStyle={{
									marginTop: 15,
									marginBottom: 15,
									width: '100%',
									textAlign: 'left',
								}}
								inputContainerStyle={{
									backgroundColor: '#ffffff',
									width: '100%'
								}}
								inputLabelStyle={{
									color: formlabel						
								}}
								inputClass={'cus_input'}
								inputStyle={{color: inputlabel}}
								/>
								
								
								<div
								style={{
									marginTop: 5,
									borderRadius: 5,
									paddingHorizontal: 10,			
								}}
								>
								<span
								style={{		
									fontSize: 13,				
								}}
								>
								{`By providing your phone number, you consent to receive text messages from ErrandLocal related to your order. Standard message rates may apply. See our Terms of Use for more information.`}
								</span>					
								</div>
								
								{/* Business Name */}
								{typeId === 'errand' && (<FormInput
									label="Contact/Business Name *"
									placeholder="Contact/Business Name"
									secureTextEntry="text"
									inputType="input"
									inputId="businessName"
									onChange={(e) => setBusiness(e.target.value)}
									value={business}
									containerStyle={{
										marginTop: 15,
										width: '100%',
										textAlign: 'left',
									}}
									inputContainerStyle={{
										backgroundColor: '#ffffff',
										width: '100%'
									}}
									inputLabelStyle={{
										color: formlabel,
									}}
									inputClass={'cus_input'}
									inputStyle={{color: inputlabel}}
								/>)}
								
								
								{/* Address Label */}
								<FormInput
								label={`${typeId === 'errand' ? 'Errand ' : 'Delivery '} Address Label`}					
								placeholder="Address Label"
								secureTextEntry="text"
								inputType="input"
								inputId="addrlabel"
								onChange={(e) => setAddrLabel(e.target.value)}
								value={addrlabel}
								containerStyle={{
									marginTop: 15,
									width: '100%',
									textAlign: 'left',
								}}
								inputContainerStyle={{
									backgroundColor: '#ffffff',
									width: '100%'
								}}
								inputLabelStyle={{
									color: formlabel,
								}}
								inputClass={'cus_input'}
								inputStyle={{color: inputlabel}}
								/>								
								
								
								<fieldset className="row-flex" style={{marginTop: 0}}>
									{/* House No */}
									<FormInput
									label={`House No`}
									placeholder="No"
									secureTextEntry="text"
									inputType="input"
									inputId="houseNo"
									onChange={(e) => setHouseNo(e.target.value)}
									value={houseNo}
									containerStyle={{
										marginTop: 15,
										width: '30%',
										textAlign: 'left',
									}}
									inputContainerStyle={{
										backgroundColor: '#ffffff',
										width: '100%'
									}}
									inputLabelStyle={{
										color: formlabel,
									}}
									inputClass={'cus_input'}
									inputStyle={{color: inputlabel}}
									/>
									
									{/* Address */}
									<FormInput
									label={`${typeId === 'errand' ? 'Street ' : 'Street '} Name *`}
									placeholder="Street Name"
									secureTextEntry="text"
									inputType="input"
									inputId="addr"
									onChange={(e) => setAddr(e.target.value)}
									value={addr}
									containerStyle={{
										marginTop: 15,
										width: '100%',
										textAlign: 'left',
										marginLeft: '2%'
									}}
									inputContainerStyle={{
										backgroundColor: '#ffffff',
										width: '100%'
									}}
									inputLabelStyle={{
										color: formlabel,
									}}
									inputClass={'cus_input cus_input_half'}
									inputStyle={{color: inputlabel}}
									/>
								</fieldset>
								
								
								{/* Apt/Suite */}
								<FormInput
								label={`${typeId === 'errand' ? 'Errand ' : 'Delivery '} Apt/Suite`}
								placeholder="Apt/Suite"
								secureTextEntry="text"
								inputType="input"
								inputId="aptsuite"
								onChange={(e) => setAptSuite(e.target.value)}
								value={aptsuite}
								containerStyle={{
									marginTop: 15,
									width: '100%',
									textAlign: 'left',
								}}
								inputContainerStyle={{
									backgroundColor: '#ffffff',
									width: '100%'
								}}
								inputLabelStyle={{
									color: formlabel,
								}}
								inputClass={'cus_input'}
								inputStyle={{color: inputlabel}}
								/>
								
								{/* Accesscode */}
								<FormInput
								label="Building Access Code"
								placeholder="Access Code"
								secureTextEntry="text"
								inputType="input"
								inputId="accesscode"
								onChange={(e) => setAccessCode(e.target.value)}
								value={accesscode}
								containerStyle={{
									marginTop: 15,
									width: '100%',
									textAlign: 'left',
								}}
								inputContainerStyle={{
									backgroundColor: '#ffffff',
									width: '100%'
								}}
								inputLabelStyle={{
									color: formlabel,
								}}
								inputClass={'cus_input'}
								inputStyle={{color: inputlabel}}
								/>
								
                {/* City */}
								<FormInput
								label={`${typeId === 'errand' ? 'Errand ' : 'Delivery '} City *`}
								placeholder="City"
								secureTextEntry="text"
								inputType="input"
								inputId="city"
								onChange={(e) => setCity(e.target.value)}
								value={city}
								containerStyle={{
									marginTop: 15,
									width: '100%',
									textAlign: 'left',
								}}
								inputContainerStyle={{
									backgroundColor: '#ffffff',
									width: '100%'
								}}
								inputLabelStyle={{
									color: formlabel,
								}}
								inputClass={'cus_input'}
								inputStyle={{color: inputlabel}}
								/>
								
								{/* State */}
								<FormInput
								label={`${typeId === 'errand' ? 'Errand ' : 'Delivery '} State/Province *`}
								placeholder="Select state/province"
								secureTextEntry="text"
								inputType="select"
								inputId="state"
								onChange={(e) => setState(e.target.value)}
								value={state}
								containerStyle={{
									marginTop: 15,
									width: '100%',
									textAlign: 'left',
								}}
								inputContainerStyle={{
									backgroundColor: '#ffffff',
									width: '100%'
								}}
								inputLabelStyle={{
									color: formlabel,
								}}
								inputClass={'cus_select'}
								inputStyle={{color: inputlabel, backgroundColor: '#ffffff'}}
								options={stateOptions}
								/>
								
								{/* Zip */}
								<FormInput
								label={`${typeId === 'errand' ? 'Errand ' : 'Delivery '} Zip *`}
								placeholder="Zip"
								secureTextEntry="text"
								inputType="input"
								inputId="zip"
								onChange={(e) => setZip(e.target.value)}
								value={zip}
								maxLength={`${depn.zip}`}
								containerStyle={{
									marginTop: 15,
									width: '100%',
									textAlign: 'left',
								}}
								inputContainerStyle={{
									backgroundColor: '#ffffff',
									width: '100%'
								}}
								inputLabelStyle={{
									color: formlabel,
								}}
								inputClass={'cus_input'}
								inputStyle={{color: inputlabel}}
								/>
								
								{/* Delivery Instruction */}
								<FormInput
								label={`${typeId === 'errand' ? 'Errand ' : 'Delivery '} Instruction`}
								placeholder="Delivery Note"
								secureTextEntry="text"
								inputType="textarea"
								inputId="instruction"
								onChange={(e) => setInstruction(e.target.value)}
								value={instruction}
								containerStyle={{
									marginTop: 15,
									marginBottom: 35,
									width: '100%',
									textAlign: 'left',
								}}
								inputContainerStyle={{
									backgroundColor: '#ffffff',
									width: '100%'
									
								}}
								inputLabelStyle={{
									color: formlabel,
								}}
								inputClass={'cus_textarea'}
								inputStyle={{color: inputlabel, resize: 'none', height: '5rem', borderRadius: 5, border: '1px solid #0ec8b1', marginBottom: 10 }}
								/>
								
								{/* Button */}
								<FormInput
								label="Submit"
								secureTextEntry="text"
								inputType="button"
								inputId="button1"
								onClick={()=> `${typeId === 'errand' ? onErrandUpdate() : onUpdate()}`}
								containerStyle={{
									marginTop: 5,
									marginBottom: 25,
									width: '100%'
								}}
								inputClass={'cus_button'}
								inputStyle={{}}
								/>
								
								</div>
								
								</div>
								</div>
								
								</div>
								</div>
								</div>
								<SubFooter />
								{addressModalOpen && (<MapModal addressModalOpen={addressModalOpen} setAddressModalOpen={setAddressModalOpen} gps={gps} addressId={addressId} address={addr} marker={marker} setMarker={setMarker} updateMarker={updateMarker}/>)}
								</>
							)
						}
						
						if(load)
						{
							return (
								
								<div 
								style={{}}
								>
								{renderForm()}
								</div>
								
							)
						}
						else
						{
							return null;
						}
};


export default EditAddress;