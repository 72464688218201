import React, { useState, useEffect } from 'react';
import { motion, useScroll, useSpring } from "framer-motion";
import { utils } from "../../utils";
import { FormInput, MainNavbar, SubFooter } from "../../components";
import { useNavigate } from 'react-router-dom';
import { Space, message, Alert} from "antd";

const depn = {
	'zip': 10,
	'phone': 11,
};

const formlabel = "#a0a0a0";
const inputlabel = "#257a80";

const AddAddress = ({typeId}) => 
{	
	const { scrollYProgress } = useScroll();
	const scaleX = useSpring(scrollYProgress, {
		stiffness: 100,
		damping: 30,
		restDelta: 0.001
	});
	
	
	const navigate = useNavigate();	
	const [instruction, setInstruction] = useState('');
	const [phoneNo, setPhoneNo] = useState('');
	const [email, setEmail] = useState('');
	const [authToken, setAuthToken] = useState('');
	const [business, setBusiness] = useState('');
	const [houseNo, setHouseNo] = useState('');
	const [addr, setAddr] = useState('');
	const [addrlabel, setAddrLabel] = useState('');
	const [aptsuite, setAptSuite] = useState('');
	const [accesscode, setAccessCode] = useState('');
	const [city, setCity] = useState('');
	const [state, setState] = useState('');
	const [zip, setZip] = useState('');
	const [load, setLoad] = useState(false);
	const [visible, setVisible] = useState(false);	
	const [msgType, setMsgType] = useState('');	
	const [msgBody, setMsgBody] = useState('');	
	const [msgHead, setMsgHead] = useState('');	
	const [stateOptions, setStateOptions] = useState([]);
	
	const [os, setOs] = useState('');
	const [model, setModel] = useState('');
	
	
	useEffect(() => 
		{
			const checkData = async() => {
				if(!typeId){
					navigate('/delivery-addresses');
					}else if(typeId === 'delivery' || typeId === 'errand'){	
					const sessionemail = await utils.getStorage('__el_data_email');
					const authTok = await utils.getStorage('__el_data_login_token');
					setOs('');
					setModel('');
					setLoad(load);
					setEmail(sessionemail);
					setAuthToken(authTok);
					const region = await utils.getLocalCookie('__user_region');	
					const options = await utils.getStateOptions(region);
					setStateOptions(options);
					}else{
					navigate('/delivery-addresses');
				}
			}
			checkData();
		},[typeId]);
		
		const SwitchRef = (ref) => {
			navigate(ref);
		}
		
		
		const onAddAddress = async () => 
		{
			try{
				if(addr && city && state && zip){}else{
				setVisible(true);
				setMsgHead('');
				setMsgBody('Please check all required fields');
				setMsgType('error');
				window.scrollTo({top: 0, behavior: 'smooth'});
				return;
			}	
			const target = utils.USER_URL;
			const header = new Headers({
				'Content-Type': 'application/json',
				'Accept': 'application/json',
				'Authorization': 'Bearer '+authToken,
			});
			const posturl = utils.BASE_URL+target; //utils.POST_URL;
			const localtime = await utils.getTime();
			const obj = JSON.stringify({'rtype': 'addaddress', 'email': email, 'phone': phoneNo, 'addresslabel': addrlabel, 'address': houseNo+' '+addr, 'aptsuite': aptsuite, 'accesscode': accesscode, 'city': city, 'state': state, 'zip': zip, 'instruction': instruction, 'os': os, 'model': model, 'localtime': localtime, 'target': target, 'token': authToken});
			const dataToPost = await utils.cipherEncode(obj);			
			const res = await utils.fetchURL(posturl, 'POST', dataToPost, header);
			const data = await res.json();
			
			if(data.msg === 'Success'){
				setVisible(true);
				setMsgHead('');
				setMsgBody('Address was successfully updated');
				setMsgType('success');
				window.scrollTo({top: 0, behavior: 'smooth'});				
				}else{
				setVisible(true);
				setMsgHead('');
				setMsgBody(data.msg);
				setMsgType('error');
				window.scrollTo({top: 0, behavior: 'smooth'});
			}
			}catch(e){
			message.error(e.message);
			}		
		}
		
		const onAddErrandAddress = async () => 
		{
			try{
				if(addr && city && state && zip){}else{
					setVisible(true);
					setMsgHead('');
					setMsgBody('Please check all required fields');
					setMsgType('error');
					window.scrollTo({top: 0, behavior: 'smooth'});
					return;
				}
				
				const target = utils.USER_URL;
				const header = new Headers({
					'Content-Type': 'application/json',
					'Accept': 'application/json',
					'Authorization': 'Bearer '+authToken,
				});
				const posturl = utils.BASE_URL+target; //utils.POST_URL;
				const localtime = await utils.getTime();
				const obj = JSON.stringify({'rtype': 'adderrandaddress', 'email': email, 'phone': phoneNo, 'addresslabel': addrlabel, 'address': houseNo+' '+addr, 'aptsuite': aptsuite, 'businessName': business, 'instruction': instruction, 'city': city, 'state': state, 'zip': zip, 'os': os, 'model': model, 'localtime': localtime, 'target': target, 'token': authToken});
				const dataToPost = await utils.cipherEncode(obj);			
				const res = await utils.fetchURL(posturl, 'POST', dataToPost, header);
				const data = await res.json();
				
				if(data.msg === 'Success'){
					setVisible(true);
					setMsgHead('');
					setMsgBody('Address was successfully updated');
					setMsgType('success');
					window.scrollTo({top: 0, behavior: 'smooth'});				
					}else{
					setVisible(true);
					setMsgHead('');
					setMsgBody(data.msg);
					setMsgType('error');
					window.scrollTo({top: 0, behavior: 'smooth'});
				}
				}catch(e){
				message.error(e.message);
			}		
		}	
		
		function handleClose(){
			setVisible(false);
		}
		
    function renderForm() {
			return (
				<>
				<motion.div className="pprogress-bar" style={{ scaleX }} />			
				<div className="el-global--wrapper">
				<div className="el-entry el-page--transition">
				<MainNavbar />
				<div className="el-preview container slide-in-bottom" id="el-dashboard-container">
				<div className="sc-lhlUkk kRzDDN">
				<div className="animated margin-bottom-4 flex justify-between mx-auto align-center c-box-width">
				<div className="cus_title_wrap">
				<h2>{`Add  ${typeId.charAt(0).toUpperCase() + typeId.slice(1)} Address`}</h2>
				</div>
				<div className="">
				
				</div>
				</div>
				<Space className="alertbutton" direction="vertical">
				{visible && (<Alert				
					message={msgHead}
					description={msgBody}
					type={msgType}
					closable 
					afterClose={handleClose}				
				/>)}
				</Space>
				<div className="sc-cabOPr kUdOjq login-card--wrap auth-wrap elAnimated elFadeInUp text-center el-overflow--hidden slide-in-bottom c-box-width c-box">
				<div className="cus_back_button_wrap" style={{margin: "0px 0px"}}>
				<button type="solid" style={{display: "flow-root", marginBottom: 30}} className="cus_back_button" onClick={()=> SwitchRef(`${typeId === 'errand' ? '/errand-addresses' : '/delivery-addresses'}`)}>Back</button>
				</div>
				
				<div                
				style={{
					backgroundColor: '#f7f7f7',
					background: "rgb(255, 255, 255) none repeat scroll 0% 0%",
					boxShadow: "rgba(0, 0, 0, 0.06) 0px 0px 25px",
					borderRadius: 4,
					padding: "32px 23px",
					margin: "32px 0px",
				}}
				>
				
				<div
				style={{
					marginBottom: 5,
				}}
				>
				
				</div>
				
				{/* Phone */}
				<FormInput
				label="Phone Number *"
				placeholder="Phone Number"
				secureTextEntry="phone"
				inputType="input"
				inputId="phoneno"
				onChange={(e) => setPhoneNo(e.target.value)}
				value={phoneNo}
				maxLength={`${depn.phone}`}
				containerStyle={{
					marginTop: 15,
					marginBottom: 15,
					width: '100%',
					textAlign: 'left',
				}}
				inputContainerStyle={{
					backgroundColor: '#ffffff',
					width: '100%'
				}}
				inputLabelStyle={{
					color: formlabel						
				}}
				inputClass={'cus_input'}
				inputStyle={{color: inputlabel}}
				/>
				
				
				<div
				style={{
					marginTop: 5,
					borderRadius: 5,
					paddingHorizontal: 10,			
				}}
				>
				<span
				style={{		
					fontSize: 13,				
				}}
				>
				By providing your phone number, you consent to receive text messages from ErrandLocal related to your order. Standard message rates may apply. See our Terms of Use for more information.
				</span>					
				</div>
				
				{/* Business Name */}
				{typeId === 'errand' && (<FormInput
					label="Contact/Busines Name *"
					placeholder="Business Name"
					secureTextEntry="text"
					inputType="input"
					inputId="businessName"
					onChange={(e) => setBusiness(e.target.value)}
					value={business}
					containerStyle={{
						marginTop: 15,
						width: '100%',
						textAlign: 'left',
					}}
					inputContainerStyle={{
						backgroundColor: '#ffffff',
						width: '100%'
					}}
					inputLabelStyle={{
						color: formlabel,
					}}
					inputClass={'cus_input'}
					inputStyle={{color: inputlabel}}
				/>)}
				
				{/* Address Label */}
				<FormInput
				label={`${typeId === 'errand' ? 'Errand ' : 'Delivery '} Address Label`}
				placeholder="Address Label"
				secureTextEntry="text"
				inputType="input"
				inputId="addrlabel"
				onChange={(e) => setAddrLabel(e.target.value)}
				value={addrlabel}
				containerStyle={{
					marginTop: 15,
					width: '100%',
					textAlign: 'left',
				}}
				inputContainerStyle={{
					backgroundColor: '#ffffff',
					width: '100%'
				}}
				inputLabelStyle={{
					color: formlabel,
				}}
				inputClass={'cus_input'}
				inputStyle={{color: inputlabel}}
				/>
				
				<fieldset className="row-flex" style={{marginTop: 0}}>
				{/* House No */}
				<FormInput
				label={`House No`}
				placeholder="No"
				secureTextEntry="text"
				inputType="input"
				inputId="houseNo"
				onChange={(e) => setHouseNo(e.target.value)}
				value={houseNo}
				containerStyle={{
					marginTop: 15,
					width: '30%',
					textAlign: 'left',
				}}
				inputContainerStyle={{
					backgroundColor: '#ffffff',
					width: '100%'
				}}
				inputLabelStyle={{
					color: formlabel,
				}}
				inputClass={'cus_input'}
				inputStyle={{color: inputlabel}}
				/>
				
				{/* Address */}
				<FormInput
				label={`${typeId === 'errand' ? 'Street ' : 'Street '} Name *`}
				placeholder="Street Name"
				secureTextEntry="text"
				inputType="input"
				inputId="addr"
				onChange={(e) => setAddr(e.target.value)}
				value={addr}
				containerStyle={{
					marginTop: 15,
					width: '100%',
					textAlign: 'left',
					marginLeft: '2%'
				}}
				inputContainerStyle={{
					backgroundColor: '#ffffff',
					width: '100%'
				}}
				inputLabelStyle={{
					color: formlabel,
				}}
				inputClass={'cus_input cus_input_half'}
				inputStyle={{color: inputlabel}}
				/>
				</fieldset>
				
				{/* Apt/Suite */}
				<FormInput
				label={`${typeId === 'errand' ? 'Errand ' : 'Delivery '} Apt/Suite`}
				placeholder="Apt/Suite"
				secureTextEntry="text"
				inputType="input"
				inputId="aptsuite"
				onChange={(e) => setAptSuite(e.target.value)}
				value={aptsuite}
				containerStyle={{
					marginTop: 15,
					width: '100%',
					textAlign: 'left',
				}}
				inputContainerStyle={{
					backgroundColor: '#ffffff',
					width: '100%'
				}}
				inputLabelStyle={{
					color: formlabel,
				}}
				inputClass={'cus_input'}
				inputStyle={{color: inputlabel}}
				/>
				
				{/* Accesscode */}
				<FormInput
				label="Building Access Code"
				placeholder="Access Code"
				secureTextEntry="text"
				inputType="input"
				inputId="accesscode"
				onChange={(e) => setAccessCode(e.target.value)}
				value={accesscode}
				containerStyle={{
					marginTop: 15,
					width: '100%',
					textAlign: 'left',
				}}
				inputContainerStyle={{
					backgroundColor: '#ffffff',
					width: '100%'
				}}
				inputLabelStyle={{
					color: formlabel,
				}}
				inputClass={'cus_input'}
				inputStyle={{color: inputlabel}}
				/>
				
				{/* City */}
				<FormInput
				label={`${typeId === 'errand' ? 'Errand ' : 'Delivery '} City *`}
				placeholder="City"
				secureTextEntry="text"
				inputType="input"
				inputId="city"
				onChange={(e) => setCity(e.target.value)}
				value={city}
				containerStyle={{
					marginTop: 15,
					width: '100%',
					textAlign: 'left',
				}}
				inputContainerStyle={{
					backgroundColor: '#ffffff',
					width: '100%'
				}}
				inputLabelStyle={{
					color: formlabel,
				}}
				inputClass={'cus_input'}
				inputStyle={{color: inputlabel}}
				/>
				
				{/* State */}
				<FormInput
				label={`${typeId === 'errand' ? 'Errand ' : 'Delivery '} State/Province *`}
				placeholder="Select state/province"
				secureTextEntry="text"
				inputType="select"
				inputId="state"
				onChange={(e) => setState(e.target.value)}
				value={state}
				containerStyle={{
					marginTop: 15,
					width: '100%',
					textAlign: 'left',
				}}
				inputContainerStyle={{
					backgroundColor: '#ffffff',
					width: '100%'
				}}
				inputLabelStyle={{
					color: formlabel,
				}}
				inputClass={'cus_select'}
				inputStyle={{color: inputlabel, backgroundColor: '#ffffff'}}
				options={stateOptions}
				/>
				
				{/* Zip */}
				<FormInput
				label={`${typeId === 'errand' ? 'Errand ' : 'Delivery '} Zip *`}
				placeholder="Zip"
				secureTextEntry="text"
				inputType="input"
				inputId="zip"
				onChange={(e) => setZip(e.target.value)}
				value={zip}
				maxLength={`${depn.zip}`}
				containerStyle={{
					marginTop: 15,
					width: '100%',
					textAlign: 'left',
				}}
				inputContainerStyle={{
					backgroundColor: '#ffffff',
					width: '100%'
				}}
				inputLabelStyle={{
					color: formlabel,
				}}
				inputClass={'cus_input'}
				inputStyle={{color: inputlabel}}
				/>
				
				{/* Delivery Instruction */}
				<FormInput
				label={`${typeId === 'errand' ? 'Errand ' : 'Delivery '} Instruction`}
				placeholder="Delivery Note"
				secureTextEntry="text"
				inputType="textarea"
				inputId="instruction"
				onChange={(e) => setInstruction(e.target.value)}
				value={instruction}
				containerStyle={{
					marginTop: 15,
					marginBottom: 35,
					width: '100%',
					textAlign: 'left',
				}}
				inputContainerStyle={{
					backgroundColor: '#ffffff',
					width: '100%'
					
				}}
				inputLabelStyle={{
					color: formlabel,
				}}
				inputClass={'cus_textarea'}
				inputStyle={{color: inputlabel, resize: 'none', height: '5rem', borderRadius: 5, border: '1px solid #0ec8b1', marginBottom: 10 }}
				/>
				
				{/* Button */}
				<FormInput
				label="Submit"
				secureTextEntry="text"
				inputType="button"
				inputId="button1"
				onClick={()=> `${typeId === 'errand' ? onAddErrandAddress() : onAddAddress()}`}
				containerStyle={{
					marginTop: 5,
					marginBottom: 25,
					width: '100%'
				}}
				inputClass={'cus_button'}
				inputStyle={{}}
				/>
				
				</div>
				
				</div>
				</div>
				
				</div>
				</div>
				</div>
				<SubFooter />
				</>
			)
		}
		
		if(!load)
		{
			return (
				
				<div 
				style={{}}
				>
				{renderForm()}
				</div>
				
			)
		}
		else
		{
			return null
		}
};


export default AddAddress;