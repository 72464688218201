import React, { useEffect, useState } from 'react';
import { utils } from "../../utils";
import { Modal, Table, Button, Divider, Space, Drawer } from 'antd';

const BusinessHoursModal = ({ hoursData }) => {
  const [open, setOpen] = useState(false);
  const [currentDay, setCurrentDay] = useState('');

  useEffect(() => {
    setCurrentDay(new Date().toLocaleDateString('en-US', { weekday: 'long' }));
    window.document.body.style.overflow = open ? 'hidden' : '';
  }, [open]);

  const columns = [
    {
      title: 'Day',
      dataIndex: 'day',
      key: 'day',
    },
    {
      title: 'Open',
      dataIndex: 'open',
      key: 'open',
    },
    {
      title: 'Close',
      dataIndex: 'close',
      key: 'close',
    },
  ];
  

  const data = Object.keys(hoursData ?? []).map((day) => ({
    key: day,
    day: utils.convertToFullDay(day),
    open: parseFloat(hoursData[day]?.open) > 0 ? utils.convertTimeTo12HourFormat(hoursData[day]?.open) : hoursData[day]?.open,
    close: parseFloat(hoursData[day]?.close) > 0 ? utils.convertTimeTo12HourFormat(hoursData[day]?.close) : hoursData[day]?.close,
  }));

  return (
    <>
      <Button
        type="primary"
        onClick={() => setOpen(true)}
        style={{ backgroundColor: '#201e1e', border: '#201e1e' }}
      >
        VIEW HOURS
      </Button>
      <Drawer 
				title="Business Hours" 
				open={open} 
				footer={null}				
				closable={false}
				maskClosable={true}						
				placement="right"        
				onClose={() => setOpen(false)}
				size={`large`}
				extra={
					<Button
						key="back"
						className="c__button"
						onClick={() => setOpen(false)}
					>
						Close
					</Button>
        }
				>
        <Table
          columns={columns}
          dataSource={data}
          pagination={false}
          rowClassName={(record) => (record.day === currentDay ? 'current-day-row' : '')}
        />
        <Divider />
        <Space>
          <span style={{ color: '#333333' }}>
            If you have any questions or concerns, please contact business.
          </span>
        </Space>
      </Drawer>
    </>
  );
};


export default BusinessHoursModal;
