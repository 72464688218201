import React, { useEffect, useState } from 'react';
import { motion, useScroll, useSpring } from "framer-motion";
import moment from 'moment-timezone';
import { utils } from "../../utils";
import { v4 as uuidv4 } from "uuid";
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { MainNavbar, SubFooter } from "../../components";
import { setPastOrderData, selectPastOrderData } from "../../includes/navSlice";
import { OrderStatus } from "../../includes/navSlice";
import { Modal, Button, message} from "antd";


const renderOrderStatus = (orderStatus) => {
    const statusD = {
		[OrderStatus.NEW]: "Order Confirmed",
		[OrderStatus.AT_STORE]: "Out to Pick Order",
		[OrderStatus.COURIER_PICKED_UP]: "Out to Deliver Order",
		[OrderStatus.COURIER_READY_FOR_PICKUP]: "Order Ready",
		[OrderStatus.COURIER_COMPLETED]: "Delivered",
		[OrderStatus.COURIER_ACCEPTED]: "Out to Pick Order",
		[OrderStatus.COURIER_DECLINED]: "",
		[OrderStatus.PAYMENT_DECLINED]: "",
		[OrderStatus.CANCELLED]: "Cancelled",
		[OrderStatus.PENDING]: "Pending",
    };
	const el = statusD[OrderStatus[orderStatus]];
	return el;
};

let pastList = [];
const PendingOrderScreen = ({logPage}) => {

	const { scrollYProgress } = useScroll();
	const scaleX = useSpring(scrollYProgress, {
		stiffness: 100,
		damping: 30,
		restDelta: 0.001
	});
	
    const navigate = useNavigate();  
    const location = useLocation();  
	const dispatch = useDispatch();
	const pastorderlist = useSelector(selectPastOrderData);
	const sessionemail = utils.getStorage('__el_data_email');
    const authToken = utils.getStorage('__el_data_login_token');
	const [ orders, setOrders ] = useState(pastorderlist);
	const [ view, setView ] = useState(false);
	const [ email, setEmail ] = useState(sessionemail);
	const [ pasts, setPasts ] = useState(null);
	const [ viewData, setViewData ] = useState({});
	const [ viewBody, setViewBody ] = useState("");
	
	useEffect(() => {
		setEmail(email);
		window.document.body.style.overflow = "auto";
	}, []);
	
	useEffect(() => {
		const ref = document.referrer;
    const loc = location.pathname;
		logPage(ref, loc);
	}, []);
	
	useEffect(() => {
		document.title = `Pending Orders | Order Online | ErrandLocal`;
		const checkLogin = async() => {
			const sessionemail = await utils.getStorage('__el_data_email');
			const redirect = await utils.checkLogin(sessionemail);
			if((redirect && sessionemail) || redirect){
				navigate(redirect);
			}
		}
		checkLogin();
	}, []);
	
	
	useEffect(() => {
		const getParams = async () => {
			try{
				var paramsFromServer;
				if(orders === null){
					paramsFromServer = await fetchParams();
					setOrders(paramsFromServer?.orders);
					dispatch(setPastOrderData(paramsFromServer?.orders));
				}
			}catch(e){}	
		}	
		getParams();
	}, []);
	
	
	useEffect(() => 
	{
		try
		{
			if(orders)
			{
				pastList = [];
				Object.entries(orders).forEach(entry => 
				{
					document.documentElement.scrollTop = 0;
					const [key, val] = entry;					
					const value = val['data'];
					//const chat = val['chat'];
					
					const len = value.length;
					//var expected = 0;
					var itemamount = 0;
					var data = [];
					var isCode = "";
					var orderId = "";
					const state = renderOrderStatus(value[0]['status']);
					
					for(var i=0; i<len; i++){
						itemamount = parseFloat(value[0]['itemamount']) / 100;
						//expected = parseFloat(value[i]['expectedQty']);
						
						const addons = value[i]['productAddons'];
						data[i] = "<div style='color: #201e1c; font-weight: 600; font-family: Montserrat; font-size: 16px'>"+value[i]['expectedQty']+"x - "+value[i]['productName']+"</div>";
						isCode = value[i]['iscode'];
						orderId = value[i]['orderid'];
						
						for (let j = 0; j < addons.length; j++) {
						  if(j === 0){
							data[i] += addons[j] ? "<span>- "+addons[j]+"</span>" : "";
						  }else{
							data[i] += addons[j] ? "<br/><span>- "+addons[j]+"</span>" : "";
						  }	
						  if(j === addons.length - 1){
							data[i] += addons[j] ? "<br/>" : "";
						  }
						}
							
						if(state !== 'Pending'){
							if(isCode === '1'){
								data[i] += "<br/><br/><div style='color: rgb(160, 160, 160); font-weight: 600; font-family: Montserrat; font-size: 16px'>NB: Delivery code is required for this order. Please share with your driver to complete delivery</div>";
							}else{
								data[i] += "<br/><br/><div style='color: rgb(160, 160, 160); font-weight: 600; font-family: Montserrat; font-size: 16px'>NB: Delivery code is disabled for this order</div>";
							}
						}else{
							data[i] += "<br/><br/><div style='color: rgb(160, 160, 160); font-weight: 600; font-family: Montserrat; font-size: 16px'>NB: Payment was not received for this order.</div>";
						}
					}
					
							
					const estimatedTime = value[0]['deliveryTime'];
					const timeZone = value[0]['timeZone'];
					const storename = value[0]['storename'];
					const deliveryType = value[0]['deliveryType'];
					const storestatus = value[0]['storestatus'];
					const datetime = utils.dateToString(value[0]['datetime'], 'date');
					
					const areaTime = moment.unix(estimatedTime).tz(timeZone);
					const daytime = areaTime.format('ddd, MMMM Do YYYY, h:mm A');
					
					if(state !== 'Delivered' && storestatus !== 'Order Declined')
					{
						
						let list = {'id': key.toLowerCase(), 'href': {id: key.toLowerCase()}, 'title': storename, 'description': datetime+'. '+deliveryType, 'fill': utils.getRandomColor(), 'amount': utils.amountToString(itemamount), 'state': state, 'deliverytime': daytime, 'data': data, 'iscode': isCode, 'orderId': orderId, 'label': 'm26.07,24.81a2.5,2.5 0 1 1 -5,0a2.5,2.5 0 0 1 5,0z'};
						
						//pastList.push({id: key, count: expected, amount: utils.amountToString(itemamount), datetime: datetime, storename: storename, storeaddress: storeaddress, state: state, data: data, storeinstruction: storeinstruction, estimatedTime: estimatedTime, origin: origin, destination: destination, courier: courier, courierName: courierName, deliveryType: deliveryType, chat: chat, main: value});
						
						pastList.push(list);
					}
				});
				pastList.reverse();
				setPasts(pastList);
			}
			
		}catch(e){}	
		// eslint-disable-next-line		
	}, [orders]);
	
	
	const fetchParams = async () => {
		try{
			const target = utils.USER_CALL+'?email='+email+'&request=pastorders';
			const header = new Headers({
				'Content-Type': 'application/json',
				'Accept': 'application/json',
				'Authorization': 'Bearer '+authToken,
			});
			const posturl = utils.BASE_URL+target; //utils.GET_URL;
			const dataToPost = '';			
			const res = await utils.fetchURL(posturl, 'GET', dataToPost, header);			
			const data = await res.json();
			return data;
		}catch(e) {message.error(e.message);}
		
	};	
	
	const handleDeliveryCode = async (orderId, iscode) => {
		try {			
			const target = utils.USER_URL;
			const header = new Headers({
				'Content-Type': 'application/json',
				'Accept': 'application/json',
				'Authorization': 'Bearer '+authToken,
			});
			const posturl = utils.BASE_URL+target; 
			const obj = JSON.stringify({
        rtype: iscode === '1' ? "disablecode" : "enablecode",
        email: email,
        orderId: orderId,
      });
			const dataToPost = await utils.cipherEncode(obj);			

      if (posturl !== undefined) {
        const res = await utils.fetchURL(posturl, 'POST', dataToPost, header);
				const data = await res.json();
				
        if (data) {
          if (data?.msg === "Success") {						
            message.success('Action was successful');
						setView(!view);
						const nparamsFromServer = await fetchParams();
						setOrders(nparamsFromServer?.orders);
						dispatch(setPastOrderData(nparamsFromServer?.orders));
          } else {
            message.error(data.msg);
          }
        } else {
          message.error("Please try again later");          
        }
      }
    } catch (e) {
      message.error("Please try again later");      
    }
  
	}
	
	
	const makePayment = (orderId) => {
		setView(!view);
		window.location.href = "/checkoutpayment/"+orderId;		
	}
	
	const SwitchRef = (ref) => {
		navigate(ref);
	}
	
	const OpenRef = (item) => {		
		setViewData(item.listdata);
		setViewBody(`<b><font color='green'>${item.listdata.state}</font></b><br/><b>Order ID:</b> <font color='blue'> ${item.listdata.id}</font><br/><b>Delivery On:</b> <font color='blue'> ${item.listdata.deliverytime}</font><br/>...............<br/><br/>${item.listdata.data.join("<br/>")}`);
		setView(!view);
	}
	
	const unPaid = () => {
		return  <span style={{color: "red"}}> - Unpaid</span>
	}
	
	const ListOptions = ( listdata, index ) => {
		
		return (
		
			<div className="sc-iBYQkv gwipAY" onClick={()=> OpenRef(listdata)}>
				<div className=" flex justify-between list-card ">
					<div className="flex justify-between align-center list-card-content">
						<div className="list-icon ">
							<svg width="46" height="49" viewBox="0 0 46 49" fill="none" xmlns="http://www.w3.org/2000/svg">
								<g filter="url(#filter0_d_37786_12291)">
									<rect x="10" y="10" width="26" height="29" rx="4" fill="white"></rect>
									<path d={`${listdata.listdata?.label}`} fill={`${listdata.listdata?.fill}`}></path>
								</g>
								<defs>
									<filter id="filter0_d_37786_12291" x="0" y="0" width="46" height="49" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
										<feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
										<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"></feColorMatrix>
										<feOffset></feOffset>
										<feGaussianBlur stdDeviation="5"></feGaussianBlur>
										<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0"></feColorMatrix>
										<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_37786_12291"></feBlend>
										<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_37786_12291" result="shape"></feBlend>
									</filter>
								</defs>
							</svg>
						</div>
						<div className="flex-column list-card-texts">
							<h4 className="color-primary list-card-heading text-left margin-top-0 margin-bottom-0 btn-danger">{`${listdata.listdata?.title}`}{listdata.listdata?.state === 'Pending' && unPaid()}</h4>
							<div className="color-black text-xs margin-top-1">
								<div className="text-left">{`#${listdata.listdata?.id}. ${listdata.listdata?.description}`}</div>
							</div>
						</div>
					</div>
					<div className="flex align-center arrow-wrap">
						{listdata.listdata?.amount}
						{/*<svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M0 12.5825L1.51406 14L9 7V7V7L1.51406 0L0 1.4175L5.96719 7L0 12.5825Z" fill="#CACACA"></path>
						</svg>*/}
					</div>
				</div>
			</div>
		)
	}
		
	if(pasts?.length > 0)
	{
		return (
			<>
			<motion.div className="pprogress-bar" style={{ scaleX }} />			
			<div className="el-global--wrapper">
				<div className="el-entry el-page--transition">
					<MainNavbar />
					<div className="el-preview container" id="el-dashboard-container">
						<div className="sc-lhlUkk kRzDDN">
							<div className="animated margin-bottom-4 flex justify-between mx-auto align-center" style={{maxWidth: 533}}>
								<div className="cus_title_wrap">
									<h2>Pending Orders</h2>						
								</div>
								<div className="">
									
								</div>
							</div>							
							
							<div className="sc-cabOPr kUdOjq login-card--wrap auth-wrap elAnimated elFadeInUp text-center el-overflow--hidden slide-in-bottom" style={{maxWidth: 533, width: '100%', marginLeft: 'auto', marginRight: 'auto'}}>
								<div className="cus_back_button_wrap">
									<button type="solid" style={{display: "flow-root", marginBottom: 30}} className="cus_back_button" onClick={()=> SwitchRef('/dashboard')}>Back</button>
								</div>
								{pasts.map((listdata, index) => (
									<ListOptions listdata={listdata} index={index} key={uuidv4()} /> 
								))}
							</div>							
							
						</div>
						
					</div>
				</div>
			</div>			
			<SubFooter />
			{viewData?.state === 'Pending' && (<Modal
				open={view}
				title={viewData?.title}
				onCancel={() => setView(!view)}
				footer={[
					<Button
						key="control"
						className="c__button"
						onClick={()=> makePayment(viewData?.orderId)}
					>
						Pay Now
					</Button>
				,				
					<Button
						key="edit"
						className="c__button"
						onClick={()=> setView(!view)}
					>
						Cancel
					</Button>
				]}
			>
				<p dangerouslySetInnerHTML={{__html: viewBody}}></p>				
			</Modal>)}
			{viewData?.iscode === '1' && viewData?.state !== 'Pending' && (<Modal
				open={view}
				title={viewData?.title}
				onCancel={() => setView(!view)}
				footer={[
					<Button
						key="control"
						className="c__button"
						onClick={()=> handleDeliveryCode(viewData?.orderId, viewData?.iscode)}
					>
						Disable Code
					</Button>
				,				
					<Button
						key="edit"
						className="c__button"
						onClick={()=> setView(!view)}
					>
						Cancel
					</Button>
				]}
			>
				<p dangerouslySetInnerHTML={{__html: viewBody}}></p>				
			</Modal>)}
			{viewData?.iscode !== '1' && viewData?.state !== 'Pending' && (<Modal
				open={view}
				title={viewData?.title}
				onCancel={() => setView(!view)}
				footer={[
					<Button
						key="control"
						className="c__button"
						onClick={()=> handleDeliveryCode(viewData?.orderId, viewData?.iscode)}
					>
						Enable Code
					</Button>
				,				
					<Button
						key="edit"
						className="c__button"
						onClick={()=> setView(!view)}
					>
						Cancel
					</Button>
				]}
			>
				<p dangerouslySetInnerHTML={{__html: viewBody}}></p>				
			</Modal>)}
			</>
		)

	}
	else
	{
		return (
		<>
		<motion.div className="pprogress-bar" style={{ scaleX }} />			
		<div className="el-global--wrapper">
			<div className="el-entry el-page--transition">
				<MainNavbar />
				<div className="el-preview container" id="el-dashboard-container">
					<div className="sc-lhlUkk kRzDDN">
						<div className="animated margin-bottom-4 flex justify-between mx-auto align-center c-box-width">
							<div className="cus_title_wrap">
								<h2>Pending Orders</h2>						
							</div>
							<div className="">
								
							</div>
						</div>
						<div className="sc-cabOPr kUdOjq login-card--wrap auth-wrap elAnimated elFadeInUp text-center el-overflow--hidden slide-in-bottom c-box-width c-box">
							<div className="cus_back_button_wrap" style={{margin: "0px 0px"}}>
								<button type="solid" style={{display: "flow-root", marginBottom: 30}} className="cus_back_button" onClick={()=> SwitchRef('/dashboard')}>Back</button>
							</div>
							<div>
								No pending order found.
							</div>
						</div>
					</div>
					
				</div>
			</div>
		</div>
		<SubFooter />
		</>
	)


	}
	
	
	
};


export default PendingOrderScreen;