import React, { useState, useEffect } from 'react';
import { motion, useScroll, useSpring } from "framer-motion";
import { utils } from "../../utils";
import { FormInput, MainNavbar, SubFooter } from "../../components";
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from "uuid";
import { Space, message, Alert} from "antd";

const formlabel = "#a0a0a0";
const inputlabel = "#257a80";

const FindDriver = () => 
{	
	const { scrollYProgress } = useScroll();
	const scaleX = useSpring(scrollYProgress, {
		stiffness: 100,
		damping: 30,
		restDelta: 0.001
	});
	
	const sessionemail = utils.getStorage('__el_data_email');
    const authToken = utils.getStorage('__el_data_login_token');
    const ellogin = utils.getStorage('__el_data_login_status');

    const navigate = useNavigate();
	
    const [search, setSearch] = useState('');
	const [click, setClick] = useState(false);
	const [load, setLoad] = useState(false);
	const [couriers, setCouriers] = useState([]);	
	const [visible, setVisible] = useState(false);	
	const [msgType, setMsgType] = useState('');	
	const [msgBody, setMsgBody] = useState('');	
	const [msgHead, setMsgHead] = useState('');	
	
	const [os, setOs] = useState('');
	const [model, setModel] = useState('');
	
	
	useEffect(() => 
	{
		setOs('');
		setModel('');
	},[]);
	
	useEffect(() => 
	{
		setClick(click);
	},[click]);
	
	useEffect(() => 
	{
		setLoad(load);
	},[load]);
	
	const SwitchRef = (ref) => {
		navigate(ref);
	}

	
	const onSearch = async () => 
	{
		try{
			if(search){}else{
				setVisible(true);
				setMsgHead('');
				setMsgBody('Search value is required');
				setMsgType('error');
				return;
			}
			setClick(true);	
			const target = utils.USER_URL;
			const header = new Headers({
				'Content-Type': 'application/json',
				'Accept': 'application/json',
				'Authorization': 'Bearer '+authToken,
			});
			const posturl = utils.BASE_URL+target; //utils.POST_URL;
			const localtime = await utils.getTime();
			const stype = search.length <= 2 ? 'miles' : 'id';
			
			let content;
			if(stype === 'miles'){
				content = {'rtype': 'searchdriverbymile', 'miles': search, 'email': sessionemail, 'os': os, 'model': model, 'localtime': localtime};
			}else{
				content = {'rtype': 'searchdriverbyid', 'driverid': search, 'email': sessionemail, 'os': os, 'model': model, 'localtime': localtime};
			}
			const obj = JSON.stringify({...content, 'target': target, 'token': authToken});
			const dataToPost = await utils.cipherEncode(obj);			
			const res = await utils.fetchURL(posturl, 'POST', dataToPost, header);
			const data = await res.json();
			setClick(false);
			if(data.msg === 'Success'){
				setVisible(false);
				setMsgHead('');
				setMsgBody('');
				setSearch('');
				setCouriers(JSON.parse(utils.cipherDecode(data.response)));
			}else{
				window.scrollTo({top: 0, behavior: 'smooth'});
				setVisible(true);
				setMsgHead('');
				setMsgBody(data.msg);
				setMsgType('error');
			}
		}catch(e){
			setVisible(false);
			message.error(e.message);
			setClick(false);
		}		
	}
	
	const assignDriver = async (courierid) => 
	{		
		try{
			setClick(true);
			const email = sessionemail;
			const target = utils.USER_URL;
			const header = new Headers({
				'Content-Type': 'application/json',
				'Accept': 'application/json',
				'Authorization': 'Bearer '+authToken,
			});
			const posturl = utils.BASE_URL+target; //utils.POST_URL;
			const localtime = await utils.getTime();
			const obj = JSON.stringify({'rtype': 'assigndriver', 'courierid': courierid, 'email': email, 'os': os, 'model': model, 'localtime': localtime, 'target': target, 'token': authToken});
			const dataToPost = await utils.cipherEncode(obj);
			const res = await utils.fetchURL(posturl, 'POST', dataToPost, header);
			setClick(false);
			const data = await res.json();			
			if(data.msg === 'Success'){
				window.scrollTo({top: 0, behavior: 'smooth'});
				const response = await JSON.parse(utils.cipherDecode(data.response));
				setVisible(true);
				setMsgHead(`Success`);
				setMsgBody(`Your deliveries will be assign to ${response.firstName} ${response.lastInitial}`);
				setMsgType('success');
				setCouriers([]);
			}else{
				window.scrollTo({top: 0, behavior: 'smooth'});
				setVisible(true);
				setMsgHead('');
				setMsgBody(data.msg);
				setMsgType('error');
			}
		}catch(e){
			setVisible(false);
			setClick(false);
			message.error(e.message);			
		}	
	}
	
	function handleClose(){
		setVisible(false);
	}
	
	const ListOptions = ( listdata, index ) => {		
		return (
		
			<div className="sc-iBYQkv gwipAY" onClick={()=> assignDriver(`${listdata.listdata?.id}`)}>
				<div className=" flex justify-between list-card ">
					<div className="flex justify-between align-center list-card-content">
						<div className="list-icon ">
							<svg width="46" height="49" viewBox="0 0 46 49" fill="none" xmlns="http://www.w3.org/2000/svg">
								<g filter="url(#filter0_d_37786_12291)">
									<rect x="10" y="10" width="26" height="29" rx="4" fill="white"></rect>
									<path d={`m26.07,24.81a2.5,2.5 0 1 1 -5,0a2.5,2.5 0 0 1 5,0z`} fill={utils.getRandomColor()}></path>
								</g>
								<defs>
									<filter id="filter0_d_37786_12291" x="0" y="0" width="46" height="49" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
										<feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
										<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"></feColorMatrix>
										<feOffset></feOffset>
										<feGaussianBlur stdDeviation="5"></feGaussianBlur>
										<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0"></feColorMatrix>
										<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_37786_12291"></feBlend>
										<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_37786_12291" result="shape"></feBlend>
									</filter>
								</defs>
							</svg>
						</div>
						<div className="flex-column list-card-texts">
							<h4 className="color-primary list-card-heading text-left margin-top-0 margin-bottom-0 btn-danger">{`${listdata.listdata?.name}`}</h4>
							<div className="color-black text-xs margin-top-1">
								<div className="text-left">{``}</div>
							</div>
						</div>
					</div>
					<div className="flex align-center arrow-wrap">
						<svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M0 12.5825L1.51406 14L9 7V7V7L1.51406 0L0 1.4175L5.96719 7L0 12.5825Z" fill="#CACACA"></path>
						</svg>
					</div>
				</div>
			</div>
		)
	}
		


    function renderForm() {
        return (
		<>
			<motion.div className="pprogress-bar" style={{ scaleX }} />			
			<div className="el-global--wrapper">
				<div className="el-entry el-page--transition">
					<MainNavbar />
					<div className="el-preview container slide-in-bottom" id="el-dashboard-container">
						<div className="sc-lhlUkk kRzDDN">
							<div className="animated margin-bottom-4 flex justify-between mx-auto align-center c-box-width">
								<div className="cus_title_wrap">
									<h2>{`Find Driver`}</h2>
								</div>
								<div className="">
									
								</div>
							</div>
							<Space className="alertbutton" direction="vertical">
								{visible && (<Alert				
									message={msgHead}
									description={msgBody}
									type={msgType}
									closable 
									afterClose={handleClose}				
								/>)}
							</Space>
							<div className="sc-cabOPr kUdOjq login-card--wrap auth-wrap elAnimated elFadeInUp text-center el-overflow--hidden slide-in-bottom c-box-width c-box">
								<div className="cus_back_button_wrap" style={{margin: "0px 0px"}}>
									<button type="solid" style={{display: "flow-root", marginBottom: 30}} className="cus_back_button" onClick={()=> SwitchRef(`/profile`)}>Back</button>
								</div>
		
            <div                
				style={{
                    backgroundColor: '#f7f7f7',
					background: "rgb(255, 255, 255) none repeat scroll 0% 0%",
					boxShadow: "rgba(0, 0, 0, 0.06) 0px 0px 25px",
					borderRadius: 4,
					padding: "32px 23px",
					margin: "32px 0px",
                }}
            >
					
				<div
					style={{
						marginBottom: 5,
					}}
				>
				
				</div>
				
				{/* Phone */}
				<FormInput
					label="Search by driver ID or miles *"
					placeholder="Driver ID or Miles"
					inputType="input"
					inputId="search"
					onChange={(e) => setSearch(e.target.value)}
					value={search}
					containerStyle={{
						marginTop: 15,
						marginBottom: 15,
						width: '100%',
						textAlign: 'left',
					}}
					inputContainerStyle={{
						backgroundColor: '#ffffff',
						width: '100%'
					}}
					inputLabelStyle={{
						color: formlabel						
					}}
					inputClass={'cus_input'}
					inputStyle={{color: inputlabel}}
				/>
				
				<ul 
					style={{
						color: 'rgb(160, 160, 160)',
						width: '100%',
						fontSize: 14,
					}}
				>
					<li>{`- To search within mile, select values from 1 to 10, or, in case you have a Driver ID, use that instead.`}</li>
				</ul>
				<ul 
					style={{
						color: 'rgb(160, 160, 160)',
						width: '100%',
						fontSize: 14,
						marginTop: 10,
					}}
				>
					<li>{`- If your designated driver is unavailable, your orders will be assigned to another available driver to prevent any delays.`}</li>
				</ul>
				
				
				{/* Button */}
				<FormInput
					label="Search"
					secureTextEntry="text"
					inputType="button"
					inputId="button1"
					onClick={()=> onSearch()}
					containerStyle={{
						marginTop: 5,
						marginBottom: 55,
						width: '100%'
					}}
					inputClass={'cus_button'}
					inputStyle={{}}
				/>
				
				{couriers.map((listdata, index) => (
					<ListOptions listdata={listdata} index={index} key={uuidv4()} /> 
				))}
				
            </div>
									
						</div>
					</div>
					
				</div>
			</div>
		</div>
		<SubFooter />
		</>
        )
    }
	
	if(!load && ellogin === '1')
	{
		return (
			
			<div 
				style={{}}
			>
				{renderForm()}
			</div>
			
		)
	}
	else
	{
		return null
	}
};


export default FindDriver;