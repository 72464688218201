import React,{ useEffect } from "react";
import { UpdateEmail } from '../../components';
import { v4 as uuidv4 } from "uuid";
import { useNavigate, useLocation } from 'react-router-dom';
import { utils } from "../../utils";

const UpdateEmailScreen = ({logPage}) => 
{	
	const navigate = useNavigate();
	const location = useLocation();
	
	useEffect(() => {
		window.document.title = `Update Email | Order Online | ErrandLocal`;
		
	}, []);
	
	useEffect(() => {
		const ref = document.referrer;
    const loc = location.pathname;
		logPage(ref, loc);
	}, []);
	
	useEffect(() => {
		window.document.body.style.overflow = "auto";
	}, []);
	
	useEffect(() => {
		const checkLogin = async() => {
			const sessionemail = await utils.getStorage('__el_data_email');
			const redirect = await utils.checkLogin(sessionemail);
			if((redirect && sessionemail) || redirect){
				navigate(redirect);
			}
		}
		checkLogin();
	}, []);
	
	
  /* 
	function convertAddress(address){
		return address.toLowerCase().replace(/[^0-9a-zA-Z -]/g, '').replace(/ /g, '-').replace(/--/g, '-');
	} */
	
	
	return (
		<>		
			<UpdateEmail key={uuidv4()} />
		</>			
	)
};

export default UpdateEmailScreen;
