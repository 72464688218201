import React, {useState, useEffect} from 'react';
import { Switch, message } from 'antd';
import { GiHamburgerMenu } from 'react-icons/gi';
import { MdOutlineRestaurantMenu } from 'react-icons/md';
import images from '../../constants/images';
import { LoginSignupModal, AddressNavbarModal, CountrySelectorHead } from '../../components';
import { utils } from "../../utils";
import './AddressNavbar.css';

const AddressNavbar = ({sendApp, searchAddress, address, loadCoordinateInfo, closeModal, deliveryInfo, errandInfo, updateErrandData, updateDeliveryData}) => {
	
  const [toggleMenu, setToggleMenu] = useState(false);
  const [width2, setWidth2] = useState(window.innerWidth);
  const [query, setQuery] = useState('');
  const [results, setResults] = useState([]);
  const [fulfillmentType, setFulfillmentType] = useState('');
  const [selectedItem, setSelectedItem] = useState('');
  const [isChecked, setIsChecked] = useState(true);
  const [triggerModal, setTriggerModal] = useState(false);
	
  const sessionemail = utils.getStorage("__el_data_email");
  const ellogin = utils.getStorage("__el_data_login_status");
  
	useEffect(() => {
		handleFulfillment(deliveryInfo?.type);
	}, []);
	
	useEffect(() => {
		setQuery(address);
	}, [address]);
	
	useEffect(() => {
		const initial = async() => {
			const getAddr = await utils.getLocalCookie("elAddr");
			if(!getAddr?.address && sessionemail && ellogin === "1"){
				setTriggerModal(true);
				}else if(getAddr?.address && sessionemail && ellogin === "1"){
				setTriggerModal(false);
				}else if(!getAddr?.address && ellogin !== "1"){				
				setTriggerModal(true);
			}
		}
		initial();
	}, [triggerModal]);
	
	useEffect(() => {
		const initial = async() => {
			const getAddr = await utils.getLocalCookie("elAddr");
			if(getAddr?.address){
				setTriggerModal(false);
			}
		}
		initial();
	}, [closeModal]);
	
	useEffect(() => {
		if(width2 <= 1150){
			document.getElementById('delivery_app__navbar').style.position = 'relative';
		}
	}, [width2]);
  
	useEffect(() => {
		setSelectedItem(selectedItem);
	}, [selectedItem]);
	
	useEffect(() => {
		setResults(results);
	}, [results]);
	
	useEffect(() => {
		const initial = async() => {
			const getAddr = await utils.getLocalCookie("elAddr");
			if(getAddr?.address){
				setQuery(getAddr?.address);
				setSelectedItem(getAddr?.address);
			}
		}
		initial();		
	}, []);
	
	useEffect(() => {
		const handleResize2 = () => {
		  setWidth2(window.innerWidth);
		};
		window.addEventListener("resize", handleResize2);
		
		return () => {
		  window.removeEventListener("resize", handleResize2);
		};
	}, []);
  
  const handleSwitchChange = (checked) => {
		setIsChecked(true);
		setFulfillmentType("Delivery");
		message.info("Sorry, Delivery is the available fulfillment option");
		return;
	};
  
  const handleFulfillment = (fulfillment) => {
		setFulfillmentType(fulfillment);
		const checked = fulfillment === 'Delivery' ? true : false;
		setIsChecked(checked);
	};
	
	
  const handleNewAddress = (val, lat, lng) => {
		setSelectedItem(val);
		setQuery(val);
		setResults([]);
		searchAddress(val);
		loadCoordinateInfo(val, lat, lng);
	};
  
  return (
    <nav className="delivery_app__navbar" id="delivery_app__navbar">
		<div className="delivery_app__navbar-logo">
		<a href="https://errandlocal.com" className=""><img src={images.errandlocal} alt="app__logo" /></a>
		</div>
	  <div className="deliveryAddress">
		<div className="deliveryAddressWrapper">
		<div className="deliveryAddressSuggest">
		<div className="deliveryAddressSuggest">					
		<AddressNavbarModal deliveryInfo={deliveryInfo} errandInfo={errandInfo} updateDeliveryData={updateDeliveryData} updateErrandData={updateErrandData} address={query} setAddress={handleNewAddress} fulfillmentType={fulfillmentType} setFulfillmentType={handleFulfillment} triggerModal={triggerModal} setTriggerModal={setTriggerModal} />
		</div>				
		</div>
		</div>
		<span className="deliveryLocationIcon"><svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M2.91827 10.8571L6.00053 14.0582L9.08469 10.8548C9.82748 10.0834 10.2955 9.40494 10.5836 8.73445C10.869 8.07007 11 7.36199 11 6.5C11 3.62468 8.77557 1.5 6.00019 1.5C3.22439 1.5 1 3.62472 1 6.5C1 7.39126 1.10205 8.05644 1.36482 8.68659C1.63078 9.32439 2.09199 9.99912 2.91827 10.8571ZM6.00056 15.5L2.19792 11.5507C0.445635 9.73122 0 8.44245 0 6.5C0 3.05851 2.68613 0.5 6.00019 0.5C9.31387 0.5 12 3.05851 12 6.5C12 8.44129 11.4046 9.88717 9.80508 11.5484L6.00056 15.5ZM8.5 6.5C8.5 7.88071 7.38071 9 6 9C4.61929 9 3.5 7.88071 3.5 6.5C3.5 5.11929 4.61929 4 6 4C7.38071 4 8.5 5.11929 8.5 6.5ZM6 8C6.82843 8 7.5 7.32843 7.5 6.5C7.5 5.67157 6.82843 5 6 5C5.17157 5 4.5 5.67157 4.5 6.5C4.5 7.32843 5.17157 8 6 8Z" fill="#142138"></path></svg></span>
		
		{query && (<button type="button" className="deliveryClearButton"><span className="deliveryClearIcon"><svg width="12px" height="12px" viewBox="0 0 20 20" version="1.1"><g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd"><g id="Artboard" transform="translate(-114.000000, -118.000000)" fill="#000000" fillRule="nonzero"><g id="Close-Icon--24pt" transform="translate(114.000000, 118.000000)"><path d="M8.58578644,10 L0.807611845,2.22182541 L2.22182541,0.807611845 L10,8.58578644 L17.7781746,0.807611845 L19.1923882,2.22182541 L11.4142136,10 L19.1923882,17.7781746 L17.7781746,19.1923882 L10,11.4142136 L2.22182541,19.1923882 L0.807611845,17.7781746 L8.58578644,10 Z" id="Combined-Shape"></path></g></g></g></svg></span></button>)}
	  </div>
	  
		<div className="delivery_app__navbar-login">
		<a
		style={{borderBottom: 0, textDecoration: 'none', paddingTop: '0.5rem'}}
		>
		<CountrySelectorHead />
		</a>
		<LoginSignupModal myClass="p__opensans"/>
		<div />
		<a href="https://get.errandlocal.com" className="p__opensans">Restaurant</a>
		</div>
		<div className="delivery_app__navbar-smallscreen">
		<GiHamburgerMenu color="#fff" fontSize={27} onClick={() => setToggleMenu(true)} />
		{toggleMenu && (
			<div className="delivery_app__navbar-smallscreen_overlay flex__center slide-bottom">
			<MdOutlineRestaurantMenu fontSize={27} className="overlay__close" onClick={() => setToggleMenu(false)} />			
			<ul className="delivery_app__navbar-smallscreen_links">
			<li><LoginSignupModal/></li>
			<li><a href="https://get.errandlocal.com">For Restaurant</a></li>
			</ul>
			<CountrySelectorHead />
			</div>
		)}
		</div>
    </nav>
	);
};

export default AddressNavbar;
