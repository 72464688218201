import React, { useState, useEffect, useRef } from 'react';
import { utils } from '../../utils';
import { v4 as uuidv4 } from 'uuid';
import { Drawer, Button, Space, Spin, message, Alert, Modal } from 'antd';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';

window['headerName'] = 'Title';
window['visible'] = false;

const OrderModal2 = ({
  cartType,
  setCartSubTotal,
  cartSubTotal,
  refId,
  headqId,
  cartData,
  updateCartData,
  orderCart,
  setOpenOrderModal,
  openOrderModal,
  saveStore,
  savedStores,
  setCheckOutError,
  deliveryInfo,
  shopHours,
  shopStoreName,
  shopStoreCity,
  shopStoreZip,
  shopStoreState,
  updateDeliveryData,
}) => {
  const [open, setOpen] = useState(openOrderModal);
  const [gridData, setGridData] = useState([]);
  const [cartId, setCartId] = useState('');
  const [cartTotal, setCartTotal] = useState(0);
  const [cartQuantity, setCartQuantity] = useState(1);
  const [loading, setLoading] = useState(true);
  const [storeId, setStoreId] = useState('');
  const [hqId, setHqId] = useState('');
  const [itemId, setItemId] = useState('');
  const [itemCurrency, setItemCurrency] = useState('');
  const [msgType, setMsgType] = useState('');
  const [msgBody, setMsgBody] = useState('');
  const [msgHead, setMsgHead] = useState('');
  const cartDivRef = useRef(null);
  const authToken = utils.getStorage('__el_data_login_token');

	return (
    <>
      <Modal
        title={'haddr'}
        open={open}
        closable={true}
        className="ordermodal"
        maskClosable={true}       
        placement="right"
        onClose={()=> setOpen(false)}
        size={`large`}
      >			
            <p>{`Product item is not available at this time. Please try again later.`}</p>
         
      </Modal>
    </>
  );
	
};

export default OrderModal2;
