import React, { useEffect, useState, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import { store } from "./Store";
import { Provider } from "react-redux";
import { HomeScreenUS, HomeScreenGB, HomeScreenNG, HomeScreenCA, TosScreen, PrivacyScreen, StoreScreen, CheckOutScreen, SignInScreen, DashboardScreen, SignUpScreen, AddressListScreen, AddAddressScreen, EditAddressScreen, PastOrderScreen, ErrandAddressListScreen, StoreListScreen, PaymentCardListScreen, FindDriverScreen, ChangePasswordScreen, ForgotPasswordScreen, DecodeScreen, AddCardScreen, TipScreen, PendingOrderScreen, DeliveryCodeScreen, NotFoundScreen, ErrandCheckOutScreen, QRDownloadScreen, MyAccountScreen, UpdateEmailScreen, UpdateNameScreen, CheckOutPaymentScreen, NotAllowedScreen, DriverScreen, MerchantScreen, FaqGroupScreen, FaqScreen, SMSCampaignScreen, OpenCouponScreen } from "./screens";
import { utils, GlobalDebug  } from "./utils";
import { v4 as uuidv4 } from "uuid";
import { Modal, Recorder } from "./components";
import { useNavigate } from "react-router-dom";
import { message, FloatButton } from "antd";
import { ShoppingCartOutlined } from "@ant-design/icons";

const App = () => 
{    
	const navigate = useNavigate();
	const preOrderCart = localStorage.getItem('__cart') ? utils.decryptObject('__cart') : {};
	const preOrderData = localStorage.getItem('__detail') ? utils.decryptObject('__detail') : {};
	const cartOrder =
	typeof preOrderCart === "object" ? preOrderCart : JSON.parse(preOrderCart);
	const orderData =
	typeof preOrderData === "object" ? preOrderData : JSON.parse(preOrderData);
	const [deliveryInfo, setDeliveryInfo] = useState(orderData);	
	const details = localStorage.getItem('__detail') ? localStorage.getItem('__detail') : {};
	const driveObj = localStorage.getItem('__el_drive') ? JSON.parse(utils.cipherDecode(localStorage.getItem('__el_drive'))) : "";
	const driveData = typeof driveObj === "object" ? driveObj : {};
	
	const [drive, setDrive] = useState(driveData);
	const [target, setTarget] = useState('');
	const [startpoint, setStartPoint] = useState('');
	const [mainstage, setMainStage] = useState('');
	const [mstartpoint, setMStartPoint] = useState('');
	const [mstage, setMStage] = useState('');
	const [modalOpen, setModalOpen] = useState(false);	
	const [modalTitle, setModalTitle] = useState('');
	const [modalBody, setModalBody] = useState('');
	const [modalButton, setModalButton] = useState(false);
	const [modalButtonFunc, setModalButtonFunc] = useState();
	const [modalPage, setModalPage] = useState('');	
	// State to store user's country
  const [userCountry, setUserCountry] = useState(false);
  const [uri, setUri] = useState('');
	
  // State to track whether the user is allowed or not
  const [isAllowed, setIsAllowed] = useState(true);
	
  useEffect(() => {			
    // Function to get user's country using geolocation API
    const getUserCountry = async () => {
      try {
				const region = await utils.getLocalCookie('__user_region');
				if(!region && !userCountry){
					const response = await fetch('https://api.errandlocal.com/v1/ip');
					const data = await response.json();
					if(data.success){
						setUserCountry(data.country_code.toUpperCase().trim());
						}else{
						setUserCountry(null);
					}
					}else{
					setUserCountry(region.toUpperCase().trim());
				}
				} catch (error) {
        // Set a default country or handle the error accordingly
        setUserCountry(null);
			}
		};
    // Call the function to get user's country on component mount
    getUserCountry();
	}, []);
	
	useEffect(() => {
		const pathDomain = window.location.pathname.replace('/', '');
		setUri(pathDomain);
	}, []);
	
  useEffect(() => {
		if(userCountry){
			// List of supported countries
			const isPresent = utils.supportedCountries.some(country => country.code === userCountry);
			
			// Check if the user's country is in the supported list
			if (!isPresent && userCountry) {
				setIsAllowed(false);
				}else if (isPresent && userCountry) {
				utils.setLocalCookie('__user_region', userCountry);
				setIsAllowed(true);
			}	
		}
	}, [userCountry]);	
	
	useEffect(() => {
		const getHis = async () => {
			try {
					
				let region = await utils.getLocalCookie('__user_region');
				let redirectUrl = '', finalUrl = '', domain = '', parsedUrl = '', redirectingFromHostname = '';
				
				let currentDomain = window.location.hostname;
				const redirectingFromUrl = document.referrer;
				currentDomain = currentDomain.includes('localhost') ? `localhost:3000` : currentDomain;
				
				const subdomain = ['ca', 'ng', 'gb'];
				const lowerRegion = region ? region.toLowerCase() : 'us';
				
				if (!currentDomain.includes('localhost')) {					
					try {
						parsedUrl = new URL(redirectingFromUrl);
						redirectingFromHostname = parsedUrl.hostname.toLowerCase();
					} catch (urlError) {
						console.log('Error parsing redirectingFromUrl:', urlError.message);
					}
					
					const parts = currentDomain.split('.');
					
					// Check if the current domain has more than two parts
					if (parts.length === 3 && lowerRegion !== parts[0] && !subdomain.includes(lowerRegion) && !subdomain.includes(parts[0])) {
						redirectUrl = `https://${currentDomain}`;
					} else if (parts.length === 3 && lowerRegion !== parts[0] && !subdomain.includes(lowerRegion) && subdomain.includes(parts[0])) {
					currentDomain = parts.slice(-2).join('.');
						redirectUrl = `https://${currentDomain}`;
					} else if (parts.length === 2 && subdomain.includes(lowerRegion)) {
						redirectUrl = `https://${lowerRegion}.${currentDomain}`;
					} else if (parts.length > 2) {
						currentDomain = parts.slice(-2).join('.');
					}
					domain = `${currentDomain}`;
					} else {
					domain = currentDomain;
				}		
				
				if (!region) {
					if (currentDomain === `ca.${domain}`) {
						region = 'CA';
						} else if (currentDomain === `gb.${domain}`) {
						region = 'GB';
						} else if (currentDomain === `ng.${domain}`) {
						region = 'NG';
						} else if (currentDomain === `${domain}`) {
						region = 'US';
					}
					await utils.setLocalCookie('__user_region', region);
				}
				
				switch (region && !redirectUrl) {
					case 'CA':					
					redirectUrl =  currentDomain.includes('localhost') ? `${domain}` : `https://ca.${domain}`;
					break;
					case 'NG':
					redirectUrl =  currentDomain.includes('localhost') ? `${domain}` : `https://ng.${domain}`;
					break;
					case 'GB':
					redirectUrl =  currentDomain.includes('localhost') ? `${domain}` : `https://gb.${domain}`;
					break;
					case 'US':
					redirectUrl = currentDomain.includes('localhost') ? `${domain}` : `https://${domain}`;
					break;
					default:
					// Handle other cases if needed
					break;
				}
				
				if (redirectingFromHostname && redirectUrl && redirectingFromHostname.includes(domain) && redirectingFromHostname !== redirectUrl.replace('https://', '')) {
					finalUrl = uri ? `${redirectUrl}/${uri}` : redirectUrl;
					window.location.href = finalUrl;
				} 
				else if (!redirectingFromHostname && region && redirectUrl) {
					finalUrl = uri ? `${redirectUrl}/${uri}` : redirectUrl;
					window.location.href = finalUrl;
				}
				} catch (error) {
				console.log('Error during redirection:', error.message);
			}
		};
		getHis();
	}, [userCountry, uri]);
	
	useEffect(() => {
		setDeliveryInfo(deliveryInfo);
		(process.env.NODE_ENV === "production" ||
		process.env.REACT_APP_ENV === "STAGING") && GlobalDebug(false);
	}, [deliveryInfo, details]);
	
	useEffect(() => {
		if(!localStorage.getItem('__el_drive')){
			driveLink();
		}
	}, [drive, driveData]);	
	
  useEffect(() => {
		if(cartOrder?.orderPlaced && cartOrder?.orderPlaced.length > 0){
			if(cartOrder?.orderPlaced[0]?.product?.storeId !== deliveryInfo?.storeId){
				const sId = cartOrder?.orderPlaced[0]?.product?.storeId;
				const sName = cartOrder?.orderPlaced[0]?.product?.storeName;
				const sCity = cartOrder?.orderPlaced[0]?.product?.storeCity;
				const sState = cartOrder?.orderPlaced[0]?.product?.storeState;
				const sZip = cartOrder?.orderPlaced[0]?.product?.storeZip;	
				
				const navStore = utils.convertAddress(sName);
				const navCity = utils.convertAddress(sCity);
				const navId = utils.convertAddress(sId);
				const navZip = utils.convertAddress(sZip);
				const navState = utils.convertAddress(sState);
				const navLink = `/${navState}/${navCity}/${navZip}/${navStore}/${navId}`;		
				
				updateDeliveryData({...deliveryInfo, checkOutLink: navLink, storeId: sId, storeName: sName, storeCity: sCity, storeState: sState, storeZip: sZip});				
			}
		}
    
	}, [cartOrder, deliveryInfo]);
	
	
	useEffect(() => 
		{ 		
			if(localStorage.getItem('driverReg')){
				let nstage = localStorage.getItem('driverReg');
				let nstart = localStorage.getItem('driverStart');
				setMainStage(nstage);
				setStartPoint(nstart);
			}
		}, [startpoint, mainstage]);
		
		useEffect(() => 
			{ 
				if(localStorage.getItem('merchantReg')){
					let mstage = localStorage.getItem('merchantReg');
					let mstart = localStorage.getItem('merchantStart');
					setMStage(mstage);
					setMStartPoint(mstart);
				}
			}, [mstartpoint, mstage]);
			
			useEffect(() => 	
				{
					setTarget(target);
					if(!modalOpen){
						window.document.body.style.overflow = '';
					}
				}, [modalOpen, target]);
				
				const logPage = async (ref, loc) => {
					try {			
						const logUrl = utils.USER_REPORT+`?ref=${encodeURIComponent(ref)}&loc=${loc}`;
						const header = {
							'Content-Type': 'image/png'
						};
						const dataToPost = '';
						const res = await utils.fetchURL(logUrl, 'GET', dataToPost, header);
						const data = await res.json();
						
					} catch (e) {}
				};
				
				const genJWT = async() => 
				{
					const authToken = await utils.getStorage('__el_data_login_token');
					return authToken;
				}
				
				const updateDeliveryData = async (data) => {
					try {
						setDeliveryInfo(data);
						//console.log(data);
						//dispatch(setOrderData(JSON.stringify(data)));
					} catch (e) {}
				};
				
				const onPostDriver = async (obj) => 
				{		
					try{
						const authToken = await genJWT();
						const url = utils.DRIVER_SIGNUP_URL;
						const header = {
							'Content-Type': 'application/json',
							'Authorization': 'Bearer '+authToken
						};
						const dataToPost = await utils.cipherEncode(JSON.stringify(obj));
						const res = await utils.fetchURL(url, 'POST', dataToPost, header);
						const data = await res.json();
						
						if(data.msg === 'Success'){
							var state;
							if(obj.data_type === 'signupDriver'){
								state = document.getElementById('status');
								state.style.display = 'block';
								state.className = 'incsizespan_info center show';
								state.innerHTML = "<b>"+data.screen+"</b>";					
								localStorage.setItem('driverReg', data.screen);
								localStorage.setItem('driverStart', data.startpoint);
								localStorage.setItem('driverCountry', data.country);
								setStartPoint(data.startpoint);
								setMainStage(data.screen);
								window.location.reload();
								}else if(obj.data_type === 'signupDriver2'){
								state = document.getElementById('status2');
								state.style.display = 'block';
								state.className = 'incsizespan_info center show';		
								state.innerHTML = "<b>"+data.screen+"</b>";	
								localStorage.setItem('driverReg', data.screen);
								localStorage.setItem('driverStart', '');
								localStorage.setItem('driverCountry', '');
								setStartPoint('');
								setMainStage(data.screen);
							}				
							}else{
							let state;
							if(obj.data_type === 'signupDriver'){
								state = document.getElementById('status');
								state.style.display = 'block';
								state.className = 'cus_error center show';		
								state.innerHTML = "<b>"+data.msg+"</b>";
								}else if(obj.data_type === 'signupDriver2'){
								state = document.getElementById('status2');
								state.style.display = 'block';
								state.className = 'cus_error center show';		
								state.innerHTML = "<b>"+data.msg+"</b>";					
							}
						}
						}catch(e){
						message.error(e.message);
					}
				}
								
				const handlePrint = (imgSrc) => {
					const imageUrl = utils.PARTNER_URL+'getimage/?image='+imgSrc;
					
					// Function to convert image to Base64
					function convertImageToBase64(url, callback) {
						const canvas = document.createElement('canvas');
						const ctx = canvas.getContext('2d');
						const img = new Image();
						img.crossOrigin = 'Anonymous';
						img.src = url;
						img.onload = function() {
							canvas.width = img.width;
							canvas.height = img.height;
							ctx.drawImage(img, 0, 0);
							const base64 = canvas.toDataURL('image/jpeg');
							callback(base64);
						};
					}

					// Convert image to Base64 and print
					convertImageToBase64(imageUrl, function(base64) {
						const printWindow = window.open('', '_blank');
						printWindow.document.write('<html><head><title>Print</title></head><body>');
						printWindow.document.write(`<img src="${base64}" alt="Template Preview" style="max-width: 100%; height: auto;">`);
						printWindow.document.write('</body></html>');
						printWindow.document.close();
						printWindow.print();
					});
				};
						
				const onPostPartnerCoupon = async (obj) => {		
					try{
						const authToken = await genJWT();
						const url = utils.MERCHANT_URL;
						const header = {
							'Content-Type': 'application/json',
							'Authorization': 'Bearer '+authToken
						};
						const dataToPost = await utils.cipherEncode(JSON.stringify(obj));
						const res = await utils.fetchURL(url, 'POST', dataToPost, header);
						const data = await res.json();
						let state = document.getElementById('status');						
						if(data?.msg === 'Success'){
								state.style.display = 'block';
								state.className = 'incsizespan_info center show';		
								state.innerHTML = "<font color='#fff' size='2'>Coupon was registered successfully</font>";
								await handlePrint(data?.data);							
							}else{
								state.style.display = 'block';
								state.className = 'cus_error center show';		
								state.innerHTML = "<b>"+data.msg+"</b>";
								if(data?.data){
									await handlePrint(data?.data);	
								}
						}
						}catch(e){
						message.error(e.message);
					}
				}
				
				const onPostPartnerReg = async (obj) => {		
					try{
						const authToken = await genJWT();
						const url = utils.MERCHANT_URL;
						const header = {
							'Content-Type': 'application/json',
							'Authorization': 'Bearer '+authToken
						};
						const dataToPost = await utils.cipherEncode(JSON.stringify(obj));
						const res = await utils.fetchURL(url, 'POST', dataToPost, header);
						const data = await res.json();						
						if(data.msg === 'Success'){
							message.success('Check email for partner Id');			
							}else{
							message.error(data.msg);
						}
						}catch(e){
						message.error(e.message);
					}
				}
				
				const onPostMerchant = async (obj) => {		
					try{
						const authToken = await genJWT();
						const url = utils.MERCHANT_SIGNUP_URL;
						const header = {
							'Content-Type': 'application/json',
							'Authorization': 'Bearer '+authToken
						};
						const dataToPost = await utils.cipherEncode(JSON.stringify(obj));
						const res = await utils.fetchURL(url, 'POST', dataToPost, header);
						const data = await res.json();
						
						if(data.msg === 'Success'){
							var state;
							if(obj.data_type === 'signupMerchant'){
								state = document.getElementById('status2');
								state.style.display = 'block';
								state.className = 'incsizespan_info center show';		
								state.innerHTML = "<b>"+data.screen+"</b>";					
								localStorage.setItem('merchantReg', data.screen);
								localStorage.setItem('merchantStart', '');
								setMStartPoint('');
								setMStage(data.screen);
							}				
							}else{
							if(obj.data_type === 'signupMerchant'){
								state = document.getElementById('status2');
								state.style.display = 'block';
								state.className = 'cus_error center show';		
								state.innerHTML = "<b>"+data.msg+"</b>";
							}
						}
						}catch(e){
						message.error(e.message);
					}
				}
				
				const refClick = (data) => {		
					setTarget(data);
				}
				
				const driveLink = async () => {
					try{
						let logUrl;
						const authToken = await genJWT();
						logUrl = utils.USER_DRIVE;			
						
						const header = {
							'Content-Type': 'application/json',
							'Authorization': 'Bearer '+authToken
						};
						const dataToPost = "";
						const res = await utils.fetchURL(logUrl, 'GET', dataToPost, header);		
						const data = await res.json();
						//const decodeData = await utils.cipherDecode(data?.info);
						//console.log(decodeData);
						localStorage.setItem('__el_drive', data?.info);
						//setDrive();
						
						}catch(e){
						message.error("OK => "+ e.message);
					}
					
				}
				
				const sendLink = async (obj) => {
					try{
						let url, state = document.getElementById('modal-status');
						const authToken = await genJWT();
						if(modalPage === 'merchant'){
							url = utils.MERCHANT_SIGNUP_URL;
							}else if(modalPage === 'driver'){
							url = utils.DRIVER_SIGNUP_URL;
							}else if(modalPage === 'customer'){
							url = utils.USER_URL;
						}
						
						const header = {
							'Content-Type': 'application/json',
							'Authorization': 'Bearer '+authToken
						};
						const dataToPost = await utils.cipherEncode(JSON.stringify(obj));
						const res = await utils.fetchURL(url, 'POST', dataToPost, header);		
						const data = await res.json();
						
						if(data.msg === 'Success'){
							await setModalOpen(!modalOpen);
							message.success('Download link was sent to your phone');
							}else{				
							state.style.display = 'block';
							state.className = 'cus_error center show';		
							state.innerHTML = '<b>'+data.msg+'</b>';
						}
						}catch(e){
						message.error(e.message);
					}	
				}
				
				const ExecOperation = (type) => {
					
					let linkphonetype;				
					let state = document.getElementById('modal-status');
					let linkphone = document.getElementById('linkphone').value;		
					
					if(type === 'notspecific'){
						if(!document.getElementById('android').checked && !document.getElementById('ios').checked){
							state.style.display = 'block';
							state.className = 'cus_error center show';		
							state.innerHTML = '<b>Choose phone operating system</b>';
							return;
						}
						if(document.getElementById('android').checked){
							linkphonetype = 'android';
							}else if(document.getElementById('ios').checked){
							linkphonetype = 'ios';
							}else {
							return;
						}
						}else{
						if(type){
							linkphonetype = type;
							}else {
							return;
						}
					}
					
					if(!linkphone){
						state.style.display = 'block';
						state.className = 'cus_error center show';		
						state.innerHTML = '<b>Phone number is required</b>';
						return;
					}
					
					var obj = {
						'data_type': 'linkrequest',
						'data_os': linkphonetype,			
						'data_phone': linkphone,
					}
					
					sendLink(obj);	
				}
				
				const sendAppDriver = (app) => {
					setModalPage('driver');
					if(app){	
						setModalTitle('Driver App - '+app);
						setModalBody("<label key='l1'>Phone number *</label><br/><input maxlength=10 type='phone' id='linkphone' placeholder='' class='modal-text-input' name='linkphone'/><br/><br/><div class='card-number' style='outline: none' key='d1'><div id='modal-status' style='display: none' key='d2'></div></div>");
						setModalButton(true);
						setModalButtonFunc(app);
						setModalOpen(!modalOpen);
						window.document.body.style.overflow = 'hidden';
						}else{
						setModalTitle('Driver App');
						setModalBody("<label key='l1'>Choose operating system *</label><br/><br/>Android <input type='radio' id='android' class='modal-radio' name='os'/> | iOS <input type='radio' id='ios' class='modal-radio' name='os'/> <br/><br/><label key='l2'>Phone number *</label><br/><input maxlength=10 type='phone' id='linkphone' class='modal-text-input' name='linkphone'/><br/><br/><div class='card-number' style='outline: none' key='d1'><div id='modal-status' style='display: none' key='d2'></div></div>");
						setModalButton(true);
						setModalButtonFunc('notspecific');
						setModalOpen(!modalOpen);
						window.document.body.style.overflow = 'hidden';
					}
				}
				
				const sendAppMerchant = (app) => {
					setModalPage('merchant');
					if(app){	
						setModalTitle('Merchant App - '+app);
						setModalBody("<label key='l1'>Phone number *</label><br/><input maxlength=10 type='phone' id='linkphone' placeholder='' class='modal-text-input' name='linkphone'/><br/><br/><div class='card-number' style='outline: none' key='d1'><div id='modal-status' style='display: none' key='d2'></div></div>");
						setModalButton(true);
						setModalButtonFunc(app);
						setModalOpen(!modalOpen);
						window.document.body.style.overflow = 'hidden';
						}else{
						setModalTitle('Merchant App');
						setModalBody("<label key='l1'>Choose operating system *</label><br/><br/>Android <input type='radio' id='android' class='modal-radio' name='os'/> | iOS <input type='radio' id='ios' class='modal-radio' name='os'/> <br/><br/><label key='l2'>Phone number *</label><br/><input maxlength=10 type='phone' id='linkphone' class='modal-text-input' name='linkphone'/><br/><br/><div class='card-number' style='outline: none' key='d1'><div id='modal-status' style='display: none' key='d2'></div></div>");
						setModalButton(true);
						setModalButtonFunc('notspecific');
						setModalOpen(!modalOpen);
						window.document.body.style.overflow = 'hidden';
					}
				}
				
				const sendAppCustomer = (app) => {
					setModalPage('customer');
					if(app){	
						setModalTitle('ErrandLocal App - '+app);
						setModalBody("<label key='l1'>Phone number *</label><br/><input maxlength=10 type='phone' id='linkphone' placeholder='' class='modal-text-input' name='linkphone'/><br/><br/><div class='card-number' style='outline: none' key='d1'><div id='modal-status' style='display: none' key='d2'></div></div>");
						setModalButton(true);
						setModalButtonFunc(app);
						setModalOpen(!modalOpen);
						window.document.body.style.overflow = 'hidden';
						}else{
						setModalTitle('ErrandLocal App');
						setModalBody("<label key='l1'>Choose operating system *</label><br/><br/>Android <input type='radio' id='android' class='modal-radio' name='os'/> | iOS <input type='radio' id='ios' class='modal-radio' name='os'/> <br/><br/><label key='l2'>Phone number *</label><br/><input maxlength=10 type='phone' id='linkphone' class='modal-text-input' name='linkphone'/><br/><br/><div class='card-number' style='outline: none' key='d1'><div id='modal-status' style='display: none' key='d2'></div></div>");
						setModalButton(true);
						setModalButtonFunc('notspecific');
						setModalOpen(!modalOpen);
						window.document.body.style.overflow = 'hidden';
					}
				}
				
				const infoDisplay = (title, body) => {		
					const b = body.split('|');
					var mainbody = '';
					for(let i=0; i<b.length; i++){
						mainbody += "<p style='font-size: 18px'>"+b[i]+"</p>";
					}
					setModalPage('');
					setModalTitle(title);
					setModalBody(mainbody);
					setModalButton(false);
					setModalOpen(!modalOpen);
					window.document.body.style.overflow = 'hidden';
				}
				
				
				return (
					<Provider store={store}>
					{isAllowed && userCountry ? (
						<Suspense fallback={<h3>Loading...</h3>}>
						<Routes>
							{userCountry === 'US' && (<Route path="/" element={<HomeScreenUS refClick={refClick} sendApp={sendAppCustomer} infoDisplay={infoDisplay} logPage={logPage}/>} key={`ushome`} />)}
							{userCountry === 'GB' && (<Route path="/" element={<HomeScreenGB refClick={refClick} sendApp={sendAppCustomer} infoDisplay={infoDisplay} logPage={logPage}/>} key={`gbhome`} />)}
							{userCountry === 'NG' && (<Route path="/" element={<HomeScreenNG refClick={refClick} sendApp={sendAppCustomer} infoDisplay={infoDisplay} logPage={logPage}/>} key={`nghome`} />)}
							{userCountry === 'CA' && (<Route path="/" element={<HomeScreenCA refClick={refClick} sendApp={sendAppCustomer} infoDisplay={infoDisplay} logPage={logPage}/>} key={`cahome`} />)}
							<Route path="/signin" element={<SignInScreen logPage={logPage} />} key={`signinscreen`} />				
							<Route path="/signup" element={<SignUpScreen logPage={logPage} />} key={`signupscreen`} />
							<Route path="/forgot-password" element={<ForgotPasswordScreen logPage={logPage} />} key={`forgotpasswordscreen`} />
							<Route path="/dashboard" element={<DashboardScreen logPage={logPage} />} key={`dashboardscreen`} />
							<Route path="/profile" element={<MyAccountScreen logPage={logPage} />} key={`myaccountscreen`} />
							<Route path="/update-name" element={<UpdateNameScreen logPage={logPage} />} key={`updatenamescreen`} />
							<Route path="/update-email" element={<UpdateEmailScreen logPage={logPage} />} key={`updateemailscreen`} />
							<Route path="/errand-addresses" element={<ErrandAddressListScreen logPage={logPage} />} key={`errandaddresslistscreen`} />
							<Route path="/delivery-addresses" element={<AddressListScreen logPage={logPage} />} key={`addresslistscreen`} />
							<Route path="/payments" element={<PaymentCardListScreen logPage={logPage} />} key={`paymentcardlistscreen`} />
							<Route path="/past-orders" element={<PastOrderScreen logPage={logPage} />} key={`pastorderscreen`} />
							<Route path="/pending-orders" element={<PendingOrderScreen logPage={logPage} />} key={`pendingorderscreen`} />
							<Route path="/find-driver" element={<FindDriverScreen logPage={logPage} />} key={`finddriverscreen`} />
							<Route path="/change-password" element={<ChangePasswordScreen logPage={logPage} />} key={`changepasswordscreen`} />
							<Route path="/add-card" element={<AddCardScreen logPage={logPage} />} key={`addcardscreen`} />
							<Route path="/tip/:orderId" element={<TipScreen logPage={logPage} />} key={`tipscreen`} />
							<Route path="/checkoutpayment/:orderId" element={<CheckOutPaymentScreen logPage={logPage} />} key={`checkoutpaymentscreen`} />
							<Route path="/add-new/:typeId" element={<AddAddressScreen logPage={logPage} />} key={`addaddressscreen`} />
							<Route path="/edit-address/:typeId/:addressId" element={<EditAddressScreen logPage={logPage} />} key={`editaddressscreen`} />
							<Route path="/search" element={<StoreListScreen logPage={logPage} />} key={`storelistscreen`} />
							<Route path="/search/:actionTab" element={<StoreListScreen logPage={logPage} />} key={`storelistscreenaction`} />
							<Route path="/shop/:stateId/:cityId/:zipId/:shopName/:shopId" element={<StoreScreen logPage={logPage} />} key={`storescreen`} />
							<Route path="/checkout/:stateId/:cityId/:zipId/:shopName/:shopId" element={<CheckOutScreen logPage={logPage} />} key={`checkoutscreen`} />
							<Route path="/errand/checkout/:shopName" element={<ErrandCheckOutScreen logPage={logPage} />} key={`errandcheckoutscreen`} />
							<Route path="/download/:category/:identifier" element={<QRDownloadScreen logPage={logPage} />} key={`qrdownloadscreen`} />
							<Route path="/app/:category/:identifier" element={<QRDownloadScreen logPage={logPage} />} key={`qrdownloadscreen2`} />
							<Route path="/decode/:encryptedStr" element={<DecodeScreen />} key={`decodescreen`} />
							<Route path="/delivery-code" element={<DeliveryCodeScreen onPostDriver={onPostDriver} refClick={refClick} mainstartpoint={startpoint} mainstage={mainstage} sendApp={sendAppDriver} infoDisplay={infoDisplay} logPage={logPage}/>} key={`deliverycodescreen`} />
							<Route path="/privacy" element={<PrivacyScreen sendApp={sendAppCustomer} infoDisplay={infoDisplay} logPage={logPage}/>} key={`privacyscreen`} />
							<Route path="/tos" element={<TosScreen sendApp={sendAppCustomer} infoDisplay={infoDisplay} logPage={logPage}/>} key={`tosscreen`} />
							<Route path="/faqs" element={<FaqGroupScreen sendApp={sendAppCustomer} infoDisplay={infoDisplay} logPage={logPage} />} key={uuidv4()} />
							<Route path="/faqs/:topic" element={<FaqScreen />} key={uuidv4()} />
							<Route path="/sms-subscribe" element={<SMSCampaignScreen />} key={uuidv4()} />
							<Route path="/open-coupon" element={<OpenCouponScreen onPostPartnerReg={onPostPartnerReg} onPostPartnerCoupon={onPostPartnerCoupon} sendApp={sendAppCustomer} infoDisplay={infoDisplay} logPage={logPage} />} key={uuidv4()} />
							<Route path="/driver" element={<DriverScreen onPostDriver={onPostDriver} refClick={refClick} mainstartpoint={startpoint} mainstage={mainstage} sendApp={sendAppDriver} infoDisplay={infoDisplay} logPage={logPage}/>} key={uuidv4()} />
							<Route path="/merchant" element={<MerchantScreen onPostMerchant={onPostMerchant} refClick={refClick} mstartpoint={mstartpoint} mstage={mstage} sendApp={sendAppMerchant} setModalOpen={setModalOpen} infoDisplay={infoDisplay} logPage={logPage}/>} key={uuidv4()} />	
							<Route path="*" element={<NotFoundScreen sendApp={sendAppCustomer} infoDisplay={infoDisplay} logPage={logPage}/>} key={`notfoundscreen`} />
						</Routes>
						
						{deliveryInfo?.checkOutLink && cartOrder?.orderPlaced?.length > 0 && (<FloatButton className="floatButton" size={`large`} style={{minWidth: 0}} tooltip={<div>Check Out</div>} icon={<ShoppingCartOutlined />} onClick={()=> navigate(`/checkout${deliveryInfo?.checkOutLink}`, {replace: true})}>{cartOrder?.orderPlaced?.length}</FloatButton>)}
						
						{modalOpen && (<Modal setOpenModal={setModalOpen} modalTitle={modalTitle} modalBody={modalBody} modalButton={modalButton} setModalButtonFunc={()=>ExecOperation(modalButtonFunc)} key={`modal`} />)}			
						{/*<Recorder />*/}
						
						</Suspense>
						) : (
						<NotAllowedScreen userCountry={userCountry} setUserCountry={setUserCountry} key={`notallowedscreen`} />			
					)}					
					</Provider>
				);	
				
};

export default App;
