import React, { useEffect, useState } from "react";
import { utils } from "../../utils";
import { v4 as uuidv4 } from "uuid";
import { Drawer, Button, message } from "antd";

const RestaurantRequestModal = () => {

  const [open, setOpen] = useState(false);
  const authToken = utils.getStorage("__el_data_login_token");
  
  useEffect(() => {
	  window.document.body.style.overflow = open ? "hidden" : "";	
  }, [open]);
    

  const handleContinue = async () => {
	try {
	  let name = document.getElementById('rname').value;
	  let address = document.getElementById('raddress').value;
	  let note = document.getElementById('rmessage').value;
      if (!name) {
        message.error("Restaurant name is required!");
		return false;
      }
      if (!address) {
        message.error("Restaurant address is required!");
		return false;
      }
      const localtime = await utils.getTime();
      const obj = {
        rtype: "restaurantrequest",
        name: name,
        address: address,
        note: note,
        localtime: localtime,
      };
	  const target = utils.MERCHANT_URL;
	  const header = new Headers({
		'Content-Type': 'application/json',
		'Accept': 'application/json',
		'Authorization': 'Bearer '+authToken,
	  });
	  const posturl = utils.BASE_URL+target; //utils.POST_URL;
	  let dataToPost = JSON.stringify({...obj, 'target': target, 'token': authToken});
	  dataToPost = await utils.cipherEncode(dataToPost);
	  const res = await utils.fetchURL(posturl, 'POST', dataToPost, header);
	  const data = await res.json();
	
	  if(data?.msg === 'Success')
	  {	
	    message.success('Request was successfully submitted');
		setOpen(false);
	  }else{
		message.error(data?.msg);
	  }	  
	  
    } catch (error) {
      message.error("Error logging in:", error.message);
    }	  
  };

  const handleClose = () => {
	  setOpen(false);
  };

  const getHeader = () => {
      return "Request For Restaurant Listing";
  };

  const getButton = () => {
      return [
        <Button 
			key={uuidv4()}
			className="c__button"
			onClick={() => handleContinue()}
		>
          Submit
        </Button>,
      ];
  };

  return (
	  <>
	    <div>
		 <div key={uuidv4()} style={{color: 'rgb(104, 114, 130)', fontFamily: "Montserrat",fontWeight: 600, fontSize: 16,lineHeight: 3,margin: 0}}>{`Don't See Your Restaurant?`}</div>
		  <span key={uuidv4()} style={{color: 'rgb(104, 114, 130)', fontFamily: "Montserrat",fontWeight: 400, fontSize: 13,margin: 0}}>{`You can either browse through the list of currently available restaurants or ask to include your neighborhood restaurant on ErrandLocal for future orders.`}</span>
		  <div key={uuidv4()} style={{marginTop: 20, cursor: 'pointer'}} onClick={()=> setOpen(true)}>
			<span key={uuidv4()} style={{padding: '7.5px 12.5px',borderRadius:3,border: '1px solid #7f86ac',color: '#1f2960'}}>REQUEST A RESTAURANT</span>
		  </div>		 
	  </div>
      
			<Drawer 
				key={uuidv4()}
				title={getHeader()}
				open={open} 
				footer={getButton()}				
				closable={false}
				maskClosable={true}						
				placement="right"        
				onClose={() => handleClose()}
				size={`large`}
				//extra={getButton()}				
			>
          <div
		    key={uuidv4()}
            style={{ backgroundColor: "rgba(204,220,205,0.2)", padding: 20 }}
          >    
			  <div style={{marginBottom:20}}>
			  <span style={{fontSize: 16}}>Restaurant name *</span>
			  <input
				className="cus_input"
				key={uuidv4()}
				style={{
                  width: "100%",
                }}
				defaultValue=""
				id="rname"
			  />
			  </div>
			  <div style={{marginBottom:20}}>
			  <span style={{fontSize: 16}}>Restaurant address *</span>
			  <input
				className="cus_input"
				key={uuidv4()}
				style={{
                  width: "100%",
                }}
				defaultValue=""
				id="raddress"
			  />
			  </div>
			  <div style={{marginBottom:10}}>
			  <span style={{fontSize: 16}}>Message (If any)</span>
              <textarea
                className="cus_textarea"
				key={uuidv4()}
                style={{
                  width: "100%",
                }}
				defaultValue=""
				id="rmessage"
              />
		      </div>
		  </div>
	  </Drawer>
	</>
  );
};

export default RestaurantRequestModal;
