import React, { useState, useEffect } from "react";
import { motion, useScroll, useSpring } from "framer-motion";
import { utils } from "../../utils";
import { FormInput, MainNavbar, SubFooter } from "../../components";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { Space, message, Alert } from "antd";

const formlabel = "#a0a0a0";
const inputlabel = "#257a80";

const DeliveryCode = () => {
  const { scrollYProgress } = useScroll();
  const scaleX = useSpring(scrollYProgress, {
    stiffness: 100,
    damping: 30,
    restDelta: 0.001,
  });

  const sessionemail = utils.getStorage("__el_data_email");
  const authToken = utils.getStorage("__el_data_login_token");
  const ellogin = utils.getStorage("__el_data_login_status");

  const navigate = useNavigate();
	
  const [myCode, setMyCode] = useState("");
  const [search, setSearch] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [click, setClick] = useState(false);
  const [load, setLoad] = useState(false);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [msgType, setMsgType] = useState("");
  const [msgBody, setMsgBody] = useState("");
  const [msgHead, setMsgHead] = useState("");

  const [os, setOs] = useState("");
  const [model, setModel] = useState("");

  useEffect(() => {
    setOs("");
    setModel("");
    setSearch("");
    setIsOpen(false);
  }, []);

  useEffect(() => {
    setClick(click);
  }, [click]);

  useEffect(() => {
    setLoad(load);
  }, [load]);
	
	useEffect(() => 
	{		
		const getParams = async () => {
			try{
				window.scrollTo({ top: 0, behavior: "smooth" });
				if(sessionemail && !load)
				{
					setLoading(true);
					var paramsFromServer, pcode, dcode;
					
					paramsFromServer = await fetchParams();
					
					dcode = paramsFromServer?.dcode ?? '';
					pcode = dcode ? utils.cipherDecode(dcode) : '';
					setMyCode(pcode);
					setLoading(false);
				
				}
			}catch(e){console.log(e.message)}	
		}		
		getParams();
	}, []);
	

  const SwitchRef = (ref) => {
    navigate(ref);
  };

  const onSearch = async () => {
    try {
      if (search) {
      } else {
        setVisible(true);
        setMsgHead("");
        setMsgBody("Delivery code is required");
        setMsgType("error");
        return;
      }
      setClick(true);
      const target = utils.USER_URL;
      const header = new Headers({
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + authToken,
      });
      const posturl = utils.BASE_URL + target; //utils.POST_URL;
      const localtime = await utils.getTime();
      const stype = search.length <= 2 ? "miles" : "id";

      let content = {
        rtype: "deliverycode",
        code: search,
        email: sessionemail,
        os: os,
        model: model,
        localtime: localtime,
      };

      const obj = JSON.stringify({
        ...content,
        target: target,
        token: authToken,
      });
      const dataToPost = await utils.cipherEncode(obj);
      const res = await utils.fetchURL(posturl, "POST", dataToPost, header);
      const data = await res.json();
      setClick(false);
      if (data.msg === "Success") {
        setVisible(false);
        setMsgHead("");
        setMsgBody("");
        setSearch("");
        setMyCode(`"${search}"`);
        setIsOpen(false);
        window.scrollTo({top: 0, behavior: 'smooth'});
				setTimeout(function(){
					navigate('/dashboard');					
				}, 5000);
      } else {
        window.scrollTo({ top: 0, behavior: "smooth" });
        setVisible(true);
        setMsgHead("");
        setMsgBody(data.msg);
        setMsgType("error");
      }
    } catch (e) {
      setVisible(false);
      message.error(e.message);
      setClick(false);
    }
  };
	
	const fetchParams = async () => {		
		try{
			const target = utils.USER_CALL+'?email='+sessionemail;
			const header = new Headers({
				'Content-Type': 'application/json',
				'Accept': 'application/json',
				'Authorization': 'Bearer '+authToken,
			});
			const posturl = utils.BASE_URL+target; //utils.GET_URL;
			const res = await utils.fetchURL(posturl, 'GET', '', header);
			let data = await res.json();
			if(data?.response){
				data = await utils.cipherDecode(data?.response);
				data = JSON.parse(data);
				data = data?.user;
			}else{
				data = data?.user;
			}		
			return data;
		}catch(e){}
		
	};

  function handleClose() {
    setVisible(false);
  }

  function renderForm() {
    return (
      <>
        <motion.div className="pprogress-bar" style={{ scaleX }} />
        <div className="el-global--wrapper">
          <div className="el-entry el-page--transition">
            <MainNavbar />
            <div
              className="el-preview container slide-in-bottom"
              id="el-dashboard-container"
            >
              <div className="sc-lhlUkk kRzDDN">
                <div
                  className="animated margin-bottom-4 flex justify-between mx-auto align-center c-box-width"
                >
                  <div className="cus_title_wrap">
                    <h2>{`Delivery Code`}</h2>
                  </div>
                  <div className=""></div>
                </div>
                <Space className="alertbutton" direction="vertical">
                  {visible && (
                    <Alert
                      message={msgHead}
                      description={msgBody}
                      type={msgType}
                      closable
                      afterClose={handleClose}
                    />
                  )}
                </Space>
                <div
                  className="sc-cabOPr kUdOjq login-card--wrap auth-wrap elAnimated elFadeInUp text-center el-overflow--hidden slide-in-bottom c-box-width c-box"
                >
                  <div
                    className="cus_back_button_wrap"
                    style={{ margin: "0px 0px" }}
                  >
                    <button
                      type="solid"
                      style={{ display: "flow-root", marginBottom: 30 }}
                      className="cus_back_button"
                      onClick={() => SwitchRef(`/profile`)}
                    >
                      Back
                    </button>
                  </div>									
									

                  {isOpen && (<div
                    style={{
                      backgroundColor: "#f7f7f7",
                      background: "rgb(255, 255, 255) none repeat scroll 0% 0%",
                      boxShadow: "rgba(0, 0, 0, 0.06) 0px 0px 25px",
                      borderRadius: 4,
                      padding: "32px 23px",
                      margin: "32px 0px",
                    }}
                  >
                    <div
                      style={{
                        marginBottom: 5,
                      }}
                    ></div>
										
										<div
                      style={{
                        marginTop: 15,
                        marginBottom: 15,
                      }}
                    >
											<span
                        style={{
                          fontSize: 15,
													color: 'black',
													cursor: 'pointer',
                        }}
												onClick={()=> setIsOpen(false)}
                      >
                        {`Go back? `}
                      </span>
											
											<span
                        style={{
                          fontSize: 15,
													color: 'red',
													cursor: 'pointer',
                        }}
												onClick={()=> setIsOpen(false)}
                      >
                        {` Cancel`}
                      </span>
										
										</div>
										

                    {/* Phone */}
                    <FormInput
                      label="Delivery Code [letters and numbers only]*"
                      placeholder="Length must be 4"
                      inputType="input"
                      inputId="search"
                      onChange={(e) => setSearch(e.target.value.toUpperCase())}
                      value={search}
                      maxLength={4}
                      containerStyle={{
                        marginTop: 15,
                        marginBottom: 15,
                        width: "100%",
                        textAlign: "left",
                      }}
                      inputContainerStyle={{
                        backgroundColor: "#ffffff",
                        width: "100%",
                      }}
                      inputLabelStyle={{
                        color: formlabel,
                      }}
                      inputClass={"cus_input"}
                      inputStyle={{ color: inputlabel }}
                    />
										

                    <div
                      style={{
                        marginTop: 5,
                        borderRadius: 5,
                        paddingHorizontal: 10,
                      }}
                    >
                      <span
                        style={{
                          fontSize: 13,
                        }}
                      >
                        {`By setting up delivery code, you agree to share code with driver within 5 mins of arrival. Check terms and conditions for more information.`}
                      </span>
                    </div>

                    {/* Button */}
                    <FormInput
                      label="Save"
                      secureTextEntry="text"
                      inputType="button"
                      inputId="button1"
                      onClick={() => onSearch()}
                      containerStyle={{
                        marginTop: 5,
                        marginBottom: 55,
                        width: "100%",
                      }}
                      inputClass={"cus_button"}
                      inputStyle={{}}
                    />
										
										
                  </div>
								)}
                
								
									{!isOpen && (<div
                    style={{
                      backgroundColor: "#f7f7f7",
                      background: "rgb(255, 255, 255) none repeat scroll 0% 0%",
                      boxShadow: "rgba(0, 0, 0, 0.06) 0px 0px 25px",
                      borderRadius: 4,
                      padding: "32px 23px",
                      margin: "32px 0px",
                    }}
                  >
									
									{loading && (<div
                      style={{
                        marginBottom: 5,
                      }}
                    >
											<span 
												style={{
													fontWeight: 600, 
													fontSize: 20,
												}}
											>
											{`Loading ...`}
											</span>
										</div>
									)}
                    
									{!loading && (<div
                      style={{
                        marginBottom: 5,
                      }}
                    >
											<h1 
												style={{
													fontWeight: 900, 
													fontSize: 50,
												}}
											>
											{`${myCode}`}
											</h1>
										</div>
									)}
										
										
                  {!loading && myCode && (<div
                      style={{
                        marginBottom: 5,
                      }}
                    >
											<span
                        style={{
                          fontSize: 13,
													color: 'blue',
                        }}
                      >
                        {`Put code at the delivery spot for easy access.`}
                      </span>
										
										</div>
									)}
										
										
                    <div
                      style={{
                        marginTop: 15,
                        marginBottom: 15,
                      }}
                    >
											<span
                        style={{
                          fontSize: 15,
													color: 'black',
													cursor: 'pointer',
                        }}
												onClick={()=> setIsOpen(true)}
                      >
                        {`Set or reset code? `}
                      </span>
											
											<span
                        style={{
                          fontSize: 15,
													color: inputlabel,
													cursor: 'pointer',
                        }}
												onClick={()=> setIsOpen(true)}
                      >
                        {` Change Code`}
                      </span>
										
										</div>

                    <div
                      style={{
                        marginTop: 5,
                        borderRadius: 5,
                        paddingHorizontal: 10,
                      }}
                    >
                      <span
                        style={{
                          fontSize: 13,
                        }}
                      >
                        {`By setting up delivery code, you agree to share code with driver within 5 mins of arrival. Check terms and conditions for more information.`}
                      </span>
                    </div>

                  </div>
								)}
                
								</div>
              </div>
            </div>
          </div>
        </div>
        <SubFooter />
      </>
    );
  }

  if (!load && ellogin === "1") {
    return <div style={{}}>{renderForm()}</div>;
  } else {
    return null;
  }
};

export default DeliveryCode;
