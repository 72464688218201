import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
//import gfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import { Spin } from "antd";
import faqData from '../../faqData';
import './style.css';

function FaqScreen() {
  const { topic } = useParams();
  const [faqContent, setFaqContent] = useState({});
  const [groupName, setGroupName] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [randomTopics, setRandomTopics] = useState([]);
  const [reservedTopics, setReservedTopics] = useState([]);
	const [hoveredIndex, setHoveredIndex] = useState(null);
	const [loading, setLoading] = useState(true);
	
	const setupFaqData = (clickedTopic = null) => {
    //console.log("Topic:", topic);
		//console.log("FAQ Titles:", faqData.map(item => item.title.toLowerCase()));
		const sanitizedTopic = (clickedTopic || topic).replace(/[^\w\s]/g, '').replace(/\s+/g, '').toLowerCase();
		//console.log('Sanitized Topic:', sanitizedTopic);
    const fetchedData = faqData.find(
      (item) => {
        const sanitizedTitle = item.title.replace(/[^\w\s]/g, '').replace(/\s+/g, '').toLowerCase();
        //console.log('Sanitized Title:', sanitizedTitle);
        //console.log('Comparison Result:', sanitizedTitle === sanitizedTopic);
        return sanitizedTitle === sanitizedTopic;
			}
		);		
		
		//console.log("Fetched Data:", fetchedData);
		let filteredGroups = [];		
    if (fetchedData) 
		{
      setFaqContent(fetchedData);
			// Check sanitizedTopic in faqData and filter the groups
			filteredGroups = faqData.filter((item) => {
				const sanitizedTitle = item.group.replace(/[^\w\s]/g, '').replace(/\s+/g, '').toLowerCase();
				return sanitizedTitle.includes(fetchedData.group);
			});
		}else{			
			// Check sanitizedTopic in faqData and filter the groups
			filteredGroups = faqData.filter((item) => {
				const sanitizedTitle = item.group.replace(/[^\w\s]/g, '').replace(/\s+/g, '').toLowerCase();
				return sanitizedTitle.includes(sanitizedTopic);
			});				
		}		
	
		const filteredTopicsMain = filteredGroups
		.filter((item) => {
			const sanitizedTitle = item.title.replace(/[^\w\s]/g, '').replace(/\s+/g, '').toLowerCase();
			return sanitizedTitle !== sanitizedTopic;
		})
		.sort((a, b) => b.priority - a.priority)
		.map((item) => item.title);	
		
		const filteredGroupTopics = filteredGroups
		.filter((item) => {
			const sanitizedTitle = item.title.replace(/[^\w\s]/g, '').replace(/\s+/g, '').toLowerCase();
			return sanitizedTitle !== sanitizedTopic;
		})
		.sort((a, b) => b.priority - a.priority)
		.map((item) => ({
			title: item.title,
			link: `../faqs/${item.title.toLowerCase().replace(/\s/g, '-').replace(/[\s?']/g, '')}`,
		}));
		
		//console.log('filteredGroupTopics:', filteredTopicsMain);
		setFilteredData(filteredTopicsMain);
		
		if(!fetchedData && filteredGroups.length > 0)
		{
			let faqInfo = {};			
			faqInfo.title = ucwords(filteredGroups[0]['group']) + ' Topics';
			faqInfo.info = 'Still need help? Our support are willing to help. Reach out to us.';
			// Update content to be a list of topics
			faqInfo.content = filteredGroupTopics
			.map((topic) => `[${topic.title}](${topic.link})`)
			.join('\n\n');				
			setFaqContent(faqInfo);
		}
				
		if(randomTopics.length < 1 || (randomTopics.length > 0 && filteredGroups.length > 0 && groupName !== filteredGroups[0]['group']))
		{
			// Remove items in filteredGroups from faqData
      const updatedFaqData = faqData.filter((item) => !filteredGroups.includes(item));
      
      const filteredTopics = updatedFaqData
			.filter((item) => {
				const sanitizedTitle = item.title.replace(/[^\w\s]/g, '').replace(/\s+/g, '').toLowerCase();
				return sanitizedTitle !== sanitizedTopic;
			})
			.sort((a, b) => b.priority - a.priority)
			.map((item) => item.title);			
			//console.log(filteredTopics);			
			
      const newRandomTopics = filteredTopics.slice(0, 3);
      const newReservedTopics = filteredTopics.slice(3);
			
			//console.log('newRandomTopics:', newRandomTopics);
			
      setRandomTopics(newRandomTopics);
      setReservedTopics(newReservedTopics);				
		}
		
		//console.log('Filtered Groups:', filteredGroups);
		if(filteredGroups.length > 0)
		{
			setGroupName(filteredGroups[0]['group']);
		}else{
			setGroupName('');
		}
		setTimeout(()=>{
			setLoading(false);
		}, 1000);		
	};
	
  useEffect(() => {
    setupFaqData();
	}, [topic, groupName]);
	
	useEffect(() => {
		const gettitle = `${faqContent?.title || ''}`;
		const settitle = gettitle ? gettitle+' - ' : '';
		document.title = `${settitle}FAQs | ErrandLocal`;		
	}, [faqContent]);
	
	useEffect(() => {
    const handleLoad = () => {
      // The entire page has finished loading
      setLoading(false);
    };
		
		handleLoad();
		
		/*
    // Attach the handleLoad function to the window.onload event
    window.onload = handleLoad;

    // Cleanup: Remove the event listener when the component unmounts
    return () => {
      window.onload = null;
    };
		*/
  }, []);
	
	function ucwords(str) {
			return str.replace(/\b\w/g, function (char) {
			return char.toUpperCase();
		});
	}
	
	const handleMouseOver = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseOut = () => {
    setHoveredIndex(null);
  };
  		
	const handleTopicClick = (clickedTopic) => {
		// Set up FAQ data for the clicked topic
		setupFaqData(clickedTopic);	
	
		// Move the clicked topic to the end of reserved topics
		setReservedTopics((prevReserved) => [...prevReserved, clickedTopic]);
		
		// Remove the first index from reserved topics
		setReservedTopics((prevReserved) => prevReserved.slice(1));
	
		// Filter out the clicked topic from randomTopics
		setRandomTopics((prevRandom) => prevRandom.filter((topic) => topic !== clickedTopic));
		
		if(reservedTopics > 0)
		{
			// Push the first index of the reserved topics to the random topics
			setRandomTopics((prevRandom) => [...prevRandom, reservedTopics[0]]);
		}
	};
	
	const handleGroupTopicClick = (clickedTopic) => {
		// Set up FAQ data for the clicked topic
		setupFaqData(clickedTopic);		
	};
	
	const socialMediaData = [
    { name: "Facebook", handle: "@MyErrandLocal", image: "publicasset=facebook&type=png", url: "https://www.facebook.com/MyErrandLocal" },
    { name: "Twitter", handle: "@errandlocal", image: "publicasset=twitter&type=png", url: "https://twitter.com/errandlocal" },
    { name: "LinkedIn", handle: "errandlocal", image: "publicasset=linkedin&type=png", url: "https://www.linkedin.com/company/errandlocal" },
    { name: "Instagram", handle: "@errandlocal", image: "publicasset=instagram&type=png", url: "https://www.instagram.com/errandlocal" },
    { name: "TikTok", handle: "@errandlocal", image: "publicasset=tiktok&type=png", url: "https://www.tiktok.com/@errandlocal" },
  ];
	
	function Header() {
		return (
			<div className="header">
			{/*<img src="https://elocalcdn.com/?publicasset=icon&type=png" alt="ErrandLocal Cube" className="cube" />*/}
			<img src="https://www.elocalcdn.com/public/assets/icon.png" alt="ErrandLocal Cube" className="cube" />
			<a href="/faqs">
			{/*<img src="https://elocalcdn.com/?publicasset=faqs&type=png" alt="ErrandLocal FAQs"/>*/}
			<img src="https://www.elocalcdn.com/public/assets/faqs.png" alt="ErrandLocal FAQs"/>
			</a>
			</div>
		)
	}
	
	function Footer() {
		return (
			<div className="disclaimer">
			
			{faqContent.author && (<span>
				By <a href="https://errandlocal.com/">{faqContent.author}</a></span>
			)}
			{faqContent.info && (
				<span>{faqContent?.info}</span>
			)}
			</div>
		);
	}
	
	function LinkBar() {
		return (
			<div className="subcontentWrapper">
			<div className="columns is-multiline">        
			<div className="column is-full mb-6">			
			{filteredData.length > 0 && (<div key="div1">
				<h2 className="title is-5 mb-1">{ucwords(groupName)} Topics</h2><br/>
				<ul className="active-ul" key="ul1">
				{filteredData.map((topic) => (
					<li key={`a${topic}`}>
					<Link 
					to={`../faqs/${topic.toLowerCase().replace(/\s/g, '-').replace(/[\s?']/g, '')}`}
					onClick={() => handleGroupTopicClick(topic)}
					>
					{topic}
					</Link>
					</li>
				))}
				</ul><br/></div>
			)}
			
			{randomTopics.length > 0 && (<div key="div2">
				<h2 className="title is-5 mb-1">FAQ Topics</h2><br/>
				<ul className="active-ul" key="ul2">
				{randomTopics.map((topic) => (
					<li key={`b${topic}`}>
					<Link 
					to={`../faqs/${topic.toLowerCase().replace(/\s/g, '-').replace(/[\s?']/g, '')}`}
					onClick={() => handleTopicClick(topic)}
					>
					{topic}
					</Link>
					</li>
				))}
				</ul><br/></div>
			)}
			
			{reservedTopics.length > 0 && (<div key="div3">
				<h3>More Related ...</h3><br/>
				<ul className="related-ul" key="ul3">
				{reservedTopics.map((topic) => (
					<li key={`c${topic}`}>
					<Link 
					to={`../faqs/${topic.toLowerCase().replace(/\s/g, '-').replace(/[\s?']/g, '')}`}
					onClick={() => handleGroupTopicClick(topic)}
					>
					{topic}
					</Link>
					</li>
				))}
				</ul></div>
			)}
			</div>        
			</div>
			</div>
		);
	}
	
	function SocialBar() {
		return (
			<div className="sidebar">
			<p>
			<span className="errandlocal"><a href="https://errandlocal">ErrandLocal</a></span> is a fast errand services serving the DFW area. Reach out to us on our social media handles listed below.
			</p>			
			<ul>
				{socialMediaData.map((socialMedia, index) => (
					<li 
						key={index}						
						onMouseOver={() => handleMouseOver(index)}
						onMouseOut={handleMouseOut}
					>	
						<span className="icon-text">
						<span 
							className="icon" 							
							style={{
								backgroundColor: index === hoveredIndex ? '#e0e0e0' : 'none', ...styles.socialMediaCard
							}}
						><img
							src={`https://elocalcdn.com/?${socialMedia.image}`}
							alt={`${socialMedia.name} Icon`}							
						/></span>
						<a href={socialMedia.url} target="_blank" alt="">{socialMedia.handle}</a></span>
					</li>
				))}			
			</ul>
			</div>
		);
		
	}
	
	function FAQ() {
		return (<div className="contentWrapper">
			<div className="content">
			<h1>{faqContent.title}</h1>
			{faqContent.content && (
				<ReactMarkdown
				//remarkPlugins={[gfm]}
				rehypePlugins={[rehypeRaw]}
				>
				{faqContent.content
					.replace(/(?:\r\n|\r|\n)/g, '<br />')
					.replace(/>\s+“/g, '<blockquote><p>"')
					.replace(/”\s+—/g, '"</p></blockquote> —')
					.replace(/”\s+-/g, '</blockquote> -')
				}
				</ReactMarkdown>
			)}
			<div className="meta">			
			</div>
			</div>
		</div>)
	}
	
	return (
    <div>
      {loading ? (
				<div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#ffffff'}}><Spin size={`large`} style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '25%'}}/></div>
      ) : (
        <div>
					<div className="navbar"></div>
					<div className="container">
					<div className="wrapper">
					<Header />
					<FAQ />
					<LinkBar />
					<SocialBar />
					<Footer />
					</div>
					</div>
				</div>
      )}
    </div>
  );
	
	
}

const styles = {
  container: {
    maxWidth: '800px',
    margin: 'auto',
    padding: '20px',
    textAlign: 'center',
  },
  title: {
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: '20px',
  },
  socialMediaContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    flexWrap: 'wrap',	
  },
  socialMediaCard: {
		display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
		borderRadius: 5,
		borderWidths: 1,
		borderColor: '#000',
		cursor: 'pointer',
		padding: 3,
  },
  socialMediaIcon: {
    width: '1.5rem',
    height: '1.2rem',
    marginBottom: '10px',
  },
  socialMediaHandle: {
    fontSize: '16px',
    fontWeight: 'bold',
  },
};

export default FaqScreen;
