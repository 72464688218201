import React, {useEffect, useState} from "react";
import { motion, useScroll, useSpring } from "framer-motion";
import { utils } from "../../utils";
import { useNavigate, useLocation } from 'react-router-dom';
import { v4 as uuidv4 } from "uuid";
import { Divider, message, Input, Button} from "antd";
import jwt_decode from "jwt-decode";

const ForgotPasswordScreen = ({logPage}) => 
{
	const { scrollYProgress } = useScroll();
	const scaleX = useSpring(scrollYProgress, {
		stiffness: 100,
		damping: 30,
		restDelta: 0.001
	});
	
	const navigate = useNavigate();
	const location = useLocation();
	
	const authToken = "";	
	const [emailCode, setEmailCode] = useState("");
	const [email, setEmail] = useState("");
	const [pass, setPassword] = useState("");
	const [repass, setRePassword] = useState("");
	const [sitePhone, setSitePhone] = useState('');
	
	
	const [recover, setRecover] = useState(false);
	const [checkemail, setCheckemail] = useState(false);
	const [click, setClick] = useState(false);
	const [os, setOs] = useState('');
	const [model, setModel] = useState('');
	
	useEffect(() => 
		{
			setOs(utils.getOs);
			setModel(utils.getBrowser);
		},[]);
		
		useEffect(() => {
			document.title = `Sign Up to ErrandLocal | Order Online | ErrandLocal`;		
		}, []);
		
		useEffect(() => {
			window.document.body.style.overflow = "auto";
		}, []);	
		
		useEffect(() => {
			const ref = document.referrer;
			const loc = location.pathname;
			logPage(ref, loc);
		}, []);
		
		useEffect(() => {		
			const getHis = async() => 
			{		
				const region = await utils.getLocalCookie('__user_region');
				const isPresent = await utils.supportedCountries.find(country => country.code === region);
				
				if(region){
					setSitePhone(isPresent?.phone);
				}
			}
			getHis();
		}, []);
		
		
		const openLink = async (social) => {
			let link = '';
			
			if (social === 'instagram') {
				link = 'https://www.instagram.com/errandlocal/';
				} else if (social === 'twitter') {
				link = 'https://twitter.com/errandlocal';
				} else if (social === 'facebook') {
				link = 'https://www.facebook.com/MyErrandLocal/';
			}
			
			if (link) {
				// Open the link in the device's default browser
				await window.open(link);
				} else {
				console.log('Invalid social media option.');
			}
		};
		
		
		const getAuth = async(thisauth) => 
		{
			try
			{	
				let localEL = "";
				let local_token = "";
				const ed = await utils.getStorage('__el_data_access_token');
				const lt = await utils.getStorage('__el_data_local_token');
				localEL = ed ? ed : '';			
				if(lt)
				{
					local_token = utils.getStorage('__el_data_local_token');
					utils.setStorage('__el_data_local_token', local_token);
				}
				else
				{
					local_token = uuidv4();
					utils.setStorage('__el_data_local_token', local_token);
				}
				let headers = {
					'Content-Type': 'application/json',
					'Accept': 'application/json',
					'x-local-token': local_token,
					'x-auth-token': localEL,
				};
				if(thisauth){
					headers['x-auth-data'] = thisauth;
				}
				
				const target = utils.AUTH_URL;
				const header = new Headers(headers);
				const posturl = utils.BASE_URL+target;			
				
				const dataToPost = JSON.stringify({'rtype': 'auth'});
				const res = await utils.fetchURL(posturl, 'POST', dataToPost, header);
				const data = await res.json();
				let obj = JSON.parse(JSON.stringify(data));
				let objData = obj?.data;
				
				if(objData){
					await utils.setStorage('__el_data_access_token', objData.auth.access_token);
					await utils.setStorage('__el_data_login_status', objData.credentials.el_login);
					await utils.setStorage('__el_data_email', objData.credentials.email);
					await utils.setStorage('__el_data_first_name', objData.credentials.firstname);
					await utils.setStorage('__el_data_last_name', objData.credentials.lastname);		
					}else{
					message.error(data?.msg);
				}
			}catch(e){}
		}  
		
		
		const onForgotPass = async () => 
		{
			try
			{
				if(!recover && !checkemail)
				{
					if(!email){message.error('Email is required!'); return;}
					setClick(true);			
					
					if(utils.isValidEmail(email))
					{				
						const localtime = await utils.getTime();
						const target = utils.USER_URL;
						const header = new Headers({
							'Content-Type': 'application/json',
							'Accept': 'application/json',
							'Authorization': 'Bearer '+authToken,
						});
						const posturl = utils.BASE_URL+utils.USER_URL; //utils.POST_URL;
						const obj = JSON.stringify({'rtype': 'forgetpass', 'email': email, 'os': os, 'model': model, 'localtime': localtime, 'target': target, 'token': authToken});
						const dataToPost = await utils.cipherEncode(obj);			
						const res = await utils.fetchURL(posturl, 'POST', dataToPost, header);
						const data = await res.json();
						setClick(false);
						
						if(data?.msg === 'Success')
						{	
							message.info('Check email inbox/spam for recovery code');
							setCheckemail(true);
						}
						else
						{	
							setClick(true);
							message.error(data.msg);
						}								
					}
					else
					{				
						setClick(false);
						message.error('Please enter a valid email');
					}
				}
				else if(!recover && checkemail)
				{
					if(!email){message.error('Email is required!'); return;}
					if(!emailCode){message.error('Recovery code is required!'); return;}
					setClick(true);	
					
					if(utils.isValidEmail(email))
					{				
						const localtime = await utils.getTime();
						const target = utils.USER_URL;
						const header = new Headers({
							'Content-Type': 'application/json',
							'Accept': 'application/json',
							'Authorization': 'Bearer '+authToken,
						});
						const posturl = utils.BASE_URL+utils.USER_URL; //utils.POST_URL;
						const obj = JSON.stringify({'rtype': 'resetpass', 'email': email, 'code': emailCode, 'os': os, 'model': model, 'localtime': localtime, 'target': target, 'token': authToken});
						const dataToPost = await utils.cipherEncode(obj);
						const res = await utils.fetchURL(posturl, 'POST', dataToPost, header);
						const data = await res.json();
						setClick(false);
						
						if(data?.msg === 'Success')
						{			
							message.info('Set new password');
							setRecover(true);
						}
						else
						{
							setClick(true);
							message.error(data.msg);
						}								
					}
					else
					{				
						setClick(false);
						message.error('Please enter a valid email');
					}
				}
				else if(recover && checkemail)
				{
					if(!pass){message.error('New password is required!'); return;}
					if(!repass){message.error('Please confirm password!'); return;}
					setClick(true);	
					
					if(utils.isValidEmail(email))
					{				
						const localtime = await utils.getTime();
						const target = utils.USER_URL;
						const header = new Headers({
							'Content-Type': 'application/json',
							'Accept': 'application/json',
							'Authorization': 'Bearer '+authToken,
						});
						const posturl = utils.BASE_URL+utils.USER_URL; //utils.POST_URL;
						const obj = JSON.stringify({'rtype': 'newpass', 'email': email, 'pass': pass, 'pass2': repass, 'os': os, 'model': model, 'localtime': localtime, 'target': target, 'token': authToken});
						const dataToPost = await utils.cipherEncode(obj);			
						const res = await utils.fetchURL(posturl, 'POST', dataToPost, header);
						const data = await res.json();
						setClick(false);
						
						if(data?.msg === 'Success')
						{			
							if(data?.screen === 'HomeScreen')
							{
								let thisauth = jwt_decode(data.token);
								await utils.setStorage('__el_data_login_token', data?.token);
								thisauth = await utils.base64Encode(JSON.stringify(thisauth));					
								await getAuth(thisauth);
								await navigate('/search', {user: email});		
							}
							else
							{
								setClick(false);
						    message.error(data.msg);
							}
						}
						else
						{
							setClick(false);
							message.error(data.msg);
						}								
					}
					else
					{				
						setClick(false);
						message.error('Please enter a valid email');
					}
				}	
			}
			catch(e)
			{
				setClick(false);
				message.error(e.message);
			}		
		}	
		
		const handleNavigation = (href) => {
			navigate(href);
		}
		
		return (
			<>
			<motion.div className="pprogress-bar" style={{ scaleX }} />		
			<div className="min-h-screen w-full">
			<div className="min-h-screen w-full grid grid-cols-1 md:grid-cols-11 bg-white">
			<div
			className="banner h-full flex flex-col justify-between items-center md:col-span-5 lg:col-span-6 px-5 py-20"
			>
			<div></div>
			<div className="max-w-md space-y-8 text-white pl-5">
			<svg width="50" height="50" xmlns="http://www.w3.org/2000/svg">
			<g>
			<circle cx="25.5" cy="25.5" fill="#ffffff" id="svg_1" r="23.27"/>
			<g id="svg_2">
			<path d="m22.57,12l-5.39,17.9s-0.69,2.75 0.6,4.36c1.28,1.61 3.33,2.21 5.04,2.21l11.7,0l1.62,-5l-8.92,0c-1.41,0 -1.96,-1.14 -1.58,-2.5c0.38,-1.35 0.99,-2.75 0.99,-2.75l11.1,0l-15.16,-14.22z" fill="#0ecb81" id="svg_4" strokeWidth="12"/>
			
			</g>
			</g>
			</svg>
			
			<h4
			className="elTypography-root elTypography-h4 elTypography-colorInherit elTypography-gutterBottom"
			>
			Sign up seamlessly
			</h4>
			<p
			className="elTypography-root elTypography-body1 elTypography-colorInherit"
			>
			Open an ErrandLocal account to start ordering from the best restaurants in your area.
			</p>
			<button
			className="elButtonBase-root elButton-root elButton-outlined jss14 jss15 elButton-outlinedPrimary"
			tabIndex="0"
			type="button"
			onClick={()=> handleNavigation('/signup')}
			>
			<span className="elButton-label">Get Started</span
			><span className="elTouchRipple-root"></span>
			</button>
			</div>
			
			<div className="flex space-x-6 mx-auto w-full max-w-md pl-5">
			<button
			className="elButtonBase-root elIconButton-root"
			tabIndex="0"
			type="button"
			title="INSTAGRAM"
			onClick={()=> openLink('instagram')}
			>
			<span className="elIconButton-label"
			><svg
			className="elSvgIcon-root elSvgIcon-fontSizeLarge"
			focusable="false"
			viewBox="0 0 24 24"
			aria-hidden="true"
			>
			<svg
			viewBox="0 0 32 32"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			>
			<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M16 32C26.5882 32 32 24.9137 32 16C32 7.04179 26.5882 0 16 0C5.36471 0 0 7.04179 0 16C0 24.9137 5.36471 32 16 32ZM20.1 7.6C19 7.5 18.7 7.5 16 7.5C13.3 7.5 13 7.5 11.9 7.6C9 7.7 7.6 9.1 7.5 12C7.4 13.1 7.4 13.4 7.4 16.1C7.4 17.7785 7.43865 18.5681 7.46789 19.1656C7.48569 19.5292 7.5 19.8217 7.5 20.2C7.6 23.1 9 24.5 11.9 24.6C13 24.7 13.3 24.7 16 24.7C17.6785 24.7 18.4681 24.6614 19.0656 24.6321L19.0657 24.6321C19.4293 24.6143 19.7217 24.6 20.1 24.6C23 24.5 24.4 23.1 24.5 20.2C24.6 19.1 24.6 18.8 24.6 16.1C24.6 14.4215 24.5614 13.6319 24.5321 13.0344L24.5321 13.0343C24.5143 12.6707 24.5 12.3783 24.5 12C24.4 9.1 23 7.7 20.1 7.6ZM11.9 6.1C12.9 6 13.3 6 16 6C18.7 6 19.1 6 20.1 6.1C23.7 6.3 25.7 8.3 25.9 11.9C26 12.9 26 13.3 26 16C26 17.6481 25.9627 18.4019 25.9337 18.9894C25.9152 19.3643 25.9 19.6714 25.9 20.1C25.8 23.7 23.7 25.7 20.1 25.9C19.1 26 18.7 26 16 26C14.3519 26 13.5981 25.9627 13.0106 25.9337C12.6357 25.9152 12.3286 25.9 11.9 25.9C8.3 25.8 6.3 23.7 6.1 20.1C6 19.1 6 18.7 6 16C6 13.3 6 12.9 6.1 11.9C6.3 8.3 8.3 6.3 11.9 6.1ZM19.6 16C19.6 14 18 12.4 16 12.4C14 12.4 12.4 14 12.4 16C12.4 18 14 19.6 16 19.6C18 19.6 19.6 18 19.6 16ZM10.9 16C10.9 13.2 13.2 10.9 16 10.9C18.8 10.9 21.1 13.2 21.1 16C21.1 18.8 18.8 21.1 16 21.1C13.2 21.1 10.9 18.8 10.9 16ZM21.3 11.9C21.9627 11.9 22.5 11.3627 22.5 10.7C22.5 10.0373 21.9627 9.5 21.3 9.5C20.6372 9.5 20.1 10.0373 20.1 10.7C20.1 11.3627 20.6372 11.9 21.3 11.9Z"
			fill="white"
			></path>
			</svg></svg></span
			><span className="elTouchRipple-root"></span></button
			><button
			className="elButtonBase-root elIconButton-root"
			tabIndex="0"
			type="button"
			title="FACEBOOK"
			onClick={()=> openLink('facebook')}
			>
			<span className="elIconButton-label"
			><svg
			className="elSvgIcon-root elSvgIcon-fontSizeLarge"
			focusable="false"
			viewBox="0 0 24 24"
			aria-hidden="true"
			>
			<svg
			viewBox="0 0 32 32"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			>
			<path
			d="M16.0001 0.0666199C7.16358 0.0666199 0 7.2302 0 16.0667C0 23.992 5.76835 30.5555 13.3316 31.8265V19.4048H9.47191V14.9347H13.3316V11.6386C13.3316 7.81425 15.6674 5.73018 19.0795 5.73018C20.7137 5.73018 22.1181 5.85195 22.5258 5.90559V9.90308L20.1593 9.90423C18.304 9.90423 17.9464 10.7856 17.9464 12.0795V14.9324H22.3731L21.7957 19.4025H17.9464V31.9334C25.8627 30.9699 32 24.2392 32 16.0621C32 7.2302 24.8364 0.0666199 16.0001 0.0666199Z"
			fill="white"
			></path>
			</svg></svg></span
			><span className="elTouchRipple-root"></span></button
			><button
			className="elButtonBase-root elIconButton-root"
			tabIndex="0"
			type="button"
			title="TWITTER"
			onClick={()=> openLink('twitter')}
			>
			<span className="elIconButton-label"
			><svg
			className="elSvgIcon-root elSvgIcon-fontSizeLarge"
			focusable="false"
			viewBox="0 0 24 24"
			aria-hidden="true"
			>
			<svg
			viewBox="0 0 32 32"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			>
			<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M16 32C7.16346 32 5.82897e-06 24.8366 3.75509e-06 16C0 7.16345 7.16345 3.05327e-06 16 1.96695e-06C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32ZM24.6434 10.5628C25.4734 10.4639 26.2641 10.244 27 9.91855C26.4499 10.739 25.7543 11.4596 24.9525 12.0365C24.9603 12.212 24.9644 12.3884 24.9644 12.5658C24.9644 17.9727 20.8372 24.2073 13.2899 24.2073C10.9727 24.2073 8.81587 23.5299 7.00001 22.3689C7.32103 22.4069 7.64763 22.4262 7.97884 22.4262C9.9013 22.4262 11.6706 21.7722 13.0748 20.6747C11.2793 20.6418 9.76399 19.4587 9.24181 17.8332C9.49239 17.881 9.74942 17.9068 10.0138 17.9068C10.3881 17.9068 10.7506 17.8566 11.0949 17.7631C9.21782 17.3872 7.80341 15.7335 7.80341 13.7511C7.80341 13.7339 7.80341 13.7167 7.8038 13.6997C8.35697 14.0062 8.98966 14.1902 9.66227 14.2115C8.56126 13.4777 7.83689 12.2254 7.83689 10.8058C7.83689 10.056 8.03927 9.35313 8.39247 8.74884C10.4162 11.2243 13.4397 12.8532 16.8498 13.0239C16.7799 12.7244 16.7436 12.4121 16.7436 12.0914C16.7436 9.83185 18.5807 8 20.8468 8C22.027 8 23.0934 8.49683 23.8419 9.29202C24.7765 9.10856 25.6546 8.76808 26.4475 8.29915C26.1411 9.25463 25.4905 10.0564 24.6434 10.5628Z"
			fill="white"
			></path>
			</svg></svg></span
			><span className="elTouchRipple-root"></span>
			</button>
			</div>
			</div>
			<div
			className="bg-white h-full flex flex-col justify-between md:col-span-6 lg:col-span-5 items-center px-10 py-20 box-shadow"
			>
			<div
			className="h-full flex flex-col justify-between max-w-sm w-full items-center"
			>
			<span
			className="elButtonBase-root elButton-root elButton-text jss9 jss8 underline elButton-textPrimary"
			tabIndex="0"
			aria-disabled="false"
			onClick={()=> handleNavigation("/")}
			><span className="elButton-label">Go to HOME page</span
			><span className="elTouchRipple-root"></span
			></span>
			<div className="space-y-8 w-full">
			<h1 className="elTypography-root elTypography-h5">
			Recover Password!<span
			className="block w-10 h-1 bg-el-dark-red mt-3"
			></span>
			</h1>
			<div className="space-y-8" autoComplete="off">
			{((checkemail && !recover) || !checkemail) && (<div
				className="elFormControl-root elFormControl-fullWidth"
				>
				<label
				className="elFormLabel-root elInputLabel-root jss11 elInputLabel-formControl elInputLabel-animated elInputLabel-shrink"
				data-shrink="true"
				htmlFor="email"
				>Email Address</label
				>
				<div
				className="elInputBase-root jss12 elInputBase-formControl"
				>
				<input
				aria-invalid="false"
				autoComplete="off"
				id="email"
				type="email"
				className="elInputBase-input"
				onChange={(e) => setEmail(e.target.value)}
				value={email}
				/>
				</div>
			</div>)}
			
			{!recover && checkemail && (<div
				className="elFormControl-root elFormControl-fullWidth"
				>
				<label
				className="elFormLabel-root elInputLabel-root jss11 elInputLabel-formControl elInputLabel-animated elInputLabel-shrink"
				data-shrink="true"
				htmlFor="code"
				>Recovery Code</label
				>
				<div
				className="elInputBase-root jss12 elInputBase-formControl"
				>
				<input
				aria-invalid="false"
				autoComplete="off"
				id="code"
				type="text"
				className="elInputBase-input"
				onChange={(e) => setEmailCode(e.target.value)}
				value={emailCode}
				/>
				</div>
			</div>)}								
			
			{recover && (<>
				<div
				className="elFormControl-root elFormControl-fullWidth"
				>
				<label
				className="elFormLabel-root elInputLabel-root jss11 elInputLabel-formControl elInputLabel-animated elInputLabel-shrink"
				data-shrink="true"
				htmlFor="password"
				>Password</label
				>
				<div
				className="elInputBase-root jss12 password-field elInputBase-formControl elInputBase-adornedEnd"
				>
				<Input.Password
				aria-invalid="false"
				autoComplete="off"
				id="password"
				type="password"				
				className="elInputBase-input elInputBase-inputAdornedEnd"
				onChange={(e) => setPassword(e.target.value)}
				value={pass}
				/>
				
				</div>
				</div>
				<div
				className="elFormControl-root elFormControl-fullWidth"
				>
				<label
				className="elFormLabel-root elInputLabel-root jss11 elInputLabel-formControl elInputLabel-animated elInputLabel-shrink"
				data-shrink="true"
				htmlFor="repassword"
				>Confirm Password</label
				>
				<div
				className="elInputBase-root jss12 password-field elInputBase-formControl elInputBase-adornedEnd"
				>
				<Input.Password
				aria-invalid="false"
				autoComplete="off"
				id="repass"
				type="password"				
				className="elInputBase-input elInputBase-inputAdornedEnd"
				onChange={(e) => setRePassword(e.target.value)}
				value={repass}
				/>
				
				</div>
				</div>
				</>
			)}
			
			<label
			className="elFormLabel-root elInputLabel-root jss11 elInputLabel-formControl elInputLabel-animated elInputLabel-shrink"
			data-shrink="true"
			>Already have account?<span
			className="text-el-red font-semibold"
			onClick={()=> handleNavigation("/signin")}
			style={{cursor: 'pointer'}}
			>Sign in here</span
			></label
			>
			<Button onClick={()=> !click && onForgotPass()} loading={click} type={`primary`} size={`large`} style={{backgroundColor: 'var(--color-primary)', width: '100%', fontSize: 20, fontFamily: 'Montserrat'}}>SUBMIT</Button>
			</div>
			</div>
			<Divider />
			
			<div className="flex justify-between w-full">
			<a
			className="elButtonBase-root elButton-root elButton-text jss9 jss8 underline elButton-textPrimary"
			tabIndex="0"
			aria-disabled="false"
			target="_blank"
			rel="noreferrer"
			href={`mailto:${utils.supportemail}`}
			><span className="elButton-label">{utils.supportemail}</span
			><span className="elTouchRipple-root"></span></a
			><a
			className="elButtonBase-root elButton-root elButton-text jss9 jss8 underline elButton-textPrimary"
			tabIndex="0"
			aria-disabled="false"
			target="_blank"
			rel="noreferrer"
			href={`tel:${sitePhone}`}
			><span className="elButton-label">{utils.convertToUSPhoneFormat(sitePhone)}</span
			><span className="elTouchRipple-root"></span
			></a>
			</div>
			</div>
			</div>
			</div>
			</div>
			</>
		)
		
}

export default ForgotPasswordScreen;
