import React, { useEffect, useState } from "react";
import { utils } from "../../utils";
import { MapUpdate } from '../../components';
import { v4 as uuidv4 } from "uuid";
import { Drawer, Button, Form, Select } from "antd";

const MapModal = ({  
  addressModalOpen,
  setAddressModalOpen,
  gps,
  addressId,
  address,
  setMarker,
  marker,
  updateMarker,
}) => {
	
  const [open, setOpen] = useState(addressModalOpen);
  const [lat, setLat] = useState(0);
  const [lng, setLng] = useState(0);
  const [load, setLoad] = useState(false);
  
  useEffect(() => {
    window.document.body.style.overflow = open ? "hidden" : "";	
  }, [open]);
  
  useEffect(() => {	
	setLat(parseFloat(gps?.latitude));
	setLng(parseFloat(gps?.longitude));
  }, []);
  
  useEffect(() => {
    if(lat){
		setLoad(true);
	}
  }, [lat]);
  
  
  const closeAddressModal = async () => {
    await setAddressModalOpen(false);
    await setOpen(false);
  };

   
  
  const getHeader = () => {   
      return "Adjust Address Pin";
  };

  const getButton = () => {
    
      return [
        <Button
          key={uuidv4()}
          className="c__button"
          onClick={() => closeAddressModal()}
        >
          Cancel
        </Button>,
        <Button
          key={uuidv4()}
          className="c__button"
          onClick={() => updateMarker()}
        >
          Update
        </Button>,
      ];
    
  };

  return (
    <>

			<Drawer
				title={getHeader()}
				open={addressModalOpen}
				closable={false}
				maskClosable={true}
				footer={getButton()}							
				placement="right"        
				onClose={() => closeAddressModal()}
				size={`large`}
			>
		  <>
			{lat !== 0 && load && (<div className="mapupdate"><MapUpdate lat={lat} lng={lng} height={300} width={'100%'} setMarker={setMarker} /></div>)}	
			<button
              type="button"
              style={{
                border: "none",
                paddingLeft: 0,
                marginLeft: 0,
                minWidth: 0,
				cursor: "default"
              }}
            >
              <span style={{ color: "#257a80", fontWeight: 600, fontSize: 18 }}>
				  {address}
              </span>
            </button>
		  </>
	  </Drawer>
    </>
  );
};

export default MapModal;
