import React, { useState, useEffect } from 'react';
import { motion, useScroll, useSpring } from "framer-motion";
import { utils } from "../../utils";
import { FormInput, MainNavbar, SubFooter } from "../../components";
import { useNavigate } from 'react-router-dom';
import { Space, message, Alert} from "antd";

const formlabel = "#a0a0a0";
const inputlabel = "#257a80";

const ChangePassword = () => 
{	
	const { scrollYProgress } = useScroll();
	const scaleX = useSpring(scrollYProgress, {
		stiffness: 100,
		damping: 30,
		restDelta: 0.001
	});
	
	const sessionemail = utils.getStorage('__el_data_email');
    const authToken = utils.getStorage('__el_data_login_token');
    const ellogin = utils.getStorage('__el_data_login_status');
    const navigate = useNavigate();
	
    const [current, setCurrent] = useState('');
    const [newpass, setNewPass] = useState('');
    const [renewpass, setRenewPass] = useState('');
    const [email, setEmail] = useState(sessionemail);
    
	const [click, setClick] = useState(false);
	const [load, setLoad] = useState(false);	
	const [visible, setVisible] = useState(false);	
	const [msgType, setMsgType] = useState('');	
	const [msgBody, setMsgBody] = useState('');	
	const [msgHead, setMsgHead] = useState('');	
	const [os, setOs] = useState('');
	const [model, setModel] = useState('');
	
	
	useEffect(() => 
	{
		setOs('');
		setModel('');
	},[]);
	
	useEffect(() => 
	{
		setMsgHead(msgHead);
	},[msgHead]);
	
	useEffect(() => 
	{
		setEmail(email);
	},[email]);
	
	useEffect(() => 
	{
		setLoad(load);
	},[load]);
	
	useEffect(() => 
	{
		setClick(click);
	},[click]);
	
	const SwitchRef = (ref) => {
		navigate(ref);
	}
	
	const onChangePassword = async () => 
	{
		try{
			if(current && newpass && renewpass){}else{
				setVisible(true);
				setMsgBody('Please check all fields');
				setMsgType('error');
				window.scrollTo({top: 0, behavior: 'smooth'});
				return;
			}
			setClick(true);	
			const target = utils.USER_URL;
			const header = new Headers({
				'Content-Type': 'application/json',
				'Accept': 'application/json',
				'Authorization': 'Bearer '+authToken,
			});
			const posturl = utils.BASE_URL+target; //utils.POST_URL;
			const localtime = await utils.getTime();
			
			const obj = JSON.stringify({'rtype': 'changepass', 'email': email, 'pass': current, 'pass2': newpass, 'pass3': renewpass, 'os': os, 'model': model, 'localtime': localtime, 'target': target, 'token': authToken});
			const dataToPost = await utils.cipherEncode(obj);			
			const res = await utils.fetchURL(posturl, 'POST', dataToPost, header);
			const data = await res.json();
			setClick(false);			
			
			if(data.msg === 'Success'){
				setVisible(true);
				setMsgBody('Password was updated successfully');
				setMsgType('success');
				window.scrollTo({top: 0, behavior: 'smooth'});
			}else{
				setVisible(true);
				setMsgBody(data.msg);
				setMsgType('error');
				window.scrollTo({top: 0, behavior: 'smooth'});
			}
		}catch(e){
			message.error(e.message);
			setClick(false);
		}		
	}
	
	
	function handleClose(){
		setVisible(false);
	}


    function renderForm() {
        return (
		<>
			<motion.div className="pprogress-bar" style={{ scaleX }} />			
			<div className="el-global--wrapper">
				<div className="el-entry el-page--transition">
					<MainNavbar />
					<div className="el-preview container slide-in-bottom" id="el-dashboard-container">
						<div className="sc-lhlUkk kRzDDN">
							<div className="animated margin-bottom-4 flex justify-between mx-auto align-center c-box-width">
								<div className="cus_title_wrap">
									<h2>{`Change Password`}</h2>
								</div>
								<div className="">
									
								</div>
							</div>
							<Space className="alertbutton" direction="vertical">
								{visible && (<Alert				
									message={msgHead}
									description={msgBody}
									type={msgType}
									closable 
									afterClose={handleClose}				
								/>)}
							</Space>
							<div className="sc-cabOPr kUdOjq login-card--wrap auth-wrap elAnimated elFadeInUp text-center el-overflow--hidden slide-in-bottom c-box-width c-box">
								<div className="cus_back_button_wrap" style={{margin: "0px 0px"}}>
									<button type="solid" style={{display: "flow-root", marginBottom: 30}} className="cus_back_button" onClick={()=> SwitchRef(`/profile`)}>Back</button>
								</div>
								
		
            <div                
				style={{
                    backgroundColor: '#f7f7f7',
					background: "rgb(255, 255, 255) none repeat scroll 0% 0%",
					boxShadow: "rgba(0, 0, 0, 0.06) 0px 0px 25px",
					borderRadius: 4,
					padding: "32px 23px",
					margin: "32px 0px",
                }}
            >
					
				<div
					style={{
						marginBottom: 5,
					}}
				>
				
				</div>
				
				{/* Password */}
				<FormInput
					label="Current Password *"
					placeholder="Current Password"
					secureTextEntry="password"
					inputType="input"
					inputId="current"
					onChange={(e) => setCurrent(e.target.value)}
					value={current}
					containerStyle={{
						marginTop: 15,
						marginBottom: 15,
						width: '100%',
						textAlign: 'left',
					}}
					inputContainerStyle={{
						backgroundColor: '#ffffff',
						width: '100%'
					}}
					inputLabelStyle={{
						color: formlabel						
					}}
					inputClass={'cus_input'}
					inputStyle={{color: inputlabel}}
				/>
				
				{/* New Password */}
				<FormInput
					label="New Password *"
					placeholder="New Password"
					secureTextEntry="password"
					inputType="input"
					inputId="newpass"
					onChange={(e) => setNewPass(e.target.value)}
					value={newpass}
					containerStyle={{
						marginTop: 15,
						width: '100%',
						textAlign: 'left',
					}}
					inputContainerStyle={{
						backgroundColor: '#ffffff',
						width: '100%'
					}}
					inputLabelStyle={{
						color: formlabel,
					}}
					inputClass={'cus_input'}
					inputStyle={{color: inputlabel}}
				/>
				
				<ul 
					style={{
						color: 'rgb(160, 160, 160)',
						width: '100%',
						fontSize: 14,
					}}
				>
					<li>{`- 8 character minimum.`}</li>
				</ul>
				
				{/* Re New Password */}
				<FormInput
					label="Confirm New Password *"
					placeholder="Confirm New Password"
					secureTextEntry="password"
					inputType="input"
					inputId="renewpass"
					onChange={(e) => setRenewPass(e.target.value)}
					value={renewpass}
					containerStyle={{
						marginTop: 15,
						width: '100%',
						textAlign: 'left',
					}}
					inputContainerStyle={{
						backgroundColor: '#ffffff',
						width: '100%'
					}}
					inputLabelStyle={{
						color: formlabel,
					}}
					inputClass={'cus_input'}
					inputStyle={{color: inputlabel}}
				/>
				
				{/* Button */}
				<FormInput
					label="Update Password"
					secureTextEntry="text"
					inputType="button"
					inputId="button1"
					onClick={()=> onChangePassword()}
					containerStyle={{
						marginTop: 5,
						marginBottom: 25,
						width: '100%'
					}}
					inputClass={'cus_button'}
					inputStyle={{}}
				/>
				
            </div>
									
						</div>
					</div>
					
				</div>
			</div>
		</div>
		<SubFooter />
		</>
        )
    }
	
	if(ellogin === '1')
	{
		return (
			
			<div 
				style={{}}
			>
				{renderForm()}
			</div>			
		)
	}
	else
	{
		return null
	}
};


export default ChangePassword;